import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SignControllerService} from "../../../services/sign/sign-controller.service";
import {OcpUtils} from "../../../../OcpUtils";
import {ShortnamePipe} from "../../../pipes/shortname.pipe";

@Component({
  selector: 'app-bmg',
  templateUrl: './bmg.component.html',
  styleUrls: ['./bmg.component.sass']
})
export class BmgComponent implements OnInit {
  @Output() callback = new EventEmitter<string>();
  iinForm!: FormGroup;
  spinner!: boolean;
  nameOfCompany: any;
  errorOfCompany: any;
  locale: string = document.documentElement.lang;

  constructor(private fb: FormBuilder, public translate : TranslateService,
              private signControllerService: SignControllerService,
              private shortnamePipe: ShortnamePipe
  ) {}

  ngOnInit(): void {
    this.iinForm = this.fb.group({
      iin: ['', [Validators.required, Validators.pattern(OcpUtils.IIN_PATTERN)]],
    });
  }

  clearFields() {
    this.nameOfCompany = '';
    this.errorOfCompany = '';
  }

  changeIIN() {
    this.clearFields();
    let errorIIN = OcpUtils.validateIin(this.iinForm.get('iin')?.value);
    if (!errorIIN) {
      this.spinner = true;
      this.signControllerService.companyInfo(this.iinForm.get('iin')?.value, this.locale).subscribe((result: any)  => {
        this.nameOfCompany = this.shortnamePipe.getShortName(result.name);
      }, () => {
        this.errorOfCompany = this.translate.instant('Компания не найдена');
        this.iinForm.controls['iin'].setErrors({'incorrect': true});
      }).add(()=>this.spinner = false);
    } else {
      this.iinForm.controls['iin'].setErrors({'incorrect': true});
      this.errorOfCompany = this.translate.instant('Неверный формат ИИН');
      this.spinner = false;
    }
  }

  addBMG() {
    let uin = this.iinForm.get('iin')?.value;
    this.spinner = true
    this.signControllerService.bmg(uin).subscribe(()=>{
      this.callback.emit(uin);
    }, () => {
      this.nameOfCompany = '';
      this.errorOfCompany = this.translate.instant('Введенный ИИН не соответствует привязанному номеру телефона. Обновите номер телефона в своем аккаунте на номер, привязанный к указанному ИИН')
      this.iinForm.controls['iin'].setErrors({'incorrect': true});
    }).add(() => this.spinner = false)
  }
}
