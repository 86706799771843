<div class="footer">
  <div class="copyright">{{'© ISMET.KZ, {{year}\}. Все права защищены.' | translate:{year: year} }}</div>
  <div class="footer_right_block">
    <a href="https://screenreader.tilqazyna.kz/#download" class="speech">{{'Экранный диктор' | translate}}</a>
    <div class="footer_right">
      <div class="social-logo">
        <a class="social-telegram" href="https://t.me/ismetplatform" target="_blank">
          <img src="assets/images/social/telegramm.svg" height="26" width="26" alt="">
        </a>
        <a class="social-facebook" href="https://www.facebook.com/ISMET.kz" target="_blank">
          <img src="assets/images/social/facebook.svg" height="26" width="26" alt="">
        </a>
        <a class="social-insta" href="https://instagram.com/ismetplatform?utm_source=ig_profile_share&amp;igshid=68eevmi1lm2f" target="_blank">
          <img src="assets/images/social/insta.svg" height="26" width="26" alt="">
        </a>
        <a class="social-youtube" href="https://www.youtube.com/channel/UCRWwtEMV1Ais1QSn0rgDaBQ" target="_blank">
          <img src="assets/images/social/youtube.svg" height="26" width="26" alt="">
        </a>
      </div>
      <div *ngIf="!isMobile">
        <div class="setup-app">
          <div class="setup-app_item" (click)="showModalDialog(0)" inlineSVG="assets/images/social/gp.svg"></div>
          <div class="setup-app_item" (click)="showModalDialog(1)" inlineSVG="assets/images/social/as.svg"></div>
        </div>

        <p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '400px'}" [draggable]="false" [resizable]="false" styleClass="install-app">
          <div class="dialog-title">{{'Скачайте наше приложение!' | translate}}</div>
          <div class="dialog-desc">
            {{'Отсканируйте QR-код с помощью камеры вашего устройства или нажмите кнопку ниже, чтобы перейти в магазин приложений' | translate}}
          </div>
          <div class="dialog-qr" inlineSVG = "assets/images/social/qr-code-ios.svg"></div>
          <div class="dialog-button" (click)="goToInstall()">{{'Скачать приложение' | translate}}</div>
        </p-dialog>
      </div>

      <div *ngIf="isMobile">
        <div class="setup-app mobile">
          <a class="setup-app_item" href="https://play.google.com/store/apps/details?id=kz.ismet.kt" inlineSVG="assets/images/social/gp.svg"></a>
          <a class="setup-app_item" href="https://apps.apple.com/kz/app/ismet-app/id6478113420" inlineSVG="assets/images/social/as.svg"></a>
        </div>
      </div>
    </div>
  </div>
</div>
