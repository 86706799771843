import {AfterViewInit, ElementRef, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MainService } from "../../../../services/main/main.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../../services/auth/auth.service";
import { Location } from '@angular/common';
import { OcpUtils } from "../../../../../OcpUtils";
import {AuthDialogComponent} from "../../../auth-dialog/auth-dialog.component";
import {HeaderService} from "../../../../services/header/header.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MessageService} from "primeng/api";
declare var ymaps: any;

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.sass']
})

export class CompanyCardComponent implements OnInit, AfterViewInit {  //
  bin: any;
  cardList: any = { list: { content: [] } };
  companyData: any;
  isAuth = false;
  errorNotification: boolean = false;
  mapLoaded = false;  // Флаг для проверки загрузки карты
  showAllTags = false;
  showAllBranches = false;
  reviewModal = false;
  starRating: any = 0;
  loading = false;
  isAnonymous = false;
  starErr = false;
  feedbackText = '';
  images: File[] = [];
  haveFeedbacks = false;
  answerErr = false;
  allRatings: any = 0;
  totalRatingsNumber = 0;
  feedbackList: any = []
  feedbackImagesList: any = [];
  previewImages: any = [];
  breadcrumb: any = [];
  copied = false;
  ratingCharts = {
    five: 0,
    four: 0,
    three: 0,
    two: 0,
    one: 0
  }
  socialIcons: { [key: string]: string } = {
    'facebook.com': 'facebook.svg',
    'fb.com': 'facebook.svg',
    'vk.com': 'vk.svg',
    'instagram.com': 'insta.svg',
    'ok.ru': 'ok.svg',
    'threads.net': 'threads.png',
    'tiktok.com': 'tiktok.png',
    'x.com': 'x.png',
    'twitter.com': 'x.png',
    'youtube.com': 'youtube.svg',
    'wa.me': 'wp.svg',
    'chat.whatsapp.com': 'wp.png',
    't.me': 'telegramm.svg',
    'vb.me': 'viber.png',
    'invite.viber.com': 'viber.png',
    'chats.viber.com': 'viber.png',
    'pinterest.com': 'pinterest.png',
    'ru.pinterest.com': 'pinterest.png',
  };

  @ViewChild(AuthDialogComponent) authDialog!: AuthDialogComponent;
  @ViewChild('reviewsSection') reviewsSection!: ElementRef;


  constructor(
    private route: ActivatedRoute,
    private mainService: MainService,
    private location: Location,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    public headerService: HeaderService,
    private message: MessageService,
    public translate: TranslateService
  ) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.route.params.subscribe(params => this.bin = params);
  }

  ngOnInit() {
    this.getInfo();
    this.getCompanyGoodsById({ index: 1 });
    this.getAverageRating();
    this.getAllFeedbacks();
    // this.getAllImages(); Нужно доработать галерею
  }

  ngAfterViewInit() {
    if (!this.mapLoaded) {
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=" +
        (OcpUtils.isProd() ? "8218205a-dfcb-428c-aab1-f461e30f2206" : "64600027-8ab2-406e-9d10-e66a37c0b5c8");

      s.onload = () => {
        this.mapLoaded = true;
        this.getCompanyData();

      };

      this.elementRef.nativeElement.appendChild(s);
    }
  }

  sendCompanyComment(text: any, feedbackId: any){
    if(text == null || text == ''){
      this.answerErr = true;
    }else{
      this.answerErr = false;
      let data = {
        feedbackId: feedbackId,
        comment: text
      }
      this.loading = true;
      this.mainService.sendCompanyFeedbackComment(data).subscribe((res: any) => {
        this.mainService.allCompanyFeedbacks(this.bin.bin, 0, 4).subscribe((res: any) => {
          this.feedbackList = res.content;
          this.message.add({severity: 'success', summary: 'Success',
            detail: this.translate.currentLang == 'ru' ? 'Ответ успешно опубликован' : 'Ответ успешно опубликован',
            life: 4000});

        })
      })
    }

  }



  openReviewModal(){

    if(this.isAuth){
      this.reviewModal = true;
    }else{
      this.authDialog.openDialog();
    }

  }

  copyBin(): void {
    if (!this.companyData?.bin) return; // Проверяем, есть ли BIN

    navigator.clipboard.writeText(this.companyData.bin).then(() => {
      this.copied = true; // Показываем вторую иконку
      setTimeout(() => this.copied = false, 3000); // Возвращаем через 3 секунды
    }).catch(err => console.error('Ошибка копирования:', err));
  }

  getSocialIcon(url: string): string {
    try {
      const hostname = new URL(url).hostname.replace(/^www\./, '');
      for (const key in this.socialIcons) {
        if (hostname.includes(key)) {
          return `assets/images/social/${this.socialIcons[key]}`;
        }
      }
    } catch (e) {
      console.error('Invalid URL:', url);
    }
    return 'assets/images/social/other.png'; // Иконка по умолчанию
  }

  loadMap(filial: any) {
    if (typeof ymaps === "undefined") {
      console.error("Yandex Maps не загружен!");
      return;
    }

    filial.forEach((element: any, index: number) => {
      ymaps.ready().then(() => {
        const mapId = 'map' + element.id;
        const container = document.getElementById(mapId);

        if (!container) {
          console.warn(`Элемент ${mapId} не найден!`);
          return;
        }

        const myMap = new ymaps.Map(mapId, {
          center: [51.14, 71.42], // Центр карты по умолчанию
          zoom: index === 0 ? 14 : 10, // Первый филиал ближе, остальные дальше
          controls: []
        });

        ymaps.geocode(element.address, { results: 1 }).then((res: any) => {
          const firstGeoObject = res.geoObjects.get(0);
          const bounds = firstGeoObject.properties.get('boundedBy');

          firstGeoObject.options.set('preset', 'islands#darkBlueDotIconWithCaption');
          firstGeoObject.properties.set('iconCaption', firstGeoObject.getAddressLine());

          myMap.geoObjects.add(firstGeoObject);
          myMap.setBounds(bounds, { checkZoomRange: true });

          // **🔹 Наблюдатель за изменением классов (чтобы корректировать зум после показа)**
          const observer = new MutationObserver(() => {
            if (!container.classList.contains('hidden')) {
              myMap.setZoom(14, { duration: 300 }); // Плавное приближение после раскрытия
              observer.disconnect(); // Остановить наблюдение после первого изменения
            }
          });

          observer.observe(container, { attributes: true, attributeFilter: ['class'] });
        });
      });
    });
  }

  getAverageRating(){
    this.mainService.getAvgRating(this.bin.bin).subscribe((res: any) => {
      this.allRatings = res;
      this.totalRatingsNumber = res["5"] + res["4"] + res["3"] + res["2"] + res["1"];
      this.ratingCharts.five = res["5"];
      this.ratingCharts.four = res["4"];
      this.ratingCharts.three = res["3"];
      this.ratingCharts.two = res["2"];
      this.ratingCharts.one = res["1"];
    })
  }

  getAllImages(){
    this.mainService.allFeedbackImages(this.bin.bin, 0 , 8).subscribe((res: any) => {
      this.feedbackImagesList = res.content;
    })
  }

  getAllFeedbacks(){
    this.mainService.allCompanyFeedbacks(this.bin.bin, 0, 4).subscribe((res: any) => {
      this.feedbackList = res.content;
      this.haveFeedbacks = true;

    }, (error) => {
      this.haveFeedbacks = false;
    })
  }

  sendReview(){
    if(this.starRating == 0){
      this.starErr = true;
    }else{

      let data = {
        bin: this.bin.bin,
        grade: this.starRating,
        review: this.feedbackText,
        isAnonymous: this.isAnonymous
      }

      console.log(this.previewImages)

      let formdata = new FormData();
      formdata.append("companyFeedbackRequest", new Blob([JSON.stringify(
          data
        )], {
          type: "application/json"
        }
      ));
      for (let file of this.previewImages) {
        formdata.append('images', file)
      }


      this.mainService.createNewFeedback(formdata).subscribe((res: any) => {
        this.reviewModal = false;
        this.message.add({severity: 'success', summary: 'Success',
          detail: this.translate.currentLang == 'ru' ? 'Отзыв успешно опубликован' : 'Отзыв успешно опубликован',
          life: 4000});
      })
    }
  }

  onFileSelect(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      if (this.images.length < 5) {
        this.addImage(files[i]);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  calcImageWidth(imageCount: number): string {
    return `calc((100% - (7px * ${imageCount} - 1)) / ${imageCount})`;
  }

  onDrop(event: DragEvent): void {

    event.preventDefault();
    if (event.dataTransfer?.files) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        if (this.images.length < 5) {
          this.addImage(event.dataTransfer.files[i]);
        }
      }
    }
  }

  addImage(file: File): void {
    this.images.push(file);
    const reader = new FileReader();
    reader.onload = () => {
      this.previewImages.push(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  removeImage(index: number, event: Event): void {
    event.stopPropagation();
    this.images.splice(index, 1);
    this.previewImages.splice(index, 1);
  }


  calculatePercentage(part: number, total: number): any {
    return ((part / total) * 100).toFixed(1);
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;


      this.getCompanyGoodsById({ index: 0 });
    });
  }

  getCompanyName(): string {
    if (this.companyData && this.companyData.trade_name_ru && this.companyData.trade_name_ru.trim()) {
      return this.translate.currentLang === 'ru'
        ? this.companyData.trade_name_ru
        : this.companyData.trade_name_kz;
    }
    return this.translate.currentLang === 'ru'
      ? this.companyData?.name_ru
      : this.companyData?.name_kz;
  }

  goBack() {
    this.location.back();
  }

  onPageChange(event: any) {
    this.getCompanyGoodsById({ index: 0 }, event.page, event.rows);
  }

  genInp(item: any) {
    return `url('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[0]?.full_path}'), url('/assets/images/main/card-fill.svg')`;
  }

  genInpBanner(item: any) {
    return item.length > 0
      ? '/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + item
      : '/assets/images/main/banner-fill.svg';
  }

  getCompanyGoodsById(event: any, pageNumber = 0, pageSize = 5) {
    if (event.index == 1) this.getCards('', pageNumber, pageSize);
    if (event.index == 2) this.getCards('SERVICE', pageNumber, pageSize);
    if (event.index == 3) this.cardList = [];
  }

  favorite(itemIndex: any) {
    if (this.cardList.list.content[itemIndex].favorite) {
      this.removeFavorite(this.cardList.list.content[itemIndex].id, itemIndex);
    } else {
      this.setFavorite(this.cardList.list.content[itemIndex].id, itemIndex);
    }
  }

  removeFavorite(itemId: any, itemIndex: any) {
    this.mainService.deleteFavorite(itemId).subscribe({
      next: () => {
        this.cardList.list.content[itemIndex].favorite = !this.cardList?.list.content[itemIndex].favorite;
      }
    });
  }

  setFavorite(itemId: any, itemIndex: any) {
    this.mainService.postFavorite(itemId).subscribe({
      next: () => {
        this.cardList.list.content[itemIndex].favorite = !this.cardList?.list.content[itemIndex].favorite;
      }
    });
  }

  getCards(categoryType: any = '', pageNumber = 0, pageSize = 12) {
    this.mainService.getAllCardsWithParams('', '', '', categoryType, this.bin.bin, '', '', pageNumber, 5).subscribe({
      next: (res: any) => {
        this.cardList = res;
      }
    });
  }

  getSafeDescriptionPreview(text: any){
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  scrollToReviews(): void {
    if (this.reviewsSection) {
      this.reviewsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  getCompanyData() {
    this.errorNotification = false;

    this.mainService.getPublicCompanyInfo(this.bin.bin).subscribe({
      next: (res: any) => {
        this.companyData = res;
        this.breadcrumb = [{
          label: 'Главная',
          routerLink: '/'
        },{
          label: this.getCompanyName()
        }];
        this.loadMap(res.filials);
      },
      error: () => {
        this.errorNotification = true;
      }
    });
  }

}
