import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffix'
})
export class SuffixPipe implements PipeTransform {

  transform(value: number, singularWord: string, pluralWord: string, pluralWord2: string): string {
    let result: string;

    // Получение последней цифры числа
    const lastDigit = value % 10;

    // Получение двух последних цифр числа
    const lastTwoDigits = value % 100;

    // Условия для определения правильного окончания слова
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      result = `${value} ${singularWord}`;
    } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
      result = `${value} ${pluralWord}`;
    } else {
      result = `${value} ${pluralWord2}`;
    }

    return result;
  }

}
