<p-dialog [(visible)]="openAddCompany" (visibleChange)="onCloseModal($event)" [modal]="true"
          [dismissableMask]="true" [position]="position" [draggable]="false"
          [resizable]="false" [styleClass]="'sign-dialog'">
  <ng-template pTemplate="content">
    <div class="sign-title">
      <div class="text" [ngClass]="{'none-margin' : roleModel}">
        {{!roleModel ? ('Выберите способ прикрепления компании' | translate) : 'Подтвердите компанию с помощью ЭЦП' | translate}}
      </div>
    </div>
    <div class="dialog-content">
      <div class="desktop" *ngIf="!mobile">
        <div *ngIf="!roleModel" class="sign-tabs">
          <div class="sign-tab" [ngClass]="{'active': tab == 'eds'}" (click)="tab = 'eds'">{{'Через ЭЦП' | translate}}</div>
          <div class="sign-tab" [ngClass]="{'active': tab == 'iin'}" (click)="tab = 'iin'">{{'Через ИИН' | translate}}</div>
          <div class="sign-tab" [ngClass]="{'active': tab == 'qr'}" (click)="clickQr()">{{'Через QR-код' | translate}}</div>
        </div>
        <div class="sign-tabs-content">
          <div class="sign-tab-content" [ngClass]="{'active': tab == 'eds'}">
            <app-eds [dataForSign]="stringForSign" [bin]="binForVerify" (callback)="successAddCompany($event)" [openAddCompany]="true"></app-eds>
          </div>
          <div class="sign-tab-content" [ngClass]="{'active': tab == 'iin'}">
            <app-bmg (callback)="successAddCompany($event)"></app-bmg>
          </div>
          <div class="sign-tab-content" [ngClass]="{'active': tab == 'qr'}">
            <app-qr (callback)="successAddCompany($event)" [bin]="binForVerify" [openAddCompany]="true"></app-qr>
          </div>
        </div>
      </div>
      <div class="mobile" *ngIf="mobile && !roleModel">
        <app-qr (callback)="successAddCompany($event)" [mobile]="true" [openAddCompany]="true"></app-qr>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="openSign" (visibleChange)="onCloseModal($event)" [modal]="true"
          [dismissableMask]="true" [position]="position" [draggable]="false"
          [resizable]="false" [styleClass]="'sign-dialog'">
  <ng-template pTemplate="content">
    <div class="dialog-content">
      <div class="sign-title">
        <div class="text">{{mobile ? ('Подписать через приложение «eGov»' | translate) : 'Выберите способ подписания' | translate}}</div>
      </div>
      <div class="desktop" *ngIf="!mobile">
        <div class="sign-tabs">
          <div class="sign-tab" [ngClass]="{'active': tab == 'eds'}" (click)="tab = 'eds'">{{'ЭЦП' | translate}}</div>
          <div class="sign-tab" [ngClass]="{'active': tab == 'qr'}" (click)="clickQrSign()">{{'eGov QR' | translate}}</div>
        </div>
        <div class="sign-tabs-content">
          <div class="sign-tab-content" [ngClass]="{'active': tab == 'eds'}">
            <app-eds [dataForSign]="stringForSign" [bin]="binForVerify"
                     (callback)="successSignXml($event)" (cmsCallback)="successSignCms($event)" [format]="format"></app-eds>
          </div>
          <div class="sign-tab-content" [ngClass]="{'active': tab == 'qr'}">
            <app-qr (callback)="successSign($event)" [mobile]="mobile" [dataForSign]="dataForSign" [endpoint]="qrMobileEndpoint" [bin]="binForVerify"></app-qr>
          </div>
        </div>
      </div>
      <div class="mobile" *ngIf="mobile">
        <app-qr (callback)="successSign($event)" [mobile]="mobile" [dataForSign]="dataForSign" [endpoint]="qrMobileEndpoint" [bin]="binForVerify"></app-qr>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="successAdd" [modal]="true" [draggable]="false" [styleClass]="'sign-dialog'"
          [dismissableMask]="true" [position]="position" [resizable]="false">
  <div class="success-add-block">
    <div inlineSVG="assets/images/signing/success.svg" class="success-icon"></div>
    <div class="success-title">
      {{this.binForVerify ? ('Компания успешно подтверждена' | translate) : ('Компания успешно прикреплена' | translate)}}
    </div>
    <div class="success-button" (click)="callBack()">{{'Хорошо' | translate}}</div>
  </div>
</p-dialog>

<p-dialog [(visible)]="successSignDialog" [modal]="true" [draggable]="false" [styleClass]="'sign-dialog'"
          [dismissableMask]="true" [position]="position" [resizable]="false">
  <div class="success-add-block">
    <div inlineSVG="assets/images/signing/success-circle.svg" class="success-icon"></div>
    <div class="success-title-mb12">{{'Подписано' | translate}}</div>
    <div class="success-add-text">
      {{'Документ №{{num}\} успешно подписан' | translate :{num: docNums} }}
    </div>
  </div>
</p-dialog>
