import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-install-app',
  templateUrl: './install-app.component.html',
  styleUrls: ['./install-app.component.sass']
})
export class InstallAppComponent implements OnInit {
  displayModal: boolean = false;
  linkApp: string = '';
  isMobile: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    this.isMobile = this.detectMob();
  }

  showModalDialog(id: number) {
    this.displayModal = true;
    this.linkApp = id == 0 ? 'https://play.google.com/store/apps/details?id=kz.ismet.kt' : 'https://apps.apple.com/kz/app/ismet/id1449003074?platform=iphone'
  }

  goToInstall() {
    window.location.href = this.linkApp
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
