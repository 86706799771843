import {Component, HostListener, OnInit} from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {CookieService} from "ngx-cookie-service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.sass']
})
export class FavoriteComponent implements OnInit{


  callback = false;

  favoriteItemsList: any = {totalElements: 0};

  currentPage: any = 0;

  startSale = false;

  currentFav: any;

  pageNumber = 0;

  constructor(private mainService: MainService, private cookieService: CookieService, public translate : TranslateService) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (this.isNearBottom()) {
      // Вызывайте ваш метод или обработчик события при приближении к концу страницы
      this.loadMoreData();
    }
  }

  isNearBottom(): boolean {
    if(window.innerWidth > 760) {
      return false;
    }
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0;

    // Порог, близкий к концу страницы, когда считается, что пользователь достиг конца
    const threshold = 200;

    return windowHeight + scrollPosition >= documentHeight - threshold;
  }

  loadMoreData(): void {
    // Здесь вы можете загрузить дополнительные данные или выполнить другие действия
    if(this.favoriteItemsList.totalPages > this.pageNumber){
      this.mainService.getFavList(this.pageNumber)
        .subscribe({
          next: (res: any) => {
            this.favoriteItemsList.content = [ ...this.favoriteItemsList.content, ...res.content ];
          },
          complete: () => {

          },
          error: (err: any) => {

          }
        });
      this.pageNumber = this.pageNumber + 1;
    }
  }

  ngOnInit() {
    this.getFavoriteItems();
  }

  close(){
    this.callback = false;
  }

  closeSale(){
    this.startSale = false;
  }

  genInp(item: any) {
    return `url('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[0].full_path}')`
  }

  order(item: any){
    document.body.style.overflow = 'hidden';
    this.callback = true;
    this.currentFav = item;
  }


  getFavoriteItems(pageNumber: any = '0'){
    this.mainService.getFavList(pageNumber)
      .subscribe({
        next: (res: any) => {
          this.favoriteItemsList = res;
        },
        complete: () => {

        },
        error: (err: any) => {

        }
      });
  }

  removeFavorite(itemId: any, itemIndex: any){
    this.mainService.deleteFavorite(itemId)
      .subscribe({
        next: (res: any) => {
          this.getFavoriteItems(this.currentPage);
        },
        complete: () => {

        },
        error: (err: any) => {

        }
      });
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }

    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы

    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }

  changePage(event: any){
    this.getFavoriteItems(event.page)
    this.currentPage = event.page;
  }

}
