import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OcpUtils} from "../../../../OcpUtils";
import {SignControllerService} from "../../../services/sign/sign-controller.service";

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.sass']
})
export class QrComponent implements OnInit {
  @Output() callback = new EventEmitter<string>();
  @Input() mobile!: boolean;
  @Input() bin!: string | undefined;
  @Input() openAddCompany!: boolean;
  @Input() dataForSign: any;
  @Input() endpoint: any;

  error: any;
  base64qr: any;
  spinner!: boolean;
  end: boolean = false;
  mobileSignLink!: string;
  mobileBusinessLink!: string;
  socket: any;
  hash: any;
  hashBusiness: any;
  iin: boolean = false;

  constructor(private signControllerService: SignControllerService) {}

  ngOnInit(): void {
    if (this.mobile && this.openAddCompany) {
      this.createQrForAddCompany()
    } else if(this.mobile || this.dataForSign?.docIdentifiers?.length > 1 || this.endpoint){
      this.createQrForSign()
    }
  }

  startQR() {
    this.end = false;
    if(this.openAddCompany) {
      this.createQrForAddCompany();
    } else {
      this.createQrForSign()
    }
  }

  createQrForAddCompany() {
    this.error = '';
    this.spinner = true;
    this.signControllerService.attachQR(this.mobile, this.bin).subscribe(data => {
      if (this.mobile) {
        this.mobileSignLink = this.generateMobileLink(data);
        this.hash = data.hash;
        this.socket = data.websocketURL;
      } else {
        this.showQrForAttachVerify(data);
      }
    }).add(()=> this.spinner = false)

    if(this.mobile) {
      this.signControllerService.attachQR(this.mobile, this.bin, true).subscribe(data => {
        this.mobileBusinessLink = this.generateMobileLink(data);
        this.hashBusiness = data.hash;
      })
    }
  }

  showQrForAttachVerify(data: any) {
    this.base64qr = data.qrCode;
    this.initWebSocked(data.websocketURL, data.hash);
  }

  createQrForSign() {
    this.iin = !OcpUtils.validateIin(this.bin);
    this.error = '';
    this.spinner = true;
    if(this.endpoint) {
      this.signControllerService.universalQrForSign(this.endpoint, this.mobile, this.dataForSign).subscribe(data => {
        this.handleResponse(data)
      }).add(() => this.spinner = false)
    } else {
      this.signControllerService.qrForSign(this.mobile, this.dataForSign).subscribe(data => {
        this.handleResponse(data)
      }).add(() => this.spinner = false)
    }
  }

  handleResponse(data: any) {
    if(this.mobile) {
      this.mobileSignLink = this.generateMobileLink(data);
      this.initWebSocked(data.websocketURL, data.hash);
    } else {
      this.showQrForAttachVerify(data);
    }
  }

  initWebSocked(url: any, hash: any) {
    this.end = false;
    let socket = new WebSocket(url);
    socket.onopen = () => {
      socket.send(hash);
    };
    socket.onmessage = (event: any) => {
      try {
        let data = JSON.parse(event.data);
        if (data.status == 'OK') {
          this.callback.emit(data.uin);
          socket.close();
        }
      } catch (e) {
        if (event.data == 'REQUEST END') {
          socket.close();
          this.end = true;
        }
        if (event.data == 'STAT-GOV NOT FOUND' || event.data == 'SIGNATURE AND SIGNER UIN ARE DIFFERENT') {
          this.error = event.data;
        }
        if (event.data == 'OK' && !this.openAddCompany) {
          this.callback.emit();
          socket.close();
        }
      }
    };
  }

  generateMobileLink(data?: any) {
    let userAgent = navigator.userAgent;
    let android = /android/i.test(userAgent);
    let ios = /iPad|iPhone|iPod/.test(userAgent);
    if (android || ios) {
      if (android) {
        return data.android;
      } else {
        return data.ios;
      }
    }
  }

  openSocket(hash: any) {
    this.initWebSocked(this.socket, hash);
  }
}
