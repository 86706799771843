import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {QrComponent} from './qr/qr.component';
import {EdsComponent} from './eds/eds.component';
import {HeaderService} from "../../services/header/header.service";

@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html',
  styleUrls: ['./signing.component.sass']
})
export class SigningComponent implements OnInit {
  @Output() callback = new EventEmitter<any>();
  @Output() signCallback = new EventEmitter<any>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild(QrComponent) qr!: QrComponent;
  @ViewChild(EdsComponent) eds!: EdsComponent;
  @Input() roleModel: boolean = false;
  mobile: boolean = false;
  dataForSign!: any;
  stringForSign!: string;
  successAdd: boolean = false;
  successSignDialog: boolean = false;
  openAddCompany: boolean = false;
  openSign: boolean = false;
  binForVerify!: string | undefined;
  tab: string = 'eds'
  format: string = 'xml';
  docNums: string = '';
  qrMobileEndpoint!: string;
  uin: any;
  position: any = '';

  constructor(private headerService: HeaderService) {}

  ngOnInit(): void {
    this.mobile = this.detectMob();
    if (this.mobile) {
      this.position = 'bottom';
    }
  }

  detectMob() {
    const toMatch = [
      /Android/i, /webOS/i, /iPhone/i, /iPad/i,
      /iPod/i, /BlackBerry/i, /Windows Phone/i,
      /ismet-mobile/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  successAddCompany(uin: string) {
    this.uin = uin;
    this.successAdd = true;
    this.openAddCompany = false;
  }

  callBack() {
    this.successAdd = false;
    this.callback.emit(this.uin);
    this.headerService.companyAddEmitter.emit()
  }

  openAddCompanyDialog(bin?: string) {
    this.tab = 'eds';
    this.format = 'xml';
    this.binForVerify = bin;
    this.stringForSign = '<xml>addCompany</xml>';
    this.openAddCompany = true;
  }

  clickQr() {
    this.tab = 'qr';
    this.qr.createQrForAddCompany();
  }

  clickQrSign() {
    this.tab = 'qr';
    this.qr.createQrForSign()
  }

  successSign(data: string) {
    this.docNums = this.getDocNum();
    this.successSignDialog = true
    this.openSign = false
    this.signCallback.emit(data);
  }

  successSignXml(signedXml: string[]) {
    if(this.dataForSign.docIdentifiers.length) {
      for (let [index, doc] of this.dataForSign.docIdentifiers.entries()) {
        doc['fileContent'] = signedXml[index];
      }
      this.successSign(this.dataForSign.docIdentifiers);
    }
  }

  successSignCms(signedCms: string) {
    this.successSign(signedCms);
  }

  onCloseModal(event: any) {
    this.visibleChange.emit(event)
    this.eds?.closeWebSocket()
  }

  getDocNum() {
    if(this.docNums) {
      return this.docNums;
    }
    let nums = '';
    if(this.dataForSign?.docIdentifiers) {
      this.dataForSign?.docIdentifiers.forEach((doc: any)=> {
        if(nums) {
          nums+=', '
        }
        nums+=doc.docId;
      })
    }
    return nums;
  }
}
