<div class="block-contacts">
  <p-tabView (onChange)="showContacts = 0; showAddress = 0">
    <p-tabPanel header="{{ 'Contacts' | translate }}">
      <div class="block-contacts__title">{{ 'Contacts' | translate}}</div>
      <div class="block-contacts-tabview">
        <div class="block-contacts-tabview-left">
          <ng-container *ngFor="let contact of contacts, let i = index">
            <div class="block-contacts-tabview-left__content" [ngClass]="{'active' : showContacts == i}" (click)="showRightContentContacts(contact,i)">
              {{ translate.currentLang == 'ru' ? contact.nameRu : contact.nameKz }}
            </div>
          </ng-container>
        </div>
        <hr>
        <div class="block-contacts-tabview-right">
          <ng-container *ngIf="showContacts == 0">
            <div class="block-contacts-tabview-right__title">{{'ForQuestionsAboutWorkingWithThePlatform' | translate}}</div>
            <a href="tel:88000800807">
              <div class="block-contacts-tabview-right__phone">{{'8 800 080 08 07'}} </div>
            </a>
            <a href="https://wa.me/77070000160">
              <div class="block-contacts-tabview-right__whatsApp">{{'+77070000160 (Whatsapp)'}}</div>
            </a>
            <a href="mailto:ccb2b@ismet.kz">
              <div class="block-contacts-tabview-right__mail">{{'ccb2b@ismet.kz'}}</div>
            </a>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-1' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af656995d6f6504d9e8dbad98685a3a8201006ac5edda3540d57fc2e781fbe1a0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showContacts == 1">
            <div class="block-contacts-tabview-right__title">{{'OFDContacts' | translate}}</div>
            <a href="tel:+77272590051">
              <div class="block-contacts-tabview-right__phone">{{'+7 (727) 259 00 51'}} </div>
            </a>
            <a href="tel:88000800051">
              <div class="block-contacts-tabview-right__phone">{{'8 8000 8000 51'}} </div>
            </a>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af656995d6f6504d9e8dbad98685a3a8201006ac5edda3540d57fc2e781fbe1a0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showContacts == 2">
            <div class="block-contacts-tabview-right__title">{{'MarkirovkaContacts' | translate}}</div>
            <a href="tel:88000806565">
              <div class="block-contacts-tabview-right__phone">{{'MarkirovkaNumber' | translate}} </div>
            </a>
            <a href="mailto:mark@ismet.kz">
              <div class="block-contacts-tabview-right__mail">{{'mark@ismet.kz'}}</div>
            </a>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-2' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af656995d6f6504d9e8dbad98685a3a8201006ac5edda3540d57fc2e781fbe1a0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showContacts == 3">
            <div class="block-contacts-tabview-right__title">{{'IssuesOfIntegrationAadvertisingAndCooperation' | translate}}</div>
            <a href="tel:160">
              <div class="block-contacts-tabview-right__phone">{{'160'}} </div>
            </a>
            <a href="mailto:business@ismet.kz">
              <div class="block-contacts-tabview-right__mail">{{'business@ismet.kz'}}</div>
            </a>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af656995d6f6504d9e8dbad98685a3a8201006ac5edda3540d57fc2e781fbe1a0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showContacts == 4">
            <div class="block-contacts-tabview-right__title">{{'PaidHelpService' | translate}}</div>
            <a href="tel:1691">
              <div class="block-contacts-tabview-right__phone">{{'1691'}} </div>
            </a>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af656995d6f6504d9e8dbad98685a3a8201006ac5edda3540d57fc2e781fbe1a0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showContacts == 5">
            <div class="block-contacts-tabview-right__title">{{'IntelligenceNumbers' | translate}}</div>
            <a href="tel:101">
              <div class="block-contacts-tabview-right__phoneFire">{{'SpecNumber-1' | translate}}</div>
            </a>
            <a href="tel:102">
              <div class="block-contacts-tabview-right__phonePolice">{{'SpecNumber-2' | translate}} </div>
            </a>
            <a href="tel:103">
              <div class="block-contacts-tabview-right__phoneAmbulance">{{'SpecNumber-3' | translate}} </div>
            </a>
            <a href="tel:112">
              <div class="block-contacts-tabview-right__phoneRescue">{{'SpecNumber-4' | translate}} </div>
            </a>
          </ng-container>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'AddressesAndDetails' | translate}}">
      <div class="block-contacts__title">{{ 'AddressesAndDetails' | translate}}</div>
      <div class="block-contacts-tabview">
        <div class="block-contacts-tabview-left">
          <ng-container *ngFor="let addr of address, let i = index">
            <div class="block-contacts-tabview-left__content" [ngClass]="{'active' : showAddress == i}" (click)="showRightContentAddress(addr,i)">
              {{ translate.currentLang == 'ru' ? addr.nameRu : addr.nameKz }}
            </div>
          </ng-container>
        </div>
        <hr>
        <div class="block-contacts-tabview-right">
          <ng-container *ngIf="showAddress == 0">
            <div class="block-contacts-tabview-right__title">{{'Address-1' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ51601A871001265171</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A7033c21ea5152a1ae43b3c72e7da42037745dfab497e05ffea658a9d577fc82d&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 1">
            <div class="block-contacts-tabview-right__title">{{'Address-2' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ10601A191000402061</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A8652ecbe84a5da95f7b0fbf952d5736a3ce0bf6a79d44ea3798b3797a67d43a7&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 2">
            <div class="block-contacts-tabview-right__title">{{'Address-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ87601A321000226311</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A5eab1b9b598b63a43c34a72f2ddc93772105a16d3de086d9d080647e6b4cb12a&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 3">
            <div class="block-contacts-tabview-right__title">{{'Address-4' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ65601A861001672271</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af6ffc1d2d2e06129a8634c2c5f33d3105146db8172ff6714424fc00307e93ca3&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 4">
            <div class="block-contacts-tabview-right__title">{{'Address-5' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ67601A311000139711</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab4d9be4042bfd1f016716bba294a41f90253415458e3a7b515f323f06594b3da&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 5">
            <div class="block-contacts-tabview-right__title">{{'Address-6' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ73601A251000416541</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A232a83731ae75c931132ba51ae4065f7c5cebad6d4c0056f6378570c2a8fb875&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 6">
            <div class="block-contacts-tabview-right__title">{{'Address-7' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ55601A221000378891</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac630f8d3c16e99702780dafae27554999ddcbdbd923bb230d368fc4adb962a60&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 7">
            <div class="block-contacts-tabview-right__title">{{'Address-8' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ11601A151000311641</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A02e7607647fc55006a2469f67584e0b88bd90cbb33141b453ee59fd1ada7f697&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 8">
            <div class="block-contacts-tabview-right__title">{{'Address-9' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ36601A241000291641</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A8b8d7c3cd8b0e7009cd8101a138cab3f51c0693ada1b433798d11937825c3ce5&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 9">
            <div class="block-contacts-tabview-right__title">{{'Address-10' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ57601A261000120251</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Acf8a5478f296cc5fcc9d8ee6660e307ffb2a2ad691cc955e59ef163e0bd2329c&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 10">
            <div class="block-contacts-tabview-right__title">{{'Address-11' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ88601A291000162711</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A84d144c477dc0bbd158cc95df2d5099ce24c1ae325a8e64ec522660f7b6afcbb&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 11">
            <div class="block-contacts-tabview-right__title">{{'Address-12' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ90601A161000142651</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa80b14cb2ddf0ec74f6bad9e7e47b3135b5fb9318f450d701ae7ae1507168e8b&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 12">
            <div class="block-contacts-tabview-right__title">{{'Address-13' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ88601A291000162711</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A7e06dde979d3edc880838066dd23e37d5c0d5c48190248803a09b17afed24528&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 13">
            <div class="block-contacts-tabview-right__title">{{'Address-14' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ57601A201000142661</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A4728a2232abc9df0125f87051534f9f3de8ec7f3547d157bc7046ba6fa1d53d0&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 14">
            <div class="block-contacts-tabview-right__title">{{'Address-15' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ82601A121000192461</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A95e1ef3546957be10cbb95f282131cd2539d3608df628f79639d67bf7bd795ec&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 15">
            <div class="block-contacts-tabview-right__title">{{'Address-16"' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ31601A231000209621</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A7ac9d825f221d19c6bdb8fb9470ff02d7bb508f990e6bc658909065ee8bfc6e5&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 16">
            <div class="block-contacts-tabview-right__title">{{'Address-17' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ77601A181000401971</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A959887385cedeff7f0b2799aefb4fc2f37b9439a9ba0cc4a53b4b974a0c9d88c&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
          <ng-container *ngIf="showAddress == 17">
            <div class="block-contacts-tabview-right__title">{{'Address-18' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'WorkTime-3' | translate}}</div>
            <div class="block-contacts-tabview-right__dateWork">{{'Weekend' | translate}}</div>
            <div class="block-contacts-tabview-right__title-padding">{{'Requisites' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-1' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-2' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-3' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-4' | translate}} KZ54601A141000180451</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-5' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-6' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-7' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-8' | translate}}</div>
            <br>
            <div class="block-contacts-tabview-right__text">{{'Requisites-9' | translate}}</div>
            <div class="block-contacts-tabview-right__text">{{'Requisites-10' | translate}}</div>
            <div class="block-contacts-tabview-right__cartYandex">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab9faee330c07385c7a222da19bb0587003080ea8e5d4d70db223d0fd32ddb4ad&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
          </ng-container>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <div class="block-contacts-footer">
    <div class="block-contacts-footer-copyright">{{'© ISMET.KZ, ' + currentDate +'.' | translate}} {{ 'AllRightsReserved' | translate}}</div>
    <div class="block-contacts-footer-social">
      <a href="https://wa.me/77070000160" target="_blank">
        <img src="/assets/images/main/whatsapp_color.svg" class="block-contacts-footer-social__whatsapp" alt="">
      </a>
      <a href="https://instagram.com/ismetplatform?utm_source=ig_profile_share&amp;igshid=68eevmi1lm2f" target="_blank">
        <img src="/assets/images/main/insta.svg" class="block-contacts-footer-social__insta" alt="">
      </a>
      <a href="https://www.facebook.com/ISMET.kz" target="_blank">
        <img src="/assets/images/main/facebook.svg" class="block-contacts-footer-social__facebook" alt="">
      </a>
      <a href="https://www.youtube.com/channel/UCRWwtEMV1Ais1QSn0rgDaBQ" target="_blank">
        <img src="/assets/images/main/youtube.svg" class="block-contacts-footer-social__youtube" alt="">
      </a>
      <a href="https://t.me/ismetplatform" target="_blank">
        <img src="/assets/images/main/telegramm.svg" class="block-contacts-footer-social__telegramm" alt="">
      </a>
      <app-install-app></app-install-app>
    </div>
  </div>
</div>
