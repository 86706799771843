<div class="head-line">
  {{ 'ServiceMenu' | translate }}
</div>
<div class="user-data">
  <div *ngIf="isAuth" class="profile-block">
    <ng-container *ngFor="let item of userData?.companyList" >
      <app-company-image *ngIf="item.mainCompany"
                         [uin]="item.uin"
                         [style]="'width: 48px; height: 48px'"
                         [src]="'assets/images/main/side-menu/big-avatar-filler.svg'" />
    </ng-container>
    <div class="user-name">
      {{ headerService._userData?.userInfo.firstName }} {{ headerService._userData?.userInfo.lastName }}
    </div>
  </div>
  <div *ngIf="!isAuth && !isMobileApp()" class="company-block" (click)="authDialog.openDialog()">
    <img class="mr-3" src="assets/images/main/side-menu/login-icon.svg" alt="">
    {{ 'Вход/регистрация' | translate }}
    <img class="ml-auto" src="assets/images/main/catalog-arrow.svg">
  </div>
  <div *ngIf="isAuth" class="company-block" (click)="companySideBar = true">
    <svg class="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.38084 20.4495H15.1407C18.5376 20.4495 19.146 19.0891 19.3234 17.4329L19.9572 10.673C20.1853 8.61121 19.5938 6.92969 15.9857 6.92969H7.53586C3.92776 6.92969 3.33627 8.61121 3.56441 10.673L4.19815 17.4329C4.3756 19.0891 4.98399 20.4495 8.38084 20.4495Z" stroke="#0D87EF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.38086 6.92976V6.25377C8.38086 4.75814 8.38086 3.5498 11.0848 3.5498H12.4368C15.1408 3.5498 15.1408 4.75814 15.1408 6.25377V6.92976" stroke="#0D87EF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.4508 12.845V13.69C13.4508 13.6984 13.4508 13.6984 13.4508 13.7069C13.4508 14.6279 13.4423 15.38 11.7608 15.38C10.0877 15.38 10.0708 14.6364 10.0708 13.7153V12.845C10.0708 12 10.0708 12 10.9158 12H12.6058C13.4508 12 13.4508 12 13.4508 12.845Z" stroke="#0D87EF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.9148 11.1543C17.9629 12.5739 15.7322 13.4189 13.4507 13.7062" stroke="#0D87EF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.83496 11.3828C5.73618 12.6841 7.88245 13.4699 10.071 13.715" stroke="#0D87EF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {{ 'CompanyControl' | translate }}
    <img class="ml-auto" src="assets/images/main/catalog-arrow.svg">
  </div>
  <div class="lang-block" (click)="langSideBar = true">
    <svg class="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 12H21" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9999 3C14.2511 5.46452 15.5304 8.66283 15.5999 12C15.5304 15.3372 14.2511 18.5355 11.9999 21C9.74875 18.5355 8.46942 15.3372 8.3999 12C8.46942 8.66283 9.74875 5.46452 11.9999 3Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    {{ 'Lang' | translate }}
    <div class="current-lang ml-auto">
      {{ translate.currentLang == 'ru' ? 'Русский' : 'Қазақ тілі'}}
    </div>
    <img class="ml-1" src="assets/images/main/catalog-arrow.svg">
  </div>
  <a *ngIf="isAuth && !isMobileApp()" class="company-block" [href]="logoutLink">
    <svg class="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4.7998L5.60002 4.7998C4.71637 4.7998 4.00003 5.51614 4.00003 6.39979L4.00003 17.5997C4.00003 18.4834 4.71637 19.1997 5.60002 19.1997L8 19.1997" stroke="#D51A52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 17L21 12L16 7" stroke="#D51A52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 12L8.80008 12" stroke="#D51A52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    {{ 'Exit' | translate }}
  </a>
</div>

<p-sidebar [(visible)]="companySideBar" position="right" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
  <div class="header-company">

    <svg (click)="companySideBar = false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4836_20379)">
        <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#6C757D"/>
      </g>
      <defs>
        <clipPath id="clip0_4836_20379">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>

    {{ 'CompanyControl' | translate }}

  </div>
  <div class="company-sidebar-body">
    <div class="company-data">
      <ng-container *ngFor="let item of userData?.companyList" >
        <app-company-image *ngIf="item.mainCompany"
          [uin]="item.uin"
          [style]="'width: 72px; height: 72px'"
          [src]="'assets/images/main/side-menu/big-avatar-filler.svg'" />
        <span *ngIf="item.mainCompany" class="text-center text-sm">
          {{ (translate.currentLang == 'ru' ? item.companyName : item.companyNameKz) | shortname }}
        </span>
        <span *ngIf="item.mainCompany" class="mt-2 uin-info">
          {{ 'IIN' | translate }}/{{'БИН' | translate }}: {{ item?.uin }}
        </span>
      </ng-container>

    </div>
    <span class="text-sm uppercase select-company-header">
      {{ 'choosingACompany' | translate }}
    </span>
    <ng-container *ngFor="let item of userData?.companyList; index as i">
      <div *ngIf="item.companyState == 3" class="company-list-item"  (click)="selectCompany(item, i)">

        <app-company-image [uin]="item.uin" [src]="'assets/images/main/side-menu/avatar-filler.svg'" />
        <div class="company-item-data">
          <div class="company-item-name">
            {{ (translate.currentLang == 'ru' ? item.companyName : item.companyNameKz) | shortname }}
          </div>
          <span class="mt-2 uin-info">
            {{ 'IIN' | translate }}/{{'БИН' | translate }}: {{ item.uin }}
          </span>
        </div>

        <svg *ngIf="item.uin == headerService._currentCompany" class="ml-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM15.22 8.97L10.75 13.44L8.78 11.47C8.63783 11.3375 8.44978 11.2654 8.25548 11.2688C8.06118 11.2723 7.87579 11.351 7.73838 11.4884C7.60097 11.6258 7.52225 11.8112 7.51883 12.0055C7.5154 12.1998 7.58752 12.3878 7.72 12.53L10.22 15.03C10.3606 15.1705 10.5512 15.2493 10.75 15.2493C10.9488 15.2493 11.1394 15.1705 11.28 15.03L16.28 10.03C16.3537 9.96134 16.4128 9.87854 16.4538 9.78654C16.4948 9.69454 16.5168 9.59522 16.5186 9.49452C16.5204 9.39382 16.5018 9.29379 16.4641 9.2004C16.4264 9.10701 16.3703 9.02218 16.299 8.95096C16.2278 8.87974 16.143 8.8236 16.0496 8.78588C15.9562 8.74816 15.8562 8.72963 15.7555 8.73141C15.6548 8.73318 15.5555 8.75523 15.4635 8.79622C15.3715 8.83721 15.2887 8.89631 15.22 8.97Z" fill="#0D87EF"/>
        </svg>

        <svg *ngIf="item.uin != headerService._currentCompany" class="ml-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.002C17.524 22.002 22.002 17.524 22.002 12.001C22.002 6.47702 17.524 1.99902 12 1.99902C6.47605 1.99902 1.99805 6.47702 1.99805 12.001C1.99805 17.524 6.47605 22.002 12 22.002ZM12 20.502C9.74544 20.502 7.58318 19.6064 5.98893 18.0121C4.39469 16.4179 3.49905 14.2556 3.49905 12.001C3.49905 9.74642 4.39469 7.58416 5.98893 5.98991C7.58318 4.39566 9.74544 3.50002 12 3.50002C14.2548 3.50002 16.4172 4.39571 18.0115 5.99006C19.6059 7.5844 20.5015 9.74679 20.5015 12.0015C20.5015 14.2563 19.6059 16.4187 18.0115 18.013C16.4172 19.6073 14.2548 20.503 12 20.503V20.502Z" fill="#8D959D"/>
        </svg>
      </div>
    </ng-container>

  </div>
</p-sidebar>

<p-sidebar [(visible)]="langSideBar" position="bottom" [baseZIndex]="1107" [modal]="true" [showCloseIcon]="false">
  <div class="header-lang">
      {{ 'SelectLang' | translate }}

    <svg (click)="langSideBar = false" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 1L1 15" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 1L15 15" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>
  <div class="px-4">
    <div class="flex align-items-center" (click)="setLang('kz')">
      <input type="radio" class="mr-3" [checked]="translate.currentLang == 'kz'">
      Қазақ тілі
    </div>
    <div class="flex align-items-center" (click)="setLang('ru')">
      <input type="radio" class="mr-3" [checked]="translate.currentLang == 'ru'">
      Русский
    </div>
  </div>
</p-sidebar>

<app-auth-dialog></app-auth-dialog>

<app-nav-bar></app-nav-bar>
