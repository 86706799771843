import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {MainService} from "../main/main.service";
import {HeaderService} from "../header/header.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private pageSubject = new Subject<string>();

  pageEvent = this.pageSubject.asObservable();

  emitPageEvent(event: string) {
    this.pageSubject.next(event);
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get sortBy(): any {
    return this._sortBy;
  }

  set sortBy(value: any) {
    this._sortBy = value;
    this.checkCurrentPage();
  }
  get sortByAsc(): any {
    return this._sortByAsc;
  }

  set sortByAsc(value: any) {
    this._sortByAsc = value;
    this.search();
  }
  get categoryType(): any {
    return this._categoryType;
  }

  set categoryType(value: any) {
    this._categoryType = value;
    this._categoryId = '';
    this.search();
  }

  get priceRange(): any {
    return this._priceRange;
  }

  set priceRange(value: any) {
    this._priceRange = value;
    this.checkCurrentPage();
  }

  get companyBin(): any {
    return this._companyBin;
  }

  set companyBin(value: any) {
    this._companyBin = value;
    this.checkCurrentPage();
  }
  get name(): any {
    return this._name;
  }

  set name(value: any) {
    this._name = value;
    this.checkCurrentPage();
  }
  get categoryId() {
    return this._categoryId;
  }

  set categoryId(value) {
    this._categoryId = value;
    this._categoryType = '';
    this.checkCurrentPage();
  }
  get regionId(): any {
    return this._regionId;
  }

  set regionId(value: any) {
    this._regionId = value;
    this.checkCurrentPage();
  }
  get pageSize(): any {
    return this._pageSize;
  }

  set pageSize(value: any) {
    this._pageSize = value;
  }

  get pageNumber(): any {
    return this._pageNumber;
  }

  set pageNumber(value: any) {
    this._pageNumber = value;
    this.search();
  }

  get cards(): any {
    if(this._cards){
      return this._cards.list;
    }else{
      return 0;
    }
  }

  set cards(value: any) {
    this._cards = value;
    this.checkCurrentPage();
  }

  constructor(private mainService: MainService, private headerService: HeaderService,) {
  }

  public _cards: any = {list:{content:[]}};

  public _categoryId: any;

  public _regionId: any = [];

  public _pageNumber: any = 0;

  public _pageSize: any = 16;

  public _name: any = '';

  public _companyBin: any;

  public _categoryType: any;

  public _priceRange: any = ['',''];

  public _sortByAsc: any;

  public _sortBy: any = '';

  public _loading = true;

  clearAllFilters(){
    this.headerService._catalog.forEach((element: any) => {
      element.active = false;
    });
    this.headerService._catalogPage = 0;
    this._pageNumber = 0;
    this._pageSize = 16;
    this._categoryId = '';
    this._regionId = '';
    this._name = '';
    this._companyBin = '';
    this._categoryType = '';
    this._priceRange = ['', ''];
    this._sortByAsc = '';
    this._sortBy = '';
    this.checkCurrentPage();
  }

  scrollNextPage() {
    this._pageNumber++;
    this.search(true);
  }

  checkCurrentPage(){
    if(this._pageNumber == 0){
      this.search();
    }else{
      this.emitPageEvent('0');
    }
  }

  search(scroll?: boolean){
    this._loading = true;
    this.mainService.getAllCardsWithParams(this._name,this._regionId,this._categoryId, this._categoryType, this._companyBin,this._priceRange[0],this._priceRange[1],this._pageNumber, this._pageSize, this._sortByAsc === 2 ? false : (this._sortByAsc === 1 ? true : (this._sortByAsc === 'rating' ? false : '')), this._sortBy )
      .subscribe({
        next: (res: any) => {
          this.headerService.checkFavItem(res.list.content);
          if(scroll) {
            this._cards.list.content = [ ...this._cards.list.content, ...res.list.content ];
          } else {
            this._cards = res;
          }
          this._loading = false;
        },
        complete: () => {

        },
        error: (err: any) => {
          this._loading = false;
        }
      });
  }



}
