<div class="star-rating">
  <div *ngFor="let star of stars; let i = index" class="star-container">
    <div class="star-background" [ngStyle]="{'height': height, 'width': width}">
      <svg [attr.width]="width" [attr.height]="height"
           viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0789 19.9431C15.3674 20.4752 10.8019 17.1021 9.92745 17.0946C9.05305 17.0873 4.43613 20.3828 3.7329 19.8388C3.02967 19.2947 4.68293 13.7063 4.41947 12.8334C4.156 11.9605 -0.264419 8.38164 0.0124787 7.51327C0.289438 6.6449 5.87672 6.56425 6.58826 6.03212C7.29979 5.50005 9.18479 -0.00734249 10.0592 7.34979e-06C10.9336 0.0074211 12.7334 5.54594 13.4367 6.09002C14.1399 6.63404 19.7253 6.80915 19.9888 7.68206C20.2523 8.55497 15.7773 12.0587 15.5004 12.927C15.2235 13.7954 16.7904 19.4109 16.0789 19.9431Z" fill="#F0F3F4"/>
      </svg>
    </div>
    <div class="star-foreground" [ngStyle]="{'height': height, 'width': getFillPercentage(i)}">
      <svg [attr.width]="getFillWidth(i)" [attr.height]="height"
           viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0789 19.9431C15.3674 20.4752 10.8019 17.1021 9.92745 17.0946C9.05305 17.0873 4.43613 20.3828 3.7329 19.8388C3.02967 19.2947 4.68293 13.7063 4.41947 12.8334C4.156 11.9605 -0.264419 8.38164 0.0124787 7.51327C0.289438 6.6449 5.87672 6.56425 6.58826 6.03212C7.29979 5.50005 9.18479 -0.00734249 10.0592 7.34979e-06C10.9336 0.0074211 12.7334 5.54594 13.4367 6.09002C14.1399 6.63404 19.7253 6.80915 19.9888 7.68206C20.2523 8.55497 15.7773 12.0587 15.5004 12.927C15.2235 13.7954 16.7904 19.4109 16.0789 19.9431Z"/>
      </svg>
    </div>
  </div>
</div>
