<div class="card-container">
  <div (click)="goBack()" class="flex back-wrap w-fit">
    <div class="left-arrow cursor-pointer"></div>
    <div class="back-txt cursor-pointer">{{ 'Back' | translate}}</div>
  </div>
  <ng-container *ngIf="!errorNotification">
    <div class="company-banner">
      <img [src]="genInpBanner(companyData?.banner_img_url)" alt="">
    </div>
    <div class="relative company-header-wrap">
      <div class="company-logo">
        <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+companyData?.avatar_img_url" alt="">
      </div>
      <div class="company-description">
        <div class="company-name">
          {{ (translate.currentLang == 'ru' ? companyData?.name_ru : companyData?.name_kz) | shortname }}
        </div>
        <div class="bin-info">
          {{ 'IINBIN' | translate }}: {{ companyData?.bin }}
        </div>
      </div>
    </div>
    <div>

      <p-tabView (onChange)="getCompanyGoodsById($event)">
        <p-tabPanel [header]="'AboutUs' | translate">
          <div>
            <div class="description-header">{{ 'Description' | translate }}</div>
            <div class="description-txt">
              {{ translate.currentLang == 'ru' ? companyData?.description_ru : companyData?.description_kz }}
            </div>
          </div>
          <div>
            <div class="description-header">{{ 'Address' | translate }}</div>
            <div class="description-txt">
              {{ translate.currentLang == 'ru' ? companyData?.street : companyData?.street }}
            </div>
          </div>
          <div>
            <div class="description-header">{{ 'Contacts' | translate }}</div>
            <div class="description-txt">
              Email: <a href="mailto:{{ companyData?.email }}" target="_blank">{{ companyData?.email }}</a>
            </div>
            <div class="description-txt">
              {{ 'PhoneNumber' | translate }}: <a href="tel:{{ companyData?.phone }}">{{ companyData?.phone }}</a>
            </div>
            <div class="description-txt">
              {{ 'Whatsapp' | translate }}: <a href="https://wa.me/{{ companyData?.whatsapp }}" target="_blank">{{ companyData?.whatsapp }}</a>
            </div>
            <div *ngIf="companyData?.telegram" class="description-txt">
              {{ 'Telegram' | translate }}: <a href="https://t.me/{{ companyData?.telegram }}" target="_blank">{{ companyData?.telegram }}</a>
            </div>
            <div *ngIf="companyData?.instagram" class="description-txt">
              {{ 'Instagram' | translate }}: <a href="https://www.instagram.com/{{ companyData?.instagram }}/" target="_blank">{{ companyData?.instagram }}</a>
            </div>
            <div *ngIf="companyData?.website" class="description-txt">
              {{ 'Site' | translate }}: <a href="{{ companyData?.website }}" target="_blank">{{ companyData?.website }}</a>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [header]="'Goods' | translate">
          <div>
            <div class="goods-items-wrap flex">
              <app-card-template *ngFor="let item of cardList?.list?.content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [header]="'Services' | translate" >
          <div>
            <div class="goods-items-wrap flex">
              <app-card-template *ngFor="let item of cardList?.list?.content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>
            </div>
          </div>
        </p-tabPanel>


      </p-tabView>
    </div>
    <div *ngIf="cardList.totalElements > 12" class="flex align-items-center justify-content-center">
      <div class="flex-1">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="0" [rows]="12" [totalRecords]="cardList?.totalElements" [showFirstLastIcon]="false"></p-paginator>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="errorNotification">
    <div class="block-no-content">
      <div inlineSVG="assets/images/main/no-moderation.svg"></div>
      <div class="text-block">
        <div class="text-no-content">
          {{'noCompanyWithTheSameBINIINWasFoundToFindTheNecessaryInformationUseTheNavigationToolsProvidedOnTheSiteYouCanReturnTo' | translate}}
          <a href="/" class="text-no-content-blue">{{'mainPageOfThe1' | translate}}
            <span *ngIf="translate.currentLang != 'ru'" class="text-no-content">{{'оралуыңызға болады.'}}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

