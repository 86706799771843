import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-site-unavailable',
  templateUrl: './site-unavailable.component.html',
  styleUrls: ['./site-unavailable.component.sass']
})

export class SiteUnavailableComponent implements OnInit {

  constructor(public translate : TranslateService) {
  }

  ngOnInit() {
  }
}
