<div class="qr-attach" *ngIf="!mobile">
  <div class="qr-info">
    <div class="qr-info-block"><div class="info-block_ico" inlineSVG="assets/images/signing/info.svg"></div>{{'Подходит для всех типов компаний' | translate}}</div>
  </div>
  <ng-container *ngIf="!spinner && !end && base64qr">
    <img [src]="'data:image/jpeg;base64,' +base64qr" class="qr-image" alt="qr"/>
  </ng-container>
  <div *ngIf="spinner" class="progress-block">
    <p-progressSpinner ></p-progressSpinner>
  </div>

  <div *ngIf="error" class="error-sign">
    <div *ngIf="error=='STAT-GOV NOT FOUND'">{{'Информация о компании не найдена. Попробуйте позже' | translate}}</div>
    <div *ngIf="error=='SIGNATURE AND SIGNER UIN ARE DIFFERENT'">
      {{'Подпись не соответствует запрашиваемому документу. Убедитесь в правильности выбора компании в приложении egov' | translate}}
    </div>
  </div>
  <ng-container *ngIf="end">
    <img ngSrc="assets/img/resetQR.svg" width="165" height="164" alt="resetQr" class="reset-qr" (click)="startQR()">
  </ng-container>
  <div class="qr-attach-info">{{'Отсканируйте QR-код с помощью соответствующего приложения:' | translate}}</div>

  <div *ngIf="iin || openAddCompany" class="qr-attach-info-pr" [innerHTML]="'ИП и самозанятые – Egov Mobile' | translate: {link: 'https://egov.kz/cms/ru/information/mobile/mobile_application'}"></div>

  <div *ngIf="!iin || openAddCompany" class="qr-attach-info-pr">{{'Юридические лица' | translate}} –
    <span class="qr-attach-info-pr-blue">Egov Business</span>
  </div>
</div>
<!--Прикрепить компанию-->
<div *ngIf="mobile && mobileBusinessLink">
  <a [href]="mobileSignLink" class="btn btn-md primary-btn-color qr-button" (click)="openSocket(hash)">eGov mobile ({{'ИП' | translate}})</a>
  <br>
  <a *ngIf="mobileBusinessLink" [href]="mobileBusinessLink" class="btn btn-md primary-btn-color qr-button" (click)="openSocket(hashBusiness)">eGov business</a>
</div>
<!--Подписать документы-->
<div *ngIf="mobile && !mobileBusinessLink">
  <a [href]="mobileSignLink" class="btn btn-md primary-btn-color qr-button">{{'Подписать' | translate}}</a>
</div>
