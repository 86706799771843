<div class="breadcrumb flex">
  <p-breadcrumb class="max-w-full" *ngIf="showBreadCrumbs" [model]="breadcrumb"></p-breadcrumb>
</div>
<ng-container *ngIf="!errorNotification">
  <form [formGroup]="cardFormGroup">
    <div class="edit-card">
      <p-tabView (onChange)="checkTab($event)">
        <p-tabPanel *ngIf="commentTextAreas.length > 0" [header]="'Reason' | translate">
          <div *ngFor="let area of commentTextAreas">
            <div class="comment-head mb-1">
              {{ 'ModeratorComment' | translate }} {{ area.last_change | date:'dd.MM.yy HH:mm' }}
            </div>
            <div>
            <textarea readonly rows="2" cols="90" class="max-w-full w-6" pInputTextarea
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="area.comment"></textarea>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [header]="'Description' | translate">
          <div class="mb-1 comment-head">Что вы хотите продать?</div>
          <div class="types">
            <div *ngFor="let item of categoryTypes; index as i"
                 class="category-type"
                 [ngClass]="{'active-type': item.active}">
              {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
            </div>
          </div>
          <div class="mb-1 comment-head">{{ 'Name' | translate }}</div>
          <div class="mb-4">
            <input readonly class="card-input w-6" type="text" [value]="cardData?.name_ru" pInputText />
          </div>

          <div class="" *ngFor="let item of cardData?.parent_categories; index as i">
            <div *ngFor="let items of item.child_categories; index as ind">
              <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
                <div class="w-3">
                  <div class="mb-1 comment-head">
                    {{ 'Category' | translate }}
                  </div>
                  <div class="relative">
                    <input readonly class="card-input mb-3" type="text"
                           [value]="translate.currentLang == 'ru' ? item.name_ru : item.name_kz" pInputText />
                  </div>
                </div>
                <div class="mt-3">
                  <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="w-3">
                  <div>
                    <div class="mb-1 comment-head">
                      {{ 'SubCategory' | translate }} {{ 'OnRu' | translate }}
                    </div>
                    <div class="relative">
                      <input readonly class="card-input mb-3" type="text"
                             [value]="translate.currentLang == 'ru' ? items.name_ru : items.name_kz" pInputText />
                    </div>
                  </div>
                </div>
                <div class="relative">
                  <img *ngIf="!items.is_active" (click)="addCategoryDialog(item, 'ru', ind)"
                       class="ml-1 cursor-pointer cat-correct" src="/assets/images/main/pencil-gray.svg">
                  <img *ngIf="!items.is_active" (click)="removeCategoryDialog(item.id)"
                       class="ml-1 cursor-pointer cat-remove" src="/assets/images/main/remove_red.svg">
                </div>
              </div>
            </div>
          </div>

          <div class="comment-head">
            Страна производства
          </div>
          <div class="fake-input">
            {{ cardData?.origin_country?.name_ru }}
          </div>

          <div class="comment-head">
            Производитель
          </div>
          <div class="fake-input" >
            {{ cardData?.manufacturer?.name }}
          </div>

          <div class="">
            <div class="mt-3" style="max-width: 50%;">
              <div class="lg:flex w-full align-items-center gap-2 pricing-wrap">
                <div class="w-full">
                  <div class="mb-1 comment-head">{{ 'Price' | translate }}</div>
                  <p-inputNumber [placeholder]="'SetPrice' | translate" styleClass="card-input mb-3"
                                 class="w-full"
                                 [(ngModel)]="cardData.price"
                                 [ngModelOptions]="{standalone: true}"
                                 mode="decimal"
                                 inputId="price" [useGrouping]="false"> </p-inputNumber>
                </div>
                <div class="mt-3 mobile-hidden">
                  <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
                  </svg>
                </div>
                <div class="w-full">
                  <div>
                    <div class="mb-1 comment-head">{{ 'PriceType' | translate }}</div>
                    <p-dropdown [placeholder]="'SetPriceType' | translate"
                                [(ngModel)]="cardData.is_fix_price"
                                [ngModelOptions]="{standalone: true}"
                                [options]="priceType"
                                styleClass="card-input mb-3"
                                optionValue="value"
                                optionLabel="{{ translate.currentLang == 'ru' ? 'nameRu' : 'nameKz' }}">
                      <ng-template pTemplate="dropdownicon">
                        <img src="assets/images/main/dropdown-arrow.svg" alt="">
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="text-left">
                          {{ translate.currentLang == 'ru' ? item?.nameRu : item?.nameKz }}
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="cardFormGroup.get('type')?.value != 'SERVICE'" class="lg:flex w-full align-items-center gap-2"  style="max-width: 50%;">
            <div class="w-full">
              <div class="mb-1 comment-head">{{ 'MinBatch' | translate }}</div>
              <p-inputNumber [placeholder]="'Необязательно' | translate"
                             class="w-full"
                             styleClass="card-input mb-3" formControlName="min_order_count"
                             mode="decimal" inputId="min_order_count" [useGrouping]="false"> </p-inputNumber>

            </div>
            <div class="mt-3 mobile-hidden">
              <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="w-full">
              <div>
                <div class="mb-1 comment-head">{{ 'Единицы' | translate }}</div>
                <p-dropdown [placeholder]="'Необязательно' | translate"
                            [(ngModel)]="cardData.measurement.id"
                            [ngModelOptions]="{standalone: true}"
                            [options]="batchType"
                            styleClass="card-input mb-3"
                            optionValue="id"
                            optionLabel="{{ translate.currentLang == 'ru' ? 'nameRu' : 'nameKz' }}">
                  <ng-template pTemplate="dropdownicon">
                    <img src="assets/images/main/dropdown-arrow.svg" alt="">
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="text-left">
                      {{ translate.currentLang == 'ru' ? item.nameRu : item.nameKz }}
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div *ngIf="cardFormGroup.get('type')?.value == 'SERVICE'" class="mb-1 comment-head">Как будет предоставляться услуга? (Можно выбрать оба варианта)</div>
          <div *ngIf="cardFormGroup.get('type')?.value == 'SERVICE'" class="types">
            <div *ngFor="let item of serviceType; index as i"
                 class="category-type"
                 [ngClass]="{ 'active-type': item.active }">
              {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
            </div>
          </div>

          <div *ngIf="cardFormGroup.get('type')?.value != 'SERVICE'" class="mb-1 comment-head">У вас есть доставка?</div>
          <div *ngIf="cardFormGroup.get('type')?.value != 'SERVICE'" class="types">
            <div *ngFor="let item of order; index as i"
                 class="category-type"
                 [ngClass]="{'active-type': item.active}">
              {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
            </div>
          </div>

          <div class="city">
            <div class="mb-1 comment-head">
              <span *ngIf="cardData.delivery == 1 && cardData.type != 'SERVICE'">
                {{ 'Куда вы сможете доставить' | translate }}
              </span>
              <span *ngIf="cardData.type == 'SERVICE'">
                {{ 'Регион' | translate }}
              </span>
              <span *ngIf="cardData.delivery == 2 && cardData.type != 'SERVICE'">
                {{ 'Где вы находитесь?' | translate }}
              </span>
            </div>
            <p-chips styleClass="w-6" [disabled]="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="cardData.regions"
                     field="{{ translate.currentLang == 'ru' ? 'region_name_ru' : 'region_name_kz' }}">
              <ng-template pTemplate="removetokenicon"></ng-template>
            </p-chips>
          </div>

          <div *ngIf="cardFormGroup.get('type')?.value != 'SERVICE'" class="mb-1 mt-3 comment-head">Товар в наличии?</div>
          <div *ngIf="cardFormGroup.get('type')?.value != 'SERVICE'" class="types">
            <div *ngFor="let item of stock; index as i"
                 class="category-type"
                 [ngClass]="{'active-type': item.active}">
              {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
            </div>
          </div>


          <div class="comment-head">{{ "Photos" | translate }}</div>
          <div class="photo-wrap flex">
            <div *ngFor="let image of cardData?.images; index as i" class="card-photo flex align-items-center">
              <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+image['full_path']" alt="">
            </div>
            <div *ngIf="cardData?.images?.length == 0" class="card-photo flex align-items-center">
              <div class="img-txt">{{ 'NoPhoto' | translate }}</div>
            </div>
          </div>

          <div class="mb-1 comment-head">{{ 'Description' | translate }}</div>
          <div class="mb-4">
            <div class="description-text">
              <p [innerHtml]="getSafeDescription('ru')">
              </p>
            </div>
          </div>

          <div *ngIf="cardData?.['files'][0]?.full_path" class="mt-3 flex gap-1">
            {{ 'LicenseAndOther' | translate }}:
            <img src="assets/images/main/pdfIcon.svg" alt="">
            <a [download]="cardData?.['files'][0]?.full_path"
               [href]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + cardData?.['files'][0]?.full_path">
              {{ 'License' |  translate }}.pdf
            </a>
          </div>
          <div class="flex gap-1 mt-1 w-6" *ngIf="cardData?.['files'][1]?.full_path">{{ 'OtherDocuments' | translate }}:
            <img src="assets/images/main/pdfIcon.svg" alt="">
            <a class="dop-link" [download]="cardData?.['files'][1]?.full_path"
               [href]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + cardData?.['files'][1]?.full_path">
              {{cardData?.['files'][1]?.full_path}}
            </a>
          </div>
          <div *ngIf="cardData?.['files'][2]?.full_path" class="mt-1 w-6 flex flex-wrap">
            <div *ngFor="let file of cardData?.files; let i = index">
              <div *ngIf="i > 1" class="flex gap-1 w-full">
                <img  src="assets/images/main/pdfIcon.svg" alt="">
                <a class="dop-link" [download]="cardData?.['files'][i]?.full_path"
                   [href]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + cardData?.['files'][i]?.full_path">
                  {{ cardData?.['files'][i]?.full_path }}
                </a>
              </div>
            </div>
          </div>

        </p-tabPanel>
        <p-tabPanel *ngIf="feedback?.content.length > 0" header="Отзывы">
          <div *ngFor="let area of feedback.content" class="mb-4">
            <div class="w-6 flex justify-content-between">
              <div class="text-sm flex align-items-center gap-2 font-medium">
                {{area['user_full_name']}} {{'From' | translate}} {{area['review_date'] | date:'dd.MM.yy HH:mm' }}
                <p-rating [(ngModel)]="area['grade']" [ngModelOptions]="{standalone: true}" [stars]="5" [readonly]="true">
                  <ng-template class="star-fill" pTemplate="onicon">
                    <img src="/assets/images/main/star-filled-icon.svg" width="12px" height="12px" />
                  </ng-template>
                  <ng-template pTemplate="officon">
                    <img class="star" src="/assets/images/main/star-icon.svg" width="12px" height="12px" />
                  </ng-template>
                </p-rating>
              </div>
              <div>
                <img (click)="feedbackToPublic(area['id'])" class="ml-1 cursor-pointer" src="/assets/images/main/check-mark.svg">
                <img (click)="removeComment(area['id'])" class="ml-1 cursor-pointer" src="/assets/images/main/remove_red.svg">
              </div>
            </div>
            <div>
              <textarea readonly rows="2" cols="30" class="w-6" [value]="area['review']" pInputTextarea></textarea>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
      <div class="flex mb-4">
        <div>
          <button (click)="showCorrectModal = true" class="correct-bnt">{{'SendToCorrect' | translate}}</button>
        </div>
        <div>
          <button *ngIf="cardData?.state?.name != 'PUBLIC'" (click)="toPublic()" class="publish-btn">{{ 'Public' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="errorNotification">
  <div class="block-no-content">
    <div inlineSVG="assets/images/main/no-moderation.svg"></div>
    <div class="text-block">
      <div class="text-no-content">
        {{'aCardWithThisIDWasNotFoundToFindTheNecessaryInformationUseTheNavigationToolsProvidedOnTheSiteYouCanReturnTo' | translate}}
        <a href="/" class="text-no-content-blue">{{'mainPageOfThe1' | translate}}
          <span class="text-no-content">{{'orGoToC' | translate}}
            <a href="/moderator" class="text-no-content-blue">{{'personalCabinet' | translate}}
              <span *ngIf="translate.currentLang != 'ru'" class="text-no-content">{{'өтуіңізге болады.'}}</span>
            </a>
          </span>
        </a>
      </div>
    </div>
  </div>
</ng-container>

<p-dialog class="tagDialog" header="" (onHide)="correctComment = ''" [modal]="true" [(visible)]="showCorrectModal" [style]="{width: '29vw'}">
  <div class="text-center dialog-head">
    {{ 'IndicateTheReasonForSendingForCorrection' | translate }}
  </div>
  <div class="drop-wrap">
    <textarea [placeholder]="'WriteReason' | translate"  class="w-full" [(ngModel)]="correctComment" pInputTextarea></textarea>
  </div>
  <div class="mt-2">
    <button (click)="toCorrect()" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'Send' | translate }}
    </button>
    <button (click)="close()" class="tag-dialog-cancel-btn">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" (onHide)="correctComment = ''" [modal]="true" [(visible)]="correctSuccess" [style]="{width: '29vw'}">
  <div class="text-center" inlineSVG="assets/images/main/success.svg"></div>
  <div class="text-center dialog-head">
    {{ 'CardHasBeenSuccessfullySentForCorrection' | translate }}
  </div>
  <div class="mt-2">
    <button (click)="correctSuccess = false" class="accept-btn w-full justify-content-center" pButton type="button">
      {{'Fine' | translate}}
    </button>
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" [modal]="true" [(visible)]="commentAdded" [style]="{width: '29vw'}">
  <div class="text-center">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4359 13.1919L6.87326 60.0569C5.07583 63.2273 6.1421 67.2814 9.25484 69.1121C10.2233 69.6817 11.3196 69.9875 12.4379 70H67.5632C71.1574 69.9598 74.0391 66.9595 73.9996 63.2988C73.9873 62.1599 73.6871 61.0433 73.1278 60.0569L45.5651 13.1918C43.701 10.0617 39.6984 9.06348 36.6251 10.9621C35.7299 11.5152 34.979 12.2801 34.4359 13.1919Z" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 31L40 43" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 56H40.1" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="text-center dialog-head">
    {{ 'CommentAddedSuccessfully' | translate }}!
  </div>
  <!--  <div class="drop-wrap">-->
  <!--    <textarea placeholder="Укажите причину..."  class="w-full" [(ngModel)]="correctComment" pInputTextarea   ></textarea>-->
  <!--  </div>-->
  <div class="mt-2">
    <button (click)="commentAdded = false" class="w-full justify-content-center" pButton type="button">
      {{ 'ContinueModeration' | translate }}
    </button>
    <!--    <button (click)="close()" class="tag-dialog-cancel-btn">-->
    <!--      Отмена-->
    <!--    </button>-->
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" [modal]="true" [(visible)]="succes" [style]="{width: '29vw'}">
  <div class="text-center">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4359 13.1919L6.87326 60.0569C5.07583 63.2273 6.1421 67.2814 9.25484 69.1121C10.2233 69.6817 11.3196 69.9875 12.4379 70H67.5632C71.1574 69.9598 74.0391 66.9595 73.9996 63.2988C73.9873 62.1599 73.6871 61.0433 73.1278 60.0569L45.5651 13.1918C43.701 10.0617 39.6984 9.06348 36.6251 10.9621C35.7299 11.5152 34.979 12.2801 34.4359 13.1919Z" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 31L40 43" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 56H40.1" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="text-center dialog-head">
    {{ 'CardAddedSuccessfully' | translate }}
  </div>
  <!--  <div class="drop-wrap">-->
  <!--    <textarea placeholder="Укажите причину..."  class="w-full" [(ngModel)]="correctComment" pInputTextarea   ></textarea>-->
  <!--  </div>-->
  <div class="mt-2">
    <button [routerLink]="'/moderator'" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'Fine' | translate }}
    </button>
    <!--    <button (click)="close()" class="tag-dialog-cancel-btn">-->
    <!--      Отмена-->
    <!--    </button>-->
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" [modal]="true" [(visible)]="catSucces" [style]="{width: '29vw'}">
  <div class="text-center">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4359 13.1919L6.87326 60.0569C5.07583 63.2273 6.1421 67.2814 9.25484 69.1121C10.2233 69.6817 11.3196 69.9875 12.4379 70H67.5632C71.1574 69.9598 74.0391 66.9595 73.9996 63.2988C73.9873 62.1599 73.6871 61.0433 73.1278 60.0569L45.5651 13.1918C43.701 10.0617 39.6984 9.06348 36.6251 10.9621C35.7299 11.5152 34.979 12.2801 34.4359 13.1919Z" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 31L40 43" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 56H40.1" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="text-center dialog-head">
    {{ 'CategoryAddedSuccessfully' | translate }}
  </div>
  <!--  <div class="drop-wrap">-->
  <!--    <textarea placeholder="Укажите причину..."  class="w-full" [(ngModel)]="correctComment" pInputTextarea   ></textarea>-->
  <!--  </div>-->
  <div class="mt-2">
    <button (click)="catSucces = false" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'ContinueModeration' | translate }}
    </button>
    <!--    <button (click)="close()" class="tag-dialog-cancel-btn">-->
    <!--      Отмена-->
    <!--    </button>-->
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" (onHide)="deleteCommentTxt = ''" [modal]="true" [(visible)]="modalDeleteComment" [style]="{width: '29vw'}">
  <div class="text-center dialog-head">
    {{ 'ContinueModeration' | translate }}

  </div>
  <div class="drop-wrap">
    <textarea  [placeholder]="'WriteReason' | translate"  class="w-full" [(ngModel)]="deleteCommentTxt" pInputTextarea   ></textarea>
  </div>
  <div class="mt-2">
    <button [disabled]="deleteCommentTxt.length == 0" (click)="deleteComment()" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'Send' | translate }}
    </button>
    <button (click)="close()" class="tag-dialog-cancel-btn">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" [modal]="true" [(visible)]="tagEdit" [style]="{width: '29vw'}">
  <div class="text-center dialog-head">
    {{ 'EditKeyword' | translate }}
  </div>
  <div class="dialog-label">
    {{ 'ChangeKeyword' | translate }}
  </div>
  <div class="drop-wrap">
    <p-dropdown class="absolute drop" styleClass="drop" [options]="tags" [(ngModel)]="currentTag" placeholder="" [editable]="true" optionValue="id" optionLabel="name"></p-dropdown>
  </div>
  <div>
    <button (click)="addTag(currentTag.id)" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'AddToDirectory' | translate }}
    </button>
    <button (click)="close()" class="tag-dialog-cancel-btn">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</p-dialog>
<p-dialog class="tagDialog" header="" [modal]="true" [(visible)]="categoryDiadlog" [style]="{width: '29vw'}">
  <div class="text-center dialog-head">
    {{ 'CategoryEdit' | translate }}
  </div>
  <div class="dialog-label">
    {{ 'SelectOrChangeCategory' | translate }} {{ 'ShortRus' | translate }}
  </div>
  <div class="drop-wrap">
    <p-dropdown (onChange)="subcategories = []" (onHide)="subCategory(currentCategory, 0)"  [editable]="true" [filter]="true" [filterValue]="" class="drop" styleClass="drop w-full" [options]="categories" [(ngModel)]="currentCategory" placeholder=""  optionValue="name_ru" optionLabel="{{lang == 'ru' ? 'name_ru' : 'name_kz'}}"></p-dropdown>
  </div>
  <div class="dialog-label mt-4">
    {{ 'SelectOrChangeSubcategory' | translate }} {{ 'ShortRus' | translate }}
  </div>
  <div class="drop-wrap">
    <p-dropdown [editable]="true" [filter]="true" [filterValue]="" class="drop" styleClass="drop w-full" [options]="subcategories" [(ngModel)]="currentSubCategory" placeholder=""  optionValue="name_ru" optionLabel="{{lang == 'ru' ? 'name_ru' : 'name_kz'}}"></p-dropdown>
  </div>
  <div class="dialog-label mt-4">
    {{ 'SelectOrChangeCategory' | translate }} {{ 'ShortKaz' | translate }}
  </div>
  <div class="drop-wrap">
    <p-dropdown [editable]="true" [filter]="true" [filterValue]="" class="drop" styleClass="drop w-full" [options]="categories" [(ngModel)]="currentCategoryKz" placeholder=""  optionValue="name_kz" optionLabel="{{'name_kz'}}"></p-dropdown>
  </div>
  <div class="dialog-label mt-4">
    {{ 'SelectOrChangeSubcategory' | translate }} {{ 'ShortKaz' | translate }}
  </div>
  <div class="drop-wrap">
    <p-dropdown [editable]="true" [filter]="true" [filterValue]="" class=" drop" styleClass="drop w-full" [options]="subcategories" [(ngModel)]="currentSubCategoryKz" placeholder=""  optionValue="name_kz" optionLabel="{{'name_kz'}}"></p-dropdown>
  </div>
  <div>
    <button (click)="addCategory()" class="accept-btn w-full justify-content-center" pButton type="button">
      {{ 'AddToDirectory' | translate }}
    </button>
    <button (click)="close()" class="tag-dialog-cancel-btn">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</p-dialog>
<p-toast class="main-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
