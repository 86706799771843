export class request {
  SOCKET_URL = 'wss://127.0.0.1:13579/';
  callback = null;
  webSocketInstance: any;
  resultSign: any;

  connect(): Promise<WebSocket> {
    if (this.webSocketInstance && this.webSocketInstance.readyState < 2) {
      console.log(`reusing the socket connection [state = ${this.webSocketInstance.readyState}]: ${this.webSocketInstance.url}`);
      return Promise.resolve(this.webSocketInstance);
    }
    return new Promise<WebSocket>((resolve, reject) => {
      this.webSocketInstance = new WebSocket(this.SOCKET_URL);
      this.webSocketInstance.onopen = () => {
        console.log(`socket connection is opened [state = ${this.webSocketInstance.readyState}]: ${this.webSocketInstance.url}`);
        resolve(this.webSocketInstance);
      };
      this.webSocketInstance.onerror = (err: any) => {
        console.error("socket connection error : ", err);
        reject(err);
      };
      this.webSocketInstance.onclose = (event: any) => {
        if (event.wasClean) {
          console.error("socket connection is closed ");
        } else {
          console.log('Connection error');
        }
        console.log(`Code: ${event.code} Reason: ${event.reason}`);
      };
    });
  }

  // @ts-ignore
  async request() {
    const signInfo = {
      module: "kz.gov.pki.knca.basics",
      method: "sign",
      args: {
        allowedStorages: ["PKCS12"],
        format: "xml",
        data: '<xml>addCompany</xml>',
        signingParams: {decode: "true", encapsulate: "true", digested: "false", tsaProfile: null},
        signerParams: {
          extKeyUsageOids: [],
          iin: '',
          bin: '',
          chain: null,
          serialNumber: ''
        },
        locale: "ru"
      }
    };

    try {
      const webSocket = await this.connect();
      webSocket.send(JSON.stringify(signInfo));
      return await new Promise((resolve, reject) => {
        webSocket.onmessage = ({data}) => {
          let response = JSON.parse(data);
          if (response != null) {
            const responseStatus = response['status'];
            if (responseStatus === true) {
              const responseBody = response['body'];
              if (responseBody != null) {
                if (responseBody.hasOwnProperty('result')) {
                  this.resultSign = responseBody.result;
                }
              }
            } else if (responseStatus === false) {
              const responseCode = response['code'];
              alert(responseCode);
            }
          }
          resolve(response);
        };
      });
    } catch (err) {
      console.log(err);
    }
  }
}
