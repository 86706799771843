import { Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import {MainService} from "../../../../services/main/main.service";
import {ActivatedRoute} from "@angular/router";
import { DatePipe } from '@angular/common';
import {HeaderService} from "../../../../services/header/header.service";
import {MessageService} from "primeng/api";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-tu-order',
  templateUrl: './tu-order.component.html',
  styleUrls: ['./tu-order.component.sass']
})
export class TuOrderComponent implements OnInit{

  showSvg: boolean[];

  @ViewChild('fileInput') fileInput!: ElementRef;

  cardId: any;
  cardInfo: any = {};
  statusSteps: any;

  constructor(private main: MainService,private route: ActivatedRoute, private datePipe: DatePipe,
              public translate: TranslateService,
              private headerService: HeaderService, private message: MessageService, private http: HttpClient) {
    this.showSvg = new Array(13).fill(true);
    this.route.params.subscribe(params => this.cardId = params);

    this.translate.get(['Заявка сформирована','Ожидает КП','КП на рассмотрении','Заключение договора']).subscribe((translations: any) => {
      this.statusSteps = [
        {
          label: translations['Заявка сформирована'],
          routerLink: 'personal',
          visible: false
        },
        {
          label: translations['Ожидает КП'],
          routerLink: 'personal',
          visible: true

        },
        {
          label: translations['КП на рассмотрении'],
          routerLink: 'personal'
        },
        {
          label: translations['Заключение договора'],
          routerLink: 'personal'
        }
      ]
    })
  }


  rejectApplication = false;
  rejected = false;

  KpFileTypeErr = false;
  KpFileSizeErr = false;

  loading = false;

  KpHave = 0;
  AppHave = 0;

  price: any;
  duration: any = '';

  AppFileTypeErr = false;
  AppFileSizeErr = false;

  priceErr = 0;
  durationErr = false;

  tuId: any;
  tuIsAvailable = false;



  activeIndex: any;

  agreement = false;
  KpAndAppSended = false;

  KPfile: any;
  Appfile: any;

  ngOnInit(){
    this.getCardInfo();
  }

  copy(data: any, index: any){
    if(this.showSvg[index]){
      navigator.clipboard.writeText(data);
      this.showSvg[index] = false;
      setTimeout(() => {
        this.showSvg[index] = true;
      }, 2000);
    }
  }

  getCardInfo(){
    this.loading = true;
    this.main.getTUInfo(this.cardId.id).subscribe((res: any) => {
      this.loading = false;
      this.cardInfo = res;
      let date = this.datePipe.transform(res.orderDate, 'dd.MM.yyyy HH:mm');
      this.translate.get(['Заявка сформирована']).subscribe((translations: any) => {
        this.statusSteps[0].label = translations['Заявка сформирована'];
      })
      this.statusSteps[0].label = this.statusSteps[0].label + ' ' + date;
      if(res.state == 'NEW'){
        this.activeIndex = 0;
      }
      if(res.state == 'WAITING_OFFER'){
        this.activeIndex = 1;
        this.getTUFileId();
      }
      if(res.state == 'REJECTED'){
        this.statusSteps.splice(1,3);
        this.translate.get(['Отклонена']).subscribe((translations: any) => {
          this.statusSteps.push({label: translations['Отклонена']});
        })

        this.activeIndex = 1;
      }
      if(res.state == 'IN_WORK'){
        this.activeIndex = 2;
        this.getTUFileId();
      }
      if(res.state == 'COMPLETE'){
        this.activeIndex = 3;
        this.getTUFileId();
      }

    })
  }

  downloadFileBlob(url: string): Observable<Blob> {
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadFile(url: any, filename: any): void {
    this.downloadFileBlob(url).subscribe((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  openFile(url: any): void {
    this.downloadFileBlob(url).subscribe((res) => {
      let blob = new Blob([res],{type: "application/pdf"});
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  reject(){
    this.main.rejectApplication(this.cardId.id, '').subscribe((res: any) => {
      this.rejected = true;
    })
  }

  onFileSelected(event: Event, type: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if(type == 'app'){
        this.Appfile = file;
      }else{
        this.KPfile = file;
      }

      if(type == 'app'){
        if(file.size > 31457280){
          this.AppFileSizeErr = true;
          this.Appfile = undefined;
        }else{
          this.AppFileSizeErr = false;
          this.AppHave = 0;
        }
      }else{
        if(file.size > 31457280){
          this.KpFileSizeErr = true;
          this.KPfile = undefined;
        }else{
          this.KpFileSizeErr = false;
          this.KpHave = 0;
        }
      }


      if (file.type === 'application/pdf') {
        this.uploadFile(file);
        if(type == 'app'){
          this.AppFileTypeErr = false;
          this.AppHave = 0;
        }else{
          this.KpFileTypeErr = false;
          this.KpHave = 0;
        }
      } else {
        if(type == 'app'){
          this.AppFileTypeErr = true;
          this.Appfile = undefined;
        }else{
          this.KpFileTypeErr = true;
          this.KPfile = undefined;
        }
      }
    }
  }

  onDragOver(event: DragEvent, drg: any): void {
    event.preventDefault();
    event.stopPropagation();
    drg.classList.add('dragover');
  }

  onDragLeave(drg: any){
    drg.classList.remove('dragover');
  }

  onDrop(event: DragEvent, type: any): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    target.classList.remove('dragover');

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];

      if(type == 'app'){
        this.Appfile = file;
      }else{
        this.KPfile = file;
      }

      if(type == 'app'){
        if(file.size > 31457280){
          this.AppFileSizeErr = true;
          this.Appfile = undefined;
        }else{
          this.AppFileSizeErr = false;
          this.AppHave = 0;
        }
      }else{
        if(file.size > 31457280){
          this.KpFileSizeErr = true;
          this.KPfile = undefined;
        }else{
          this.KpFileSizeErr = false;
          this.KpHave = 0;
        }
      }


      if (file.type === 'application/pdf') {
        this.uploadFile(file);
        if(type == 'app'){
          this.AppFileTypeErr = false;
          this.AppHave = 0;
        }else{
          this.KpFileTypeErr = false;
          this.KpHave = 0;
        }
      } else {
        if(type == 'app'){
          this.AppFileTypeErr = true;
          this.Appfile = undefined;
        }else{
          this.KpFileTypeErr = true;
          this.KPfile = undefined;
        }
      }
      event.dataTransfer.clearData();
    }
  }

  sendKp(){

    let formdata = new FormData();

    formdata.append("createOfferRequest", new Blob([JSON.stringify(
        {
          id: this.cardInfo.id,
          bin: this.headerService._currentCompany,
          priceOfWork: this.price,
          durationOfConstruction: this.duration
        }
      )], {type: "application/json"}
    ));

    if(this.KPfile){
      const fileExtension = this.KPfile.type.split('/')[1];
      formdata.append('offer', this.KPfile, `offer.${fileExtension}`);
      this.KpHave = 0;
    }else{
      this.KpHave = 1;
    }

    if(this.Appfile){
      const fileExtension = this.Appfile.type.split('/')[1];
      formdata.append('contract', this.Appfile, `contract.${fileExtension}`);
      this.AppHave = 0;
    }else{
      this.AppHave = 1;
    }

    if(this.price == undefined){
      this.priceErr = 1;
    }else if(this.price == 0){
      this.priceErr = 2;
    }else{
      this.priceErr = 0;
    }

    if(this.duration.length == 0){
      this.durationErr = true;
    }else{
      this.durationErr = false;
    }

    if(this.duration.length != 0 && this.priceErr == 0 && this.AppHave == 0 && this.KpHave == 0 && !this.AppFileTypeErr && !this.AppFileSizeErr && !this.KpFileSizeErr && !this.KpFileTypeErr){
      this.loading = true;
      this.main.sendTUOffer(formdata).subscribe((res: any) => {
        this.KpAndAppSended = true;
        this.loading = false;
        this.getCardInfo();
      },(err: any) => {
        this.message.add({severity: 'error', summary: 'Success', detail: err.error.description_ru, life: 4000});
        this.loading = false;
      })
    }else{
      this.loading = false;
    }

  }

  getTUFileId() {
    this.main.getTUId(this.cardId.id).subscribe({
      next: (res: any) => {
        this.tuId = res;
        this.tuIsAvailable = true;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  takeToWork(){
    this.loading = true;
    this.main.takeTUtoWork(this.cardId.id).subscribe((res: any) => {
      this.getCardInfo();
    })
  }

  private uploadFile(file: File): void {
    // Здесь можно реализовать логику загрузки файла на сервер
    console.log('Uploading file:', file);
  }

  protected readonly environment = environment;
}
