import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SigningComponent} from './signing.component';
import {DialogModule} from 'primeng/dialog';
import {EdsComponent} from './eds/eds.component';
import {BmgComponent} from './bmg/bmg.component';
import {QrComponent} from './qr/qr.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {InputMaskModule} from 'primeng/inputmask';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {ShortnamePipe} from "../../pipes/shortname.pipe";


@NgModule({
  declarations: [
    SigningComponent,
    EdsComponent,
    BmgComponent,
    QrComponent
  ],
  exports: [
    SigningComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    InputMaskModule,
    TranslateModule,
    NgOptimizedImage,
    InlineSVGModule
  ],
  providers: [
    ShortnamePipe
  ]
})
export class SigningModule {
}
