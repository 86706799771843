import {Component, OnInit} from '@angular/core';
import {MainService} from "../../../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-create-ls',
  templateUrl: './create-ls.component.html',
  styleUrls: ['./create-ls.component.sass']
})
export class CreateLsComponent implements OnInit{

  constructor(private main: MainService, public translate: TranslateService) {
  }

  items = [{ label: 'Маркет', routerLink: '/' },{ label: 'Проверка данных'}];

  userData: any;

  city: any;

  UAdress: any = '';

  searchText: any;


  ngOnInit(){

    this.getUserInfo();
  }

  getCompany(bin: any){
    this.main.companyAddData(bin)
      .subscribe({
        next: (res: any) => {
        },
        complete: () => {

        },
        error: (err: any) => {
        }
      })
  }

  searchData(){
    this.main.getUA(
      {
        from: "0",
        q: ''+this.UAdress,
        size: "30"
      }
    )
      .subscribe({
        next: (res: any) => {
          this.userData = res;
          this.getCompany(res.companyList[0].uin);
        },
        complete: () => {

        },
        error: (err: any) => {

        }
      });
  }

  getFullAddress(){
    this.main.getBPMNaddress(this.searchText)
      .subscribe({
        next: (res: any) => {
          this.UAdress = res.searchResultList;
        },
        complete: () => {

        },
        error: (err: any) => {

        }
      });
  }

  getUserInfo(){
    this.main.userInfo()
      .subscribe({
        next: (res: any) => {
          this.userData = res;
          this.getCompany(res.companyList[0].uin);
        },
        complete: () => {

        },
        error: (err: any) => {

        }
      });
  }

}
