import {Component, Input, Output, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {MainService} from "../../services/main/main.service";
import {InputNumber} from "primeng/inputnumber";
import {MessageService} from "primeng/api";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.sass'],
  providers: [MessageService]
})
export class CallbackComponent implements OnInit {
  @Input() callback = false;
  @Input() currentItem: any;
  @Input() isAuth: any;
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Output() orderCreated = new EventEmitter<void>();
  @ViewChild(InputNumber) input!: InputNumber
  callbackFormGroup!: FormGroup;
  count: any = 1;
  checked = false;
  regionList: any = [];
  companyList: any = [];
  company: any;
  showAgreeErr = false;

  constructor(public translate: TranslateService, private main: MainService,
              private auth: AuthService, private message: MessageService) {
  }

  ngOnInit() {
    this.regionList = this.currentItem.regions;
    this.initForm();
  }

  initForm() {
    this.callbackFormGroup = new FormGroup({
      phone: new FormControl('', [Validators.required, Validators.pattern(/^7[0124567][0-8]\d{7}$/i)]),
      region_id: new FormControl(null, Validators.required),
      count: new FormControl(this.currentItem.min_order_count),
      email: new FormControl('', [Validators.required, Validators.email]),
      card_id: new FormControl(this.currentItem.id),
      full_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      user_company_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      buyer_bin: new FormControl(null),
      comment: new FormControl(''),
    })
    if(this.isAuth){
      this.getUserInfo();
    }


  }

  offerClick(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-publichnaya-oferta');
  }

  getUserInfo() {
    this.main.userInfo().subscribe({
      next: (res: any) => {
        this.companyList = res.companyList;
        this.callbackFormGroup.get('full_name')?.setValue(res.userInfo.lastName + ' ' + res.userInfo.firstName + ' ' + res.userInfo.middleName);
        this.callbackFormGroup.get('email')?.setValue(res.userInfo.email);
        this.callbackFormGroup.get('phone')?.setValue(res.userInfo.phone);
        this.companyList.forEach((element: any) => {
          if(element.uin == res.userInfo.mainCompanyBin){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
            if(this.translate.currentLang == 'ru'){
              this.callbackFormGroup.get('user_company_name')?.setValue(element.companyName);
            }else{
              this.callbackFormGroup.get('user_company_name')?.setValue(element.companyNameKz);
            }
          }
        })
      },
      complete: () => {
        this.changeTariffCount();
      },
      error: () => {
      }
    });
  }

  createOrder() {
    if(this.checked){
      this.companyList.forEach((element: any) => {
        if(this.translate.currentLang == 'ru'){
          if(this.callbackFormGroup.get('user_company_name')?.value == element?.companyName){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
          }
        }else{
          if(this.callbackFormGroup.get('user_company_name')?.value == element?.companyNameKz){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
          }
        }
      })

      this.main.createOrder(this.callbackFormGroup.getRawValue()).subscribe({
        next: () => {
          // @ts-ignore
          window['yaCounter48820577'].reachGoal('market-obratny-zvonok');
          this.showAgreeErr = false;
          this.close();
          this.orderCreated.emit();
        },
        complete: () => {
        },
        error: (err: any) => {
          this.message.add({
            severity: 'error',
            summary: 'Error',
            detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz,
            life: 4000
          });
        }
      });
    }else{
      this.showAgreeErr = true;
    }

  }

  textLength() {
    return this.callbackFormGroup.get('comment')!.value.length;
  }

  close() {
    document.body.style.overflow = '';
    this.newItemEvent.emit();
  }

  changeTariffCount(input: any = this.input.el.nativeElement.children[0].children[0]) {
    let size = input.value.length;
    input.style.width = ((size * 10) + 26) + 'px';
  }
}
