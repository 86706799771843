import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.sass']
})

export class ContactsComponent implements OnInit {

  contacts: any = [
    {
      "nameRu": 'По вопросам работы с Платформой',
      "nameKz": 'Платформамен жұмыс істеу мәселелері бойынша',
      "id": 0
    },
    {
      "nameRu": 'Контакты ОФД',
      "nameKz": 'ФДО-ның байланыс деректері',
      "id": 1
    },
    {
      "nameRu": 'Контакты Маркировки',
      "nameKz": 'Маркалаудың байланыс деректері',
      "id": 2
    },
    {
      "nameRu": 'Вопросы интеграции, рекламы и сотрудничества',
      "nameKz": 'Біріктіру, жарнама және ынтымақтастық бойынша сұрақтар',
      "id": 3
    },
    {
      "nameRu": 'Платная справочная служба',
      "nameKz": 'Ақылы анықтама беру қызметі',
      "id": 4
    },
    {
      "nameRu": 'Номера спецслужб',
      "nameKz": 'Арнайы қызметттердің нөмірлері',
      "id": 5
    }
  ];
  showContacts: any = 0;
  address: any = [
    {
      "nameRu": 'г. Астана, ул. Кенесары 55',
      "nameKz": 'Астана қ, Кенесары к, 55 үй',
      "id": 0
    },
    {
      "nameRu": 'г. Караганда, ул. Ермекова, д.31',
      "nameKz": 'Қарағанды қ., Ермеков к-сі, 31-үй',
      "id": 1
    },
    {
      "nameRu": 'г. Кокшетау, ул. Абая, 108',
      "nameKz": 'Көкшетау қ., Абай к-сі, 108 үй',
      "id": 2
    },
    {
      "nameRu": 'г. Алматы, ул. Панфилова, 72/74',
      "nameKz": 'Алматы қ., Панфилов к-сі, 72/74 үй',
      "id": 3
    },
    {
      "nameRu": 'г. Талдыкорган, ул. Кабанбай батыра, 27Б',
      "nameKz": 'Талдықорған қ., Қабанбай батыр к-сі, 27Б үй',
      "id": 4
    },
    {
      "nameRu": 'г. Петропавловск, ул. Букетова, 36',
      "nameKz": 'Петропавл қ., Бөкетов к-сі, 36 үй',
      "id": 5
    },
    {
      "nameRu": 'г. Костанай, проспект Аль-Фараби, 71',
      "nameKz": 'Қостанай қ., Әл-Фараби даңғылы, 71 үй',
      "id": 6
    },
    {
      "nameRu": 'г. Усть-Каменогорск, ул.Казахстан, 67',
      "nameKz": 'Өскемен қ., Қазақстан к-ші, 67 үй',
      "id": 7
    },
    {
      "nameRu": 'г. Павлодар ул. Ак. Бектурова д. 60',
      "nameKz": 'Павлодар қ., Академик Бектұров к-сі, 60 үй',
      "id": 8
    },
    {
      "nameRu": 'г. Семей, ул . Бауыржана Момышулы д. 23',
      "nameKz": 'Семей қ., Бауыржан Момышұлы к-сі, 23 үй',
      "id": 9
    },
    {
      "nameRu": 'г. Шымкент, ул.Тыныбаева дом 4',
      "nameKz": 'Шымкент қ., Тыныбаев к-сі, 4 үй',
      "id": 10
    },
    {
      "nameRu": 'г. Тараз, ул. Абая, 124',
      "nameKz": 'Тараз қ., Абай к-сі, 124 үй',
      "id": 11
    },
    {
      "nameRu": 'г. Туркестан, ул. С. Кожанова д.5',
      "nameKz": 'Түркістан қ., С. Қожанов к-сі, 5-үй',
      "id": 12
    },
    {
      "nameRu": 'г. Кызылорда, ул. Айтеке би, 13',
      "nameKz": 'Қызылорда қ., Әйтеке би к-сі, 13 үй',
      "id": 13
    },
    {
      "nameRu": 'г. Актобе, Молдагулова, д.36, корпус 1',
      "nameKz": 'Ақтөбе қ., Молдағұлова к-сі, 36 үй, 1 корпус',
      "id": 14
    },
    {
      "nameRu": 'г. Актау, 14 микрорайон, 81, здание "Дом связи"',
      "nameKz": 'Ақтау қ., 14 ы/а, 81 үй, «Байланыс үйі» ғимараты',
      "id": 15
    },
    {
      "nameRu": 'г. Уральск, проспект Назарбаева, 186',
      "nameKz": 'Орал қ, Назарбаев даңғылы, 186 үй',
      "id": 16
    },
    {
      "nameRu": 'г. Атырау, ул. Кулманова д. 152, здание Пушкина “Дом связи”',
      "nameKz": 'Атырау қ, Құлманов к-сі, 152 үй, Пушкиннің «Байланыс үйі» ғимараты',
      "id": 17
    }
  ]
  showAddress: any = 0;
  currentDate = new Date().getFullYear();

  constructor(public translate : TranslateService) {}

  ngOnInit() {

  }


  showRightContentContacts(id: any, index: any) {
    if (id.id == index) {
      this.showContacts = index;
    }
  }

  showRightContentAddress(id: any, index: any) {
    if (id.id == index) {
      this.showAddress = index;
    }
  }
}
