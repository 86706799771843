import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {Router} from "@angular/router";
import {HeaderService} from "../../../services/header/header.service";
import {SearchService} from "../../../services/search/search.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.sass']
})
export class GoodsComponent implements OnInit{

  saleModal = false;
  isAuth: boolean = false;
  breadcrumbs: any = [
    {
      name_ru: 'Товары и услуги',
      name_kz: 'Тауарлар мен қызметтер',
      link: 'search'

    },
  ];
  breadcrumbsIndex = 0;
  breadcrumbsList: any = [

  ];

  constructor(private authService: AuthService, private route: Router,
              public translate: TranslateService,
              public headerService: HeaderService, public searchService: SearchService) {
    this.authService.auth.subscribe(() => this.getInfo());
  }

  ngOnInit() {
    this.getInfo();
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
    });
  }

  resetFilters(){
    window.location.reload();
  }

  setActiveFalse(array: any[]): void {
    for (let item of array) {
      if (item.active !== undefined) {
        item.active = false;
      }

      if (item.parentlist) {
        this.setActiveFalse(item.parentlist);
      }

      if (item.child_categories) {
        this.setActiveFalse(item.child_categories);
      }
    }
  }

  setCatalogItem(item: any, index: any){
    if(isNaN(item.id)){
      this.searchService.categoryType = item.type;
      if(this.breadcrumbs[index+1] === undefined){
        this.breadcrumbs.push({name: item.nameRu});
      }else{
        this.breadcrumbs[index+1].name = item.nameRu
      }
    }else{

    }
  }

  setCatalog(index: any, item: any){
    this.breadcrumbsList = this.headerService.mainCatalog;
    this.breadcrumbsIndex = index;
  }

  search(){
    this.searchService.search();
  }

  closeSale(){
    this.saleModal = false;
  }

  checkModal(){
    if(this.isAuth){
      this.route.navigate(['account/my-products-and-services']);
    }else{
      this.saleModal = true
    }
  }

}
