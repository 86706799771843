import {Component, OnInit} from '@angular/core';
import {SearchService} from "../../services/search/search.service";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.sass']
})
export class NavBarComponent implements OnInit{
     constructor(private searchService: SearchService) {}


  ngOnInit(){

  }

  search(){
    this.searchService._categoryId = '';
    this.searchService._name = '';
    this.searchService._cards = [];
    this.searchService.search();
  }

  protected readonly window = window;
  protected readonly navigator = navigator;

}
