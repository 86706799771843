import { Component, OnInit } from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-tu-orders',
  templateUrl: './tu-orders.component.html',
  styleUrls: ['./tu-orders.component.sass']
})
export class TuOrdersComponent implements OnInit{

  tableParams: any = {
    page: 0,
    size: 10,
    searchText: ''
  }

  tuList: any;
  loading = true;

  constructor(private main: MainService, private route: Router, public translate: TranslateService) {

  }

  ngOnInit() {
    this.getTUList()
  }

  getTUList(){
    this.main.getSellsTU(this.tableParams).subscribe((res: any) => {
      this.tuList = res;
      this.loading = false;
    })
  }

  takeToWork(id: any){
    this.main.takeTUtoWork(id).subscribe((res: any) => {
      this.route.navigate(['tu-order/'+id]);
    })
  }

  changePage(event: any){
    this.tuList = [];
    this.loading = true;
    this.tableParams.page = event.page;
    this.tableParams.size = event.rows;
    this.getTUList();
  }

}
