import {AbstractControl} from "@angular/forms";

export enum IinOrBinCheckResult {
  IIN,
  BIN,
  UNKNOWN
}

/**
 * Ocp utils class
 */
export class OcpUtils {
  static readonly IIN_PATTERN = /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])[0-6][0-9][0-9][0-9][0-9][0-9]$/;
  static readonly BIN_PATTERN = /^[0-9][0-9](0[1-9]|1[0-2])[4-6][0-3][0-9][0-9][0-9][0-9][0-9][0-9]$/;


  /**
   * Check there is BIN or IIN
   * @param data - data for check
   * @returns {IinOrBinCheckResult}
   */
  static isIinOrBin(data: any): IinOrBinCheckResult {
    if (data && data.length == 12) {
      var attribute = +data.substring(4, 5);
      if (attribute > 3) {
        return IinOrBinCheckResult.BIN
      }
      else {
        return IinOrBinCheckResult.IIN;
      }
    } else {
      return IinOrBinCheckResult.UNKNOWN;
    }
  }

  /**
   * Method for IIN validation
   * @param iin
   * @returns {any} null - bin valid, string - validation error
   */
  static validateIin(iin: string | undefined) {
    if (iin && iin.length == 12) {
      if (!OcpUtils.IIN_PATTERN.test(iin)) {
        return "Неверный формат ИИН";
      }
      if (OcpUtils.checkIinOrBinCheckSum(iin)) {
        return null;
      } else {
        return "Неверный формат ИИН (контрольная сумма)";
      }
    } else {
      return "ИИН должен содержать 12 цифр. Пожалуйста, проверьте значение.";
    }
  }

  /**
   * Method for BIN validation
   * @param bin
   * @returns {any} null - bin valid, string - validation error
   */
  static validateBin(bin: string) {
    if (bin && bin.length == 12) {
      if (!OcpUtils.BIN_PATTERN.test(bin)) {
        return "Invalid BIN format";
      }
      if (OcpUtils.checkIinOrBinCheckSum(bin)) {
        return null;
      } else {
        return "Invalid BIN format (checksum)";
      }
    } else {
      return "BIN can not be less than 12 characters";
    }
  }

  /**
   * IIN or BIN check checksum
   * @param iinBin
   * @returns {boolean} - true - checksum validation success, false - checksum validation fail
   */
  static checkIinOrBinCheckSum(iinBin: string) {
    if (iinBin && iinBin.length == 12) {
      let sum = 0;
      for (let i = 0; i < 11; i++) {
        sum += (i + 1) * (+iinBin[i]);
      }
      if (sum % 11 == 10) {
        sum = 3 * (+iinBin[0]) + 4 * (+iinBin[1]) + 5 * (+iinBin[2]) + 6 * (+iinBin[3]) + 7 * (+iinBin[4]) + 8 * (+iinBin[5])
          + 9 * (+iinBin[6]) + 10 * (+iinBin[7]) + 11 * (+iinBin[8]) + (+iinBin[9]) + 2 * (+iinBin[10]);
      }
      return sum % 11 === (+iinBin[11]);
    }
    else {
      return false;
    }
  }
  static isProd() {
    return window.location.host.indexOf('ismet.kz') >= 0;
  }
}

export function multiplePatternValidator(patterns: RegExp[]) {
  return (control: AbstractControl) => {
    if (!patterns || patterns.length === 0) {
      return null;
    }

    for (const pattern of patterns) {
      if (pattern.test(control.value)) {
        return null; // По крайней мере один из паттернов соответствует
      }
    }

    return { patternMismatch: true };
  };
}
