import {Component, OnInit} from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {MessageService} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.sass']
})
export class PurchasesComponent implements OnInit {
  products: any;
  items: any;
  loadTable = true;
  display: boolean = false;
  reviewSuccess = false;
  cardId: any;
  pmenu: any = [
    {
      label: 'WriteReview',
      command: (): any => {
        this.menuItemCommand()
      }
    }
  ];
  reviewData = {
    grade: 0,
    review: '',
    card_id: 0
  }
  productId: any;
  tableParams: any = {
    page: 0,
    size: 10,
    stateList: [],
    name: '',
    bin: '',
    vendorCode: '',
    sortBy: 'id',
    sortByAsc: true,
    searchText: ''
  }

  constructor(private main: MainService, public translate: TranslateService,private message: MessageService,) {}

  ngOnInit(){
    this.menuItems();
    this.getProducts();
  }

  changeLang(product: any){
    this.translate.get('WriteReview').subscribe((translate: any) => {
      this.pmenu[4][0].label = translate;
    });
    this.translate.get('Approve').subscribe((translate: any) => {
      this.pmenu[2][0].label = translate;
    });
    this.cardId =  product.card_id;
    this.productId = product.id;
  }

  quickSearch(){
    this.getProducts();
  }

  clear(){
    this.tableParams.searchText = '';
  }

  menuItems(){
    this.pmenu[0] = [
      {
        label: 'WriteReview',
        command: (): any => {
          this.menuItemCommand()
        }
      }
    ];
    this.pmenu[1] = [
      {
        label: 'WriteReview',
        command: (): any => {
          this.menuItemCommand()
        }
      }
    ];
    this.pmenu[2] = [
      {
        label: 'Approve',
        command: (): any => {
          this.approve();
        }
      }
    ];
    this.pmenu[3] = [
      {
        label: 'WriteReview',
        command: (): any => {
          this.menuItemCommand()
        }
      }
    ];
    this.pmenu[4] = [
      {
        label: 'WriteReview',
        command: (): any => {
          this.menuItemCommand()
        }
      }
    ];
  }

  approve(){
    this.main.approve(this.productId).subscribe({
      next: (res: any) => {
        this.getProducts();
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({ severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000 });
      }
    });
  }

  disableBtn(){
    return this.reviewData.review == '' || this.reviewData.grade == 0;
  }

  menuItemCommand(){
    this.display = true;
  }

  sendReview(){
    this.reviewData = {
      grade: this.reviewData.grade,
      review: this.reviewData.review,
      card_id: this.cardId
    }

    this.main.reviewSend(this.reviewData).subscribe({
      next: (res: any) => {
        this.reviewSuccess = true;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({ severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000 });
      }
    });
  }

  changePage(event: any){
    this.loadTable = true;
    this.tableParams.page = event.page;
    this.tableParams.size = event.rows;
    this.getProducts();
  }

  getProducts() {
    this.products = [];
    this.main.getPurchases(this.tableParams).subscribe({
      next: (res: any) => {
        this.products = res;
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  cancel(){
    this.display = false;
    this.reviewSuccess = false;
    this.reviewData.grade = 0;
    this.reviewData.review = '';
  }
}
