<div class="pagination-wrap">
  <div class="relative" *ngIf="showOptions">
    <div class="show-options rowsdropdown">
      {{ "Show" | translate }}
      <p-dropdown styleClass="" [options]="rows" optionLabel="name" [showClear]="true">
        <ng-template pTemplate="clearicon">

        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="flex w-full justify-content-center">
    <div class="flex arrow-wrap">
      <div *ngIf="currentPage != 1" (click)="setCurrentPage(-1)"
           class="left-nav-arrow arrow-item flex justify-content-center align-items-center mx-2 cursor-pointer">
        <img src="assets/images/main/catalog-arrow.svg" alt="">
      </div>
      <div class="nav-item" *ngIf="(totalPages == 6 && currentPage > 4) || currentPage >= 6 || currentPage > 4" (click)="setCurrentPage(1, 'number')"
           [ngClass]="{'current-page': currentPage == 1}">
        1
      </div>
    </div>
    <div class="" *ngIf="totalElements > 80 && currentPage >= 4  && totalPages != 6">
      <div class="nav-item">
        ...
      </div>
    </div>
    <div class="flex">
      <div class="nav-item"
           *ngFor="let page of pages; let i = index"
           [ngClass]="{'current-page': currentPage === page}"
           (click)="setCurrentPage(page, 'number')">
        {{page}}
      </div>
    </div>
    <div class="" *ngIf="totalElements > 80 && currentPage < totalPages - 2 && totalPages != 6">
      <div class="nav-item">
        ...
      </div>
    </div>
    <div *ngIf="currentPage < totalPages - 2 && totalPages != 4 && totalPages != 5" class="flex">
      <div class="nav-item" (click)="setCurrentPage(totalPages, 'number')">
        {{ totalPages }}
      </div>
    </div>
    <div class="flex arrow-wrap">
      <div *ngIf="currentPage < totalPages" (click)="setCurrentPage(1)"
           class="arrow-item flex justify-content-center align-items-center mx-2 cursor-pointer">
        <img src="assets/images/main/catalog-arrow.svg" alt="">
      </div>
    </div>
  </div>
</div>
