<div class="breadcrumb flex" *ngIf="hideNotTU">
    <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
</div>
<div class="attaching-company">
  <div class="attaching-company-sign-text" *ngIf="noCompany">
    <div class="attaching-company-sign-text__title">{{'AttachACompany' | translate}}</div>
    <div *ngIf="hideNotTU" class="attaching-company-sign-text__cancel" [routerLink]="'/'">{{'Cancel' | translate}}</div>
    <div *ngIf="!hideNotTU" class="attaching-company-sign-text__cancel" (click)="cancel.emit()">{{'Cancel' | translate}}</div>
  </div>
  <div class="attaching-company-sign-button">
    <div class="attaching-company-sign-button__button" [ngClass]="{'active': signButton == 'ecp'}" (click)="setButton('ecp')">{{'WithDigitalSignature' | translate}}</div>
    <div class="attaching-company-sign-button__button" [ngClass]="{'active': signButton == 'iin'}" (click)="setButton('iin')">{{'WithIIN' | translate}}</div>
    <div class="attaching-company-sign-button__button" [ngClass]="{'active': signButton == 'qr'}" (click)="setButton('qr')">{{'WithEGOV' | translate}}</div>
  </div>
  <hr>

  <div class="attaching-company-information-sign" *ngIf="signButton == 'ecp'">
    <div class="attaching-company-sign-text__title">{{'AttachWithDigitalSignature' | translate}}</div>
    <div class="attaching-company-sign-text__text">{{'SuitableForAllTypesOfCompanies' | translate}}</div>
    <div class="attaching-company-information-sign__button" (click)="request()">{{'SelectCertificate' | translate}}</div>
  </div>

  <div class="attaching-company-information-sign" *ngIf="signButton == 'iin'">
    <div class="attaching-company-sign-text__title">{{'AttachWthIIN' | translate}}</div>
    <div class="attaching-company-sign-text__text">{{'OnlyForIP' | translate}}</div>
    <div class="attaching-company-information-sign__info">{{'IINWithNumber' | translate}}</div>
    <p-progressSpinner *ngIf="loadSpinner" [class]="'load-spinner'"></p-progressSpinner>
    <form [formGroup]="iinForm" *ngIf="!loadSpinner" class="attaching-company-information-sign__form">
      <div class="attaching-company-information-sign__label">{{'IIN' | translate}}</div>
      <p-inputMask mask="999999999999" id="iin" placeholder="{{'SetIIN' | translate}}"
                   [autoClear]="false" formControlName="iin" [unmask]="true" (onComplete)="changeIIN()"></p-inputMask>
      <span class="attaching-company-information-sign__error" *ngIf="error">{{nameOfCompany}}</span>
    </form>
    <div class="attaching-company-information-sign-result" *ngIf="nameOfCompany">
      <div class="attaching-company-information-sign-result__img"></div>
      <div class="attaching-company-information-sign-result-company">
        <div class="attaching-company-information-sign-result-company__title">{{'FoundCompany' | translate}}</div>
        <div class="attaching-company-sign-text__title">{{nameOfCompany}}</div>
      </div>
    </div>
    <button class="attaching-company-information-sign__button" (click)="addBMG()"
            [disabled]="error || !disabledButton" [ngClass]="{'disabled': error || !disabledButton}">{{'AddCompany' | translate}}</button>
  </div>

  <div class="attaching-company-information-sign" *ngIf="signButton == 'qr'">
    <div class="attaching-company-sign-text__title">{{'AttachWithQR' | translate}}</div>
    <div class="attaching-company-sign-text__text">{{'OnlyForIP' | translate}}</div>
    <div class="attaching-company-information-sign__info">{{'UseEGOVMobile' | translate}}</div>
    <ng-container *ngIf="!requestEnd">
      <img class="attaching-company-information-sign__qr-img" [src]="'data:image/jpeg;base64,' + qr" alt="" *ngIf="qr && !statGovNotFound">
      <span class="attaching-company-information-sign__error" *ngIf="statGovNotFound">{{'STATGOVIINNOTFOUND' | translate}}</span>
    </ng-container>
    <ng-container *ngIf="requestEnd">
      <div class="attaching-company-information-sign__qr-reset" (click)="qrSign()"></div>
      <span class="attaching-company-information-sign__errorQR">{{'QRTimeout' | translate}}</span>
    </ng-container>
  </div>
</div>

<p-dialog [(visible)]="openSuccess" [modal]="true" [draggable]="false" [resizable]="false" styleClass="dialog-success" (onHide)="hideForm()">
  <div class="attaching-company-information-sign__img-success"></div>
  <div class="attaching-company-information-sign__title-success">{{'CompanyAdded' | translate}}</div>
  <div *ngIf="hideNotTU" class="attaching-company-information-sign__button-success" (click)="goToProducts()">{{'Continue' | translate}}</div>
  <div *ngIf="!hideNotTU" class="attaching-company-information-sign__button-success" (click)="continue.emit();">{{'Continue' | translate}}</div>
</p-dialog>
