<div class="overlay"></div>
<div class="modal">
  <div class="modal-head">
    <div class="cross-icon w-full text-right">
      <svg (click)="close()" width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.084 9.69375L23.6402 8.25L16.834 15.0563L10.0277 8.25L8.58398 9.69375L15.3902 16.5L8.58398 23.3063L10.0277 24.75L16.834 17.9437L23.6402 24.75L25.084 23.3063L18.2777 16.5L25.084 9.69375Z" fill="white"/>
      </svg>
    </div>
    <img class="mobile-banner" src="assets/images/banner/sale-banner.png" alt="">
    <div class="banner-txt">
      <span *ngIf="translate.currentLang == 'ru'">{{"Smart-1" | translate}}
        <span class="text-orange">B2B</span> {{"Smart-3" | translate}}
      </span>
      <span *ngIf="translate.currentLang == 'kz'">
        <span class="text-orange">B2B-ге</span> {{"Smart-1" | translate}}
      </span>
    </div>
    <div class="banner-btn-wrap">
      <button class="banner-btn" (click)="goToSales()">{{"CreateShop" | translate}}</button>
      <div class="block-download">
        <div inlineSVG="assets/images/main/download.svg"></div>
        <a target="_blank"
           [href]="translate.currentLang == 'ru' ? 'assets/docs/Инструкция%202.0.pdf' : 'assets/docs/Инструкция_KZ%202.0.pdf'"
           class="download-instruction"
           (click)="yaCheck()"
           [download]="translate.currentLang == 'ru' ? 'Инструкция 2.0.pdf' : 'Инструкция_KZ 2.0.pdf'">
          {{'downloadInstructionsForWorkingWithTheMarket' | translate}}
        </a>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="body-item text-center">
      <div inlineSVG="assets/images/main/circle-1.svg"></div>
      <div class="body-item-header">{{"CreateShopTxt-0" | translate}}</div>
      <div>{{"CreateShopTxt-1" | translate}}</div>
    </div>
    <div class="body-item text-center">
      <div inlineSVG="assets/images/main/circle-5.svg"></div>
      <div class="body-item-header">{{"CreateShopTxt-2" | translate}}</div>
      <div>{{"CreateShopTxt-3" | translate}}</div>
    </div>
    <div class="body-item text-center">
      <div inlineSVG="assets/images/main/circle-3.svg"></div>
      <div class="body-item-header">{{"CreateShopTxt-4" | translate}}</div>
      <div>{{"CreateShopTxt-5" | translate}}</div>
    </div>
    <div class="body-item text-center">
      <div inlineSVG="assets/images/main/circle-4.svg"></div>
      <div class="body-item-header">{{"CreateShopTxt-6" | translate}}</div>
      <div>{{"CreateShopTxt-7" | translate}}</div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="footer-head text-center">
      {{ "CreateShopTxt-10" | translate }}
    </div>
    <div class="faq">
      <p-panel [header]="'CreateShopTxt-11' | translate" [toggleable]="true" [collapsed]="true"
               [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
       <div class="faq-txt">
         {{ "CreateShopTxt-12" | translate }}
       </div>
      </p-panel>
      <p-divider></p-divider>
      <p-panel [header]="'CreateShopTxt-13' | translate" [toggleable]="true" [collapsed]="true"
               [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
        <div class="faq-txt">
          {{ "CreateShopTxt-14" | translate }}
        </div>
      </p-panel>
      <p-divider></p-divider>
      <p-panel [header]="'CreateShopTxt-15' | translate" [toggleable]="true" [collapsed]="true"
               [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
        <div class="faq-txt">
          {{ "CreateShopTxt-16" | translate }}
        </div>
      </p-panel>
      <p-divider></p-divider>
      <p-panel [header]="'CreateShopTxt-17' | translate" [toggleable]="true" [collapsed]="true"
               [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
        <div class="faq-txt">
          {{ "CreateShopTxt-18" | translate }}
        </div>
      </p-panel>
    </div>
  </div>
</div>
