import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Pipe({
  name: 'secure'
})
export class Secure implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url: any): Observable<SafeUrl> {
    return this.http.get(encodeURI(url), { responseType: 'blob' }).pipe(
      map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      catchError(error => {
        console.error('Произошла ошибка при выполнении запроса:', error);
        // Вернуть ссылку на заглушку в случае ошибки
        if(url == '/proxy/profile-api.bpmn/api/v1/profile/avatar'){
          return of('/assets/images/main/side-menu/avatar-filler.svg');
        }else{
          return of('/assets/images/main/company-filler.svg');
        }

      })
    );
  }
}
