<div class="breadcrumb">
  <div class="flex" *ngIf="headerService.goodName.length == 0">
      <div class="breadcrumb-item" [routerLink]="'/'">
        {{ 'Main' | translate }}
      </div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.73984 6.76302L10.9732 9.99635L7.73984 13.2297C7.41484 13.5547 7.41484 14.0797 7.73984 14.4047C8.06484 14.7297 8.58984 14.7297 8.91484 14.4047L12.7398 10.5797C13.0648 10.2547 13.0648 9.72969 12.7398 9.40469L8.91484 5.57969C8.58984 5.25469 8.06484 5.25469 7.73984 5.57969C7.42318 5.90469 7.41484 6.43802 7.73984 6.76302Z" fill="#8D959D"/>
      </svg>
      <div class="breadcrumb-item" [routerLink]="'/search'" (click)="resetFilters()">
        {{ translate.currentLang == 'ru' ? breadcrumbs[0]?.name_ru : breadcrumbs[0]?.name_kz }}
      </div>
    <ng-container *ngIf="breadcrumbs.length > 1">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.73984 6.76302L10.9732 9.99635L7.73984 13.2297C7.41484 13.5547 7.41484 14.0797 7.73984 14.4047C8.06484 14.7297 8.58984 14.7297 8.91484 14.4047L12.7398 10.5797C13.0648 10.2547 13.0648 9.72969 12.7398 9.40469L8.91484 5.57969C8.58984 5.25469 8.06484 5.25469 7.73984 5.57969C7.42318 5.90469 7.41484 6.43802 7.73984 6.76302Z" fill="#8D959D"/>
      </svg>
      <div *ngFor="let parentItem of breadcrumbs; index as i">
        <div class="breadcrumb-item">
          {{ translate.currentLang == 'ru' ? parentItem?.name_ru : parentItem?.name_kz }}
        </div>
        <svg *ngIf="breadcrumbs[i+1]?.name?.length > 0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.73984 6.76302L10.9732 9.99635L7.73984 13.2297C7.41484 13.5547 7.41484 14.0797 7.73984 14.4047C8.06484 14.7297 8.58984 14.7297 8.91484 14.4047L12.7398 10.5797C13.0648 10.2547 13.0648 9.72969 12.7398 9.40469L8.91484 5.57969C8.58984 5.25469 8.06484 5.25469 7.73984 5.57969C7.42318 5.90469 7.41484 6.43802 7.73984 6.76302Z" fill="#8D959D"/>
        </svg>
      </div>
    </ng-container>
  </div>

</div>
<div class="catalog-header">
  {{ 'Каталог товаров и услуг' | translate }}
</div>
<div class="catalog-search">
  <input type="text" [placeholder]="'Введите название товара или услуги…' | translate" [(ngModel)]="searchService._name" (keydown.enter)="search()">
  <svg *ngIf="searchService._name.length > 0" (click)="searchService.name = ''" class="search-cross" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.875 8.84668L9.13585 24.5858" stroke="#6C757D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.13855 8.84668L24.8777 24.5858" stroke="#6C757D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>


  <svg (click)="search()" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7986 24.3055C20.4969 24.3055 24.3056 20.4968 24.3056 15.7986C24.3056 11.1003 20.4969 7.29163 15.7986 7.29163C11.1004 7.29163 7.29169 11.1003 7.29169 15.7986C7.29169 20.4968 11.1004 24.3055 15.7986 24.3055Z" stroke="#0D87EF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.7107 27.7077L21.8126 21.8096" stroke="#0D87EF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</div>
<div class="flex goods-container">
  <app-filters class="filters"></app-filters>
  <div class="mobile-start-sale-wrap">
    <div (click)="checkModal()" class="mobile-start-sale">
      <i class="pi pi-plus sell-icon"></i>
      {{ 'StartSelling' | translate }}
    </div>
  </div>
  <app-start-sale *ngIf="saleModal" (newItemEvent)="closeSale()" [startSale]="saleModal"></app-start-sale>
  <app-goods-list class="goods-list"></app-goods-list>
</div>
