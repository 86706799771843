import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MainService} from "../../../../../services/main/main.service";
import {MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../../../services/auth/auth.service";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { Meta,Title } from '@angular/platform-browser';
import {Location} from "@angular/common";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InputNumber} from "primeng/inputnumber";
@Component({
  selector: 'app-goods-card',
  host: { ngSkipHydration: 'true' },
  templateUrl: './goods-card.component.html',
  styleUrls: ['./goods-card.component.sass']
})
export class GoodsCardComponent implements OnInit {

  @ViewChild(InputNumber) input!: InputNumber

  cardId: any;
  cardData: any = {
    rating: 0,
    feedback: {
      all: 0,
      negative: 0,
      positive: 0
    },
    price: 0
  };
  display: boolean = false;
  items: MenuItem[] | any;
  feedbackList: any = [];
  rating: any;
  reviewPageNumber = 0;
  callback = false;
  currentFav: any;
  reviewData = {
    grade: 0,
    review: '',
    card_id: 0
  };
  showReviews = false;
  activeBtn = [true, false, false]
  currentImageIndex: any;
  reviewTotalElements: any;
  loading: any = true;
  isAuth: boolean = false;
  errorNotification: boolean = false;
  showFullImage = false;
  showAgreeErr = false;

  descriptionPreview: any;


  imagesSideBar = false;

  showOrderForm = false;

  callbackFormGroup!: FormGroup;
  count: any = 1;
  checked = false;
  regionList: any = [];
  companyList: any = [];
  company: any;
  showForm = false;


  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private location: Location,
              private meta: Meta,private title: Title,
              private message: MessageService, private mainService: MainService,
              private authService: AuthService, public translate: TranslateService,
              private renderer: Renderer2, private el: ElementRef) {
    this.route.params.subscribe(params => this.cardId = params);
    this.authService.auth.subscribe(() => this.getInfo());
    this.getCardData();
  }

  ngOnInit() {
    this.getInfo();
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
    })
  }

  textLength() {
    return this.callbackFormGroup.get('comment')!.value.length;
  }

  createOrder() {
    if(this.checked){
      this.companyList.forEach((element: any) => {
        if(this.translate.currentLang == 'ru'){
          if(this.callbackFormGroup.get('user_company_name')?.value == element?.companyName){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
          }
        }else{
          if(this.callbackFormGroup.get('user_company_name')?.value == element?.companyNameKz){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
          }
        }
      })

      this.mainService.createOrder(this.callbackFormGroup.getRawValue()).subscribe({
        next: () => {
          // @ts-ignore
          window['yaCounter48820577'].reachGoal('market-obratny-zvonok');
          this.showOrderForm = false;
          this.showAgreeErr = false;
        },
        complete: () => {
          this.message.add({
            severity: 'success',
            summary: 'Success',
            detail: this.translate.currentLang == 'ru' ? 'Заказ создан' : 'Заказ создан',
            life: 4000
          });
        },
        error: (err: any) => {
          this.message.add({
            severity: 'error',
            summary: 'Error',
            detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz,
            life: 4000
          });
        }
      });
    }else{
      this.showAgreeErr = true;
    }

  }

  initForm() {
    this.callbackFormGroup = new FormGroup({
      phone: new FormControl('', [Validators.required, Validators.pattern(/^7[0124567][0-8]\d{7}$/i)]),
      region_id: new FormControl(null),
      count: new FormControl(this.cardData.min_order_count),
      email: new FormControl('', [Validators.email]),
      card_id: new FormControl(this.cardData.id),
      full_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      user_company_name: new FormControl('', [Validators.minLength(2)]),
      buyer_bin: new FormControl(null),
      comment: new FormControl(''),
    })
    this.showForm = true;

    if(this.isAuth){
      this.getUserInfo();
    }


  }

  getUserInfo() {
    this.mainService.userInfo().subscribe({
      next: (res: any) => {
        this.companyList = res.companyList;
        this.callbackFormGroup.get('full_name')?.setValue(res.userInfo.lastName + ' ' + res.userInfo.firstName + ' ' + res.userInfo.middleName);
        this.callbackFormGroup.get('email')?.setValue(res.userInfo.email);
        this.callbackFormGroup.get('phone')?.setValue(res.userInfo.phone);
        this.companyList.forEach((element: any) => {
          if(element.uin == res.userInfo.mainCompanyBin){
            this.callbackFormGroup.get('buyer_bin')?.setValue(element.uin);
            if(this.translate.currentLang == 'ru'){
              this.callbackFormGroup.get('user_company_name')?.setValue(element.companyName);
            }else{
              this.callbackFormGroup.get('user_company_name')?.setValue(element.companyNameKz);
            }
          }
        })
      },
      complete: () => {
        this.changeTariffCount();
      },
      error: () => {
      }
    });
  }

  changeTariffCount(input: any = this.input.el.nativeElement.children[0].children[0]) {
    let size = input.value.length;
    input.style.width = ((size * 10) + 26) + 'px';
  }

  mobileShare(){
    let text = this.translate.currentLang === 'ru' ? this.cardData?.name_ru + '\n' + this.cardData.parent_categories[0].child_categories[0].name_ru + '\n': this.cardData?.name_kz + '\n' + this.cardData.parent_categories[0].child_categories[0].name_kz + '\n'
    navigator.share({
        title: this.translate.currentLang === 'ru' ? this.cardData?.name_ru : this.cardData?.name_kz,
        text: text,
        url: window.location.href
      })
  }

  goBack() {
    this.location.back();
  }

  showAllReviews(){
    this.showReviews = true;
    this.mainService.getFeedBackListByCardId(this.cardId.id, 0, this.cardData.feedback.all).subscribe({
      next: (res: any) => {
        this.reviewTotalElements = res.totalElements;
        res.content.forEach((element: any) => {
          this.feedbackList.push(element)
        });
        this.reviewPageNumber++;
      },
      error: () => {
      }
    });
  }

  changeMainImage(additionalIndex: any){

    this.renderer.setStyle(this.el.nativeElement.querySelector('.full-main-image'), 'width', `500px`);

    if(additionalIndex == -1 && this.currentImageIndex == 0){
      this.currentImageIndex = this.cardData.images.length - 1;
    }else if(additionalIndex == 1 && this.currentImageIndex == this.cardData.images.length - 1){
      this.currentImageIndex = 0;
    }else{
      this.currentImageIndex = this.currentImageIndex + additionalIndex;
    }
  }

  zoom(zoom = true){
    if(zoom){
      this.renderer.setStyle(this.el.nativeElement.querySelector('.full-main-image'),
        'width', `calc(${this.el.nativeElement.querySelector('.full-main-image').offsetWidth}px * 1.1)`);
    }else{
      this.renderer.setStyle(this.el.nativeElement.querySelector('.full-main-image'),
        'width', `calc(${this.el.nativeElement.querySelector('.full-main-image').offsetWidth}px * 0.9)`);
    }
  }

  getSafeDescription(): any {
    const description = this.translate.currentLang === 'ru' ? this.cardData?.description_ru : this.cardData?.description_kz;
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  getSafeDescriptionPreview(text: any){
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  fillerUrl(item: any) {
    return item.images[this.currentImageIndex]?.full_path ? `/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[this.currentImageIndex].full_path}` : '/assets/images/main/card-fill.svg';
  }

  setMainImage(index = 0) {
    this.currentImageIndex = index;
  }

  close() {
    this.callback = false;
    this.showOrderForm = false;
  }

  showDialog() {
    this.display = true;
  }

  orderCreated() {
    this.message.add({
      severity: 'success',
      summary: 'Success',
      detail: this.translate.currentLang == 'ru' ? 'Заказ создан' : 'Тапсырыс жасалды',
      life: 4000
    });
  }

  sendReview() {
    this.display = false;
    this.reviewData = {
      grade: this.reviewData.grade,
      review: this.reviewData.review,
      card_id: this.cardId.id
    }
    this.mainService.reviewSend(this.reviewData).subscribe({
      next: (res: any) => {
        this.cardData = res;
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz,
          life: 4000
        });
        this.display = false;
      }
    });
  }

  share() {
    navigator.clipboard.writeText(window.location.href);
  }

  disableBtn() {
    return this.reviewData.review == '' || this.reviewData.grade == 0;
  }

  cancel() {
    this.display = false;
    this.reviewData.grade = 0;
    this.reviewData.review = '';
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }
    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы
    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }

  getCardData(): any {
    this.errorNotification = false;
    this.mainService.getCardById(this.cardId.id).subscribe({
      next: (res: any) => {
        this.cardData = res;
        this.initForm();
        this.loading = false;
        this.regionList = this.cardData.regions;


        if(this.translate.currentLang == 'ru'){
          if(res.description_ru.length > 150){
            this.descriptionPreview = res.description_ru.slice(0,150)+ '... <span style="color: #0d87ef">Ещё</span>'
          }
        }else{
          if(res.description_kz.length > 150){
            this.descriptionPreview = res.description_kz.slice(0,150)+ '... <span style="color: #0d87ef">Көбірек</span>'
          }
        }

        this.currentImageIndex = 0;
        this.meta.removeTag('description');
        this.meta.updateTag({
          name: 'description',
          content: (this.translate.currentLang == 'kz' ?
            res.description_kz.slice(0,150)+ '... | Пікірлер, сипаттамасы. Толығырақ Ismet Partner-де.' :
            res.description_ru.slice(0,150)+ '... | Отзывы, описание. Подробнее на Ismet Partner.')
        });
        this.title.setTitle(this.translate.currentLang == 'kz'
          ? res.name_kz + ' | Ismet Partner-дегі бизнеске арналған тауарлар мен қызметтер.'
          : res.name_ru + ' | Товары и услуги для бизнеса на Ismet Partner.'
        );

        this.getFeedBackList();
      },
      error: () => {
        this.errorNotification = true;
      }
    });

  }

  order(item: any) {
    document.body.style.overflow = 'hidden';
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-perezvonit-nam');
    this.callback = true;
    this.currentFav = item;


  }

  companyClick(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-click-po-kompaniy');
  }

  getFeedBackList(event: any = {index: 1}) {
    if (event.index == 1 && this.cardData.feedback.all > this.feedbackList.length) {
      this.mainService.getFeedBackListByCardId(this.cardId.id, this.reviewPageNumber, 5).subscribe({
        next: (res: any) => {
          this.reviewTotalElements = res.totalElements;
          res.content.forEach((element: any) => {
            this.feedbackList.push(element)
          });
          this.reviewPageNumber++;
        },
        error: () => {
        }
      });
    }
  }

  getNegativeFeedBackList(event: any = {index: 1}) {
    if (event.index == 1 && this.cardData.feedback.all > this.feedbackList.length) {
      this.mainService.getNegativeList(this.cardId.id, this.reviewPageNumber, 5).subscribe({
        next: (res: any) => {
          this.reviewTotalElements = res.totalElements;
          res.content.forEach((element: any) => {
            this.feedbackList.push(element)
          });
          this.reviewPageNumber++;
        },
        error: () => {
        }
      });
    }
  }

  getPositiveFeedBackList(event: any = {index: 1}) {
    if (event.index == 1 && this.cardData.feedback.all > this.feedbackList.length) {
      this.mainService.getPositiveList(this.cardId.id, this.reviewPageNumber, 5).subscribe({
        next: (res: any) => {
          this.reviewTotalElements = res.totalElements;
          res.content.forEach((element: any) => {
            this.feedbackList.push(element)
          });
          this.reviewPageNumber++;
        },
        error: () => {
        }
      });
    }
  }

  favorite() {
    if (this.cardData.favorite) {
      this.mainService.deleteFavorite(this.cardId.id).subscribe({
        next: () => {
          this.cardData.favorite = !this.cardData.favorite;
        },
        error: () => {
        }
      });
    } else {
      this.mainService.postFavorite(this.cardId.id).subscribe({
        next: () => {
          this.cardData.favorite = !this.cardData.favorite;
        },
        error: () => {
        }
      });
    }
  }

  nextPage() {
    if (this.activeBtn[0]) {
      this.getFeedBackList();
    } else if (this.activeBtn[1]) {
      this.getPositiveFeedBackList();
    } else {
      this.getNegativeFeedBackList();
    }
  }

  toggleBtn(btnIndex: any) {
    this.feedbackList = [];
    this.reviewPageNumber = 0;
    this.activeBtn = [false, false, false];
    this.activeBtn[btnIndex] = !this.activeBtn[btnIndex];
    if (btnIndex == 0) {
      this.getFeedBackList();
    } else if (btnIndex == 1) {
      this.getPositiveFeedBackList();
    } else {
      this.getNegativeFeedBackList();
    }
  }
}
