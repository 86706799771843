import {inject} from '@angular/core';
import {AuthService} from "./services/auth/auth.service";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

export const authGuard: CanActivateFn = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  let authenticated = await inject(AuthService).isLoggedIn(true)
  if (authenticated && next.fragment) {
    return router.parseUrl(state.url.split('#')[0]);
  }
  return authenticated;
}
