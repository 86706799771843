import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchService} from "../../services/search/search.service";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent {

  @Input() totalPages: any;
  @Input() totalElements: any;
  @Input() showOptions = false;
  @Output() pageChanged = new EventEmitter<number>();

  rows = [{name: 10},{name: 20},{name: 30}]
  currentPage = 1; // текущая страница
  visiblePages = 5; // количество видимых элементов пагинации

  constructor(public searchService: SearchService) {
  }

  setCurrentPage(count: any, selector = 'arrow'){
    if(selector == 'arrow'){
      this.currentPage = this.currentPage + count;
    }else{
      this.currentPage = count;
    }

    if(this.currentPage > 3 && this.currentPage <= this.totalPages - 3){
      this.visiblePages = 3
    }else{
      this.visiblePages = 5
    }

    this.pageChanged.emit(this.currentPage);

  }

  get pages(): number[] {
    let start = Math.max(1, this.currentPage - Math.floor(this.visiblePages / 2));
    let end = Math.min(this.totalPages, start + this.visiblePages - 1);

    if (end - start + 1 < this.visiblePages) {
      start = Math.max(1, end - this.visiblePages + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

}
