<div class="relative">
  <div (click)="favorite()" [ngClass]="{'favorite-icon-filled': cardInfo['favorite'], 'already-added': cardInfo['favorite']}" class="favorite-icon"></div>
  <a [href]="['/item/' + cardInfo['id']]" target="_self">
    <div class="good-item relative" (click)="metrikClick()">
      <div class="img-wrap">
        <div *ngIf="cardInfo['images']?.length > 0" class="card-image"
             [ngStyle]="{'background-image': genInp(cardInfo)}"></div>
        <div *ngIf="cardInfo['images']?.length == 0" class="card-image"
             style="background-image: url('/assets/images/main/card-fill.svg')"></div>
      </div>
      <div class="price">
        {{ 'CardPrice' | translate : {price: ('' + cardInfo['price'] | numberspacer), ru: (translate.currentLang == 'ru' && !cardInfo['is_fix_price']) ? 'От' : '', kz: (translate.currentLang == 'kz' && !cardInfo['is_fix_price']) ? 'бастап' : ''} }}
      </div>
      <div [title]="translate.currentLang == 'ru' ? cardInfo['name_ru'] : cardInfo['name_kz']"
           class="item-title">
        {{translate.currentLang == 'ru' ? cardInfo['name_ru'] : cardInfo['name_kz']}}
      </div>
      <a [href]="['/company/' + cardInfo['company_bin']]">
        <div [title]="(translate.currentLang == 'ru' ? cardInfo['company_name'] : cardInfo['company_name_kz']) | shortname"
             class="company-name">
          {{(translate.currentLang == 'ru' ? cardInfo['company_name'] : cardInfo['company_name_kz']) | shortname}}
        </div>
      </a>
      <div class="flex rating-wrap">
        <div>
          <img *ngIf="cardInfo['rating'] > 0" src="/assets/images/main/star-filled-icon.svg" width="12px" height="12px"/>
          <img *ngIf="cardInfo['rating'] == 0" class="star" src="/assets/images/main/star-icon.svg" width="12px" height="12px"/>
        </div>
        <div class="feedback-count flex">
          <span *ngIf="cardInfo['rating'] > 0">{{ cardInfo['rating'] }}</span>
          <div class="feedback-count-txt" *ngIf="cardInfo['rating'] > 0">
            {{ cardInfo['feedback'].all | suffix: ('OneReview' | translate): ('SomeReviews' | translate): ('ManyReviews' | translate)}}
          </div>
          <div class="" *ngIf="cardInfo['rating'] == 0">
            {{'NoRating' | translate}}
          </div>
        </div>
      </div>
    </div>
  </a>

</div>
