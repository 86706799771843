import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {MainService} from "../../../services/main/main.service";
import {SigningComponent} from "../../signing/signing.component";
import {HeaderService} from "../../../services/header/header.service";

@Component({
  selector: 'app-yandex',
  templateUrl: './yandex.component.html',
  styleUrls: ['./yandex.component.sass']
})
export class YandexComponent implements OnInit {
  @ViewChild(SigningComponent) sign!: SigningComponent;
  existCompany: boolean = false;
  filledCompany: boolean = false;

  constructor(private authService: AuthService,
              private headerService: HeaderService,
              private main: MainService) {
  }

  ngOnInit() {
    this.addScript("https://geoadv-partner.yandex.ru/static/partner-script.js")
    this.existCompany = this.headerService.userData?.companyList?.length;
    this.headerService.changeUserDataEmitter.subscribe(userData => {
      this.existCompany = userData?.companyList?.length;
    })
    this.main.attachFromIsmetFilled().subscribe({
      next: (data: any) => {
        this.filledCompany = data.success;
      }
    })
  }

  initWidget() {
    //@ts-ignore
    window.YaPartnerWidget.init({
      container: document.querySelector('#yandex-banner'),
      partnerName: 'kz_telecom',
      partnerSiteId: Math.abs(this.hashCode(this.authService.userInfo.id)),
      products: ['websubscription'],
      iframeDomain: 'yandex.kz'
    });
  }

  hashCode(str: string) {
    let hash = 0;
    if (str.length == 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      let charCode = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + charCode;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  isScriptInHead(src: string) {
    const scripts = document.head.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === src) {
        return true;
      }
    }
    return false;
  }

  addScript(path: string) {
    if (this.isScriptInHead(path)) {
      this.initWidget()
      return;
    }
    let script = document.createElement('script')
    script.src = path
    document.head.appendChild(script)
    script.addEventListener('load', () => {
      this.initWidget()
    })
  }

  attachCompany() {
    this.sign.openAddCompanyDialog()
  }

  yaCode: string = ''
  yaLoad: boolean = false
  yaCopied: boolean = false

  getYandexCode() {
    if (this.yaLoad) {
      return
    }
    if (this.yaCode) {
      navigator.clipboard.writeText(this.yaCode).then(() => {
        this.yaCopied = true;
        setTimeout(() => this.yaCopied = false, 3000)
      }, (err) => {
        console.error('Async: Could not copy text:', err);
      });
    } else {
      this.yaLoad = true;
      this.main.getYandexCode().subscribe({
        next: (code: any) => {
          this.yaCode = code
          try {
            // @ts-ignore
            window['yaCounter48820577'].reachGoal('yandex-promo')
          } catch (e) {}
        },
        complete: () => {
          this.yaLoad = false
        }
      })
    }
  }

  updateAfterAdd() {
    try {
      // @ts-ignore
      window['yaCounter48820577'].reachGoal('yandex-company')
    } catch (e) {}
  }

  yaEvent() {
    try {
      // @ts-ignore
      window['yaCounter48820577'].reachGoal('yandex-vizitka')
    } catch (e) {}
  }
}
