<div class="progress-company" *ngIf="!filledCompany">
  <div class="progress-company-title">
    {{'Хотите подарок?' | translate}}
  </div>
  <div class="progress-company-description">
    {{'Завершите несколько простых шагов на Ismet Partner и получите промокод на 30 000 ₸ для первого запуска рекламы!' | translate}}
  </div>
  <div class="progress-company-steps">
    <div [attr.data-id]="1" class="step" [ngClass]="{'active': !existCompany, 'done': existCompany}">
      <div class="step-center">
        {{'Прикрепите компанию' | translate}}
        <button (click)="attachCompany()">{{'Прикрепить' | translate}}</button>
      </div>
    </div>
    <div [attr.data-id]="2" class="step" [ngClass]="{'active': existCompany}">
      <div class="step-center">
        {{'Заполните визитку компании' | translate}}
        <button [routerLink]="['/company-info']" (click)="yaEvent()" [queryParams]="{fromYandex: true}">{{'Заполнить' | translate}}</button>
      </div>
    </div>
    <div [attr.data-id]="3" class="step">{{'Получите промокод' | translate}}</div>
  </div>
</div>

<div class="success-company" *ngIf="filledCompany">
  <div class="success-company-left">
    <div class="success-company-left-title">{{'Вам подарок!' | translate}}</div>
    <div class="success-company-left-description">{{'Дарим 30 000 ₸ на первый запуск рекламы при минимальном бюджете рекламой компании от 30 000 ₸' | translate}}</div>
  </div>
  <div class="success-company-right">
    <button (click)="getYandexCode()"><p-progressSpinner styleClass="custom-spinner" strokeWidth="3" *ngIf="yaLoad"></p-progressSpinner>
      <ng-container *ngIf="!yaLoad">{{yaCode ? yaCode : ('Показать промокод' | translate)}} <span *ngIf="yaCode" class="copy-text" [ngClass]="{'copied': yaCopied}">{{(yaCopied ? 'Скопировано' : 'Скопировать') | translate}}</span></ng-container></button>
  </div>
</div>

<app-signing (callback)="updateAfterAdd()"></app-signing>

<div id="yandex-banner"></div>
