<div class="acc-tabs">
    <p-tabView (onChange)="changeRoute($event)" [(activeIndex)]="activeIndex" *ngIf="loaded">
        <p-tabPanel [header]="'TuOrders' | translate"></p-tabPanel>
        <p-tabPanel [header]="'Sales' | translate"></p-tabPanel>
        <p-tabPanel [header]="'Purchases' | translate"></p-tabPanel>
        <p-tabPanel [header]="'MyProductsAndServices' | translate"></p-tabPanel>
        <p-tabPanel [disabled]="!(stateModeration === 4 || stateModeration === 5)" [header]="'CreateShopTxt-4' | translate" *ngIf="stateModeration >= 1"></p-tabPanel>
        <p-tabPanel [header]="'Яндекс Бизнес' | translate" ></p-tabPanel>
    </p-tabView>
</div>
<router-outlet></router-outlet>
<app-nav-bar></app-nav-bar>
