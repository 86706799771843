import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebViewCommunicationService {

  constructor() { }

  sendAction(message: string): void {
    const data = {
      type: 'action',
      message: message
    };
    (window as any).ReactNativeWebView?.postMessage(JSON.stringify(data));
  }

}
