import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignControllerService {
  constructor(private http: HttpClient) {}

  attachEds(xml: any) : Observable<any> {
    return this.http.post('/proxy/profile-api.bpmn/api/v1/eds/attach', { signedXml: xml });
  }

  verifyEds(xml: string, uin: string) : Observable<any> {
    return this.http.post('/proxy/profile-api.bpmn/api/v1/eds/verify', { uin: uin, signedXml: xml });
  }

  attachQR(mobile: boolean = false, uin?: string, isNotIp: boolean = false) : Observable<any> {
    let body = {};
    let action = 'attach'
    if(uin) {
      action = 'verify'
      body = { uin: uin }
    }
    let url = `/proxy/profile-api.bpmn/api/v1/qr-sign${(mobile ? '/mobile' : '')}/${action}?is-not-ip=${isNotIp}`;
    return this.http.post(url, body)
  }

  qrForSign(mobile: boolean = false, data: any) : Observable<any> {
    return this.http.post(`/proxy/profile-api.bpmn/api/v1/qr-sign/sign${(mobile ? '/mobile' : '')}/xml`, data)

  }

  universalQrForSign(endpoint: string, mobile: boolean = false, data: any) : Observable<any> {
    return this.http.post(mobile ? endpoint : endpoint.replace('mobile/', ''), data)
  }

  bmg(bin: any) : Observable<any> {
    return this.http.post(`/proxy/profile-api.bpmn/api/v1/bmg/attach`, {uin: bin})
  }

  companyInfo(bin: any, locale: string = 'ru') : Observable<any> {
    return this.http.get(`/proxy/profile-api.bpmn/api/v1/profile/public/get-company-info/by-bin/${bin}?lang=${locale == 'kk' ? 'kz' : locale}`)
  }
}
