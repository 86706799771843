import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MainService} from "../../../../services/main/main.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {HeaderService} from "../../../../services/header/header.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.sass']
})

export class CompanyInfoComponent {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent | undefined;
  @Input() hideNotTU: boolean = true;
  @Input() binTU: any;
  @Output() continue= new EventEmitter<boolean>();
  @Output() cancel= new EventEmitter<boolean>();

  imageChangedEvent: any = '';
  croppedImageLogo: any = '';
  croppedImageBanner: any = '';
  items = [{label: 'Маркет', routerLink: '/'}, {label: 'Визитка компании'}];
  companyData: any;
  imgLogo: any;
  imgBanner: any;
  tgField = false;
  insField = false;
  companyForm!: FormGroup;
  error: boolean = false;
  userId: any;
  qr: any;
  callback = null;
  name!: string;
  cropModalLogo = false;
  cropModalBanner = false;
  informSendingModeration = false;
  fromYa: boolean = false;

  constructor(public translate: TranslateService, private main: MainService,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder, private headerService: HeaderService, private route: Router) {
    this.activatedRoute.queryParamMap.subscribe((paramMap) => {
      this.fromYa = paramMap.get('fromYandex') == 'true' || false;
    });
  }

  ngOnInit() {
    this.initMainForm();
    if (this.headerService.currentCompany) {
      if (this.binTU) {
        this.getCompany(this.binTU);
      } else {
        this.getCompany(this.headerService.currentCompany);
      }
    }
    this.headerService.companyChangeEmitter.subscribe(company => {
      if (this.binTU) {
        this.getCompany(this.binTU);
      } else {
        this.getCompany(company);
      }
    });
  }

  initMainForm() {
    this.companyForm = this.fb.group({
      bin: new FormControl(null, [Validators.required]),
      trade_name_ru: new FormControl('', [Validators.required]),
      field_of_activity_ru: new FormControl('', [Validators.required]),
      show_bin: [false],
      show_name: [false],
      description_ru: new FormControl('', [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      website: new FormControl(),
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^7[0124567][0-8]\d{7}$/i)]),
      show_phone: [false],
      street: new FormControl(null, [Validators.required]),
      show_address: [false],
      whatsapp: new FormControl(null, [Validators.required]),
      telegram: new FormControl(),
      instagram: new FormControl()
    })
  }

  textLength(text: any) {
    return text ? text.length : 0;
  }

  request() {
    let formdata = new FormData();
    formdata.append("updateCompanyRequest", new Blob([JSON.stringify(
        this.companyForm.value
      )], {
        type: "application/json"
      }
    ));
    if (this.imgLogo) {
      const fileExtension = this.imgLogo.type.split('/')[1];
      formdata.append('avatarImg', this.imgLogo, `logo.${fileExtension}`);
    }
    if (this.imgBanner) {
      const fileExtension = this.imgBanner.type.split('/')[1];
      formdata.append('bannerImg', this.imgBanner, `banner.${fileExtension}`);
    }
    this.main.editCompany(formdata).subscribe((data: any) => {
      // @ts-ignore
      window['yaCounter48820577'].reachGoal('obshee-kolichestvo-dobavleniy-kompaniy');
      if (!this.hideNotTU) {
        this.continue.emit();
      } else {
        this.informSendingModeration = true;
      }
    });
  }

  getCompany(bin: any) {
    this.main.companyAddData(bin).subscribe({
      next: (res: any) => {
        this.companyData = res;
        this.companyForm.get('bin')?.setValue(res.bin);
        this.companyForm.get('street')?.setValue(res.street);
        this.companyForm.get('description_kz')?.setValue(res.shortDescriptionKz);
        this.companyForm.get('description_ru')?.setValue(res.shortDescriptionRu);
        this.companyForm.get('trade_name_kz')?.setValue(res.tradeNameKz);
        this.companyForm.get('trade_name_ru')?.setValue(res.tradeNameRu);
        this.name = this.translate.currentLang == 'ru' ? res.nameRu : res.nameKz
      }
    });
  }

  changeShowField(fieldName: string) {
    this.companyForm.get(fieldName)?.setValue(!this.companyForm.get(fieldName)?.value);
  }

  fileChangeEvent(event: any, fileNumber: number): void {
    if (fileNumber == 1) {
      this.imageChangedEvent = event;
      this.cropModalLogo = true;
    } else {
      this.imageChangedEvent = event;
      this.cropModalBanner = true;
    }
  }

  imageCropped(event: ImageCroppedEvent, fileNumber: number) {
    if (fileNumber == 1) {
      this.croppedImageLogo = event;
    } else {
      this.croppedImageBanner = event;
    }
  }

  cropIt(fileNumber: number) {
    if (fileNumber == 1) {
      this.cropModalLogo = false;
      this.imgLogo = this.croppedImageLogo.blob
    } else {
      this.cropModalBanner = false;
      this.imgBanner = this.croppedImageBanner.blob;
    }
  }

  imageLoaded() {
  }

  cropperReady() {
  }

  loadImageFailed() {
  }

  closeModal() {
    this.route.navigate(['account/my-products-and-services']);
  }
}
