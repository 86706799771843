<div class="main-table bg-white p-3">
  <div class="head-search-bar flex justify-content-between bg-white p-3">
    <div>
      <span class="p-input-icon-left relative" *ngIf="products.content?.length > 0">
        <i class="pi pi-search"></i>
        <input [placeholder]="'Search' | translate" class="main-search" type="text" pInputText [(ngModel)]="tableParams.searchText" />
        <img *ngIf="tableParams.searchText != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
        <span class="search-txt" (click)="quickSearch()">{{ 'Find' | translate}}</span>
      </span>
    </div>
  </div>
  <ng-container *ngIf="products.content?.length > 0">
    <p-table [loading]="loadTable" [first]="0" [rows]="10" [value]="products?.content" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "DateOfCreation" | translate }}</th>
          <th>{{ "LastModifiedDate" | translate }}</th>
          <th>{{ "Name" | translate }}</th>
          <th>{{ "Type" | translate }}</th>
          <th>{{ "Salesman" | translate }}</th>
          <th>{{ "Status" | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr>
          <td>{{ product.create_date | date: 'dd.MM.yy HH:mm' }}</td>
          <td>{{ product.last_update_date | date: 'dd.MM.yy HH:mm'}}</td>
          <td>
            {{ translate.currentLang == 'ru' ? product?.card_name_ru : product?.card_name_kz }}
          </td>
          <td>
            {{ translate.currentLang == 'ru' ? product?.type?.name_ru : product?.type?.name_kz }}
          </td>
          <td>{{ product.card_company_name | shortname }}</td>
          <td>
            <div class="state" [ngClass]="{'green': product.state.color_id == 0, 'yellow': product.state.color_id == 1, 'blue': product.state.color_id == 4, 'red': product.state.color_id == 2}">
              {{ translate.currentLang == 'ru' ? product?.state?.name_ru : product?.state?.name_kz }}
            </div>
          </td>
          <td style="position:relative;">
            <div *ngIf="product && (product.state.id == 4 || product.state.id == 2)" (click)="menu.toggle($event)" class="dropdown-menu">
              <svg class="cursor-pointer"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6654 4.66667C11.6654 5.58714 10.9192 6.33333 9.9987 6.33333C9.07822 6.33333 8.33203 5.58714 8.33203 4.66667C8.33203 3.74619 9.07822 3 9.9987 3C10.9192 3 11.6654 3.74619 11.6654 4.66667ZM11.6654 10.5C11.6654 11.4205 10.9192 12.1667 9.9987 12.1667C9.07822 12.1667 8.33203 11.4205 8.33203 10.5C8.33203 9.57952 9.07822 8.83333 9.9987 8.83333C10.9192 8.83333 11.6654 9.57952 11.6654 10.5ZM9.9987 18C10.9192 18 11.6654 17.2538 11.6654 16.3333C11.6654 15.4129 10.9192 14.6667 9.9987 14.6667C9.07822 14.6667 8.33203 15.4129 8.33203 16.3333C8.33203 17.2538 9.07822 18 9.9987 18Z" fill="#637381"/>
              </svg>
              <p-menu (onShow)="changeLang(product)" class="table-menu" #menu [model]="pmenu[product.state.id]" [popup]="true"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="table relative mt-2">
      <p-paginator [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} из {totalRecords}" (onPageChange)="changePage($event)" [first]="0" [rows]="10" [rowsPerPageOptions]="[10, 20, 30]" [totalRecords]="products?.totalElements"></p-paginator>
      <div class="show-options">{{ "Show" | translate }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="products.content?.length == 0">
    <div class="block-no-content">
      <div inlineSVG="assets/images/main/no-content.svg"></div>
      <div class="text-no-content">{{'youHaveNoCompletedPurchases' | translate}}</div>
    </div>
  </ng-container>
</div>

<p-dialog (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{width: '400px'}" [draggable]="true" [resizable]="false">
  <div *ngIf="!reviewSuccess">
    <div class="text-center">
      <h5>{{ 'LeaveFeedbackToSeller' | translate  }}</h5>
    </div>
    <div class="text-center my-3 review-wrap">
      <p-rating [(ngModel)]="reviewData.grade" class="mr-2 pt-1" [stars]="5" [readonly]="false">
        <ng-template class="star-fill" pTemplate="onicon">
          <img src="/assets/images/main/star-filled-icon.svg" width="50px" height="50px" class="cursor-pointer" />
        </ng-template>
        <ng-template pTemplate="officon">
          <img class="star" src="/assets/images/main/star-icon.svg" width="50px" height="50px" class="cursor-pointer" />
        </ng-template>
      </p-rating>
    </div>
    <div class="review-txt-area flex">
      <textarea [(ngModel)]="reviewData.review" [placeholder]="'WriteYourReview' | translate" class="mx-auto" [rows]="5" [cols]="60" pInputTextarea></textarea>
    </div>
    <div class="feedback-btn-group">
      <div>
        <button [disabled]="disableBtn()" (click)="sendReview()" class="justify-content-center" pButton type="button">
          {{ 'PostAReview' | translate  }}
        </button>
      </div>
      <div>
        <button (click)="cancel()" class="cancel-review">{{ 'Cancel' | translate  }}</button>
      </div>
    </div>
  </div>
  <div *ngIf="reviewSuccess">
    <div class="text-center">
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" fill="white"/>
        <path d="M52.8203 8.5C48.8639 6.88814 44.5357 6 40 6C21.2223 6 6 21.2223 6 40C6 58.7777 21.2223 74 40 74C58.7777 74 74 58.7777 74 40C74 37.9527 73.819 35.9477 73.4723 34" stroke="#0D87EF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75 10L37.2857 48L27 37.6364" stroke="#0D87EF" stroke-width="5" stroke-linecap="round"/>
      </svg>
    </div>
    <div class="text-center">
      <div class="info-head">
        {{ "ReviewSent" | translate }}
      </div>
      <div class="info-txt">
        {{ "AfterPassingModerationItWillBePublished" | translate }}
      </div>
    </div>
    <div class="feedback-btn-group">
      <div>
        <button (click)="cancel()" class="justify-content-center" pButton type="button">
          {{ 'Continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</p-dialog>


