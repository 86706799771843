<div class="filter-wrap bg-white">
  <div class="first-filter-wrap">
    <div class="filter-body">
      <div class="mb-3 top-catalog">
        <svg *ngIf="headerService._catalogPage > 0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="back()">
          <g clip-path="url(#clip0_8060_139373)">
            <path d="M15.0005 7.00022H3.83047L8.71047 2.12021C9.10047 1.73021 9.10047 1.09021 8.71047 0.700215C8.32047 0.310215 7.69047 0.310215 7.30047 0.700215L0.710469 7.29022C0.320469 7.68021 0.320469 8.31021 0.710469 8.70021L7.30047 15.2902C7.69047 15.6802 8.32047 15.6802 8.71047 15.2902C9.10047 14.9002 9.10047 14.2702 8.71047 13.8802L3.83047 9.00021H15.0005C15.5505 9.00021 16.0005 8.55021 16.0005 8.00022C16.0005 7.45021 15.5505 7.00022 15.0005 7.00022Z" fill="#363636"/>
          </g>
          <defs>
            <clipPath id="clip0_8060_139373">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>

        {{nameCheck()}}
      </div>
      <div *ngIf="headerService._catalogPage == 0" class="">
        <ng-container *ngFor="let item of this.headerService._catalog; index as i">
          <div class="catalog-item" [ngClass]="{'active': item.active}" (click)="setActive(1, item, i)">
            {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
          </div>
        </ng-container>
      </div>
      <div *ngIf="headerService._catalogPage == 1" class="">
        <ng-container *ngFor="let item of this.headerService._catalog[headerService._typeIndex].parentlist; index as i">
          <div class="catalog-item" [ngClass]="{'active': item.active}" (click)="setActive(2, item, i)">
            {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
          </div>
        </ng-container>
      </div>
      <div *ngIf="headerService._catalogPage == 2" class="">
        <ng-container *ngFor="let item of this.headerService._catalog[headerService._typeIndex].parentlist[headerService._parentIndex].child_categories; index as i">
          <div class="catalog-item" [ngClass]="{'active': item.active}" (click)="setActive(3, item, i)">
            {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
          </div>
        </ng-container>
      </div>
      <div class="mt-3 filter-head">
        {{ 'Price' | translate }}
      </div>
      <div class="filter-content">
        <div class="range-wrap">
          <p-inputNumber [(ngModel)]="searchService._priceRange[0]" prefix="{{ 'From' | translate }} " (ngModelChange)="changeValue(0)" [max]="100000000" [min]="0"> </p-inputNumber>
          <p-inputNumber [(ngModel)]="searchService._priceRange[1]" prefix="{{ 'UpTo' | translate }} " (ngModelChange)="changeValue(1)" [max]="100000000" [min]="0"> </p-inputNumber>
        </div>

<!--        <p-slider [(ngModel)]="searchService._priceRange" [range]="true"></p-slider>-->
      </div>
      <div class="mt-3 filter-head">
        {{ 'Region' | translate }}
      </div>
      <div class="filter-checkboxes">
        <div class="filter-search-block" *ngIf="cityNumber > 5">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8714_2907)">
              <path d="M12.9162 11.6667H12.2579L12.0245 11.4417C13.0245 10.275 13.5412 8.68337 13.2579 6.9917C12.8662 4.67503 10.9329 2.82503 8.59955 2.5417C5.07454 2.10837 2.10788 5.07503 2.54121 8.60003C2.82454 10.9334 4.67455 12.8667 6.99121 13.2584C8.68288 13.5417 10.2745 13.025 11.4412 12.025L11.6662 12.2584V12.9167L15.2079 16.4584C15.5495 16.8 16.1079 16.8 16.4495 16.4584C16.7912 16.1167 16.7912 15.5584 16.4495 15.2167L12.9162 11.6667ZM7.91621 11.6667C5.84121 11.6667 4.16621 9.9917 4.16621 7.9167C4.16621 5.8417 5.84121 4.1667 7.91621 4.1667C9.99121 4.1667 11.6662 5.8417 11.6662 7.9167C11.6662 9.9917 9.99121 11.6667 7.91621 11.6667Z" fill="#6C757D"/>
            </g>
            <defs>
              <clipPath id="clip0_8714_2907">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <input [placeholder]="'Search' | translate" type="text" [(ngModel)]="filteredRegion">
          <svg *ngIf="filteredRegion.length > 0"
               (click)="filteredRegion = ''"
               class="close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8059_65072)">
              <path d="M15.5748 4.43417C15.2298 4.08908 14.6723 4.08908 14.3272 4.43417L10.0003 8.75221L5.67344 4.42532C5.32835 4.08023 4.7709 4.08023 4.42581 4.42532C4.08072 4.77041 4.08072 5.32786 4.42581 5.67295L8.7527 9.99984L4.42581 14.3267C4.08072 14.6718 4.08072 15.2293 4.42581 15.5744C4.7709 15.9194 5.32835 15.9194 5.67344 15.5744L10.0003 11.2475L14.3272 15.5744C14.6723 15.9194 15.2298 15.9194 15.5748 15.5744C15.9199 15.2293 15.9199 14.6718 15.5748 14.3267L11.248 9.99984L15.5748 5.67295C15.9111 5.33671 15.9111 4.77041 15.5748 4.43417Z" fill="#6C757D"/>
            </g>
            <defs>
              <clipPath id="clip0_8059_65072">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>

        </div>
        <div class="checkbox-list">
          <div *ngFor="let item of filterParams(cities, filteredRegion, 'region_name_ru', 'region_name_kz'); index as i"
               class="checkbox-line" [ngClass]="{'py-1': i < cityNumber}">
            <ng-container *ngIf="i < cityNumber">
              <p-checkbox name="group1" [value]="item.id" [(ngModel)]="searchService.regionId" (onChange)="checkAll(item.id)"
                          [inputId]="translate.currentLang == 'ru' ? item?.region_name_ru : item?.region_name_kz">
                <ng-template pTemplate="icon">
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.375 6.16487L3.92789 8.9375L9.625 2.75" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </ng-template>
              </p-checkbox>
              <label class="w-full cursor-pointer" [for]="translate.currentLang == 'ru' ? item?.region_name_ru : item?.region_name_kz">
                {{ translate.currentLang == 'ru' ? item?.region_name_ru : item?.region_name_kz }}
              </label>
            </ng-container>
          </div>
        </div>
        <div class="show-all" >
          <span *ngIf="cityNumber != cities.length" (click)="cityNumber = cities.length">
            {{ 'Показать всё' | translate }}
          </span>
          <span *ngIf="cityNumber == cities.length" (click)="cityNumber = 5; filteredRegion = ''">
            {{ 'Свернуть' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
