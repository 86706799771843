<div class="flex align-items-center py-4">
  <svg class="cursor-pointer" [routerLink]="'/account/tu-orders/'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7359_6097)">
      <path d="M19 11.4548H7.82998L12.71 6.5748C13.1 6.1848 13.1 5.5448 12.71 5.1548C12.32 4.7648 11.69 4.7648 11.3 5.1548L4.70998 11.7448C4.31998 12.1348 4.31998 12.7648 4.70998 13.1548L11.3 19.7448C11.69 20.1348 12.32 20.1348 12.71 19.7448C13.1 19.3548 13.1 18.7248 12.71 18.3348L7.82998 13.4548H19C19.55 13.4548 20 13.0048 20 12.4548C20 11.9048 19.55 11.4548 19 11.4548Z" fill="#363636"/>
    </g>
    <defs>
      <clipPath id="clip0_7359_6097">
        <rect width="24" height="24" fill="white" transform="translate(0 0.455078)"/>
      </clipPath>
    </defs>
  </svg>

  <div class="ml-2">
    {{ 'Заявка' | translate }} № {{cardInfo.crmOrderId}}
  </div>
</div>
<div class="">
  <div class="flex">
    <div class="order-status mr-4">
      <div class="status-head">
        {{ 'Статус заявки' | translate }}
      </div>
      <div class="tu-status" [ngClass]="{'first-step': activeIndex == 0, 'second-step': activeIndex == 1, 'third-step': activeIndex == 2, 'fourth-step': activeIndex == 3, 'rejected': statusSteps.length == 2}">
        <p-steps [(activeIndex)]="activeIndex" [model]="statusSteps" [readonly]="true"></p-steps>
      </div>
    </div>
    <div class="w-full">
      <div class="tu-panel">
        <p-panel *ngIf="cardInfo.state == 'NEW'" [toggleable]="true" [collapsed]="false"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              {{ 'Заявка' | translate }} № {{cardInfo.crmOrderId}}
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div  class="flex">
            <div *ngIf="cardInfo?.state != 'REJECTED'" class="blue-btn mr-3" (click)="takeToWork()">

              <span *ngIf="!loading">
                {{ 'Принять в работу' | translate }}
              </span>

              <svg *ngIf="loading" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.75 9C16.75 7.46719 16.2955 5.96881 15.4439 4.69433C14.5923 3.41985 13.3819 2.42651 11.9658 1.83993C10.5497 1.25335 8.9914 1.09988 7.48805 1.39891C5.9847 1.69795 4.60378 2.43607 3.51992 3.51992C2.43607 4.60378 1.69795 5.9847 1.39891 7.48805C1.09988 8.9914 1.25335 10.5497 1.83993 11.9658C2.42651 13.3819 3.41985 14.5923 4.69433 15.4439C5.96881 16.2955 7.46719 16.75 9 16.75" stroke="white" stroke-width="1.5"/>
              </svg>

            </div>
            <div *ngIf="cardInfo?.state != 'REJECTED'" class="white-btn" (click)="rejectApplication = true">
              {{ 'Reject' | translate }}
            </div>
          </div>
          <div *ngIf="cardInfo?.state == 'REJECTED'" class="red-info-block mt-3">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.16675 4.28874H9.83342V5.9554H8.16675V4.28874ZM9.00008 12.6221C9.45842 12.6221 9.83342 12.2471 9.83342 11.7887V8.4554C9.83342 7.99707 9.45842 7.62207 9.00008 7.62207C8.54175 7.62207 8.16675 7.99707 8.16675 8.4554V11.7887C8.16675 12.2471 8.54175 12.6221 9.00008 12.6221ZM9.00008 0.12207C4.40008 0.12207 0.666748 3.8554 0.666748 8.4554C0.666748 13.0554 4.40008 16.7887 9.00008 16.7887C13.6001 16.7887 17.3334 13.0554 17.3334 8.4554C17.3334 3.8554 13.6001 0.12207 9.00008 0.12207ZM9.00008 15.1221C5.32508 15.1221 2.33341 12.1304 2.33341 8.4554C2.33341 4.7804 5.32508 1.78874 9.00008 1.78874C12.6751 1.78874 15.6667 4.7804 15.6667 8.4554C15.6667 12.1304 12.6751 15.1221 9.00008 15.1221Z" fill="#D11635"/>
            </svg>

            <div class="">
              {{ 'Вы отклонили эту заявку' | translate }}
            </div>
          </div>
        </p-panel>
        <p-panel *ngIf="cardInfo?.state == 'IN_WORK' || cardInfo?.state == 'COMPLETE'" [toggleable]="true" [collapsed]="false"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div *ngIf="cardInfo?.state != 'COMPLETE'" class="">
              {{ 'Коммерческое предложение на рассмотрении у заказчика' | translate }}
            </div>
            <div *ngIf="cardInfo?.state == 'COMPLETE'" class="">
              {{ 'Ваше коммерческое предложение принято!' | translate }}
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="blue-info-block" [ngClass]="{'blue-info-block': cardInfo?.state != 'COMPLETE', 'green-info-block': cardInfo?.state == 'COMPLETE'}">
            <svg *ngIf="cardInfo?.state != 'COMPLETE'" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7720_4740)">
                <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#0D87EF"/>
              </g>
              <defs>
                <clipPath id="clip0_7720_4740">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                </clipPath>
              </defs>
            </svg>
            <svg *ngIf="cardInfo?.state == 'COMPLETE'" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7720_4740)">
                <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#05A660"/>
              </g>
              <defs>
                <clipPath id="clip0_7720_4740">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                </clipPath>
              </defs>
            </svg>
            <div *ngIf="cardInfo?.state != 'COMPLETE'" class="">
              {{ 'Заказчик рассматривает ваше коммерческое предложение. Мы уведомим вас о решении, независимо от результата' | translate }}
            </div>
            <div *ngIf="cardInfo?.state == 'COMPLETE'" class="">
              {{ 'Заказчик выбрал вас в качестве подрядчика. Свяжитесь с заказчиком для обсуждения дальнейших деталей' | translate }}
            </div>
          </div>
          <div class="mt-4">

            <div class="copy-board-disabled">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Стоимость услуг' | translate }}
                </div>
                <div class="">
                  {{ cardInfo?.priceOfWork }}
                </div>
              </div>
            </div>
            <div class="copy-board-disabled">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Срок строительства' | translate }}
                </div>
                <div class="">
                  {{ cardInfo?.durationOfConstruction }}
                </div>
              </div>
            </div>
            <div class="copy-board-disabled">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Коммерческое предложение' | translate }}
                </div>
                <div class="">
                  <div class="file-preview">
                    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9703 1.89551H7.65031C5.8112 1.89551 4.32031 3.3864 4.32031 5.22551V31.8655C4.32031 33.7046 5.8112 35.1955 7.65031 35.1955H27.6303C29.4694 35.1955 30.9603 33.7046 30.9603 31.8655V11.8855L20.9703 1.89551Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M20.9707 1.89551V11.8855H30.9607" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 18.4551H11.4C11.86 18.4551 12.26 18.5084 12.6 18.6151C12.94 18.7217 13.22 18.8717 13.44 19.0651C13.6667 19.2517 13.8333 19.4784 13.94 19.7451C14.0467 20.0051 14.1 20.2917 14.1 20.6051C14.1 20.9184 14.0467 21.2084 13.94 21.4751C13.8333 21.7351 13.6667 21.9617 13.44 22.1551C13.22 22.3417 12.94 22.4884 12.6 22.5951C12.26 22.7017 11.86 22.7551 11.4 22.7551H9.94V25.4551H9V18.4551ZM11.35 21.9351C11.6833 21.9351 11.9633 21.9051 12.19 21.8451C12.4233 21.7784 12.61 21.6884 12.75 21.5751C12.8967 21.4617 13 21.3217 13.06 21.1551C13.1267 20.9884 13.16 20.8051 13.16 20.6051C13.16 20.4051 13.1267 20.2217 13.06 20.0551C13 19.8884 12.8967 19.7484 12.75 19.6351C12.61 19.5217 12.4233 19.4351 12.19 19.3751C11.9633 19.3084 11.6833 19.2751 11.35 19.2751H9.94V21.9351H11.35ZM15.5527 18.4551H17.6027C18.1294 18.4551 18.5994 18.5284 19.0127 18.6751C19.4261 18.8151 19.7761 19.0251 20.0627 19.3051C20.3494 19.5851 20.5694 19.9351 20.7227 20.3551C20.8761 20.7751 20.9527 21.2584 20.9527 21.8051V22.0051C20.9527 22.5651 20.8727 23.0617 20.7127 23.4951C20.5594 23.9217 20.3327 24.2817 20.0327 24.5751C19.7394 24.8617 19.3794 25.0817 18.9527 25.2351C18.5261 25.3817 18.0427 25.4551 17.5027 25.4551H15.5527V18.4551ZM17.4027 24.6351C17.7961 24.6351 18.1494 24.5851 18.4627 24.4851C18.7827 24.3851 19.0527 24.2317 19.2727 24.0251C19.4927 23.8117 19.6594 23.5384 19.7727 23.2051C19.8927 22.8717 19.9527 22.4717 19.9527 22.0051V21.8051C19.9527 21.3584 19.8961 20.9751 19.7827 20.6551C19.6694 20.3351 19.5061 20.0751 19.2927 19.8751C19.0794 19.6684 18.8194 19.5184 18.5127 19.4251C18.2127 19.3251 17.8761 19.2751 17.5027 19.2751H16.4927V24.6351H17.4027ZM22.5547 18.4551H27.0547V19.2751H23.4947V21.5451H26.7547V22.3651H23.4947V25.4551H22.5547V18.4551Z" fill="#0D87EF"/>
                    </svg>

                    <div class="flex w-full flex-column">
                      <div class="file-name">
                        {{ 'Коммерческое предложение' | translate }}.pdf
                      </div>

                    </div>
                    <div class="link-block">
                      <svg (click)="openFile('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+cardInfo?.contractUrl)" class="link cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.4553C2 12.4553 5.63636 5.18262 12 5.18262C18.3636 5.18262 22 12.4553 22 12.4553C22 12.4553 18.3636 19.7281 12 19.7281C5.63636 19.7281 2 12.4553 2 12.4553Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 15.1831C13.506 15.1831 14.727 13.962 14.727 12.4558C14.727 10.9496 13.506 9.72852 11.9997 9.72852C10.4935 9.72852 9.27246 10.9496 9.27246 12.4558C9.27246 13.962 10.4935 15.1831 11.9997 15.1831Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                      <svg (click)="downloadFile('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+cardInfo?.contractUrl, (translate.currentLang == 'ru' ? 'Коммерческое предложение.pdf' : 'Коммерциялық ұсыныс.pdf'))" class="link cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 21.4551L21 21.4551M6 11.4551L12 17.4551M12 17.4551L18 11.4551M12 17.4551L12 3.45508" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>

                  </div>

                </div>

              </div>


            </div>
            <div class="copy-board-disabled">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Форма договора' | translate }}
                </div>
                <div class="">
                  <div class="file-preview">
                    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9703 1.89551H7.65031C5.8112 1.89551 4.32031 3.3864 4.32031 5.22551V31.8655C4.32031 33.7046 5.8112 35.1955 7.65031 35.1955H27.6303C29.4694 35.1955 30.9603 33.7046 30.9603 31.8655V11.8855L20.9703 1.89551Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M20.9707 1.89551V11.8855H30.9607" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 18.4551H11.4C11.86 18.4551 12.26 18.5084 12.6 18.6151C12.94 18.7217 13.22 18.8717 13.44 19.0651C13.6667 19.2517 13.8333 19.4784 13.94 19.7451C14.0467 20.0051 14.1 20.2917 14.1 20.6051C14.1 20.9184 14.0467 21.2084 13.94 21.4751C13.8333 21.7351 13.6667 21.9617 13.44 22.1551C13.22 22.3417 12.94 22.4884 12.6 22.5951C12.26 22.7017 11.86 22.7551 11.4 22.7551H9.94V25.4551H9V18.4551ZM11.35 21.9351C11.6833 21.9351 11.9633 21.9051 12.19 21.8451C12.4233 21.7784 12.61 21.6884 12.75 21.5751C12.8967 21.4617 13 21.3217 13.06 21.1551C13.1267 20.9884 13.16 20.8051 13.16 20.6051C13.16 20.4051 13.1267 20.2217 13.06 20.0551C13 19.8884 12.8967 19.7484 12.75 19.6351C12.61 19.5217 12.4233 19.4351 12.19 19.3751C11.9633 19.3084 11.6833 19.2751 11.35 19.2751H9.94V21.9351H11.35ZM15.5527 18.4551H17.6027C18.1294 18.4551 18.5994 18.5284 19.0127 18.6751C19.4261 18.8151 19.7761 19.0251 20.0627 19.3051C20.3494 19.5851 20.5694 19.9351 20.7227 20.3551C20.8761 20.7751 20.9527 21.2584 20.9527 21.8051V22.0051C20.9527 22.5651 20.8727 23.0617 20.7127 23.4951C20.5594 23.9217 20.3327 24.2817 20.0327 24.5751C19.7394 24.8617 19.3794 25.0817 18.9527 25.2351C18.5261 25.3817 18.0427 25.4551 17.5027 25.4551H15.5527V18.4551ZM17.4027 24.6351C17.7961 24.6351 18.1494 24.5851 18.4627 24.4851C18.7827 24.3851 19.0527 24.2317 19.2727 24.0251C19.4927 23.8117 19.6594 23.5384 19.7727 23.2051C19.8927 22.8717 19.9527 22.4717 19.9527 22.0051V21.8051C19.9527 21.3584 19.8961 20.9751 19.7827 20.6551C19.6694 20.3351 19.5061 20.0751 19.2927 19.8751C19.0794 19.6684 18.8194 19.5184 18.5127 19.4251C18.2127 19.3251 17.8761 19.2751 17.5027 19.2751H16.4927V24.6351H17.4027ZM22.5547 18.4551H27.0547V19.2751H23.4947V21.5451H26.7547V22.3651H23.4947V25.4551H22.5547V18.4551Z" fill="#0D87EF"/>
                    </svg>

                    <div class="flex w-full flex-column">
                      <div class="file-name">
                        {{ 'Форма договора' | translate }}.pdf
                      </div>

                    </div>
                    <div class="link-block">
                      <svg (click)="openFile('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+cardInfo?.contractUrl)" class="cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.4553C2 12.4553 5.63636 5.18262 12 5.18262C18.3636 5.18262 22 12.4553 22 12.4553C22 12.4553 18.3636 19.7281 12 19.7281C5.63636 19.7281 2 12.4553 2 12.4553Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 15.1831C13.506 15.1831 14.727 13.962 14.727 12.4558C14.727 10.9496 13.506 9.72852 11.9997 9.72852C10.4935 9.72852 9.27246 10.9496 9.27246 12.4558C9.27246 13.962 10.4935 15.1831 11.9997 15.1831Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                      <svg (click)="downloadFile('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+cardInfo?.contractUrl, (translate.currentLang == 'ru' ? 'Форма договора.pdf' : 'Келісімшарттың нысаны.pdf'))" class="cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 21.4551L21 21.4551M6 11.4551L12 17.4551M12 17.4551L18 11.4551M12 17.4551L12 3.45508" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>

        </p-panel>
        <p-panel *ngIf="cardInfo?.state == 'WAITING_OFFER'" [toggleable]="true" [collapsed]="false"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              {{ 'Отправка коммерческого предложения и формы договора' | translate }}
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="blue-info-block">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7720_4740)">
                <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#0D87EF"/>
              </g>
              <defs>
                <clipPath id="clip0_7720_4740">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                </clipPath>
              </defs>
            </svg>
            <div class="">
              {{ 'Ознакомьтесь с информацией в заявке. При необходимости свяжитесь с заказчиком для уточнения деталей, затем отправьте коммерческое предложение и форму договора' | translate}}
            </div>
          </div>
          <div class="flex">
            <div class="blue-btn mt-4" (click)="agreement = true">
              {{ 'Send' | translate }}
            </div>
          </div>
        </p-panel>
        <div *ngIf="cardInfo?.state != 'REJECTED'" class="mb-4"></div>
        <p-panel *ngIf="cardInfo?.state != 'NEW' && tuIsAvailable" [toggleable]="true" [collapsed]="false"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              {{ 'Specifications' | translate }}
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="flex">
            <a target="_blank" [href]="environment.ismetLink + '/bin/ocp/bpms.rest/bpmn/v2/kt/files/' + cardInfo?.crmOrderId">
              <div class="white-btn">
                {{ "DownloadSpecifications" | translate }}
              </div>
            </a>
          </div>
        </p-panel>
        <div class="mb-4"></div>
        <p-panel [toggleable]="true" [collapsed]="false"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              {{ 'RequestInfo' | translate }}
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="">
            <div class="copy-board" (click)="copy(translate.currentLang == 'ru' ? cardInfo.region?.region_name_ru : cardInfo?.region?.region_name_kz, 0)">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Region' | translate }}
                </div>
                <div class="">
                  {{ translate.currentLang == 'ru' ? cardInfo?.region?.region_name_ru : cardInfo?.region?.region_name_kz }}
                </div>
              </div>
              <svg *ngIf="showSvg[0]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[0]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="copy-board" (click)="copy(cardInfo?.fullName, 1)">
              <div class="w-full">
                <div class="copy-board-head">
                  {{'FIO'|translate}}
                </div>
                <div class="">
                  {{ cardInfo?.fullName }}
                </div>
              </div>

              <svg *ngIf="showSvg[1]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[1]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div *ngIf="cardInfo.state != 'NEW'" class="copy-board" (click)="copy(cardInfo?.phone, 2)">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Телефон' | translate }}
                </div>
                <div class="">
                  {{ cardInfo.phone }}
                </div>
              </div>

              <svg *ngIf="showSvg[1]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[1]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div *ngIf="cardInfo.state != 'NEW'" class="copy-board" (click)="copy(cardInfo?.email, 3)">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Почта' | translate }}
                </div>
                <div class="">
                  {{ cardInfo?.email }}
                </div>
              </div>

              <svg *ngIf="showSvg[1]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[1]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div *ngIf="cardInfo.state != 'NEW'" class="copy-board" (click)="copy(cardInfo?.comment, 4)">
              <div class="w-full">
                <div class="copy-board-head">
                  {{ 'Comment'|translate}}
                </div>
                <div class="">
                  {{ cardInfo?.comment }}
                </div>
              </div>

              <div class="svg-wrap">
                <svg *ngIf="showSvg[1]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg *ngIf="!showSvg[1]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_7359_55687)">
                    <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_7359_55687">
                      <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>

            </div>
            <div *ngIf="cardInfo?.state == 'NEW'" class="blue-info-block mt-2">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7720_4740)">
                  <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#0D87EF"/>
                </g>
                <defs>
                  <clipPath id="clip0_7720_4740">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="">
                {{ 'Вы сможете просмотреть всю информацию после принятия заявки в работу' | translate }}
              </div>
            </div>
          </div>
        </p-panel>

      </div>
    </div>
  </div>
</div>
<div class="tu-dialogs">
  <p-dialog [modal]="true" [(visible)]="rejectApplication" [closable]="true" [style]="{width: '25vw'}">
    <div class="text-center">
      <svg *ngIf="!rejected" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9999 3.79004C29.2066 3.79004 36.6666 11.2517 36.6666 20.4567C36.6666 29.6617 29.2066 37.1234 19.9999 37.1234C10.7966 37.1234 3.33325 29.6617 3.33325 20.4567C3.33325 11.2517 10.7966 3.79004 19.9999 3.79004ZM27.0833 19.2067H12.9166C12.5851 19.2067 12.2671 19.3384 12.0327 19.5728C11.7983 19.8072 11.6666 20.1252 11.6666 20.4567C11.6666 20.7882 11.7983 21.1062 12.0327 21.3406C12.2671 21.575 12.5851 21.7067 12.9166 21.7067H27.0833C27.4148 21.7067 27.7327 21.575 27.9671 21.3406C28.2016 21.1062 28.3333 20.7882 28.3333 20.4567C28.3333 20.1252 28.2016 19.8072 27.9671 19.5728C27.7327 19.3384 27.4148 19.2067 27.0833 19.2067Z" fill="#D51A52"/>
      </svg>
      <svg *ngIf="rejected" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7359_19356)">
          <path d="M19.9999 3.78809C10.7999 3.78809 3.33325 11.2548 3.33325 20.4548C3.33325 29.6548 10.7999 37.1214 19.9999 37.1214C29.1999 37.1214 36.6666 29.6548 36.6666 20.4548C36.6666 11.2548 29.1999 3.78809 19.9999 3.78809ZM15.4833 27.6048L9.49992 21.6214C8.84992 20.9714 8.84992 19.9214 9.49992 19.2714C10.1499 18.6214 11.1999 18.6214 11.8499 19.2714L16.6666 24.0714L28.1333 12.6048C28.7833 11.9548 29.8333 11.9548 30.4833 12.6048C31.1333 13.2548 31.1333 14.3048 30.4833 14.9548L17.8333 27.6048C17.1999 28.2548 16.1333 28.2548 15.4833 27.6048Z" fill="#05A660"/>
        </g>
        <defs>
          <clipPath id="clip0_7359_19356">
            <rect width="40" height="40" fill="white" transform="translate(0 0.455078)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="mt-4 text-center dialog-head">
    <span *ngIf="!rejected">
      {{ 'Отклонить заявку' | translate }}
    </span>
      <span *ngIf="rejected">
      {{ 'Заявка отклонена' | translate }}!
    </span>
    </div>
    <div class="mt-2 text-center">
    <span *ngIf="!rejected">
      {{ 'Вы действительно хотите отклонить эту заявку' | translate }}?
    </span>
      <span *ngIf="rejected">
      {{ 'Вы успешно отклонили заявку' | translate }}
    </span>
    </div>
    <div class="mt-4 dialog-btn-group">
      <div class="blue-btn mb-2" *ngIf="!rejected" (click)="reject()">
        {{ 'Да, отклонить' | translate}}
      </div>
      <div class="blue-btn" *ngIf="rejected" (click)="rejectApplication = false; rejected = false">
        {{ 'Fine' | translate }}
      </div>
      <div class="white-btn" *ngIf="!rejected">
        {{ 'Cancel' | translate }}
      </div>
    </div>
  </p-dialog>

  <p-dialog [modal]="true" [(visible)]="agreement" (onHide)="KpAndAppSended = false" [closable]="true" [style]="{width: '25vw'}">

    <div class="kp-success" *ngIf="KpAndAppSended">
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7359_6084)">
          <path d="M20.0002 3.78809C10.8002 3.78809 3.3335 11.2548 3.3335 20.4548C3.3335 29.6548 10.8002 37.1214 20.0002 37.1214C29.2002 37.1214 36.6668 29.6548 36.6668 20.4548C36.6668 11.2548 29.2002 3.78809 20.0002 3.78809ZM15.4835 27.6048L9.50016 21.6214C8.85016 20.9714 8.85016 19.9214 9.50016 19.2714C10.1502 18.6214 11.2002 18.6214 11.8502 19.2714L16.6668 24.0714L28.1335 12.6048C28.7835 11.9548 29.8335 11.9548 30.4835 12.6048C31.1335 13.2548 31.1335 14.3048 30.4835 14.9548L17.8335 27.6048C17.2002 28.2548 16.1335 28.2548 15.4835 27.6048Z" fill="#05A660"/>
        </g>
        <defs>
          <clipPath id="clip0_7359_6084">
            <rect width="40" height="40" fill="white" transform="translate(0 0.455078)"/>
          </clipPath>
        </defs>
      </svg>

      <div class="success-header">
        {{ 'Успешно отправлено' | translate }}!
      </div>
      <div class="success-txt">
        {{ 'Коммерческое предложение и договор отправлены заказчику' | translate }}
      </div>
      <div class="blue-btn w-full text-center mt-4" (click)="agreement = false">
        {{ 'Fine' | translate }}
      </div>
    </div>
    <div class="" *ngIf="!KpAndAppSended">
      <div class="text-center dialog-head mb-3">
        {{ 'Отправка КП и формы договора' | translate }}
      </div>

      <div class="text-sm text-center mb-4">
        {{ 'Заполните все поля и загрузите файлы' | translate }}
      </div>
      <div class="mb-4">
        <div class="text-sm field-head mb-1">
          {{ 'Стоимость услуг' | translate }}, ₸
        </div>
        <p-inputNumber [placeholder]="'Введите стоимость' | translate"
                       styleClass="w-full"
                       [(ngModel)]="price"
                       mode="decimal"
                       class="w-full"
                       (onInput)="priceErr = 0"
                       [ngClass]="{'ng-invalid ng-dirty': priceErr > 0}"
                       inputId="price" [useGrouping]="true"> </p-inputNumber>
        <div class="error-text" *ngIf="priceErr == 2">
          {{ 'Стоимость не может быть равна нулю' | translate }}
        </div>
        <div class="error-text" *ngIf="priceErr == 1">
          {{ 'Укажите стоимость' | translate }}
        </div>
      </div>

      <div class="mb-4">
        <div class="text-sm field-head mb-1">
          {{ 'Срок строительства' | translate }}
        </div>
        <input class="w-full" type="text" [(ngModel)]="duration"
               [ngClass]="{'field-err': durationErr}"
               (change)="durationErr = false"
               [placeholder]="'Например, 3 месяца' | translate">
        <div class="error-text" *ngIf="durationErr">
          {{ 'Укажите срок строительства' | translate }}
        </div>
      </div>
      <div class="">
        <div class="text-sm field-head mb-1">
          {{ 'Загрузите коммерческое предложение' | translate }}
        </div>


        <div *ngIf="!KPfile" class="file-upload" (drop)="onDrop($event, 'kp')"
             [ngClass]="{'file-err': KpHave == 1}"
             (dragover)="onDragOver($event, drg)" (click)="fileInput.click()" #drg>
          <div class="file-upload-head">
            {{ 'Нажмите, чтоб выбрать файл или перетащите файл в эту область' | translate }}
          </div>
          <div class="add-file" (dragleave)="onDragLeave(drg)">
            {{ 'Добавить 1 файл' | translate }}
          </div>
          <div class="file-upload-desc">
            {{ 'Один файл до 30 МБ в формате .pdf' | translate }}
          </div>
          <input type="file" #fileInput (change)="onFileSelected($event,'kp')" accept="application/pdf" hidden>
        </div>

        <div class="error-text" *ngIf="KpFileTypeErr && !KpFileSizeErr">
          {{ 'Файл должен быть в формате .pdf' | translate }}
        </div>
        <div class="error-text" *ngIf="KpFileSizeErr && !KpFileTypeErr">
          {{ 'Файл слишком большой, максимальный размер 30 МБ' | translate }}
        </div>
        <div class="error-text" *ngIf="KpFileSizeErr && KpFileTypeErr">
          {{ 'Файл должен быть в формате .pdf и не превышать 30 МБ' | translate }}
        </div>
        <div class="error-text" *ngIf="KpHave == 1">
          {{ 'Загрузите коммерческое предложение' | translate }}
        </div>

        <div class="file-loaded" *ngIf="KPfile">
          <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9701 1.43994H4.65007C2.81096 1.43994 1.32007 2.93083 1.32007 4.76994V31.4099C1.32007 33.249 2.81096 34.7399 4.65007 34.7399H24.6301C26.4692 34.7399 27.9601 33.249 27.9601 31.4099V11.4299L17.9701 1.43994Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.97 1.43994V11.4299H27.96" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 18H8.4C8.86 18 9.26 18.0533 9.6 18.16C9.94 18.2667 10.22 18.4167 10.44 18.61C10.6667 18.7967 10.8333 19.0233 10.94 19.29C11.0467 19.55 11.1 19.8367 11.1 20.15C11.1 20.4633 11.0467 20.7533 10.94 21.02C10.8333 21.28 10.6667 21.5067 10.44 21.7C10.22 21.8867 9.94 22.0333 9.6 22.14C9.26 22.2467 8.86 22.3 8.4 22.3H6.94V25H6V18ZM8.35 21.48C8.68333 21.48 8.96333 21.45 9.19 21.39C9.42333 21.3233 9.61 21.2333 9.75 21.12C9.89667 21.0067 10 20.8667 10.06 20.7C10.1267 20.5333 10.16 20.35 10.16 20.15C10.16 19.95 10.1267 19.7667 10.06 19.6C10 19.4333 9.89667 19.2933 9.75 19.18C9.61 19.0667 9.42333 18.98 9.19 18.92C8.96333 18.8533 8.68333 18.82 8.35 18.82H6.94V21.48H8.35ZM12.5527 18H14.6027C15.1294 18 15.5994 18.0733 16.0127 18.22C16.4261 18.36 16.7761 18.57 17.0627 18.85C17.3494 19.13 17.5694 19.48 17.7227 19.9C17.8761 20.32 17.9527 20.8033 17.9527 21.35V21.55C17.9527 22.11 17.8727 22.6067 17.7127 23.04C17.5594 23.4667 17.3327 23.8267 17.0327 24.12C16.7394 24.4067 16.3794 24.6267 15.9527 24.78C15.5261 24.9267 15.0427 25 14.5027 25H12.5527V18ZM14.4027 24.18C14.7961 24.18 15.1494 24.13 15.4627 24.03C15.7827 23.93 16.0527 23.7767 16.2727 23.57C16.4927 23.3567 16.6594 23.0833 16.7727 22.75C16.8927 22.4167 16.9527 22.0167 16.9527 21.55V21.35C16.9527 20.9033 16.8961 20.52 16.7827 20.2C16.6694 19.88 16.5061 19.62 16.2927 19.42C16.0794 19.2133 15.8194 19.0633 15.5127 18.97C15.2127 18.87 14.8761 18.82 14.5027 18.82H13.4927V24.18H14.4027ZM19.5547 18H24.0547V18.82H20.4947V21.09H23.7547V21.91H20.4947V25H19.5547V18Z" fill="#0D87EF"/>
          </svg>

          <div class="flex w-full flex-column">
            <div class="file-name">
              {{ KPfile?.name }}
            </div>
            <div class="file-size">
              {{ KPfile?.size | fileSize }}
            </div>
          </div>

          <svg (click)="KPfile = undefined" class="mr-3 cursor-pointer trash-can" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.6001H2.77778H17" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.9723 4.6C15.9723 4.18579 15.6365 3.85 15.2223 3.85C14.8081 3.85 14.4723 4.18579 14.4723 4.6H15.9723ZM3.52783 4.6C3.52783 4.18579 3.19205 3.85 2.77783 3.85C2.36362 3.85 2.02783 4.18579 2.02783 4.6H3.52783ZM4.6945 4.6C4.6945 5.01421 5.03029 5.35 5.4445 5.35C5.85871 5.35 6.1945 5.01421 6.1945 4.6H4.6945ZM11.8056 4.6C11.8056 5.01421 12.1414 5.35 12.5556 5.35C12.9698 5.35 13.3056 5.01421 13.3056 4.6H11.8056ZM14.4723 4.6V17.2H15.9723V4.6H14.4723ZM14.4723 17.2C14.4723 17.7887 14.0034 18.25 13.4445 18.25V19.75C14.8493 19.75 15.9723 18.5995 15.9723 17.2H14.4723ZM13.4445 18.25H4.55561V19.75H13.4445V18.25ZM4.55561 18.25C3.99674 18.25 3.52783 17.7887 3.52783 17.2H2.02783C2.02783 18.5995 3.1508 19.75 4.55561 19.75V18.25ZM3.52783 17.2V4.6H2.02783V17.2H3.52783ZM6.1945 4.6V2.8H4.6945V4.6H6.1945ZM6.1945 2.8C6.1945 2.21129 6.66341 1.75 7.22228 1.75V0.25C5.81747 0.25 4.6945 1.40048 4.6945 2.8H6.1945ZM7.22228 1.75H10.7778V0.25H7.22228V1.75ZM10.7778 1.75C11.3367 1.75 11.8056 2.21129 11.8056 2.8H13.3056C13.3056 1.40048 12.1826 0.25 10.7778 0.25V1.75ZM11.8056 2.8V4.6H13.3056V2.8H11.8056Z" fill="#B2B7BD"/>
            <path d="M7.22217 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.7778 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div>

      </div>
      <div class="mt-4">
        <div class="text-sm field-head mb-1">
          {{ 'Загрузите форму договора' | translate }}
        </div>

        <div *ngIf="!Appfile" class="file-upload" (drop)="onDrop($event, 'app')"
             (dragover)="onDragOver($event, drg2)" #drg2
             [ngClass]="{'file-err': AppHave == 1}"
             (click)="fileInput.click()">
          <div class="file-upload-head">
            {{ 'Нажмите, чтоб выбрать файл или перетащите файл в эту область' | translate }}
          </div>
          <div class="add-file" (dragleave)="onDragLeave(drg2)">
            {{ 'Добавить 1 файл' | translate }}
          </div>
          <div class="file-upload-desc">
            {{ 'Один файл до 30 МБ в формате .pdf' | translate }}
          </div>
          <input type="file" #fileInput (change)="onFileSelected($event, 'app')" accept="application/pdf" hidden>
        </div>

        <div class="error-text" *ngIf="AppFileTypeErr && !AppFileSizeErr">
          {{ 'Файл должен быть в формате .pdf' | translate }}
        </div>
        <div class="error-text" *ngIf="AppFileSizeErr && !AppFileTypeErr">
          {{ 'Файл слишком большой, максимальный размер 30 МБ' | translate }}
        </div>
        <div class="error-text" *ngIf="AppFileSizeErr && AppFileTypeErr">
          {{ 'Файл должен быть в формате .pdf и не превышать 30 МБ' | translate }}
        </div>
        <div class="error-text" *ngIf="AppHave == 1">
          {{ 'Загрузите форму договора' | translate }}
        </div>

        <div class="file-loaded" *ngIf="Appfile">
          <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9701 1.43994H4.65007C2.81096 1.43994 1.32007 2.93083 1.32007 4.76994V31.4099C1.32007 33.249 2.81096 34.7399 4.65007 34.7399H24.6301C26.4692 34.7399 27.9601 33.249 27.9601 31.4099V11.4299L17.9701 1.43994Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.97 1.43994V11.4299H27.96" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 18H8.4C8.86 18 9.26 18.0533 9.6 18.16C9.94 18.2667 10.22 18.4167 10.44 18.61C10.6667 18.7967 10.8333 19.0233 10.94 19.29C11.0467 19.55 11.1 19.8367 11.1 20.15C11.1 20.4633 11.0467 20.7533 10.94 21.02C10.8333 21.28 10.6667 21.5067 10.44 21.7C10.22 21.8867 9.94 22.0333 9.6 22.14C9.26 22.2467 8.86 22.3 8.4 22.3H6.94V25H6V18ZM8.35 21.48C8.68333 21.48 8.96333 21.45 9.19 21.39C9.42333 21.3233 9.61 21.2333 9.75 21.12C9.89667 21.0067 10 20.8667 10.06 20.7C10.1267 20.5333 10.16 20.35 10.16 20.15C10.16 19.95 10.1267 19.7667 10.06 19.6C10 19.4333 9.89667 19.2933 9.75 19.18C9.61 19.0667 9.42333 18.98 9.19 18.92C8.96333 18.8533 8.68333 18.82 8.35 18.82H6.94V21.48H8.35ZM12.5527 18H14.6027C15.1294 18 15.5994 18.0733 16.0127 18.22C16.4261 18.36 16.7761 18.57 17.0627 18.85C17.3494 19.13 17.5694 19.48 17.7227 19.9C17.8761 20.32 17.9527 20.8033 17.9527 21.35V21.55C17.9527 22.11 17.8727 22.6067 17.7127 23.04C17.5594 23.4667 17.3327 23.8267 17.0327 24.12C16.7394 24.4067 16.3794 24.6267 15.9527 24.78C15.5261 24.9267 15.0427 25 14.5027 25H12.5527V18ZM14.4027 24.18C14.7961 24.18 15.1494 24.13 15.4627 24.03C15.7827 23.93 16.0527 23.7767 16.2727 23.57C16.4927 23.3567 16.6594 23.0833 16.7727 22.75C16.8927 22.4167 16.9527 22.0167 16.9527 21.55V21.35C16.9527 20.9033 16.8961 20.52 16.7827 20.2C16.6694 19.88 16.5061 19.62 16.2927 19.42C16.0794 19.2133 15.8194 19.0633 15.5127 18.97C15.2127 18.87 14.8761 18.82 14.5027 18.82H13.4927V24.18H14.4027ZM19.5547 18H24.0547V18.82H20.4947V21.09H23.7547V21.91H20.4947V25H19.5547V18Z" fill="#0D87EF"/>
          </svg>

          <div class="flex w-full flex-column">
            <div class="file-name">
              {{ Appfile?.name }}
            </div>
            <div class="file-size">
              {{ Appfile?.size | fileSize }}
            </div>
          </div>

          <svg (click)="Appfile = undefined" class="mr-3 cursor-pointer trash-can" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.6001H2.77778H17" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.9723 4.6C15.9723 4.18579 15.6365 3.85 15.2223 3.85C14.8081 3.85 14.4723 4.18579 14.4723 4.6H15.9723ZM3.52783 4.6C3.52783 4.18579 3.19205 3.85 2.77783 3.85C2.36362 3.85 2.02783 4.18579 2.02783 4.6H3.52783ZM4.6945 4.6C4.6945 5.01421 5.03029 5.35 5.4445 5.35C5.85871 5.35 6.1945 5.01421 6.1945 4.6H4.6945ZM11.8056 4.6C11.8056 5.01421 12.1414 5.35 12.5556 5.35C12.9698 5.35 13.3056 5.01421 13.3056 4.6H11.8056ZM14.4723 4.6V17.2H15.9723V4.6H14.4723ZM14.4723 17.2C14.4723 17.7887 14.0034 18.25 13.4445 18.25V19.75C14.8493 19.75 15.9723 18.5995 15.9723 17.2H14.4723ZM13.4445 18.25H4.55561V19.75H13.4445V18.25ZM4.55561 18.25C3.99674 18.25 3.52783 17.7887 3.52783 17.2H2.02783C2.02783 18.5995 3.1508 19.75 4.55561 19.75V18.25ZM3.52783 17.2V4.6H2.02783V17.2H3.52783ZM6.1945 4.6V2.8H4.6945V4.6H6.1945ZM6.1945 2.8C6.1945 2.21129 6.66341 1.75 7.22228 1.75V0.25C5.81747 0.25 4.6945 1.40048 4.6945 2.8H6.1945ZM7.22228 1.75H10.7778V0.25H7.22228V1.75ZM10.7778 1.75C11.3367 1.75 11.8056 2.21129 11.8056 2.8H13.3056C13.3056 1.40048 12.1826 0.25 10.7778 0.25V1.75ZM11.8056 2.8V4.6H13.3056V2.8H11.8056Z" fill="#B2B7BD"/>
            <path d="M7.22217 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.7778 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div>

      </div>
      <div class="blue-btn w-full text-center mt-4" (click)="sendKp()">

        <span *ngIf="!loading">
          {{ 'Send' | translate}}
        </span>

        <svg *ngIf="loading" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.75 9C16.75 7.46719 16.2955 5.96881 15.4439 4.69433C14.5923 3.41985 13.3819 2.42651 11.9658 1.83993C10.5497 1.25335 8.9914 1.09988 7.48805 1.39891C5.9847 1.69795 4.60378 2.43607 3.51992 3.51992C2.43607 4.60378 1.69795 5.9847 1.39891 7.48805C1.09988 8.9914 1.25335 10.5497 1.83993 11.9658C2.42651 13.3819 3.41985 14.5923 4.69433 15.4439C5.96881 16.2955 7.46719 16.75 9 16.75" stroke="white" stroke-width="1.5"/>
        </svg>

      </div>
    </div>



  </p-dialog>
</div>

<p-toast class="main-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
