import {Component, Input, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MainService} from "../../services/main/main.service";
import {SearchService} from "../../services/search/search.service";
import {HeaderService} from "../../services/header/header.service";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.sass']
})
export class CardTemplateComponent {

  @Input() cardInfo: any;
  @Input() cardIndex: any;
  @Input() isAuth: any;
  needAddToFavAfterAuth: boolean = false;

  constructor(public translate: TranslateService,
              private headerService: HeaderService,
              private mainService: MainService,
              private authService: AuthService,
              public searchService: SearchService) {
    this.authService.auth.subscribe(()=> {
      if(this.needAddToFavAfterAuth) {
        this.setFavorite(this.cardInfo.id);
      }
    })
  }


  metrikClick(){
    // @ts-ignore
    window['yaCounter48820577']?.reachGoal('click-po-kartochkam');
  }

  genInp(item: any) {
    return `url('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[0]?.full_path}')`;
  }

  favorite() {
    if(this.isAuth){
      if (this.cardInfo.favorite == true) {
        this.removeFavorite(this.cardInfo.id);
      } else {
        this.setFavorite(this.cardInfo.id);
      }
    } else {
      this.headerService.openAuthDialog();
    }
  }

  setFavorite(itemId: any) {
    this.mainService.postFavorite(itemId).subscribe({
      next: () => {
        this.cardInfo.favorite = !this.cardInfo.favorite;
        // @ts-ignore
        window['yaCounter48820577'].reachGoal('obshee-kolichestvo-dobavleniy-tovarov-v-izbrannoe');
        localStorage.removeItem('favId')
      },
      complete: () => {
        this.headerService.favoriteCounter();
      },
      error: () => {
      }
    });
  }

  removeFavorite(itemId: any) {
    this.mainService.deleteFavorite(itemId).subscribe({
      next: () => {
        this.cardInfo.favorite = !this.cardInfo.favorite;
      },
      complete: () => {
        this.headerService.favoriteCounter();
      },
      error: () => {
      }
    });
  }
}
