<div class="flex search-header-wrap">
  <div (click)="backToMain(); openSearch = false" class="back-arrow" [routerLink]="'/'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5329_29354)">
        <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
      </g>
      <defs>
        <clipPath id="clip0_5329_29354">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="search-header">
    <img class="search-icon" src="assets/images/main/search.svg" alt="">
    <input class="search-input" placeholder="Поиск в Partner" [(ngModel)]="searchService._name" (keydown.enter)="search();" type="search">
    <img *ngIf="searchService._name" (click)="searchService._name = ''" src="assets/images/main/cross-icon.svg" alt="" class="cross-icon">
  </div>
</div>

<div class="good-types-tabs">
  <div *ngFor="let item of categoryTypes" class="tab-item" [ngClass]="{'active' : currentTab == item.id}"
       (click)="currentTab = item.id; getCategories(item.name)">
    {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
    <div [ngClass]="{'active-border' : currentTab == item.id}"></div>
  </div>

</div>

<div class="category-type-wrap">
  <div class="category-types">
    <div class="category-item" *ngFor="let item of catalogItems" (click)="openParentSideBar(item)">
      <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+item.icon" alt="">
      {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
      <img src="assets/images/main/catalog-arrow.svg" alt="">
    </div>
  </div>
</div>
<p-sidebar [(visible)]="parentSideBar" position="right" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
  <div class="parent-side-bar-header">
    <svg (click)="parentSideBar = false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5329_29354)">
        <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
      </g>
      <defs>
        <clipPath id="clip0_5329_29354">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <div class="category-wrap">
      {{ translate.currentLang == 'ru' ? category?.name_ru : category?.name_kz }}
    </div>

  </div>
  <div class="">
    <div class="category-type-wrap">
      <div class="category-types">
        <div class="category-item" *ngFor="let item of subCategoriesList?.child_categories" (click)="goodsByParentCategoryId(item)">
          {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
          <img src="assets/images/main/catalog-arrow.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="cardsSideBar" position="right" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
  <div class="parent-side-bar-header">
    <svg (click)="closeCardsSideBar()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5329_29354)">
        <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
      </g>
      <defs>
        <clipPath id="clip0_5329_29354">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <div class="category-wrap">
      {{
        (category?.name_ru || category?.name_kz) ?
          (translate.currentLang == 'ru' ? category?.name_ru : category?.name_kz) :
          'Search' | translate
      }}
    </div>
    <img (click)="openFilters()" class="filter-sliders"
         [src]="checkFilters() ? 'assets/images/main/filter-sliders.svg' : 'assets/images/main/active-filters.svg'" alt="">
  </div>
  <div class="subcategory-wrap">
    <div *ngFor="let item of subCategoriesList?.child_categories" class="subcategory" [ngClass]="{'active': item.active == true}" (click)="changeSubCategory(item)">
      {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
    </div>
  </div>
  <div class="cards-wrap">
    <div *ngIf="!searchService._loading && searchService._cards.list.content.length == 0" class="empty-search">
      <img src="assets/images/errors/mobile-empty-request.svg" alt="">
      <div class="mb-3 empty-search-head mx-auto">
        {{ 'EmptyRequest' | translate }}
      </div>
      <div class="empty-search-desc mx-auto">
        {{ 'PleaseTryAgain' | translate }}
      </div>
    </div>
    <div class="good-item relative" *ngFor="let item of searchService._cards.list.content; index as i">
      <a [href]="['/item/' + item['id']]" target="_self">
        <div class="img-wrap">
          <div *ngIf="item['images']?.length > 0" class="card-image"
               [ngStyle]="{'background-image': genInp(item)}"></div>
          <div *ngIf="item['images']?.length == 0" class="card-image"
               style="background-image: url('/assets/images/main/card-fill.svg')"></div>
        </div>
        <div *ngIf="isAuth" (click)="favorite(i)" [ngClass]="{'favorite-icon-filled': item['favorite']}" class="favorite-icon"></div>
        <div class="price">
          {{ 'CardPrice' | translate : {price: addSpacesToNumberString('' + item['price']), ru: (translate.currentLang == 'ru' && !item['is_fix_price']) ? 'От' : '', kz: (translate.currentLang == 'kz' && !item['is_fix_price']) ? 'бастап' : ''} }}
        </div>
        <div [title]="translate.currentLang == 'ru' ? item['name_ru'] : item['name_kz']"
             class="item-title">
          {{translate.currentLang == 'ru' ? item['name_ru'] : item['name_kz']}}
        </div>
        <a [href]="['/company/' + item['company_bin']]">
          <div [title]="(translate.currentLang == 'ru' ? item['company_name'] : item['company_name_kz']) | shortname"
               class="company-name">
            {{(translate.currentLang == 'ru' ? item['company_name'] : item['company_name_kz']) | shortname}}
          </div>
        </a>
        <div class="flex rating-wrap">
          <div>
            <img *ngIf="item['rating'] > 0" src="/assets/images/main/star-filled-icon.svg" width="12px" height="12px"/>
            <img *ngIf="item['rating'] == 0" class="star" src="/assets/images/main/star-icon.svg" width="12px" height="12px"/>
          </div>
          <div class="feedback-count flex">
            <span *ngIf="item['rating'] > 0">{{ item['rating'] }}</span>
            <div class="feedback-count-txt" *ngIf="item['rating'] > 0">
              {{ item['feedback'].all | suffix: ('OneReview' | translate): ('SomeReviews' | translate): ('ManyReviews' | translate)}}
            </div>
            <div class="" *ngIf="item['rating'] == 0">
              {{'NoRating' | translate}}
            </div>
          </div>
        </div>
      </a>

    </div>
  </div>
</p-sidebar>

<div class="filter-sidebar">
  <div class="main-sidebar">
    <p-sidebar [(visible)]="showFilters" position="bottom" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
      <div class="flex filter-header-wrap">
        <div (click)="showFilters = false" class="back-arrow">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5329_29354)">
              <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
            </g>
            <defs>
              <clipPath id="clip0_5329_29354">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="filter-header">
          Фильтр
        </div>
        <div *ngIf="!checkFilters()" class="clear-filters" (click)="removeFilters()">
          Сбросить фильтры
        </div>
      </div>
      <div class="filter-content">
        <div class="filter-content-wrap">
          <div class="">
            <div class="w-full mb-1">
              {{ 'Price' | translate }}
            </div>
            <div class="price-wrap mb-4">
              <input type="number" [(ngModel)]="searchService._priceRange[0]" (input)="changeValue(0,$event.target)" placeholder="От">
              <input type="number" [(ngModel)]="searchService._priceRange[1]" (input)="changeValue(1,$event.target)" placeholder="До">
            </div>
          </div>
          <div class="region-wrap" (click)="openRegionList()">
            <div class="">
              {{ 'Region' | translate }}
            </div>
            <div class="flex align-items-center">
              <div class="region-name">
                {{ translate.currentLang == 'ru' ? selectedCity.region_name_ru : selectedCity.region_name_kz }}
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5329_28687)">
                  <path d="M10 17L15 12L10 7" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_5329_28687">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="sort-wrap" (click)="sortOpen = true">
            <div class="">
              {{ 'Sorting' | translate }}
            </div>
            <div class="flex align-items-center">
              <div class="region-name">
                {{ translate.currentLang == 'ru' ? selectedSortType.sort_name_ru : selectedSortType.sort_name_kz }}
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5329_28687)">
                  <path d="M10 17L15 12L10 7" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_5329_28687">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <p-button styleClass="filter-btn" [disabled]="checkFilters()" (click)="applyFilters()">
          {{ 'Apply' | translate }}
        </p-button>
      </div>

    </p-sidebar>
  </div>

  <p-sidebar [(visible)]="regionListOpen" position="bottom" styleClass="filter-item">
    <ng-template pTemplate="header">
      <div class="region-sidebar">
        <div class="w-full text-center">
          <div class="sidebar-header">
            {{ 'Region' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
    <div class="">
      <p-listbox (onClick)="regionListOpen = false" [options]="regions" [(ngModel)]="selectedCity"
                 optionLabel="region_name_ru"
                 [filter]="true"
                 [checkbox]="false"
                 filterPlaceHolder="Найти город, регион или область"
                 [listStyle]="{'max-height': '84vh','min-height': '84vh'}">
        <ng-template let-city pTemplate="item">
          <div class="w-full region-item">
            <input type="radio" [id]="city.id" [checked]="selectedCity.id == city.id">
            {{ translate.currentLang == 'ru' ? city.region_name_ru : city.region_name_kz }}
          </div>
        </ng-template>
      </p-listbox>
    </div>
    <ng-template pTemplate="closeicon">
      <img src="assets/images/main/cross-icon.svg" alt="">
    </ng-template>
  </p-sidebar>
  <p-sidebar [(visible)]="sortOpen" position="bottom" styleClass="filter-item">
    <ng-template pTemplate="header">
      <div class="region-sidebar">
        <div class="w-full text-center">
          <div class="sidebar-header">
            {{ 'Sorting' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
    <div class="">
      <p-listbox (onClick)="sortOpen = false" [options]="sort" [(ngModel)]="selectedSortType"
                 optionLabel="sort_name_ru"
                 [filter]="false"
                 [checkbox]="false"
                 [listStyle]="{'max-height': '84vh'}">
        <ng-template let-sortType pTemplate="item">
          <div class="w-full region-item">
            <input type="radio" [id]="sortType?.id" [checked]="selectedSortType.id == sortType.id">
            {{ translate.currentLang == 'ru' ? sortType.sort_name_ru : sortType.sort_name_kz }}
          </div>
        </ng-template>
      </p-listbox>
    </div>
    <ng-template pTemplate="closeicon">
      <img src="assets/images/main/cross-icon.svg" alt="">
    </ng-template>
  </p-sidebar>
</div>
