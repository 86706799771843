<div class="menu-bar" [ngClass]="{'menu-bar-gap': navigator.userAgent.includes('ismet-mobile')}">
  <div (click)="search()" [routerLink]="'/'" class="menu-bar-item" [ngClass]="{'active' : window.location.pathname == '/' || window.location.pathname.includes('item')}">
    <svg class="menu-bar-item-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.26676 22V13.7045C9.26676 13.0767 9.26676 12.7628 9.38787 12.523C9.49439 12.3121 9.66437 12.1406 9.87344 12.0331C10.1111 11.9109 10.4223 11.9109 11.0445 11.9109H14.1557C14.7779 11.9109 15.0891 11.9109 15.3268 12.0331C15.5358 12.1406 15.7058 12.3121 15.8123 12.523C15.9334 12.7628 15.9334 13.0767 15.9334 13.7045V22M11.5086 1.55731L3.97275 7.47077C3.46901 7.86606 3.21714 8.06371 3.03568 8.31123C2.87495 8.53048 2.75521 8.77748 2.68235 9.0401C2.6001 9.33657 2.6001 9.6585 2.6001 10.3024V18.4128C2.6001 19.6684 2.6001 20.2962 2.84231 20.7758C3.05536 21.1977 3.39531 21.5407 3.81345 21.7556C4.28881 22 4.91109 22 6.15565 22H19.0445C20.2891 22 20.9114 22 21.3867 21.7556C21.8049 21.5407 22.1448 21.1977 22.3579 20.7758C22.6001 20.2962 22.6001 19.6684 22.6001 18.4128V10.3024C22.6001 9.6585 22.6001 9.33657 22.5178 9.0401C22.445 8.77748 22.3252 8.53048 22.1645 8.31123C21.9831 8.06371 21.7312 7.86606 21.2274 7.47077L13.6915 1.55731C13.3012 1.25099 13.106 1.09783 12.8905 1.03896C12.7003 0.987013 12.4999 0.987013 12.3097 1.03896C12.0942 1.09783 11.899 1.25099 11.5086 1.55731Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {{ 'Main' | translate }}
  </div>
  <div [routerLink]="'/catalog'" class="menu-bar-item" [ngClass]="{'active' : window.location.pathname.includes('catalog')}">
    <svg class="menu-bar-item-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.80029 5H21.8003" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.80029 12H21.8003" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.80029 19H21.8003" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {{ 'Catalog' | translate }}
  </div>
  <div [routerLink]="'/favorite'" class="menu-bar-item" [ngClass]="{'active' : window.location.pathname.includes('favorite')}">
    <svg class="menu-bar-item-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3036 4.67974C20.2179 3.60425 18.7452 3 17.2095 3C15.6738 3 14.201 3.60425 13.1153 4.67974L11.9997 5.7844L10.8841 4.67974C8.62297 2.44083 4.95697 2.44083 2.69584 4.67974C0.434719 6.91865 0.434719 10.5486 2.69584 12.7875L3.81147 13.8922L11.9997 22L20.188 13.8922L21.3036 12.7875C22.3898 11.7126 23 10.2543 23 8.73364C23 7.21302 22.3898 5.75472 21.3036 4.67974Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {{ 'Favorite' | translate }}
  </div>
  <div [routerLink]="'/account/my-products-and-services'" class="menu-bar-item" [ngClass]="{'active' : window.location.pathname.includes('account') || window.location.pathname.includes('card')}">
    <svg class="menu-bar-item-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.2002" y="7.5" width="20.25" height="13.5" rx="2" stroke="currentColor" stroke-width="1.5"/>
      <path d="M7.8252 5C7.8252 3.89543 8.72063 3 9.8252 3H14.8252C15.9298 3 16.8252 3.89543 16.8252 5V7.5H7.8252V5Z" stroke="currentColor" stroke-width="1.5"/>
      <path d="M2.2002 13.125H22.4502" stroke="currentColor" stroke-width="1.5"/>
    </svg>
    {{ 'Cabinet' | translate }}
  </div>
  <div [routerLink]="'/side-menu'" class="menu-bar-item" [ngClass]="{'active' : window.location.pathname.includes('side-menu')}">
    <svg class="menu-bar-item-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3999 22C17.9227 22 22.3999 17.5228 22.3999 12C22.3999 6.47715 17.9227 2 12.3999 2C6.87705 2 2.3999 6.47715 2.3999 12C2.3999 17.5228 6.87705 22 12.3999 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.4001 15.3333C14.7013 15.3333 16.5667 13.4679 16.5667 11.1667C16.5667 8.86548 14.7013 7 12.4001 7C10.0989 7 8.2334 8.86548 8.2334 11.1667C8.2334 13.4679 10.0989 15.3333 12.4001 15.3333Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.71289 19.4349C6.34061 18.2007 7.2976 17.1643 8.47792 16.4404C9.65824 15.7166 11.0159 15.3334 12.4005 15.3334C13.7851 15.3334 15.1427 15.7165 16.323 16.4404C17.5034 17.1643 18.4604 18.2007 19.0881 19.4348" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {{ 'ServiceMenu' | translate }}
  </div>

</div>
