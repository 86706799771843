export class Constants {
  static IP_PATTERN = '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$'
  static IP_MASK_PATTERN = '^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$'
  static RUSSIAN_KAZAKH_ENGLISH_LETTERS_AND_DIGITS_PATTERN = '^[A-Za-zА-Яа-яЁёҢңҒғҚқҰұҮүӘәӨөҺһҖҗ0-9]+$'
  static LOCALE_RU = "ru"
  static LOCALE_KK = "kk"
  static IIK_PATTERN= '^[A-Z0-9]+$';
  static EMAIL_PATTERN = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  static PHONE_PATTERN = /^7[04567][0-8]\d{7}$/i;
  static PHONE_PATTERN_7 = /^\+77[04567][0-8]\d{7}$/i;
  static PHONE_PATTERN_77 = /^77[04567][0-8]\d{7}$/i;
  static PHONE_PATTERN_8 = /^87[04567][0-8]\d{7}$/i;
  static PASSWORD = /^(?!.*([a-zA-Z\d])\1{7})[a-zA-Z\d\S]{8,}$/;
  static readonly QA_PHONE_NUMBER = ['77066930827', '77055463022'];
}
