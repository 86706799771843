<div class="breadcrumb">
  <p-breadcrumb [model]="items"></p-breadcrumb>
</div>
<div class="steps">
  <hr>
  <div class="steps__stepper">
    <div class="steps-step">
      <div class="steps-step__circle" [ngClass]="{'active': activeIndex >= 1}">1</div>
      <div *ngIf="userData" class="steps-step__txt" [ngClass]="{'active-txt': activeIndex >= 1}">
        {{"choosingACompany" | translate}}
      </div>
      <div *ngIf="!userData" class="steps-step__txt" [ngClass]="{'active-txt': activeIndex >= 1}">
        {{'attachingACompany' | translate}}
      </div>
    </div>
    <div class="steps__line"></div>
    <div class="steps-step">
      <div class="steps-step__circle" [ngClass]="{'active': activeIndex >= 2}">2</div>
      <div class="steps-step__txt" [ngClass]="{'active-txt': activeIndex >= 2}">
        {{'fillingOutACompanyBusinessCard' | translate}}
      </div>
    </div>
    <div class="steps__line"></div>
    <div class="steps-step">
      <div class="steps-step__circle" [ngClass]="{'active': activeIndex == 3}">3</div>
      <div class="steps-step__txt" [ngClass]="{'active-txt': activeIndex == 3}">
        {{'creatingAServiceCard' | translate}}
      </div>
    </div>
  </div>
  <hr>
  <p-progressSpinner *ngIf="loadSpinner" [class]="'load-spinner'"></p-progressSpinner>
  <div *ngIf="activeIndex == 1 && !loadSpinner" class="steps-one">
    <ng-container *ngIf="userData.companyList && !showAddCompany">
      <div class="steps-one-choosing-company">
        <label class="steps-one-choosing-company__label" for="company">{{'selectACompanyFromTheListOrAddANewOne' | translate}}</label>
        <p-dropdown [options]="userData.companyList" id="company" [(ngModel)]="companyBin" optionValue="uin" (ngModelChange)="selectCompany(companyBin)"
                    optionLabel="{{translate.currentLang == 'ru' ? 'companyName' : 'companyNameKz'}}">
          <ng-template let-item pTemplate="item">
            <div>
              {{(translate.currentLang == 'ru' ? item?.companyName : item?.companyNameKz) | shortname}}
            </div>
          </ng-template>
        </p-dropdown>
        <div class="steps-one-choosing-company__add" (click)="showAddCompany = true">{{'attachANewCompany' | translate}}</div>
        <div class="steps-one-choosing-company-buttons">
          <div class="steps-one-choosing-company-buttons__white" (click)="cancel()">{{'Back' | translate}}</div>
          <div class="steps-one-choosing-company-buttons__blue" (click)="checkCard()">{{'Chose' | translate}}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(!userData.companyList && !showAddCompany) || showAddCompany">
      <app-attaching-a-company [hideNotTU]="false" [noCompany]="noCompany" (continue)="getUserInfo()" (cancel)="showAddCompany = false"></app-attaching-a-company>
    </ng-container>
  </div>

  <div *ngIf="activeIndex == 2 && !loadSpinner" class="steps-two">
    <app-company-info [hideNotTU]="false" (cancel)="activeIndex = 1" (continue)="nexStep()" [binTU]="companyBin"></app-company-info>
  </div>

  <div *ngIf="activeIndex == 3 && !loadSpinner" class="steps-three">
    <form [formGroup]="cardForm">
      <div class="steps-three-create-card">
        <div>
          <label class="steps-three-create-card__label">{{'SelectProductType' | translate}}</label>
          <span class="steps-three-create-card__required">*</span>
          <p-dropdown [readonly]="true" [options]="categoryTypes" formControlName="type" class="readonly"
                      optionLabel="{{translate.currentLang == 'ru' ? 'name_ru' : 'name_kz'}}"></p-dropdown>
        </div>
        <div class="steps-three-create-card-categories">
          <div class="steps-three-create-card-block-width">
            <label class="steps-three-create-card__label">{{'Category' | translate}}</label>
            <span class="steps-three-create-card__required">*</span>
            <p-dropdown [readonly]="true" [options]="categories" class="readonly"
                        optionLabel="{{translate.currentLang == 'ru' ? 'parent_category.name_ru' : 'parent_category.name_kz'}}"></p-dropdown>
          </div>
          <div class="steps-three-create-card__inline-img" inlineSVG="assets/images/main/line-card.svg"></div>
          <div class="steps-three-create-card-block-width">
            <label class="steps-three-create-card__label">{{'SubCategory' | translate}}</label>
            <span class="steps-three-create-card__required">*</span>
            <p-dropdown [readonly]="true" [options]="categories" class="readonly"
                        optionLabel="{{translate.currentLang == 'ru' ? 'name_ru' : 'name_kz'}}"></p-dropdown>
          </div>
        </div>
        <div>
          <label class="steps-three-create-card__label">{{'SelectRegion' | translate }}</label>
          <span class="steps-three-create-card__required">*</span>
          <p-multiSelect [placeholder]="'SelectRegion' | translate" (onChange)="selectRegion($event)"
                         [options]="regionList" [filter]="true" optionValue="id"
                         optionLabel="{{translate.currentLang == 'ru' ? 'region_name_ru' : 'region_name_kz'}}"></p-multiSelect>
        </div>
        <div>
          <label class="steps-three-create-card__label">{{'AddPhoto' | translate}}</label>
          <span class="steps-three-create-card__required">*</span>
          <div class="steps-three-create-card-add-photo">
            <div class="steps-three-create-card-block">
              <p-fileUpload chooseLabel=" " (onRemove)="removeFileImg(0)" [customUpload]="true"
                            (uploadHandler)="onUploadImg($event,0)" mode="advanced"
                            [multiple]="false" [auto]="true" accept=".jpg, .jpeg, .png"
                            [maxFileSize]="5000000">
                <ng-template pTemplate="chooseicon">
                  <img src="assets/images/main/add-file-icon.svg" alt="">
                </ng-template>
              </p-fileUpload>
              <div class="steps-three-create-card__photo">{{'MainPhoto' | translate }}</div>
              <p-fileUpload chooseLabel=" " (onRemove)="removeFileImg(1)" [customUpload]="true"
                            (uploadHandler)="onUploadImg($event,1)" mode="advanced"
                            [multiple]="false" [auto]="true" accept=".jpg, .jpeg, .png"
                            [maxFileSize]="5000000">
                <ng-template pTemplate="chooseicon">
                  <img src="assets/images/main/add-file-icon.svg" alt="">
                </ng-template>
              </p-fileUpload>
              <p-fileUpload chooseLabel=" " (onRemove)="removeFileImg(2)" [customUpload]="true"
                            (uploadHandler)="onUploadImg($event,2)" mode="advanced"
                            [multiple]="false" [auto]="true" accept=".jpg, .jpeg, .png"
                            [maxFileSize]="5000000">
                <ng-template pTemplate="chooseicon">
                  <img src="assets/images/main/add-file-icon.svg" alt="">
                </ng-template>
              </p-fileUpload>
              <p-fileUpload chooseLabel=" " (onRemove)="removeFileImg(3)" [customUpload]="true"
                            (uploadHandler)="onUploadImg($event,3)" mode="advanced"
                            [multiple]="false" [auto]="true" accept=".jpg, .jpeg, .png"
                            [maxFileSize]="5000000">
                <ng-template pTemplate="chooseicon">
                  <img src="assets/images/main/add-file-icon.svg" alt="">
                </ng-template>
              </p-fileUpload>
              <p-fileUpload chooseLabel=" " (onRemove)="removeFileImg(4)" [customUpload]="true"
                            (uploadHandler)="onUploadImg($event,4)" mode="advanced"
                            [multiple]="false" [auto]="true" accept=".jpg, .jpeg, .png"
                            [maxFileSize]="5000000">
                <ng-template pTemplate="chooseicon">
                  <img src="assets/images/main/add-file-icon.svg" alt="">
                </ng-template>
              </p-fileUpload>
            </div>
            <div class="steps-three-create-card__text-notification">
              {{'FileFormatMax' | translate}}</div>
          </div>
        </div>
        <div>
          <label class="steps-three-create-card__label">{{'NameRu' | translate}}</label>
          <span class="steps-three-create-card__required">*</span>
          <input formControlName="name_ru" class="steps-three-create-card__input" type="text" pInputText required/>
        </div>
        <div>
          <label class="steps-three-create-card__label">{{'Description' | translate}}</label>
          <span class="steps-three-create-card__required">*</span>
          <textarea formControlName="description_ru" maxlength="500" rows="4" class="steps-three-create-card__textarea" cols="90"
                    pInputTextarea [placeholder]="'describeWhatYourCompanyDoes' | translate"></textarea>
          <div class="err-txt">{{textLength(cardForm.get('description_ru')?.value)}}/500 {{'Characters' | translate}}</div>
        </div>


        <div class="steps-three-create-card-categories">
          <div class="steps-three-create-card-block-width">
            <label class="steps-three-create-card__label">{{'Price' | translate}}</label>
            <span class="steps-three-create-card__required">*</span>
            <p-inputNumber [placeholder]="'SetPrice' | translate" formControlName="price" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"></p-inputNumber>
          </div>
          <div class="steps-three-create-card__inline-img" inlineSVG="assets/images/main/line-card.svg"></div>
          <div class="steps-three-create-card-block-width">
            <label class="steps-three-create-card__label">{{'PriceType' | translate}}</label>
            <span class="steps-three-create-card__required">*</span>
            <p-dropdown [placeholder]="'SetPriceType' | translate" formControlName="is_fix_price" [options]="priceType" optionValue="value"
                        optionLabel="{{translate.currentLang == 'ru' ? 'nameRu' : 'nameKz'}}"></p-dropdown>
          </div>
        </div>
        <div>
          <div class="steps-three-create-card-block-add">
            <div class="steps-three-create-card__add-files">
              {{'uploadALicensePermitForTheConstructionOfCommunicationNetworks' | translate}}<span class="steps-three-create-card__required">*</span>
            </div>
            <span inlineSVG="assets/images/main/add-company.svg"></span>
            <p-fileUpload [disabled]="disabled" class="block-add" chooseLabel=" " (uploadHandler)="onUploadPdf($event)"
                          [customUpload]="true" [multiple]="false" [auto]="true"
                          accept=".pdf" [maxFileSize]="3000000">
            </p-fileUpload>
          </div>
          <div class="steps-three-create-card__text-notification">
            {{'fileSizeLimit30MBNumberOfDocumentsLimited1PieceFormatPdf' | translate}}
          </div>
        </div>
        <div *ngIf="uploadedFilesPDF?.length > 0">
          <div class="steps-three-create-card-block-add">
            <span inlineSVG="assets/images/main/pdf.svg"></span>
            <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDF[0]?.file.name}}</div>
            <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg" (click)="removeFilePdf()"></span>
          </div>
        </div>
        <div>
          <div class="steps-three-create-card-block-add">
            <div class="steps-three-create-card__add-files">{{'loadMoreDocuments' | translate}}</div>
            <span inlineSVG="assets/images/main/add-company.svg"></span>
            <p-fileUpload [disabled]="disabledOther" class="block-add" chooseLabel=" " (uploadHandler)="onUploadPdfOther($event)"
                          [customUpload]="true" [multiple]="true" [auto]="true"
                          accept=".pdf" [maxFileSize]="3000000">
            </p-fileUpload>
          </div>
          <div class="steps-three-create-card__text-notification">
            {{'fileSizeLimit30MBNumberOfDocumentsLimited5PieceFormatPdf' | translate}}
          </div>
        </div>
        <div *ngIf="uploadedFilesPDFOther?.length > 0">
          <div *ngIf="uploadedFilesPDFOther?.length >= 1" class="steps-three-create-card-block-file">
            <div *ngIf="uploadedFilesPDFOther.length >= 1" class="steps-three-create-card-block-add">
              <span inlineSVG="assets/images/main/pdf.svg"></span>
              <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDFOther[0]?.file.name}}</div>
              <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg"
                    (click)="removeFilePdfMas(uploadedFilesPDFOther[0])"></span>
            </div>

            <div *ngIf="uploadedFilesPDFOther.length >= 2" class="steps-three-create-card-block-add">
              <span inlineSVG="assets/images/main/pdf.svg"></span>
              <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDFOther[1]?.file.name}}</div>
              <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg"
                    (click)="removeFilePdfMas(uploadedFilesPDFOther[1])"></span>
            </div>
          </div>

          <div *ngIf="uploadedFilesPDFOther?.length >= 3" class="steps-three-create-card-block-file">
            <div *ngIf="uploadedFilesPDFOther.length >= 3" class="steps-three-create-card-block-add">
              <span inlineSVG="assets/images/main/pdf.svg"></span>
              <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDFOther[2]?.file.name}}</div>
              <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg"
                    (click)="removeFilePdfMas(uploadedFilesPDFOther[2])"></span>
            </div>

            <div *ngIf="uploadedFilesPDFOther.length >= 4" class="steps-three-create-card-block-add">
              <span inlineSVG="assets/images/main/pdf.svg"></span>
              <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDFOther[3]?.file.name}}</div>
              <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg"
                    (click)="removeFilePdfMas(uploadedFilesPDFOther[3])"></span>
            </div>
          </div>

          <div *ngIf="uploadedFilesPDFOther?.length == 5" class="steps-three-create-card-block-file">
            <div *ngIf="uploadedFilesPDFOther.length == 5" class="steps-three-create-card-block-add">
              <span inlineSVG="assets/images/main/pdf.svg"></span>
              <div class="steps-three-create-card__upload-pdf">{{uploadedFilesPDFOther[4]?.file.name}}</div>
              <span class="steps-three-create-card__remove-pdf" inlineSVG="assets/images/main/remove_red.svg"
                    (click)="removeFilePdfMas(uploadedFilesPDFOther[4])"></span>
            </div>
          </div>
        </div>
        <div class="steps-one-choosing-company-buttons">
          <div class="steps-one-choosing-company-buttons__white" (click)="activeIndex = 2">{{'Back' | translate}}</div>
          <button class="steps-one-choosing-company-buttons__white" (click)="loadBigImg()"
               [disabled]="cardForm.invalid || uploadedFiles.length == 0">
            {{'Preview' | translate}}
          </button>
          <button class="steps-one-choosing-company-buttons__blue" (click)="public()"
              [disabled]="cardForm.invalid || uploadedFiles.length == 0 || !onCheck || uploadedFilesPDF == 0">
            {{'Public' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<p-dialog [(visible)]="preview" [modal]="true" [draggable]="false" [resizable]="false" styleClass="preview-card">
  <div class="preview">{{'Preview' | translate}}</div>
  <div class="top-zone">
    <div class="left-zone">
      <img [src]="bigImg" class="left-zone__big-img" alt=""/>
      <div class="left-zone-images">
        <ng-container *ngFor="let file of uploadedFiles">
          <img [src]="file?.file.objectURL.changingThisBreaksApplicationSecurity" class="left-zone-images__img" alt="" (click)="previewImg(file)"
               [ngClass]="{'active': bigImg == file?.file.objectURL.changingThisBreaksApplicationSecurity}"/>
        </ng-container>
      </div>
    </div>
    <div class="right-zone" *ngIf="cardForm">
      <div class="right-zone__name">{{translate.currentLang == 'ru' ? cardForm.get('name_ru')?.value : cardForm.get('name_kz')?.value}}</div>
      <div class="right-zone-statistics">
        <div class="right-zone-statistics-rating">
          <div class="right-zone-statistics-rating__stars"></div>
          <div class="right-zone-statistics-rating__stars"></div>
          <div class="right-zone-statistics-rating__stars"></div>
          <div class="right-zone-statistics-rating__stars"></div>
          <div class="right-zone-statistics-rating__stars"></div>
        </div>
        <div class="right-zone-statistics__reviews">0 {{'ManyReviews' | translate}}</div>
      </div>
      <hr>
      <div class="right-zone__company">{{ (translate.currentLang == 'ru' ? companyName?.companyName : companyName?.companyNameKz) | shortname }}</div>
      <hr>
      <div class="right-zone__price">
        {{cardForm.get('is_fix_price')?.value == true ? cardForm.get('price')?.value : (translate.currentLang == 'ru' ? "От " : "Бастап ") + cardForm.get('price')?.value | translate}} ₸
      </div>
      <hr>
      <div class="right-zone-buttons">
        <div class="right-zone-buttons__blue">{{'Order' | translate}}</div>
        <div class="right-zone-buttons__white">{{'ToFavorites' | translate}}
          <img src="assets/images/main/heart-icon.svg" alt="">
        </div>
        <div class="right-zone-buttons__white">
          <img src="assets/images/main/share-icon.svg" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-zone" *ngIf="cardForm">
    <div class="bottom-zone__description-title">{{'Description' | translate}}</div>
    <div class="bottom-zone__description-line"></div>
    <div class="bottom-zone__description-text">{{ translate.currentLang == 'ru' ? cardForm.get('description_ru')?.value : cardForm.get('description_kz')?.value }}</div>
  </div>
</p-dialog>

<p-dialog [(visible)]="informSendingModeration" [modal]="true" [draggable]="false" [resizable]="false" styleClass="sending-moderation" (onHide)="closeModal()">
  <div class="info-block">
    <ng-container *ngIf="step == 'success'">
      <div inlineSVG="assets/images/main/success.svg"></div>
      <div class="info-text">{{'yourBusinessCardAndCompanyCardHaveBeenSentForModerationAfterVerificationByTheModeratorYourDataWillBePublished' | translate}}</div>
      <button class="steps-one-choosing-company-buttons__modal-button" pButton type="button" (click)="cancel()">
        {{'Fine' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="step == 'cardAvailability'">
      <div inlineSVG="assets/images/main/warning.svg"></div>
      <div class="info-title">{{'youAreAlreadyOurPartner' | translate}}</div>
      <div class="info-text-warning">{{'toManageYourAccountGoToYourPersonalAccount' | translate}}</div>
      <button class="steps-one-choosing-company-buttons__modal-button" pButton type="button" (click)="personalAccount()">
        {{'GoToPersonalAccount' | translate}}
      </button>
    </ng-container>
  </div>
</p-dialog>

