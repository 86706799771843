import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RecaptchaComponent} from "ng-recaptcha";
import {RegAuthControllerService} from "../../services/reg-auth-controller.service";
import {OcpUtils} from "../../../OcpUtils";
import {Constants} from "../../../assets/Constants";
import {AuthService} from "../../services/auth/auth.service";
import {HeaderService} from "../../services/header/header.service";
import {MainService} from "../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";

declare var $: any;
declare var ym: any;

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.sass']
})
export class AuthDialogComponent implements OnInit {
  @ViewChild('captchaAuth') captchaAuth!: RecaptchaComponent;
  @ViewChild('captchaRegistration') captchaRegistration!: RecaptchaComponent;
  @ViewChild('captchaResetPassword') captchaResetPassword!: RecaptchaComponent;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @Output() successAuth = new EventEmitter<any>();
  @Input() redirect: boolean = false;
  @Input() addCompany: boolean = false;
  @Input() noAuth: boolean = false;
  @Input() url!: string;
  queryParams = new URLSearchParams(typeof window !== 'undefined' ? window?.location?.search : '')
  recoveryPas!: FormGroup;
  locale: string = typeof window !== 'undefined' ? document.documentElement.lang: 'ru';
  auth: boolean = false;
  ac: any;
  universalField: any;
  checked: boolean = false;
  codeError: boolean = false;
  countdownSecs: number = 0;
  limitSecs: number = 0;
  timer: any;
  tab: string = 'login-password';
  error: boolean = false;
  notFount: boolean = false;
  step: any;
  type: any;
  unFiled: any;
  verifyCode: any;
  emailOrPhone!: string;
  errors: string = '';
  password: any;
  patternErrorPhone: boolean = false;
  patternErrorEmail: boolean = false;
  textEmail: boolean = false;
  errorMail: boolean = false;
  errorPhone: boolean = false;
  nonResident: boolean = false;
  errorPassword: boolean = false;
  forgot: boolean = false;
  isMobile: boolean = false;
  limitError: boolean = false;
  limitChecked: boolean = false;
  position: any = '';
  notPassword: boolean = false;
  blockingSecs: number = 0;
  blockingError: boolean = false;
  min: any;
  userCode: any;
  authToken: any;
  progressSpinner: boolean = false;
  codeUsed: boolean = false;
  reCaptchaToken: any;
  typeField: string = 'password';
  passwordAuth: any;
  showHide: boolean = true;
  passwordNonResident: any;
  emailNonResident: any;
  phoneFormResident: any;
  emailFormResident: any;
  passwordFormResident: any;
  notPhone: boolean = false;
  manyAccounts: boolean = false;

  constructor(private regAuthControllerService: RegAuthControllerService,
              private authService: AuthService,
              private main: MainService,
              public translate: TranslateService,
              private headerService: HeaderService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    let userAgent = navigator.userAgent;
    if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
      this.isMobile = true;
    }
    this.recoveryPas = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(Constants.PASSWORD)]]
    });
    if (this.queryParams.has('cp')) {
      if (this.isMobile) {
        this.position = 'bottom';
      }
      this.auth = true;
      this.step = "forgot-password";
      $(".bottom-menu").addClass("open-auth");
    }
    if (this.queryParams.has('pr')) {
      if (this.isMobile) {
        this.position = 'bottom';
      }
      this.auth = true;
      this.step = "password-recovery";
      $(".bottom-menu").addClass("open-auth");
    }
    if (this.queryParams.has('le')) {
      if (this.isMobile) {
        this.position = 'bottom';
      }
      this.step = "link-expired";
      this.auth = true;
      $(".bottom-menu").addClass("open-auth");
    }
    if (this.noAuth) {
      $(".bottom-menu").addClass("open-auth");
      this.step = 'auth';
      this.progressSpinner = false;
    }
  }

  showHidePas() {
    this.showHide = !this.showHide;
    if (this.showHide) {
      this.typeField = 'password';
    } else {
      this.typeField = 'text';
    }
  }

  resetBlock() {
    this.notFount = false;
    this.limitChecked = false;
  }

  openDialog(phone?: string) {
    if (this.isMobile) {
      this.position = 'bottom';
    }
    $(".bottom-menu").addClass("open-auth");
    this.auth = true;
    this.step = 'auth';
    this.progressSpinner = false;
    if (phone) {
      this.universalField = '+7' + phone;
    }
  }

  closeDialog() {
    $(".bottom-menu").removeClass("open-auth");
    this.tab = 'login-password';
    this.nonResident = false;
    this.progressSpinner = false;
    this.universalField = '';
    this.clear();
    const currentUrl = new URL(window?.location?.href);
    const newUrl = `${currentUrl.origin}${currentUrl.pathname}`;
    window?.history?.replaceState({}, document.title, newUrl);
    if (this.step == 'password-changed') {
      this.setCookieAndClose();
    }
  }

  clear() {
    this.typeField = 'password';
    this.showHide = true;
    this.checked = false;
    this.manyAccounts = false;
    this.notFount = false;
    this.notPhone = false;
    this.error = false;
    this.patternErrorPhone = false;
    this.patternErrorEmail = false;
    this.errorMail = false;
    this.errorPhone = false;
    this.errorPassword = false;
    this.notPassword = false;
    this.unFiled = '';
    this.forgot = false;
    this.limitChecked = false;
    this.limitError = false;
    this.emailOrPhone = '';
    this.progressSpinner = false;
    this.phoneFormResident = '';
    this.emailFormResident = '';
    this.passwordFormResident = '';
    this.emailNonResident = '';
    this.passwordNonResident = '';
    this.passwordAuth = '';
    this.errors = '';
    this.recoveryPas.get('password')?.setValue('');
    clearInterval(this.timer);
  }

  back() {
    this.progressSpinner = false;
    clearInterval(this.timer);
    if ((this.step == 'registration' && !this.nonResident) || this.step == 'forgot-password' || this.step == 'password-recovery') {
      this.tab = 'login-password';
      this.step = 'auth';
      this.clear();
    } else if (this.step == 'registration' && this.nonResident) {
      this.step = 'registration';
      this.nonResident = false;
      this.errorPassword = false;
      this.patternErrorEmail = false;
      this.errorMail = false;
      this.typeField = 'password';
      this.showHide = true;
      this.notFount = false;
      this.notPassword = false;
      this.limitChecked = false;
      this.limitError = false;
    } else if (this.step == 'code') {
      this.limitChecked = false;
      this.limitError = false;
      this.tab = 'login-code';
      this.step = 'auth';
    } else if (this.step == 'code-forgot') {
      this.limitChecked = false;
      this.limitError = false;
      this.step = 'forgot-password';
    } else if (this.step == 'code-reg' && !this.nonResident) {
      this.typeField = 'password';
      this.showHide = true;
      this.limitChecked = false;
      this.limitError = false;
      this.step = 'registration';
      this.nonResident = false;
      this.phoneFormResident = 7 + this.universalField;
      this.passwordFormResident = '';
      this.notFount = false;
      this.notPassword = false;
    } else if (this.step == 'code-reg' && this.nonResident) {
      this.typeField = 'password';
      this.showHide = true;
      this.limitChecked = false;
      this.limitError = false;
      this.step = 'registration';
      this.nonResident = true;
      this.passwordNonResident = '';
      this.notFount = false;
      this.notPassword = false;
    }
  }

  authSubmit(token?: any) {
    this.reCaptchaToken = token;
    this.checked = false;
    this.manyAccounts = false;
    this.notFount = false;
    this.error = false;
    this.notPassword = false;
    this.codeError = false;
    this.codeUsed = false;
    this.errorPassword = false;
    this.progressSpinner = true;
    if (this.step == 'auth' && this.tab == 'login-password') {
      this.notFount = !this.universalField;
      this.notPassword = !this.passwordAuth;
      if (this.notFount || this.notPassword) {
        this.progressSpinner = false;
        return;
      }
    } else {
      if (!this.universalField) {
        this.notFount = true;
        this.progressSpinner = false;
        return;
      }
    }
    if (Constants.PHONE_PATTERN_7.test(this.universalField)) {
      this.checkExist(this.universalField.replace("+", ""), 'phone');
    } else if (Constants.PHONE_PATTERN_77.test(this.universalField)) {
      this.checkExist(this.universalField, 'phone');
    } else if (Constants.PHONE_PATTERN_8.test(this.universalField)) {
      this.checkExist(this.universalField.replace("8", "7"), 'phone');
    } else if (Constants.EMAIL_PATTERN.test(this.universalField)) {
      this.checkExist(this.universalField, 'email');
    } else {
      this.error = true;
      this.progressSpinner = false;
    }
  }

  checkingValidity() {
    this.checked = false;
    this.manyAccounts = false;
    this.notFount = false;
    this.error = false;
    if (!this.universalField) {
      this.notFount = true;
      return;
    }
    if (Constants.PHONE_PATTERN_7.test(this.universalField) || Constants.PHONE_PATTERN_77.test(this.universalField) ||
      Constants.PHONE_PATTERN_8.test(this.universalField) || Constants.EMAIL_PATTERN.test(this.universalField)) {
      return;
    } else {
      this.error = true;
    }
  }

  async checkExist(emailOrPhone: any, type: string) {
    this.codeError = false;
    this.type = type;
    this.emailOrPhone = emailOrPhone;
    this.countdownSecs = 0;
    this.limitSecs = 0;
    this.blockingSecs = 0;
    this.errors = '';
    this.blockingError = false;
    this.ngOtpInputRef?.otpForm.enable();
    try {
      if ((this.step == 'auth' && this.tab == 'login-code') || this.step == 'code') {
        if (this.reCaptchaToken) {
          if (this.step == 'auth' && this.tab == 'login-code') {
            if (!this.noAuth) {
              try {
                ym(96365240,'reachGoal','odnorazovy-kod-poluchit-kod')
              } catch (ignore) {}
            } else {
              try {
                ym(96365240,'reachGoal','lk-kod-poluchit-kod')
              } catch (ignore) {}
            }
          }
          if (type === 'phone') {
            await this.regAuthControllerService.userVerificationAndSMSSendingOtp(emailOrPhone, this.locale, this.reCaptchaToken).toPromise();
          } else {
            await this.regAuthControllerService.userVerificationAndEmailSendingOtp(emailOrPhone, this.locale, this.reCaptchaToken).toPromise();
          }
          this.captchaAuth.reset();
          this.step = 'code';
        } else {
          this.captchaAuth.execute();
        }
      } else if (this.step == 'auth' && this.tab == 'login-password') {
        let authData = {
          login: this.emailOrPhone,
          password: this.passwordAuth,
          lang: this.locale
        }
        if (!this.noAuth) {
          try {
            ym(96365240,'reachGoal','po-poroly-voity')
          } catch (ignore) {}
        } else {
          try {
            ym(96365240,'reachGoal','lk-po-paroly-voity')
          } catch (ignore) {}
        }

        await this.regAuthControllerService.authLogin(authData).toPromise();
        this.successAuth.emit();

        this.authService.isLoggedIn().then(auth => {
          this.auth = !auth;
          if (auth) {
            this.main.userInfo().subscribe({
              next: (res: any) => {
                if (res.userInfo.mainCompanyBin != null) {
                  localStorage.setItem('company', JSON.stringify(res.userInfo));
                  this.headerService.currentCompany = res.userInfo.mainCompanyBin;
                } else {
                  localStorage.setItem('company', '0');
                }
              },
              error: () => {
              }
            });
          }
        });

        if(typeof window !== 'undefined') {
          if (this.redirect) {
            window.location.href = `/${this.locale == 'ru' ? '/' : '/?lang=kk'}`;
          }
          if (this.url) {
            window.location.href = this.url;
          }
        }
      } else if (this.step == 'forgot-password' || this.step == 'code-forgot') {
        if (this.reCaptchaToken) {
          if (this.step == 'forgot-password') {
            if (!this.noAuth) {
              try {
                ym(96365240,'reachGoal','vostonavleniye-poluchit-kod')
              } catch (ignore) {}
            } else {
              try {
                ym(96365240,'reachGoal','lk-vosstanovleniye-kod')
              } catch (ignore) {}
            }
          }
          await this.regAuthControllerService.sendCodeRecoveryPassword(emailOrPhone, this.locale, this.reCaptchaToken).toPromise();
          this.captchaAuth.reset();
          this.step = 'code-forgot';
        } else {
          this.captchaAuth.execute();
        }
      } else if (this.step == 'code-reg') {
        if (!this.nonResident) {
          await this.regAuthControllerService.userVerificationAndSMSSendingReg(emailOrPhone, this.locale).toPromise();
        } else {
          await this.regAuthControllerService.userVerificationAndEmailSendingReg(emailOrPhone, this.locale).toPromise();
        }
      }
      this.universalFormat(this.universalField);
      this.enterCode();
      this.progressSpinner = false;
    } catch (error: any) {
      this.captchaAuth.reset();
      if (error.status === 404) {
        this.checked = true;
      } else if (error.status === 409) {
        this.manyAccounts = true;
      } else if (error.status === 403) {
        if (this.step == 'auth' && this.tab == 'login-password') {
          this.errorFunctional();
          this.step = 'account-blocking';
          this.min = error.error.access;
          this.blockingSecs = error.error.access;
          this.blockingError = true;
          this.blockingTime();
        } else {
          this.errorFunctional();
          this.limitChecked = true;
          this.limitSecs = error.error.access;
          this.limitTime();
        }
      } else if (error.status === 400) {
        if (this.step == 'auth' && this.tab == 'login-password') {
          this.errorPassword = true;
        }
      }
      this.progressSpinner = false;
    }
  }

  errorFunctional() {
    clearInterval(this.timer);
    this.checked = false;
    this.manyAccounts = false;
    this.error = false;
    this.notFount = false;
    this.errorPassword = false;
    this.notPassword = false;
  }

  blockingTime() {
    this.timer = setInterval(() => {
      this.blockingSecs--;
      if (this.blockingSecs == 0 || this.blockingSecs < 0) {
        clearInterval(this.timer);
        this.blockingSecs = this.min;
        this.blockingError = false;
      }
    }, 1000);
  }

  blockingTimeLeft() {
    let time = this.blockingSecs;
    let minutes = Math.floor(time / 60);
    let seconds = (time - minutes * 60);
    return minutes + ':' + (seconds.toString().length == 1 ? '0' + seconds : seconds);
  }

  enterCode() {
    clearInterval(this.timer);
    this.countdown();
    this.waitSms();
  }

  waitSms() {
    if(typeof window !== 'undefined') {
      if ('OTPCredential' in window) {
        this.ac = new AbortController();
        // @ts-ignore
        navigator.credentials.get({otp: {transport: ['sms']}, signal: this.ac.signal}).then(otp => {
          // @ts-ignore
          if (otp.code) {
            // @ts-ignore
            this.verifyCode = otp.code;
            this.verifyCodeSend(true);
          }
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  countdown() {
    this.countdownSecs = 59;
    this.timer = setInterval(() => {
      this.countdownSecs--;
      if (this.countdownSecs == 0 || this.countdownSecs < 0) {
        clearInterval(this.timer);
        this.countdownSecs = 60;
      }
    }, 1000);
  }

  timeLeft(): string {
    let minutes = Math.floor(this.countdownSecs / 60);
    let seconds = (this.countdownSecs - minutes * 60);
    return minutes + '0:' + (seconds.toString().length == 1 ? '0'+ seconds : seconds);
  }

  universalFormat(universal: any) {
    this.textEmail = false;
    if (Constants.PHONE_PATTERN_7.test(universal) || Constants.PHONE_PATTERN_77.test(universal) ||
      Constants.PHONE_PATTERN_8.test(universal) || Constants.PHONE_PATTERN.test(universal)) {
      this.formatPhoneNumber(universal);
    } else {
      this.unFiled = universal;
      this.textEmail = true;
    }
  }

  formatPhoneNumber(universal: string) {
    let cleanedPhone: any;
    if (universal.length > 11) {
      cleanedPhone = universal.replace("+7", "");
    } else if (universal.length === 11) {
      cleanedPhone = universal.slice(1);
    } else {
      cleanedPhone = universal;
    }
    cleanedPhone = cleanedPhone.toString();
    let num = cleanedPhone.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
    this.unFiled = "+7 (" + num?.[1] + ") " + num?.[2] + " " + num?.[3] + " " + num?.[4];
  }

  focusInput(event: any) {
    this.verifyCode = event;
    if (this.verifyCode.length === 4) {
      this.verifyCodeSend();
    } else {
      this.codeError = false;
      this.codeUsed = false;
      this.errors = '';
    }
  }

  deleteSymbol(emailOrPhone: any) {
    this.limitChecked = false;
    if (emailOrPhone.length == 10 && /^\d+$/.test(emailOrPhone)) {
      emailOrPhone = '+7' + emailOrPhone;
    }
    if (Constants.EMAIL_PATTERN.test(emailOrPhone)) {
      return;
    }
    this.universalField = emailOrPhone.replace(/\s/g, '').replace(/-/g, '').replace(/[()]/g, '');
  }

  async verifyCodeSend(otp: boolean = false) {
    this.codeError = false;
    this.codeUsed = false;
    this.limitError = false;
    this.userCode = '';
    if (!otp && this.ac) this.ac.abort();
    try {
      let data: any;
      if (this.step === 'code') {
        data = this.type === 'phone' ?
          await this.regAuthControllerService.checkingCodeSMSAndGettingTokenOtp(this.emailOrPhone, this.verifyCode, this.locale).toPromise() :
          await this.regAuthControllerService.checkingCodeEmailAndGettingTokenOtp(this.emailOrPhone, this.verifyCode, this.locale).toPromise();
        this.setCookieAndClose();
      } else if (this.step === 'code-forgot') {
        let dataPas = {
          target: this.emailOrPhone,
          code: this.verifyCode,
          lang: this.locale
        }
        data = await this.regAuthControllerService.verifyCodeRecoveryPassword(dataPas).toPromise();
        this.userCode = data.message;
        this.step = 'password-recovery';
      } else if (this.step == 'code-reg') {
        data = !this.nonResident ?
          await this.regAuthControllerService.checkingCodeSMSAndGettingTokenReg(this.emailOrPhone, this.verifyCode, this.locale).toPromise() :
          await this.regAuthControllerService.checkingCodeEmailAndGettingTokenReg(this.emailOrPhone, this.verifyCode, this.locale).toPromise();
        this.setCookieAndClose();
      }
    } catch (error: any) {
      if (error.status === 400 || error.status === 404) {
        this.ngOtpInputRef.setValue('');
        let eleId = this.ngOtpInputRef.getBoxId(0);
        this.ngOtpInputRef.focusTo(eleId);
        this.codeError = true;
        this.codeUsed = false;
        this.errors = 'errors';
      } else if (error.status === 403) {
        this.ngOtpInputRef.setValue('');
        clearInterval(this.timer);
        this.codeError = false;
        this.codeUsed = false;
        this.limitSecs = error.error.access;
        this.limitTime();
        this.limitError = true;
        this.errors = 'error-limit';
        this.ngOtpInputRef.otpForm.disable();
      } else if (error.status === 419) {
        this.ngOtpInputRef.setValue('');
        clearInterval(this.timer);
        this.codeUsed = true;
        this.codeError = false;
        this.limitError = false;
        this.errors = 'error-limit';
        this.ngOtpInputRef.otpForm.disable();
        setTimeout(()=>{
          this.auth = false;
        },3000);
      }
    }
  }

  limitTime() {
    this.timer = setInterval(() => {
      this.limitSecs--;
      if (this.limitSecs == 0 || this.limitSecs < 0) {
        clearInterval(this.timer);
        this.countdownSecs = 60;
        this.limitSecs = 300;
        this.limitError = false;
        this.limitChecked = false;
      }
    }, 1000);
  }

  limitTimeLeft(): string {
    let time = this.limitSecs;
    let minutes = Math.floor(time / 60);
    let seconds = (time - minutes * 60);
    return minutes + ':' + (seconds.toString().length == 1 ? '0'+ seconds : seconds);
  }

  authBack() {
    if (this.step == 'registration' && !this.nonResident) {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','registraciya-voity')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-registraciya-voity')
        } catch (ignore) {}
      }
    } else {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','nerezident-voity')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-nerezident-voity')
        } catch (ignore) {}
      }
    }
    this.step = 'auth';
    this.tab = 'login-password';
    this.nonResident = false;
    this.universalField = '';
    this.clear();
  }

  recoveryPasswordSubmit(token?: any) {
    this.error = false;
    this.errorPassword = false;
    let passwordLengthValid = this.recoveryPas.get('password')?.value?.length > 0;
    let passwordValid = Constants.PASSWORD.test(this.recoveryPas.get('password')?.value);
    if (passwordLengthValid && !passwordValid) {
      this.errorPassword = true;
      return
    }
    let dataPass: any;
    if (this.queryParams.has('pr')) {
      dataPass = {
        target: this.queryParams.get('target'),
        code: this.queryParams.get('token'),
        lang: this.queryParams.get('lang'),
        newPassword: this.recoveryPas.get('password')?.value,
        reCaptcha: token,
      }
    } else {
      dataPass = {
        target: this.emailOrPhone,
        code: this.userCode,
        lang: this.locale,
        newPassword: this.recoveryPas.get('password')?.value,
        reCaptcha: token,
      }
    }
    if (token) {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','smenit-parol')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-vosstanovleniye-parol')
        } catch (ignore) {}
      }
      this.regAuthControllerService.sendNewPassword(dataPass).subscribe((data: any)=> {
        this.captchaResetPassword.reset();
        this.authToken = data.access_token;
        this.step = 'password-changed';
      }, error => {
        this.captchaResetPassword.reset();
        if (error.status === 400) {
          this.error = true;
        }
      });
    } else {
      this.captchaResetPassword.execute();
    }
  }

  /**
   * Регистрация
   * */
  openRegistration() {
    if (this.step == 'auth' && this.tab == 'login-password') {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','po-poroly-zaregistrirovatsya')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-po-paroly-registraciya')
        } catch (ignore) {}
      }
    } else if (this.step == 'auth' && this.tab == 'login-code') {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','odnorazovy-kod-zaregistrirovatsya')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-kod-registraciya')
        } catch (ignore) {}
      }
    }
    this.nonResident = false;
    this.step = 'registration';
    this.clear();
  }

  openNonResidentRegistration() {
    if (!this.noAuth) {
      try {
        ym(96365240,'reachGoal','nerezident')
      } catch (ignore) {}
    } else {
      try {
        ym(96365240,'reachGoal','lk-registraciya-nerezident')
      } catch (ignore) {}
    }
    this.nonResident = true;
    this.step = 'registration';
    this.clear();
  }

  phoneEmailRegPattern() {
    this.patternErrorEmail = false;
    this.patternErrorPhone = false;
    let phoneValue = this.phoneFormResident;
    let emailValue = this.nonResident ? this.emailNonResident : this.emailFormResident;
    this.patternErrorPhone = phoneValue ? !Constants.PHONE_PATTERN.test(phoneValue) : false;
    this.patternErrorEmail = emailValue ? !Constants.EMAIL_PATTERN.test(emailValue) : false;
  }

  sendSmsEmailForRegistration(token?: any) {
    this.ngOtpInputRef?.otpForm.enable();
    this.errors = '';
    this.error = false;
    this.notFount = false;
    this.notPassword = false;
    this.errorMail = false;
    this.errorPhone = false;
    this.errorPassword = false;
    this.phoneEmailRegPattern();
    if (this.step == 'registration' && this.nonResident) {
      this.notFount = !this.emailNonResident;
      this.notPassword = !this.passwordNonResident;
      if (this.notFount || this.notPassword) {
        return;
      }
    } else {
      this.notPhone = !this.phoneFormResident;
      this.notFount = !this.emailFormResident;
      this.notPassword = !this.passwordFormResident;
      if (this.notPhone || this.notFount || this.notPassword) {
        return;
      }
    }
    if (this.patternErrorPhone || this.patternErrorEmail) {
      return;
    }
    let passwordLengthValid = this.passwordFormResident.length > 0 || this.passwordNonResident.length > 0;
    let passwordValid = Constants.PASSWORD.test(this.passwordFormResident || this.passwordNonResident);
    if (passwordLengthValid && !passwordValid) {
      this.errorPassword = true;
      return;
    }
    let phoneValue = '7' + this.phoneFormResident;
    let emailValue = this.nonResident ? this.emailNonResident : this.emailFormResident;
    this.emailOrPhone = this.nonResident ? this.emailNonResident : phoneValue;
    let passwordValue = this.nonResident ? this.passwordNonResident : this.passwordFormResident;
    let domain = OcpUtils.isProd() ? 'ismet.kz' : 'test.i-smet.kz';
    let path = this.locale == 'ru' ? '' : '/kk';
    if (token) {
      if (this.step == 'registration' && !this.nonResident) {
        if (!this.noAuth) {
          try {
            ym(96365240,'reachGoal','registraciya')
          } catch (ignore) {}
        } else {
          try {
            ym(96365240,'reachGoal','lk-registraciya')
          } catch (ignore) {}
        }
      } else {
        if (!this.noAuth) {
          try {
            ym(96365240,'reachGoal','nerezident-registraciya')
          } catch (ignore) {}
        } else {
          try {
            ym(96365240,'reachGoal','lk-nerezident-registraciya')
          } catch (ignore) {}
        }
      }
      let dataRegistration = {
        lang: this.locale,
        firstName: emailValue,
        lastName: " ",
        email: emailValue,
        phoneNumber: !this.nonResident ? phoneValue : undefined,
        password: passwordValue,
        redirectUrl: `https://${domain}${path}`,
        reCaptcha: token
      };
      this.regAuthControllerService.sendingCodeAndUserRegistration(dataRegistration).subscribe(()=> {
        this.captchaRegistration.reset();
        this.universalField = !this.nonResident ? phoneValue.replace("7", "") : emailValue;
        this.universalFormat(this.universalField);
        this.step = 'code-reg';
        this.enterCode();
      }, error => {
        this.captchaRegistration.reset();
        if (error.status === 400 && error.error.access == 'email') {
          this.errorMail = true;
        } else if (error.status === 400 && error.error.access == 'phoneNumber') {
          this.errorPhone = true;
        } else if (error.status === 400 && error.error.access == 'email/phoneNumber') {
          this.errorMail = true;
          this.errorPhone = true;
        } else if (error.status === 403) {
          this.errorFunctional();
          this.passwordFormResident = '';
          this.passwordNonResident = '';
          this.limitChecked = true;
          this.limitSecs = error.error.access;
          this.limitTime();
        }
      });
    } else {
      this.captchaRegistration.execute();
    }
  }

  /**
   * Сохранение COOKIE
   * */
  setCookieAndClose() {
    this.auth = false;
    this.authService.isLoggedIn().then(auth => {
      this.auth = !auth;
      if (auth) {
        this.successAuth.emit();
        this.main.userInfo().subscribe({
          next: (res: any) => {
            if (res.userInfo.mainCompanyBin != null) {
              localStorage.setItem('company', JSON.stringify(res.userInfo));
              this.headerService.currentCompany = res.userInfo.mainCompanyBin;
            } else {
              localStorage.setItem('company', '0');
            }
          }
        });
      }
    });

    if(typeof window !== 'undefined') {
      if (this.redirect) {
        window.location.href = `/${this.locale == 'ru' ? '/' : '/?lang=kk'}`;
      }
      if (this.url) {
        window.location.href = this.url;
      }
    }
  }

  openOfferLocale() {
    if (this.step == 'registration' && !this.nonResident) {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','public-oferta')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-oferta')
        } catch (ignore) {}
      }
    } else {
      if (!this.noAuth) {
        try {
          ym(96365240,'reachGoal','nerezident-public-oferta')
        } catch (ignore) {}
      } else {
        try {
          ym(96365240,'reachGoal','lk-nerezident-oferta')
        } catch (ignore) {}
      }
    }
    let domain = OcpUtils.isProd() ? 'ismet.kz' : 'test.i-smet.kz';
    let path = this.locale == 'ru' ? '/ru/help/offer' : '/kk/help/offer';
    window?.open(`https://${domain}${path}`, '_blank');
  }

  clickToPassword() {
    if (!this.noAuth) {
      try {
        ym(96365240,'reachGoal','po-paroly')
      } catch (ignore) {}
    } else {
      try {
        ym(96365240,'reachGoal','lk-po-paroly')
      } catch (ignore) {}
    }
    this.tab = 'login-password';
    this.clear();
  }

  clickToForgotPassword() {
    if (!this.noAuth) {
      try {
        ym(96365240,'reachGoal','zabily-parol')
      } catch (ignore) {}
    } else {
      try {
        ym(96365240,'reachGoal','lk-po-paroly-zabily-parol')
      } catch (ignore) {}
    }
    this.step = 'forgot-password';
    this.clear();
  }

  clickToCode() {
    if (!this.noAuth) {
      try {
        ym(96365240,'reachGoal','odnorazovy-kod')
      } catch (ignore) {}
    } else {
      try {
        ym(96365240,'reachGoal','lk-kod')
      } catch (ignore) {}
    }
    this.tab = 'login-code';
    this.clear();
  }
}
