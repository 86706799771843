<ng-container *ngIf="companyData?.state.id == 3 || text != null">
  <div class="breadcrumb flex">
    <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
  </div>
  <div class="comment-moderation yellow">
    <div [inlineSVG]="'assets/images/main/infoModerationOrange.svg'"></div>
    <div>
      <div class="moderation-text">{{'moderatorsComment:' | translate}}</div>
      <div class="moderation-text">{{text}}</div>
    </div>
  </div>
</ng-container>

<div class="mt-4">
  {{ 'Status' | translate }}: {{translate.currentLang == 'ru' ? companyData?.state.name_ru : companyData?.state.name_kz}}
</div>

<ng-container>
  <div class="over-header mt-4">{{'CompanyData' | translate}}
    <span *ngIf="(companyData?.state.id == 4 || companyData?.state.id == 5) && disableFields"
          (click)="disableFields = !disableFields"
          class="edit-link">
      {{ 'Edit-2' | translate }}
    </span>
  </div>
</ng-container>

<form [formGroup]="companyForm">
  <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
    <div class="w-3">
      <div class="label-txt">{{ 'CompanyName' | translate }}</div>
      <div class="relative field-wrap">
        <input readonly [value]="name" class="card-input" type="text" pInputText/>
        <div class="field-eye">
          <img class="cursor-pointer" alt="" (click)="changeShowField('show_name')"
               [src]="this.companyForm.get('show_name')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
        </div>
      </div>
    </div>
    <div class="mt-1">
      <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
      </svg>
    </div>
    <div class="w-3">
      <div>
        <div class="label-txt">{{ 'IINBIN' | translate }}</div>
        <div class="relative field-wrap">
          <input formControlName="bin" readonly class="card-input" type="text" pInputText/>
          <div class="field-eye">
            <img class="cursor-pointer" alt="" (click)="changeShowField('show_bin')"
                 [src]="this.companyForm.get('show_bin')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
    <div class="w-3">
      <div class="label-txt">{{ 'Tradename' | translate }}
        <span class="text-red-500">*</span>
      </div>
      <div class="relative field-wrap">
        <input [readonly]="disableFields" formControlName="trade_name_ru" class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
  <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
    <div class="w-3">
      <div class="label-txt">{{ 'FieldOfActivity' | translate }}
        <span class="text-red-500">*</span>
      </div>
      <div class="relative field-wrap">
        <input [readonly]="disableFields" formControlName="field_of_activity_ru" class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
  <div class="flex">
    <div>
      <div>{{'logo' | translate}}</div>
      <div class="second-logo-wrap mr-2 relative">
        <img (click)="croppedImageLogo = ''" *ngIf="croppedImageLogo" class="cropped-cross" src="assets/images/main/cross-icon.svg" alt=""/>
        <label for="crop-file">
          <img style="background: #f5f7f9;" class="cursor-pointer" src="assets/images/main/add-file-icon.svg" alt="">
          <img *ngIf="!croppedImageLogo" class="data-img"
               [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + companyData?.avatar_img_url" alt="">
          <img *ngIf="!disableFields" src="/assets/images/main/pencil.svg" alt="">
          <img class="cropped" [src]="croppedImageLogo.objectUrl" alt=""/>
        </label>
        <input [disabled]="disableFields" accept="image/png, image/jpg, image/jpeg" id="crop-file" class="crop-first"
               type="file" (change)="fileChangeEvent($event, 1)" />
      </div>
    </div>
    <div>
      <div>{{'banner' | translate}}</div>
      <div class="second-banner-wrap relative">
        <img (click)="croppedImageBanner = ''" *ngIf="croppedImageBanner" class="cropped-cross" src="assets/images/main/cross-icon.svg" alt=""/>
        <label for="crop-file-banner">
          <img style="background: #f5f7f9;" class="cursor-pointer" src="assets/images/main/add-file-icon.svg" alt="">
          <img *ngIf="!croppedImageBanner" class="data-img-banner"
               [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + companyData?.banner_img_url" alt=""/>
          <img *ngIf="!disableFields" src="/assets/images/main/pencil.svg" alt="">
          <img class="cropped-banner" [src]="croppedImageBanner.objectUrl" alt=""/>
        </label>
        <input [disabled]="disableFields" accept="image/png, image/jpg, image/jpeg" id="crop-file-banner" class="crop-first"
               type="file" (change)="fileChangeEvent($event, 2)" />
      </div>
    </div>
  </div>
  <div class="file-txt">
    {{ 'FileFormat' | translate }} {{ "SizeRecommendation-1" | translate }} <br> {{ "SizeRecommendation-2" | translate }}
  </div>
  <div class="mt-3">
    <div class="mb-1 comment-head">
      {{ 'ShortDescription' | translate }} <span class="text-red-500">*</span>
    </div>
    <div class="mb-3 relative">
      <textarea [readonly]="disableFields" formControlName="description_ru" maxlength="500" rows="4"
                class="w-6" cols="90" pInputTextarea [placeholder]="'describeWhatYourCompanyDoes' | translate"></textarea>
      <div class="err-txt">{{textLength(companyForm.get('description_ru')?.value)}}/500 {{ 'Characters' | translate }}</div>
    </div>
  </div>
  <div class="over-header mt-4">
    {{ 'LegalAddress' | translate }}
  </div>
  <div class="field-wrap">
    <div class="label-txt">
      {{ 'Address' | translate }} <span class="text-red-500">*</span>
    </div>

    <div class="relative w-6 field-wrap">
      <input [readonly]="disableFields" class="card-input" type="text" pInputText formControlName="street"/>
      <div class="field-eye">
        <img class="cursor-pointer" alt="" (click)="changeShowField('show_address')"
             [src]="this.companyForm.get('show_address')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
      </div>
    </div>
  </div>
  <div class="over-header mt-4">
    {{ 'ContactData' | translate }}
  </div>
  <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
    <div class="w-3">
      <div class="label-txt">
        {{ 'PhoneNumber' | translate }} <span class="text-red-500">*</span>
      </div>
      <div class="relative field-wrap">
        <p-inputMask [readonly]="disableFields" placeholder="+7 (___) ___ __ __" [styleClass]="'card-input'" mask="+7 (999) 999 99 99"
                     [unmask]="true" formControlName="phone"></p-inputMask>
        <div class="field-eye">
          <img class="cursor-pointer" alt="" (click)="changeShowField('show_phone')"
               [src]="this.companyForm.get('show_phone')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
        </div>
      </div>
    </div>
    <div class="mt-1">
      <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
      </svg>
    </div>
    <div class="w-3">
      <div>
        <div class="label-txt">{{'Email'}}
          <span class="text-red-500">*</span>
        </div>
        <div class="relative field-wrap">
          <input [readonly]="disableFields" class="card-input" type="text" pInputText formControlName="email"/>
        </div>
      </div>
    </div>
  </div>
  <div class="field-wrap pr-3 w-3">
    <div class="label-txt">{{'Сайт' | translate}}
    </div>
    <input [readonly]="disableFields" class="card-input" type="text" pInputText formControlName="website"/>
  </div>
  <div class="over-header mt-4">{{ 'Social' | translate }}</div>
  <div class="w-6">
    <div class="mb-2">{{'WhatsApp'}}
      <span class="text-red-500">*</span>
    </div>
    <input [readonly]="disableFields" class="card-input mb-3" type="text" pInputText formControlName="whatsapp"/>
    <div *ngIf="tgField" class="mb-2">{{'Telegram'}}</div>
    <input [readonly]="disableFields" *ngIf="tgField" class="card-input mb-3" type="text" pInputText formControlName="telegram"/>
    <div *ngIf="insField" class="mb-2">{{'Instagram'}}</div>
    <input [readonly]="disableFields" *ngIf="insField" class="card-input mb-3" type="text" pInputText formControlName="instagram"/>
  </div>
  <div class="flex mb-4 social" *ngIf="companyData?.state.id == 3 || !disableFields">
    <div (click)="tgField = !tgField" class="flex cursor-pointer align-items-center gap-2 mr-3">
      {{'Telegram'}}
      <div *ngIf="!tgField" inlineSVG="assets/images/common/add.svg"></div>
      <div *ngIf="tgField" inlineSVG="assets/images/common/check.svg"></div>
    </div>
    <div (click)="insField = !insField" class="flex cursor-pointer align-items-center gap-2 mr-3">
      {{'Instagram'}}
      <div *ngIf="!insField" inlineSVG="assets/images/common/add.svg"></div>
      <div *ngIf="insField" inlineSVG="assets/images/common/check.svg"></div>
    </div>
  </div>
  <div *ngIf="companyData?.state.id == 3 || companyData?.state.id == 2 || !disableFields" class="flex my-3">
    <div>
      <button *ngIf="companyData?.state.id == 3" class="cancel-btn" (click)="closeModalAndBack()">{{ 'Cancel' | translate }}</button>
      <button *ngIf="companyData?.state.id == 4" class="cancel-btn" (click)="disableFields = true">{{ 'Cancel' | translate }}</button>
    </div>
    <div>
      <button [disabled]="companyForm.invalid" class="sign-btn" (click)="request()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</form>

<p-dialog [(visible)]="informSendingModeration" [modal]="true" [draggable]="false" [resizable]="false" styleClass="sending-moderation" (onHide)="closeModalAndBack()">
  <div class="info-block">
    <div inlineSVG="assets/images/main/success.svg"></div>
    <div class="info-text">{{'theCompany\'sBusinessCardHasBeenSentForModerationAndWillBeUpdateAfterVerification' | translate}}</div>
    <button (click)="closeModalAndBack()" class="accept-btn w-full justify-content-center" pButton type="button">
      {{'Fine' | translate}}
    </button>
  </div>
</p-dialog>

<p-dialog [(visible)]="cropModalLogo" [modal]="true" [style]="{width: '400px'}" [draggable]="false" [resizable]="false">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    [resizeToWidth]="170"
    format="png"
    (imageCropped)="imageCropped($event, 1)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <button class="crop-btn" (click)="cropIt(1)">
    {{ 'Continue' | translate }}
  </button>
</p-dialog>

<p-dialog [(visible)]="cropModalBanner" [modal]="true" [style]="{width: '600px'}" [draggable]="false" [resizable]="false">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="6"
    [resizeToWidth]="1200"
    [resizeToHeight]="200"
    format="png"
    (imageCropped)="imageCropped($event, 2)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <button class="crop-btn" (click)="cropIt(2)">
    {{ 'Continue' | translate }}
  </button>
</p-dialog>
