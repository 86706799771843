<p-dialog [(visible)]="auth" [modal]="true" [draggable]="false" [resizable]="false" [position]="position"
          [dismissableMask]="true" [styleClass]="step == 'auth' ? 'auth-dialog' : 'registration'"
          (onHide)="closeDialog()">
  <ng-template pTemplate="header">
    <ng-container *ngTemplateOutlet="authHeaderTemplate"></ng-container>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="authContentTemplate"></ng-container>
  </ng-template>
</p-dialog>

<div *ngIf="!auth && noAuth" class="auth open">
  <div class="auth-open">
    <ng-container *ngTemplateOutlet="authHeaderTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="authContentTemplate"></ng-container>
  </div>
</div>

<ng-template #authHeaderTemplate>
  <div class="auth-content-img">
    <div *ngIf="step == 'code' || this.step == 'code-reg' || this.step == 'code-forgot' ||
           step == 'registration' || step == 'forgot-password' || step == 'password-recovery'"
         class="auth-content-img__back" (click)="back()"></div>
  </div>
</ng-template>

<ng-template #authContentTemplate>
  <div class="auth">
    <!--Авторизация-->
    <ng-container *ngIf="step == 'auth'">
      <div class="auth__title">{{'Вход' | translate}}</div>
      <div class="auth-tab">
        <div class="auth-tab__title" [ngClass]="{'active': tab == 'login-password'}" (click)="clickToPassword()">{{'По паролю' | translate}}</div>
        <div class="auth-tab__title" [ngClass]="{'active': tab == 'login-code'}" (click)="clickToCode()">{{'Одноразовый код' | translate}}</div>
      </div>
      <div class="auth-content">
        <!--Вход по логину-->
        <ng-container *ngIf="tab == 'login-password'">
          <form class="auth-content" (ngSubmit)="authSubmit()">
            <div class="auth-block">
              <label class="auth-content__label" for="login">{{'Почта или телефон' | translate}}</label>
              <input class="auth-content__input" id="login" [(ngModel)]="universalField" autocomplete="username" (ngModelChange)="notFount = false"
                     (change)="deleteSymbol(universalField); checkingValidity()" [ngClass]="{'error': checked || notFount || error}" name="login"
                     autofocus [placeholder]="'Например: example@email.kz или +77011234567' | translate">
              <div class="auth-content__error" *ngIf="checked">{{'Учётная запись с такими данными не зарегистрирована' | translate}}</div>
              <div class="auth-content__error" *ngIf="error">{{'Неверный формат почты или телефона' | translate}}</div>
              <div class="auth-content__error" *ngIf="notFount">{{'Введите почту или телефон' | translate}}</div>
              <div class="auth-content__error" *ngIf="manyAccounts">{{'К этому номеру привязано несколько аккаунтов. Войдите, используя адрес электронной почты' | translate}}</div>
            </div>
            <div class="auth-block">
              <label class="auth-content__label" for="password">{{'Пароль' | translate}}</label>
              <div class="auth-relative">
                <input class="auth-content__input-pas" id="password" [(ngModel)]="passwordAuth" autocomplete="current-password"
                       [ngClass]="{'error': notPassword || errorPassword}" [minlength]="1" [type]="typeField"
                       (ngModelChange)="notPassword = false" name="password">
                <div class="auth-content__img-input-pas" [ngClass]="{'off' : typeField == 'password'}" (click)="showHidePas()"></div>
              </div>
              <div class="auth-content__error" *ngIf="notPassword">{{'Введите пароль' | translate}}</div>
              <div class="auth-content__error" *ngIf="errorPassword">{{'Неверный пароль' | translate}}</div>
              <div class="auth-password">
                <div class="auth-content__forgot-password" (click)="clickToForgotPassword()">{{'Забыли пароль?' | translate}}</div>
              </div>
              <button class="auth-content__btn" [ngClass]="{'disabled' : progressSpinner}">
                <p-progressSpinner styleClass="custom-spinner" strokeWidth="3" *ngIf="progressSpinner"></p-progressSpinner>
                <div *ngIf="!progressSpinner">{{'Войти' | translate}}</div>
              </button>
            </div>
          </form>
          <div class="auth-content__registration" (click)="openRegistration()">{{'Зарегистрироваться' | translate}}</div>
        </ng-container>
        <!--Вход по одноразовому коду-->
        <ng-container *ngIf="tab == 'login-code'">
          <div class="auth-block">
            <label class="auth-content__label" for="phone">{{'Почта или телефон' | translate}}</label>
            <input class="auth-content__input" id="phone" [(ngModel)]="universalField" autocomplete="username" (ngModelChange)="resetBlock()"
                   (change)="deleteSymbol(universalField); checkingValidity()" [ngClass]="{'error': checked || notFount || error}"
                   pAutoFocus [autofocus]="true" [placeholder]="'Например: example@email.kz или +77011234567' | translate">
            <div class="auth-content__error" *ngIf="checked">{{'Учётная запись с такими данными не зарегистрирована' | translate}}</div>
            <div class="auth-content__error" *ngIf="error">{{'Неверный формат почты или телефона' | translate}}</div>
            <div class="auth-content__error" *ngIf="notFount">{{'Введите почту или телефон' | translate}}</div>
            <div class="auth-content__error" *ngIf="limitChecked">{{'Вы многократно ввели неправильный код' | translate}}</div>
            <div class="auth-content__error" *ngIf="manyAccounts">{{'К этому номеру привязано несколько аккаунтов. Войдите, используя адрес электронной почты' | translate}}</div>
          </div>
          <div class="auth-content__btn-lim" *ngIf="limitChecked">{{'Попробуйте снова через' | translate}}
            <span class="auth-content__error-limit">{{limitTimeLeft()}}</span>
          </div>
          <div class="auth-content__btn" (click)="authSubmit()" *ngIf="!limitChecked">{{'Получить код' | translate}}</div>
          <div class="auth-content__registration" (click)="openRegistration()">{{'Зарегистрироваться' | translate}}</div>
        </ng-container>
      </div>
    </ng-container>
    <!--Блокировка учетной записи-->
    <ng-container *ngIf="step == 'account-blocking'">
      <div class="auth__title">{{'Аккаунт заблокирован' | translate}}</div>
      <div class="auth__text-blocking">{{'Похоже, было слишком много попыток входа с неправильным паролем, и мы временно заблокировали аккаунт в целях безопасности. Попробуйте войти позже или восстановите пароль прямо сейчас' | translate}}</div>
      <div class="auth-blocking">
        <div class="auth-content__btn" (click)="step = 'forgot-password'; clear()">{{'Восстановить пароль' | translate}}</div>
        <div *ngIf="blockingSecs == min && !blockingError" class="auth-content__btn-blocking" (click)="step = 'auth'; clear()">{{'Войти' | translate}}</div>
        <div *ngIf="blockingSecs < min && blockingError && locale == 'ru'" class="auth-content__count">{{'Аккаунт разблокируется через' | translate}}
          <span class="auth-content__time">{{blockingTimeLeft()}}</span>
        </div>
        <div *ngIf="blockingSecs < min && blockingError && locale != 'ru'" class="auth-content__count">{{'Аккаунт'}}
          <span class="auth-content__time"> {{blockingTimeLeft()}} </span>
          <span class="auth-content__count">{{'кейін бұғаттан шығады'}}</span>
        </div>
      </div>
    </ng-container>
    <!--Одноразовый код-->
    <ng-container *ngIf="step == 'code' || step == 'code-reg' || step == 'code-forgot'">
      <div class="auth__title">{{'Код подтверждения' | translate}}</div>
      <div class="auth__text" *ngIf="!textEmail">{{'Введите код из СМС, отправленный на номер {{name}\}' | translate : {name: unFiled} }}</div>
      <div class="auth__text" *ngIf="textEmail">{{'Введите код из письма, отправленный на адрес {{name}\}' | translate : {name: unFiled} }}</div>
      <div class="auth-content-code">
        <ng-otp-input (onInputChange)="focusInput($event)"
                      [config]="{length: 4, allowNumbersOnly: true, inputClass: errors}" #ngOtpInput></ng-otp-input>
      </div>
      <div class="auth-content__error-code" *ngIf="codeError">{{'Неверный код подтверждения' | translate}}</div>
      <div class="auth-content__error-code" *ngIf="limitError">{{'Вы многократно ввели неправильный код' | translate}}</div>
      <div class="auth-content__error-code" *ngIf="codeUsed">{{'Вы уже восстановили доступ через ссылку. Код больше не действителен' | translate}}</div>
      <div *ngIf="(countdownSecs == 60 && !limitError && !codeUsed) || (limitSecs == 300 && !limitError && !codeUsed)" class="auth-content__no-count"
           (click)="checkExist(emailOrPhone, type)">{{'Отправить новый код' | translate}}
      </div>
      <div *ngIf="(countdownSecs < 60 && !limitError && !codeUsed) || (limitSecs < 300 && limitError && !codeUsed)" class="auth-content__count">
        {{'Отправить новый код через' | translate}}
        <span *ngIf="countdownSecs < 60 && !limitError" class="auth-content__time">{{timeLeft()}}</span>
        <span *ngIf="limitSecs < 300 && limitError" class="auth-content__time">{{limitTimeLeft()}}</span>
      </div>
    </ng-container>
    <!--Забыли пароль-->
    <ng-container *ngIf="step == 'forgot-password'">
      <div class="auth-content">
        <div class="auth__title">{{'Восстановление пароля' | translate}}</div>
        <div class="auth-block">
          <label class="auth-content__label" for="phone-forgot">{{'Почта или телефон' | translate}}</label>
          <input class="auth-content__input" id="phone-forgot" [(ngModel)]="universalField" (change)="deleteSymbol(universalField); checkingValidity()"
                 [ngClass]="{'error': checked || notFount || error}" pAutoFocus [autofocus]="true" autocomplete="username" (ngModelChange)="notFount = false"
                 [placeholder]="'Например: example@email.kz или +77011234567' | translate">
          <div class="auth-content__error" *ngIf="checked">{{'Учётная запись с такими данными не зарегистрирована' | translate}}</div>
          <div class="auth-content__error" *ngIf="error">{{'Неверный формат почты или телефона' | translate}}</div>
          <div class="auth-content__error" *ngIf="notFount">{{'Введите почту или телефон' | translate}}</div>
          <div class="auth-content__error" *ngIf="limitChecked">{{'Вы многократно ввели неправильный код' | translate}}</div>
          <div class="auth-content__error" *ngIf="manyAccounts">{{'К этому номеру привязано несколько аккаунтов. Используйте адрес электронной почты для восстановления пароля' | translate}}</div>
        </div>
        <div class="auth-content__btn-lim" *ngIf="limitChecked">{{'Попробуйте снова через' | translate}}
          <span class="auth-content__error-limit">{{limitTimeLeft()}}</span>
        </div>
        <div *ngIf="!limitChecked" class="auth-content__btn" (click)="authSubmit()">{{'Получить код' | translate}}</div>
      </div>
    </ng-container>
    <!--Восстановления пароля-->
    <ng-container *ngIf="step == 'password-recovery'">
      <form class="auth-content" [formGroup]="recoveryPas" (ngSubmit)="recoveryPasswordSubmit()">
        <div class="auth__title">{{'Восстановление пароля' | translate}}</div>
        <div class="auth-block">
          <label class="auth-content__label" for="password-recovery">{{'Придумайте новый пароль' | translate}}</label>
          <p-password id="password-recovery" [placeholder]="'Не менее 8 символов' | translate" pAutoFocus [autofocus]="true"
                      [ngClass]="{'error': recoveryPas.get('password')?.invalid && (error || errorPassword)}"
                      [toggleMask]="true" [feedback]=false formControlName="password" [minlength]="8">
          </p-password>
          <div class="auth-content__error" *ngIf="recoveryPas.get('password')?.invalid && error">
            {{'Придумайте пароль' | translate}}</div>
          <div class="auth-content__error" *ngIf="recoveryPas.get('password')?.invalid && errorPassword">
            {{'Требования к паролю: не менее 8 символов (может быть 8 разных цифр и/или букв)' | translate}}
          </div>
        </div>
        <button class="auth-content__btn">{{'Сменить пароль' | translate}}</button>
      </form>
    </ng-container>
    <!--Пароль успешно восстановлен-->
    <ng-container *ngIf="step == 'password-changed'">
      <div inlineSVG="img/auth-dialog/check-password.svg" class="auth__img"></div>
      <div class="auth__title">{{'Восстановление пароля' | translate}}</div>
      <div class="auth__text">{{'Пароль для вашей учётной записи был успешно изменён' | translate}}</div>
    </ng-container>
    <!--Срок действия ссылки-->
    <ng-container *ngIf="step == 'link-expired'">
      <div class="auth__title">{{'Срок действия ссылки истёк' | translate}}</div>
      <div class="auth__text">{{'Начните процесс восстановления пароля заново' | translate}}</div>
      <div class="auth-content__link" (click)="step = 'forgot-password'">{{'Восстановить пароль' | translate}}</div>
    </ng-container>
    <!--Регистрация резидент-->
    <ng-container *ngIf="step == 'registration' && !nonResident">
      <form class="auth-content" (ngSubmit)="sendSmsEmailForRegistration()">
        <div class="auth__title">{{'Регистрация' | translate}}</div>
        <div class="auth-block">
          <label class="auth-content__label" for="phone-reg">{{'Телефон' | translate}}</label>
          <p-inputMask mask="+7 999 999 99 99" id="phone-reg" placeholder="+7 ___ ___ __ __" [autoFocus]="true" name="phone-reg"
                       [styleClass]="notPhone || patternErrorPhone || errorPhone ? 'error' : ''" autocomplete="+7__________"
                       [autoClear]="false" [(ngModel)]="phoneFormResident" (ngModelChange)="resetBlock()" [unmask]="true"
                       (change)="phoneEmailRegPattern()"></p-inputMask>
          <div class="auth-content__error" *ngIf="notPhone">{{'Введите телефон' | translate}}</div>
          <div class="auth-content__error" *ngIf="errorPhone">{{'Телефон уже зарегистрирован. Введите другой номер или войдите' | translate}}</div>
          <div class="auth-content__error" *ngIf="patternErrorPhone">{{'Неверный формат телефона' | translate}}</div>
        </div>
        <div class="auth-block">
          <label class="auth-content__label" for="email">{{'Почта' | translate}}</label>
          <input class="auth-content__input" id="email" [placeholder]="'Например: example@email.kz' | translate" name="email"
                 (change)="phoneEmailRegPattern()" [(ngModel)]="emailFormResident" type="email" (ngModelChange)="notFount = false"
                 [ngClass]="{'error': patternErrorEmail || notFount || errorMail}" autocomplete="example@email.kz">
          <div class="auth-content__error" *ngIf="notFount">{{'Введите почту' | translate}}</div>
          <div class="auth-content__error" *ngIf="errorMail">{{'Почта уже зарегистрирована. Введите другой адрес или войдите' | translate}}</div>
          <div class="auth-content__error" *ngIf="patternErrorEmail">{{'Неверный формат почты' | translate}}</div>
        </div>
        <div class="auth-block">
          <label class="auth-content__label" for="password-reg">{{'Придумайте пароль' | translate}}</label>
          <div class="auth-relative">
            <input class="auth-content__input-pas" id="password-reg" [(ngModel)]="passwordFormResident" autocomplete="new-password"
                   (ngModelChange)="notPassword = false" [ngClass]="{'error': notPassword || errorPassword}"
                   [placeholder]="'Не менее 8 символов' | translate" [type]="typeField" name="password-reg">
            <div class="auth-content__img-input-pas" [ngClass]="{'off' : typeField == 'password'}" (click)="showHidePas()"></div>
          </div>
          <div class="auth-content__error" *ngIf="notPassword">{{'Придумайте пароль' | translate}}</div>
          <div class="auth-content__error" *ngIf="errorPassword">
            {{'Требования к паролю: не менее 8 символов (может быть 8 разных цифр и/или букв)' | translate}}
          </div>
        </div>
        <div class="auth-content__error-lim" *ngIf="limitChecked">{{'Ранее создан такой пользователь и не завершил регистрацию' | translate}}</div>
        <div class="auth-content__btn-lim" *ngIf="limitChecked">{{'Попробуйте снова через' | translate}}
          <span class="auth-content__error-limit">{{limitTimeLeft()}}</span>
        </div>
        <button *ngIf="!limitChecked" class="auth-content__btn">{{'Зарегистрироваться' | translate}}</button>
      </form>
      <div class="auth-content-offer">
        <div class="auth-content-offer__text">{{'Нажимая кнопку «Зарегистрироваться», вы принимаете условия' | translate}}
          <span class="auth-content-offer__text-blue" (click)="openOfferLocale()">{{'публичной оферты' | translate}}</span>
          <span *ngIf="locale != 'ru'" class="auth-content-offer__text"> {{'шарттарын қабылдайсыз'}}</span>
        </div>
      </div>
      <div class="auth-content__registration" (click)="authBack()">{{'Войти' | translate}}</div>
      <div class="auth-content__registration" (click)="openNonResidentRegistration()">{{'Я нерезидент Казахстана' | translate}}</div>
    </ng-container>
    <!--Регистрация нерезидент-->
    <ng-container *ngIf="step == 'registration' && nonResident">
      <form class="auth-content" (ngSubmit)="sendSmsEmailForRegistration()">
        <div class="auth__title">{{'Нерезидент' | translate}}</div>
        <div class="auth-block">
          <label class="auth-content__label" for="email-non">{{'Почта' | translate}}</label>
          <input class="auth-content__input" id="email-non" pAutoFocus [autofocus]="true" (change)="phoneEmailRegPattern()"
                 [placeholder]="'Например: example@email.kz' | translate" [(ngModel)]="emailNonResident" (ngModelChange)="notFount = false"
                 [ngClass]="{'error': patternErrorEmail || notFount || errorMail}" autocomplete="example@email.kz" name="email-non">
          <div class="auth-content__error" *ngIf="notFount">{{'Введите почту' | translate}}</div>
          <div class="auth-content__error" *ngIf="errorMail">{{'Почта уже зарегистрирована. Введите другой адрес или войдите' | translate}}</div>
          <div class="auth-content__error" *ngIf="patternErrorEmail">{{'Неверный формат почты' | translate}}</div>
        </div>
        <div class="auth-block">
          <label class="auth-content__label" for="password-reg-non">{{'Придумайте пароль' | translate}}</label>
          <div class="auth-relative">
            <input class="auth-content__input-pas" id="password-reg-non" [(ngModel)]="passwordNonResident" autocomplete="new-password"
                   (ngModelChange)="notPassword = false" [ngClass]="{'error': notPassword || errorPassword}" name="password-reg-non"
                   [placeholder]="'Не менее 8 символов' | translate" [type]="typeField">
            <div class="auth-content__img-input-pas" [ngClass]="{'off' : typeField == 'password'}" (click)="showHidePas()"></div>
          </div>
          <div class="auth-content__error" *ngIf="notPassword">{{'Придумайте пароль' | translate}}</div>
          <div class="auth-content__error" *ngIf="errorPassword">
            {{'Требования к паролю: не менее 8 символов (может быть 8 разных цифр и/или букв)' | translate}}
          </div>
        </div>
        <div class="auth-content__error-lim" *ngIf="limitChecked">{{'Ранее создан такой пользователь и не завершил регистрацию' | translate}}</div>
        <div class="auth-content__btn-lim" *ngIf="limitChecked">{{'Попробуйте снова через' | translate}}
          <span class="auth-content__error-limit">{{limitTimeLeft()}}</span>
        </div>
        <button *ngIf="!limitChecked" class="auth-content__btn">{{'Зарегистрироваться' | translate}}</button>
      </form>
      <div class="auth-content-offer">
        <div class="auth-content-offer__text">{{'Нажимая кнопку «Зарегистрироваться», вы принимаете условия' | translate}}
          <span class="auth-content-offer__text-blue" (click)="openOfferLocale()">{{'публичной оферты' | translate}}</span>
          <span *ngIf="locale != 'ru'" class="auth-content-offer__text"> {{'шарттарын қабылдайсыз'}}</span>
        </div>
      </div>
      <div class="auth-content__registration" (click)="authBack()">{{'Войти' | translate}}</div>
    </ng-container>
  </div>
  <re-captcha (resolved)="sendSmsEmailForRegistration($event)" #captchaRegistration="reCaptcha"
              siteKey="6Le9xmMUAAAAAAVUcFiBRq_dbFDhWCo8wE1Ta7DT" style="visibility: hidden" size="invisible">
  </re-captcha>
  <re-captcha (resolved)="authSubmit($event)" #captchaAuth="reCaptcha"
              siteKey="6Le9xmMUAAAAAAVUcFiBRq_dbFDhWCo8wE1Ta7DT" style="visibility: hidden" size="invisible">
  </re-captcha>
  <re-captcha (resolved)="recoveryPasswordSubmit($event)" #captchaResetPassword="reCaptcha"
              siteKey="6Le9xmMUAAAAAAVUcFiBRq_dbFDhWCo8wE1Ta7DT" style="visibility: hidden" size="invisible">
  </re-captcha>
</ng-template>
