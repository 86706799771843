import {Component, OnInit, ViewChild} from '@angular/core';
import {MainService} from "../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";
import {HeaderService} from "../../services/header/header.service";
import {WebViewCommunicationService} from "../../services/webview/web-view-communication.service";
import {AuthService} from "../../services/auth/auth.service";
import {MessageService} from "primeng/api";
import {AuthDialogComponent} from "../auth-dialog/auth-dialog.component";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent implements OnInit{

  companySideBar = false;
  langSideBar = false;
  isAuth: any = false;
  userData: any;
  logoutLink = `/auth/logout?redirectUrl=${window.location.origin}`;

  @ViewChild(AuthDialogComponent) authDialog!: AuthDialogComponent;

  constructor(private main: MainService,
              public headerService: HeaderService,
              private authService: AuthService,
              private message: MessageService,
              private webViewService: WebViewCommunicationService,
              public translate: TranslateService) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.getInfo()
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      if (auth) {
        this.getUserInfo();
      }
    });
  }

  ngOnInit() {
  }

  isMobileApp() {
    return typeof window !== 'undefined' && navigator.userAgent.includes('ismet-mobile')
  }

  getUserInfo() {
    this.main.userInfo().subscribe({
      next: (res: any) => {
        this.userData = res;
        this.headerService.userData = res;
        if (res.userInfo.mainCompanyBin != null) {
          localStorage.setItem('company', JSON.stringify(res.userInfo));
          this.headerService.currentCompany = res.userInfo.mainCompanyBin;
        } else {
          localStorage.setItem('company', '0');
        }
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  setLang(lang: any) {
    this.translate.use(lang);
    this.webViewService.sendAction('CHANGE_LANG')
    localStorage.setItem('lang', lang);
    this.headerService.changeLanguage(lang);
    window.location.reload();
  }

  selectCompany(company: any, index: any) {
    this.main.selectCompany(company.uin).subscribe({
      next: () => {
        this.headerService.currentCompany = company.uin;
        this.userData.companyList.forEach((element: any) => {
          element.mainCompany = false;
        })
        this.userData.companyList[index].mainCompany = true;
        this.webViewService.sendAction('CHANGE_COMPANY');
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? 'Произошла ошибка при смене компании, повторите позднее' : 'Компанияны өзгерту кезінде қате орын алды, әрекетті кейінірек қайталаңыз',
          life: 4000
        });
      }
    });
  }


}
