import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-start-sale',
  templateUrl: './start-sale.component.html',
  styleUrls: ['./start-sale.component.sass']
})
export class StartSaleComponent {

  @Output() newItemEvent = new EventEmitter<boolean>();

  @Input() startSale = false;

  constructor(public translate: TranslateService, private auth: AuthService) {}

  close(){
    this.newItemEvent.emit();
  }

  goToSales() {
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('sozdat-magazin');

    window.location.pathname = '/account/my-products-and-services';
  }

  yaCheck(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('skachat-instrukciy');
  }

}
