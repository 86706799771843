export class StatGovCompanyInfo {
  id:any
  bin: any
  nameRu: any
  nameKz: any
  addressRu: any
  addressKz: any
  shortNameRu: any
  shortNameKz: any
  descriptionRu: any
  descriptionKz: any
  site: any
  phone: any
  email: any
  shortDescriptionRu: any
  shortDescriptionKz: any
  tradeNameRu: any
  tradeNameKz: any
  city: any
  street:any
}
