<div class="universal-callback-button" [ngClass]="{'mob-button' : isMobile}" (click)="openUniversalCallback()">
  <div class="universal-callback-button__circles"></div>
</div>
<p-dialog [(visible)]="open" [modal]="true" [draggable]="false" [resizable]="false" styleClass="dialog-universal-button">
  <div class="universal-callback-button__title">{{'Need some advice?' | translate}}</div>
  <div class="universal-callback-button__text">{{'Choose the communication channel that is most convenient for you' | translate}}</div>
  <div class="universal-callback-button-btn-consult" (click)="openWhatsApp()">
    <div class="universal-callback-button-btn-consult__img-wa">{{'WhatsApp'}}</div>
  </div>
  <div class="universal-callback-button-btn-consult" (click)="openTelegramBot()">
    <div class="universal-callback-button-btn-consult__img-tg">{{'Telegram'}}</div>
  </div>
  <div class="universal-callback-button-btn-consult" (click)="openJivo()">
    <div class="universal-callback-button-btn-consult__img-js">{{'Jivosite'}}</div>
    </div>
</p-dialog>
