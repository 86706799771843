<div class="carousel-container">
  <!-- Slider main container -->
  <swiper #swiper
    [config]="config"
  >
    <ng-template swiperSlide>
      <img src="assets/images/banner/new-banner.png" alt="">
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/images/banner/new-banner.png" alt="">
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/images/banner/new-banner.png" alt="">
    </ng-template>
  </swiper>
  <button class="prev-swipe" (click)="slidePrev()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 19L16 12L9 5" stroke="#8D959D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
  <button class="next-swipe" (click)="slideNext()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 19L16 12L9 5" stroke="#8D959D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

</div>
