<div class="main-table bg-white p-3">
  <div class="head-search-bar flex justify-content-between bg-white p-3">
    <div>
      <span class="p-input-icon-left relative" *ngIf="products?.content.length > 0">
        <i class="pi pi-search"></i>
        <input [placeholder]="'Search' | translate" class="main-search" type="text" pInputText [(ngModel)]="tableParams.searchText" />
        <img *ngIf="tableParams.searchText != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
        <span class="search-txt" (click)="quickSearch()">{{ 'Find' | translate}}</span>
      </span>
    </div>
  </div>
  <ng-container *ngIf="products?.content.length > 0">
    <p-table [loading]="loadTable" [first]="0" [rows]="10" [value]="products?.content" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "DateOfCreation" | translate }}</th>
          <th>{{ "LastModifiedDate" | translate }}</th>
          <th>{{ "Name" | translate }}</th>
          <th>{{ "Type" | translate }}</th>
          <th>{{ "Buyer" | translate }}</th>
          <th>{{ "Salesman" | translate }}</th>
          <th>{{ "Status" | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr>
          <td>{{ product.create_date | date: 'dd.MM.yy HH:mm' }}</td>
          <td>{{ product.last_update_date | date: 'dd.MM.yy HH:mm'}}</td>
          <td>
            {{ translate.currentLang == 'ru' ? product.card_name_ru : product.card_name_kz }}
          </td>
          <td>
            {{ translate.currentLang == 'ru' ? product.type.name_ru : product.type.name_kz }}
          </td>
          <td>
            {{ product.user_company_name | shortname }}
          </td>
          <td>
            {{ product.card_company_name | shortname }}
          </td>
          <td>
            <div class="state" [ngClass]="{'green': product.state.color_id == 0, 'yellow': product.state.color_id == 1, 'blue': product.state.color_id == 4, 'red': product.state.color_id == 2}">
              {{ translate.currentLang == 'ru' ? product.state.name_ru : product.state.name_kz }}
            </div>
          </td>
          <td style="position:relative;">
            <div *ngIf="product && product.state.id != 3 && (product?.card_category_dto?.category_id == 331 || product.state.id != 2)" (click)="menu.toggle($event)" class="dropdown-menu">
              <svg class="cursor-pointer"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6654 4.66667C11.6654 5.58714 10.9192 6.33333 9.9987 6.33333C9.07822 6.33333 8.33203 5.58714 8.33203 4.66667C8.33203 3.74619 9.07822 3 9.9987 3C10.9192 3 11.6654 3.74619 11.6654 4.66667ZM11.6654 10.5C11.6654 11.4205 10.9192 12.1667 9.9987 12.1667C9.07822 12.1667 8.33203 11.4205 8.33203 10.5C8.33203 9.57952 9.07822 8.83333 9.9987 8.83333C10.9192 8.83333 11.6654 9.57952 11.6654 10.5ZM9.9987 18C10.9192 18 11.6654 17.2538 11.6654 16.3333C11.6654 15.4129 10.9192 14.6667 9.9987 14.6667C9.07822 14.6667 8.33203 15.4129 8.33203 16.3333C8.33203 17.2538 9.07822 18 9.9987 18Z" fill="#637381"/>
              </svg>
              <p-menu (onShow)="changeLang(product)" class="table-menu" #menu [model]="pmenu[product?.card_category_dto?.category_id == 546 ? 5 : product.state.id]" [popup]="true"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="table relative">
      <p-paginator [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} из {totalRecords}" (onPageChange)="changePage($event)" [first]="0" [rows]="10" [rowsPerPageOptions]="[10, 20, 30]" [totalRecords]="products?.totalElements"></p-paginator>
      <div class="show-options">{{ "Show" | translate }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="products?.content.length == 0">
    <div class="block-no-content">
      <div inlineSVG="assets/images/main/no-content.svg"></div>
      <div class="text-no-content">{{'youHaveNoPurchaseRequests' | translate}}</div>
      <div>
        <button *ngIf="stateModeration == 4" [routerLink]="'/create-card'" class="add-card">
          <i class="pi pi-plus sell-icon"></i>
          {{ 'AddCard' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<p-dialog [(visible)]="shortData" [modal]="true" >
  <div class="mb-4 text-center font-bold text-xl dialog-header">
    {{ 'PurchaseRequest' | translate }}
  </div>
  <div class="request-data-container">
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Name" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ translate.currentLang == 'ru' ? currentProduct?.card_name_ru : currentProduct?.card_name_kz }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Quantity" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.count }} {{ 'Units' | translate }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "TotalCost" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.price }} ₸
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "DeliveryRegion" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ translate.currentLang == 'ru' ? currentProduct?.region?.region_name_ru : currentProduct?.region?.region_name_kz }}
      </div>
    </div>
  </div>

  <div class="request-btn-group">
    <div>
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'AcceptToWork' | translate" [loading]="loading" (onClick)="getToWork()"></p-button>
    </div>
    <div class="draft">
      <p-button styleClass="sub-bnt mt-3 w-full cursor-pointer" (click)="rejectModal = true" [label]="'Reject' | translate" [loading]="loading"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="fullData" [modal]="true">
  <div class="mb-4 text-center font-bold text-xl dialog-header">
    {{ 'CardData' | translate }}
  </div>
  <div class="request-data-container">
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "ApplicationStatus" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ translate.currentLang == 'ru' ? currentProduct?.state.name_ru : currentProduct?.state.name_kz }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "SellingCompany" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{currentProduct?.card_company_name | shortname }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "DeliveryRegion" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ translate.currentLang == 'ru' ? currentProduct?.region?.region_name_ru : currentProduct?.region?.region_name_kz }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Name" | translate }}
      </div>
      <a target="_blank" [href]="'/item/' + currentProduct?.card_id" class="w-6">
        <div class="w-full right-txt">
          {{ translate.currentLang == 'ru' ? currentProduct?.card_name_ru : currentProduct?.card_name_kz }}
        </div>
      </a>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Артикул" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.vendor_code }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Price" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.price }} ₸
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Quantity" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.count }} {{ 'Units' | translate }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "TotalCost" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.total_price }} ₸
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "FIO" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.full_name }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "PhoneNumber" | translate }}
      </div>
      <div class="w-6 right-txt">
        +7{{ currentProduct?.phone}}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "Email" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.email }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">
        {{ "ApplicationComment" | translate }}
      </div>
      <div class="w-6 right-txt">
        {{ currentProduct?.comment }}
      </div>
    </div>
  </div>

  <div *ngIf="currentProduct?.state?.id != 2 && currentProduct?.state?.id != 4" class="application-btn-group">
    <div class="draft">
      <p-button styleClass="sub-bnt w-full cursor-pointer" (click)="rejectModal = true" [label]="'Reject' | translate" [loading]="loading"></p-button>
    </div>
    <div class="draft">
      <p-button styleClass="sub-bnt w-full cursor-pointer" [label]="'Appoint' | translate" (click)="appoint()" [loading]="loading"></p-button>
    </div>
    <div>
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'Completed' | translate" [loading]="loading" (onClick)="closeApplication = true"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="tuData" [modal]="true" >
  <div class="mb-4 text-center font-bold text-xl dialog-header">
    {{ 'RequestInfo' | translate }}
  </div>
  <div class="request-data-container">
    <div class="flex request-item">
      <div class="w-6 left-txt">{{"Region" | translate }}</div>
      <div class="w-6 right-txt">
        {{ translate.currentLang == 'ru' ? currentProduct?.region?.region_name_ru : currentProduct?.region?.region_name_kz }}
      </div>
    </div>
    <div class="flex request-item">
      <div class="w-6 left-txt">{{"FIO" | translate}}</div>
      <div class="w-6 right-txt">{{currentProduct?.full_name}}</div>
    </div>

    <ng-container *ngIf="step == 'shown'">
      <div class="flex request-item">
        <div class="w-6 left-txt">{{ "PhoneNumber" | translate }}</div>
        <div class="w-6 right-txt">+7{{ currentProduct?.phone }}</div>
      </div>
      <div class="flex request-item">
        <div class="w-6 left-txt">{{ "Email" | translate }}</div>
        <div class="w-6 right-txt">{{ currentProduct?.email }}</div>
      </div>
      <div class="flex request-item">
        <div class="w-6 left-txt">{{ "ApplicationComment" | translate }}</div>
        <div class="w-6 right-txt">{{ currentProduct?.comment }}</div>
      </div>
      <div class="flex request-item align-items-center">
        <div class="w-6 left-txt">{{ "Specifications" | translate }}</div>
        <div class="w-6 right-txt flex align-items-center">
          <div *ngIf="!tuIsAvailable" class="">—</div>
          <a *ngIf="tuIsAvailable" target="_blank" [href]="environment.ismetLink + '/bin/ocp/bpms.rest/bpmn/v2/kt/files/' + tuId">
            <div (click)="downloadFile('/bpmn/api/v2/private/kt/files/' + tuId)" class="tu-link">
              {{ "DownloadSpecifications" | translate }}
            </div>
          </a>
          <svg *ngIf="tuIsAvailable" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2543_37089)">
              <path d="M16.59 9.5H15V4.5C15 3.95 14.55 3.5 14 3.5H10C9.45 3.5 9 3.95 9 4.5V9.5H7.41C6.52 9.5 6.07 10.58 6.7 11.21L11.29 15.8C11.68 16.19 12.31 16.19 12.7 15.8L17.29 11.21C17.92 10.58 17.48 9.5 16.59 9.5ZM5 19.5C5 20.05 5.45 20.5 6 20.5H18C18.55 20.5 19 20.05 19 19.5C19 18.95 18.55 18.5 18 18.5H6C5.45 18.5 5 18.95 5 19.5Z" fill="#0D87EF"/>
            </g>
            <defs>
              <clipPath id="clip0_2543_37089">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div *ngIf="currentProduct?.state?.id != 4 && currentProduct?.state?.id != 2 && tuIsAvailable" class="tu-info">
        <img src="assets/images/main/info.svg" alt="">{{ 'tuInfo' | translate }}</div>
      <div *ngIf="currentProduct?.state?.id != 4 && currentProduct?.state?.id != 2 && !tuIsAvailable" class="tu-info">
        <img src="assets/images/main/info.svg" alt="">{{ 'tuInfo-2' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="step == 'hidden'">
      <div class="flex request-item">
        <div class="w-6 left-txt">{{"PhoneNumber" | translate}}</div>
        <div class="w-6 right-txt">**********</div>
      </div>
      <div class="flex request-item">
        <div class="w-6 left-txt">{{"Email" | translate}}</div>
        <div class="w-6 right-txt">**********</div>
      </div>
      <div class="flex request-item">
        <div class="w-6 left-txt">{{"ApplicationComment" | translate}}</div>
        <div class="w-6 right-txt">**********</div>
      </div>
      <div class="flex request-item align-items-center">
        <div class="w-6 left-txt">{{"Specifications" | translate}}</div>
        <div class="w-6 right-txt flex align-items-center">
          <div *ngIf="!tuIsAvailable || tuIsAvailable">**********</div>
        </div>
      </div>
      <img *ngIf="(currentProduct?.state?.id != 4 && tuIsAvailable) || (currentProduct?.state?.id != 4 && !tuIsAvailable)" class="hidden-info-tu"
           src="assets/images/main/hidden-info-tu.svg" alt=""/>
    </ng-container>
  </div>
  <div *ngIf="currentProduct?.state?.id != 4 && currentProduct?.state?.id != 2" class="request-btn-group">
    <div *ngIf="currentProduct?.state?.id != 1 && !tuInWork">
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'AcceptToWork' | translate" [loading]="loading" (onClick)="getToWork(currentProduct?.card_category_dto?.category_id)"></p-button>
    </div>
    <div class="flex gap-3 tu-btn-group mt-3" [ngClass]="{'mt-5': currentProduct?.state?.id == 1}">
      <div class="w-full" *ngIf="currentProduct?.state?.id == 1 || tuInWork">
        <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'Completed' | translate" [loading]="loading" (onClick)="closeApplication = true"></p-button>
      </div>
      <div class="draft">
        <p-button styleClass="sub-bnt w-full cursor-pointer" (click)="rejectModal = true" [label]="'Reject' | translate" [loading]="loading"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="rejectModal" [modal]="true">
  <div class="mb-4 text-center font-bold text-xl dialog-header">
    {{ 'IndicateRejectReason' | translate }}
  </div>
  <div class="request-data-container">
    <div class="flex">
      <textarea [placeholder]="'RejectReason' | translate" rows="4" cols="90" class="w-full" pInputTextarea [ngModelOptions]="{standalone: true}" [(ngModel)]="rejectComment"></textarea>
    </div>
  </div>
  <div class="request-btn-group">
    <div>
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'Send' | translate" [loading]="loading" [disabled]="rejectComment.length == 0" (onClick)="rejectApp()"></p-button>
    </div>
    <div class="draft">
      <p-button styleClass="sub-bnt mt-3 w-full cursor-pointer" (click)="close()" [label]="'Cancel' | translate" [loading]="loading"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="employeeModal" styleClass="emp-modal" [modal]="true" >
  <div class="mb-4 text-center font-bold text-xl dialog-header">
    {{ 'SelectEmployee' | translate }}
  </div>
  <div class="mb-3">
    <div class="flex">
      <p-dropdown [options]="employees" [ngModelOptions]="{standalone: true}" [(ngModel)]="employee" styleClass="w-full" class="w-full" optionValue="id" optionLabel="fullName">
      </p-dropdown>
    </div>
  </div>
  <div class="request-btn-group">
    <div>
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'Chose' | translate" [loading]="loading" (onClick)="delegateApp()"></p-button>
    </div>
    <div class="draft">
      <p-button styleClass="sub-bnt mt-3 w-full cursor-pointer" (click)="close()" [label]="'Cancel' | translate" [loading]="loading"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="closeApplication" [modal]="true">
  <div class="text-center mb-2">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4359 13.1919L6.87326 60.0569C5.07583 63.2273 6.1421 67.2814 9.25484 69.1121C10.2233 69.6817 11.3196 69.9875 12.4379 70H67.5632C71.1574 69.9598 74.0391 66.9595 73.9996 63.2988C73.9873 62.1599 73.6871 61.0433 73.1278 60.0569L45.5651 13.1918C43.701 10.0617 39.6984 9.06348 36.6251 10.9621C35.7299 11.5152 34.979 12.2801 34.4359 13.1919Z" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 31L40 43" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 56H40.1" stroke="#FFD600" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="mb-4 text-center font-bold text-xl dialog-header">{{ 'CloseApplication' | translate }}</div>
  <div class="request-btn-group">
    <div>
      <p-button styleClass="publish-btn w-full cursor-pointer" [label]="'Yes' | translate" (click)="completeOrder()" [loading]="loading"></p-button>
    </div>
    <div class="draft">
      <p-button styleClass="sub-bnt mt-3 w-full cursor-pointer" (click)="close()" [label]="'No' | translate" [loading]="loading"></p-button>
    </div>
  </div>
</p-dialog>
