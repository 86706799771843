import { Component, OnInit } from '@angular/core';
import {SearchService} from "../../../../services/search/search.service";
import {MainService} from "../../../../services/main/main.service";
import {HeaderService} from "../../../../services/header/header.service";
import {TranslateService} from "@ngx-translate/core";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.sass']
})

export class FiltersComponent implements OnInit{

  private eventSubscription: any;

  constructor(public searchService: SearchService,
              public translate: TranslateService,
              private main: MainService, public headerService: HeaderService) {
  }

  priceRange: any = [0, 100];

  filter: any;

  show = 0;

  cities: any = [];

  cityNumber = 5;

  levelName = 'Каталог';

  filteredRegion = '';

  changeValue(index: any){
    if((''+this.searchService._priceRange[index]).length <= 9){
      this.searchService.search();
    }
  }

  ngOnInit() {
    this.getRegions();

    this.eventSubscription = this.headerService.eventEmitter.subscribe((data: any) => {
      this.checkActiveAndIncrement(data.level, data.item, data.index);
    });

  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  filterParams(filteredArray: any, filter: any, fieldNameRu: any = '', fieldNameKz: any = ''){
    return filteredArray.filter((item: any) =>
      (this.translate.currentLang == 'ru' ? item[fieldNameRu] : item[fieldNameKz]).toLowerCase().includes(filter.toLowerCase()))
  }

  nameCheck(){
    if(this.headerService._catalogPage == 0){
      this.levelName = "Каталог";
    }
    if(this.headerService._catalogPage == 1){
      this.headerService._catalog.forEach((element: any) => {
        if(element.active){
          this.levelName = this.translate.currentLang == 'ru' ? element?.name_ru : element?.name_kz;
        }
      })
    }
    if(this.headerService._catalogPage == 2){
      this.headerService._catalog.forEach((element: any) => {
        element.parentlist.forEach((elem: any) => {
          if(elem.active){
            this.levelName = this.translate.currentLang == 'ru' ? elem?.name_ru : elem?.name_kz;
          }
        });

      })
    }
    return this.levelName;
  }

  back(){
    this.headerService._catalogPage = this.headerService._catalogPage-1;

    if(this.headerService._catalogPage == 0){
      this.disableItems(this.headerService._catalog);
      this.headerService._catalog[this.headerService._typeIndex].active = true;
      this.searchService.categoryType = this.headerService._catalog[this.headerService._typeIndex].type;
    }

    if(this.headerService._catalogPage == 1){
      this.disableItems(this.headerService._catalog[this.headerService._typeIndex].parentlist)
      this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex].active = true;
    }
  }

  checkActiveAndIncrement(level: any, item: any, index: any): any {
    this.setActive(level, item, index);
  }

  setActive(level: any, item: any, index: any){

    if(level == 1){
        this.disableItems(this.headerService._catalog)
        this.disableItems(this.headerService._catalog[this.headerService._typeIndex].parentlist)
        this.headerService._typeIndex = index;

        this.searchService._categoryId = '';
        this.searchService._pageSize = 16;
        this.searchService._sortBy = '';
        this.searchService._pageNumber = 0;

        this.searchService.categoryType = this.headerService._catalog[this.headerService._typeIndex].type;
    }else if(level == 2){
      if(this.headerService._parentIndex != index){
        this.disableItems(this.headerService._catalog[this.headerService._typeIndex].parentlist)
        this.headerService._parentIndex = index;
        this.disableItems(this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex].child_categories)
      }
      this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex].active = true;
      this.searchService.categoryId = item.id;
      this.headerService._catalogPage = 2;
    }else if (level == 3){
      this.disableItems(this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex].child_categories)
      this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex].child_categories[index].active = true;
      this.searchService._pageSize = 16;
      this.searchService._sortBy = '';
      this.searchService._pageNumber = 0;
      this.searchService.categoryId = item.id;
    }

    this.headerService._catalog[this.headerService._typeIndex].active = true;

    if(level != 3){
      this.levelName = this.translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz;
      this.headerService._catalogPage = level;
    }

  }

  disableItems(array: any){
    array.forEach((element: any) => {
      element.active = false;
    })
  }


  checkAll(id: any) {
    if (id == 0) {
      if (this.searchService._regionId.length <= 1) {
        this.cities.forEach((element: any) => {
          this.searchService._regionId.push(element.id);
        });
      } else {
        this.searchService._regionId.length = 0;
      }
    } else {
      // Удаление значения 0 из массива this.searchService._regionId
      this.searchService._regionId = this.searchService._regionId.filter((regionId: any) => regionId !== 0);

      // Если длина массива равна длине cities - 1, вернуть 0 в массив
      if (this.searchService._regionId.length === this.cities.length - 1) {
        this.searchService._regionId.push(0);
      }
    }
  }

  getRegions() {
    this.main.getRegionList().subscribe({
      next: (res: any) => {
        this.cities = res;
        this.cities.forEach((element: any) => {
          this.searchService._regionId.push(element.id)
        });
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

}
