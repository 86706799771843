import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MainService} from "../../../../../services/main/main.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HeaderService} from "../../../../../services/header/header.service";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-edit-company-card',
  templateUrl: './edit-company-card.component.html',
  styleUrls: ['./edit-company-card.component.sass']
})
export class EditCompanyCardComponent {
  items = [{label: 'Маркет', routerLink: '/'}, {label: 'Визитка компании'}];
  companyData: any;
  imgLogo: any;
  imgBanner: any;
  waField = false;
  tgField = false;
  insField = false;
  companyForm!: FormGroup;
  error: boolean = false;
  userId: any;
  qr: any;
  callback = null;
  name: string = '';
  cropModalLogo = false;
  cropModalBanner = false;
  imageChangedEvent: any = '';
  croppedImageLogo: any = '';
  croppedImageBanner: any = '';
  newMasImg: any = [];
  disableFields: boolean = true;
  informSendingModeration = false;
  text: any = '';

  constructor(public translate: TranslateService, private main: MainService, private fb: FormBuilder,
              private headerService: HeaderService, private route: Router,
              private message: MessageService) {
  }

  ngOnInit() {
    this.initMainForm();
    if (this.headerService.currentCompany) {
      this.getCompanyData(this.headerService.currentCompany);
    }
    this.headerService.companyChangeEmitter.subscribe(company => {
      this.getCompanyData(company);
    });
  }

  getCompanyData(bin: any) {
    this.main.getPublicCompanyInfo(bin).subscribe({
      next: (res: any) => {
        this.companyData = res;
        this.companyForm.get('bin')?.setValue(res.bin);
        this.companyForm.get('street')?.setValue(res.street);
        this.companyForm.get('description_ru')?.setValue(res.description_ru);
        this.companyForm.get('trade_name_ru')?.setValue(res.trade_name_ru);
        this.companyForm.get('field_of_activity_ru')?.setValue(res.field_of_activity_ru);
        this.companyForm.get('phone')?.patchValue(res.phone);
        this.companyForm.get('email')?.setValue(res.email);
        this.companyForm.get('website')?.setValue(res.website);
        this.disableFields = !(res.state.id == 3 || res.state.id == 2);
        this.text = res.moderator_action.moderatorVisitComment;
        if (res.whatsapp) {
          this.waField = true;
          this.companyForm.get('whatsapp')?.setValue(res.whatsapp);
        }
        if (res.telegram) {
          this.tgField = true;
          this.companyForm.get('telegram')?.setValue(res.telegram);
        }
        if (res.instagram) {
          this.insField = true;
          this.companyForm.get('instagram')?.setValue(res.instagram);
        }
        this.name = this.translate.currentLang == 'ru' ? res.name_ru : res.name_kz;
        this.displayingBlocksPictures();
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  displayingBlocksPictures() {
    let blocks = document.querySelectorAll(".new-block-img");
    for (let i = 0; i < blocks.length; i++) {
      if (i < this.newMasImg.length) {
        (blocks[i] as HTMLDivElement).style.display = "none";
      } else {
        (blocks[i] as HTMLDivElement).style.display = "block";
      }
    }
  }

  initMainForm() {
    this.companyForm = this.fb.group({
      bin: new FormControl(),
      trade_name_ru: new FormControl('', [Validators.required]),
      field_of_activity_ru: new FormControl('', [Validators.required]),
      show_bin: [false],
      show_name: [false],
      description_ru: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      website: new FormControl(),
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^77[0124567][0-8]\d{7}$/i)]),
      // Важный момент с номером телефона, ниже сделана подписка на изменение значения номера телефона, и regex сделан
      // именно для неё
      show_phone: [false],
      street: new FormControl('', [Validators.required]),
      show_address: [false],
      whatsapp: new FormControl('', [Validators.required]),
      telegram: new FormControl(),
      instagram: new FormControl(),
      offerCheck: new FormControl()
    });
    this.companyForm.get('phone')?.valueChanges.subscribe((val) => {
      if (val && val.length == 10) {
          this.companyForm.get('phone')?.patchValue('7' + val, {emitEvent: false, onlySelf: true});
      }
    });
  }

  textLength(text: any) {
    return text ? text.length : 0;
  }

  request() {
    let formdata = new FormData();
    formdata.append("updateCompanyRequest", new Blob([JSON.stringify(
        this.companyForm.value
      )], {
        type: "application/json"
      }
    ));
    if (this.imgLogo) {
      const fileExtension = this.imgLogo.type.split('/')[1];
      formdata.append('avatarImg', this.imgLogo, `logo.${fileExtension}`)
    }
    if (this.imgBanner) {
      const fileExtension = this.imgBanner.type.split('/')[1];
      formdata.append('bannerImg', this.imgBanner, `banner.${fileExtension}`)
    }
    this.main.editCompany(formdata).subscribe( {
      next: (res:any) => {
        this.informSendingModeration = true;

      },
      complete: () => {

      },
      error: (error: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? error.error.description_ru : error.error.description_kz, life: 4000
        });
      }


    });
  }

  changeShowField(fieldName: string) {
    this.companyForm.get(fieldName)?.setValue(!this.companyForm.get(fieldName)?.value);
  }

  fileChangeEvent(event: any, fileNumber: number): void {
    if (fileNumber == 1) {
      this.imageChangedEvent = event;
      this.cropModalLogo = true;
    } else {
      this.imageChangedEvent = event;
      this.cropModalBanner = true;
    }
  }

  imageCropped(event: ImageCroppedEvent, fileNumber: number) {
    if (fileNumber == 1) {
      this.croppedImageLogo = event;
    } else {
      this.croppedImageBanner = event;
    }
  }

  cropIt(fileNumber: number) {
    if (fileNumber == 1) {
      this.cropModalLogo = false;
      this.imgLogo = this.croppedImageLogo.blob
    } else {
      this.cropModalBanner = false;
      this.imgBanner = this.croppedImageBanner.blob;
    }
  }

  imageLoaded() {
  }

  cropperReady() {
  }

  loadImageFailed() {
  }

  closeModalAndBack() {
    this.route.navigate(['account/my-products-and-services']);
    setTimeout(() => {
      window.location.reload();
    },100);
  }
}
