import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class RegAuthControllerService {
  constructor(protected http: HttpClient) {}

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      throw error;
    };
  }

  userVerificationAndSMSSendingOtp(phoneNumber: any, lang: any, reCaptcha: any) : Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/sendCode/otp/sms',
      {"phoneNumber": phoneNumber, "lang": lang, "reCaptcha": reCaptcha}).pipe(
      catchError(this.handleError('userVerificationAndSMSSendingOtp', {})));
  }

  userVerificationAndSMSSendingReg(phoneNumber: any, lang: any) : Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/sendCode/registration/sms',
      {"phoneNumber": phoneNumber, "lang": lang}).pipe(
      catchError(this.handleError('userVerificationAndSMSSendingReg', {})));
  }

  userVerificationAndEmailSendingOtp(email: any, lang: any, reCaptcha: any) : Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/sendCode/otp/email',
      {"email": email, "lang": lang, "reCaptcha": reCaptcha}).pipe(
      catchError(this.handleError('userVerificationAndEmailSendingOtp', {})));
  }

  userVerificationAndEmailSendingReg(email: any, lang: any) : Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/sendCode/registration/email',
      {"email": email, "lang": lang}).pipe(
      catchError(this.handleError('userVerificationAndEmailSendingReg', {})));
  }

  checkingCodeSMSAndGettingTokenOtp(phoneNumber: string, code: string, lang: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/verifyCode/otp/sms',
      {"phoneNumber": phoneNumber, "code": code, "lang": lang}).pipe(
      catchError(this.handleError('checkingCodeSMSAndGettingTokenOtp', {})));
  }

  checkingCodeSMSAndGettingTokenReg(phoneNumber: string, code: string, lang: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/verifyCode/registration/sms',
      {"phoneNumber": phoneNumber, "code": code, "lang": lang}).pipe(
      catchError(this.handleError('checkingCodeSMSAndGettingTokenReg', {})));
  }

  checkingCodeEmailAndGettingTokenOtp(email: string, code: string, lang: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/verifyCode/otp/email',
      {"email": email, "code": code, "lang": lang}).pipe(
      catchError(this.handleError('checkingCodeEmailAndGettingTokenOtp', {})));
  }

  checkingCodeEmailAndGettingTokenReg(email: string, code: string, lang: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/account-recovery/verifyCode/registration/email',
      {"email": email, "code": code, "lang": lang}).pipe(
      catchError(this.handleError('checkingCodeEmailAndGettingTokenReg', {})));
  }

  sendingCodeAndUserRegistration(data: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/api/v1/user/registration', data).pipe(
      catchError(this.handleError('sendingCodeAndUserRegistration', {})));
  }

  authLogin(data: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/auth/login', data).pipe(
      catchError(this.handleError('authLogin', {})));
  }

  sendCodeRecoveryPassword(target: any, lang: any, reCaptcha: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/auth/login/recovery/send',
      {"target": target, "lang": lang, "reCaptcha": reCaptcha}).pipe(
      catchError(this.handleError('sendCodeRecoveryPassword', {})));
  }

  verifyCodeRecoveryPassword(data: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/auth/login/recovery/verify',
      data).pipe(
      catchError(this.handleError('verifyCodeRecoveryPassword', {})));
  }

  sendNewPassword(data: any): Observable<any> {
    return this.http.post('/proxy/account-recovery-api.bpmn/auth/login/recovery/verify/change', data).pipe(
      catchError(this.handleError('sendNewPassword', {})));
  }
}
