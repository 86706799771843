import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userInfo: any;
  private checked: boolean = false;
  private request!: Promise<any>;
  public auth: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.auth = new EventEmitter();
  }

  get userInfo(): any {
    return this._userInfo;
  }

  getUserInfo(reset: boolean = false): Promise<any> {
    if(!this.request || reset) {
      this.checked = false;
      this.request = new Promise<any>((resolve, reject) => {
        if (this.checked) {
          resolve(this._userInfo);
        } else {
          this.http.get('/proxy/profile-api.bpmn/api/v1/profile/user').subscribe({
            next: (data: any) => {
              this.checked = true;
              this._userInfo = data;
              resolve(this._userInfo);
            }, error: () => {
              reject();
            }
          });
        }
      });
    }
    return this.request
  }

  isLoggedIn(needAuth: boolean = false): Promise<any> {
    return new Promise<any>((resolve) => {
      if (this._userInfo) {
        resolve(true);
      } else {
        this.getUserInfo(true).then(auth => {
          resolve(true);
        }).catch(() => {
          if(needAuth) {
            window.location.href = "/?auth=open&redirectUrl=" + window.location.pathname;
          }
          resolve(false);
        })
      }
    })
  }
}
