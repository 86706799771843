<div class="card-container">
  <div class="flex justify-content-between mobile-card-head">
    <div (click)="goBack()" class="back-wrap w-fit">
      <div class="left-arrow cursor-pointer"></div>
      <div class="back-txt cursor-pointer mobile-hidden">{{ 'Back' | translate}}</div>
    </div>
    <div class="mobile-top-buttons">
      <img (click)="mobileShare()" src="assets/images/main/share-mobile.svg" alt="" class="desktop-hidden">
      <img [src]="cardData?.favorite ? 'assets/images/main/mobile-heart-filled.svg' : 'assets/images/main/mobile-heart.svg'"
           alt="" (click)="favorite()" class="desktop-hidden">
    </div>
  </div>

  <div class="desktop-hidden">
    <p-progressSpinner *ngIf="loading" [class]="'load-spinner'"></p-progressSpinner>
    <p-carousel *ngIf="!loading" [showNavigators]="false" [value]="cardData?.images" indicatorStyleClass="indicator-dots">
      <ng-template let-product pTemplate="item">
        <img (click)="imagesSideBar = true" [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+product.full_path" alt="">
      </ng-template>
    </p-carousel>

  </div>
  <div class="filter-sidebar">
    <div class="main-sidebar">

      <p-sidebar [(visible)]="imagesSideBar" position="bottom" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
        <div class="flex align-items-center side-bar-review-header">
          <div (click)="imagesSideBar = false" class="back-arrow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5329_29354)">
                <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
              </g>
              <defs>
                <clipPath id="clip0_5329_29354">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="">
          <div class="w-full p-image-wrap">
            <div class="container-img">
              <pinch-zoom *ngIf="imagesSideBar">
                <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + cardData?.images[currentImageIndex]?.full_path" />
              </pinch-zoom>
            </div>
          </div>
          <div class="flex sub-img-container">
            <div *ngFor="let image of cardData?.images; index as i" class="mx-1 mt-2">
              <div *ngIf="loading == false" class="card-sub-img cursor-pointer" [ngClass]="{'sub-img-wrap': currentImageIndex == i}" (click)="setMainImage(i)">
                <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+image['full_path']" alt="">
              </div>
            </div>
          </div>
        </div>

      </p-sidebar>

    </div>
  </div>


  <ng-container *ngIf="!errorNotification">
    <div class="card-data-wrap">
      <div class="w-7 mobile-hidden">
        <div class="w-full p-image-wrap">
          <p-skeleton *ngIf="loading" width="400px" height="430px"></p-skeleton>
          <div class="main-image-cover" (click)="this.showFullImage = true">
            <img *ngIf="loading == false" class="main-image" [src]="fillerUrl(cardData)" alt="">
          </div>
        </div>
        <div class="flex justify-content-center">
          <p-skeleton *ngIf="loading" width="77px" height="77px"></p-skeleton>
          <div *ngFor="let image of cardData?.images; index as i" class="mx-1 mt-2">
            <div *ngIf="loading == false" class="card-sub-img cursor-pointer" [ngClass]="{'sub-img-wrap': currentImageIndex == i}" (click)="setMainImage(i)">
              <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+image['full_path']" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="description-wrap" *ngIf="!loading">

        <div class="name-txt">
          <p-skeleton *ngIf="loading" width="130px" height="29px" class="mobile-hidden"></p-skeleton>
          <h1 class="good-name" *ngIf="loading == false">{{ translate.currentLang == 'ru' ? cardData?.name_ru : cardData?.name_kz }}</h1>
        </div>
        <div class="flex rating-wrap">
          <div>
            <p-rating [(ngModel)]="cardData.rating" [stars]="5" [readonly]="true">
              <ng-template class="star-fill" pTemplate="onicon">
                <img src="/assets/images/main/star-filled-icon.svg" width="16px" height="16px" alt=""/>
              </ng-template>
              <ng-template pTemplate="officon">
                <img class="star" src="/assets/images/main/star-icon.svg" width="16px" height="16px" alt=""/>
              </ng-template>
            </p-rating>
          </div>
          <div class="feedback-count">
            {{ cardData.feedback.all | suffix: ('OneReview' | translate): ('SomeReviews' | translate): ('ManyReviews' | translate)}}
          </div>
        </div>
        <div class="company-name-wrap">
          <div (click)="companyClick()" [routerLink]="['/company/' + cardData?.company_bin]">
            {{(translate.currentLang == 'ru' ? cardData?.company_name : cardData?.company_name_kz) | shortname}}
          </div>
        </div>
        <div class="price-wrap">
          <p-skeleton *ngIf="loading" width="152px" height="36px" class="mobile-hidden"></p-skeleton>
          <div *ngIf="!loading">
            {{ 'CardPrice' | translate : { price: addSpacesToNumberString(''+ cardData?.price),
            ru: (translate.currentLang == 'ru' && !cardData?.is_fix_price) ? 'От' : '', kz: (translate.currentLang == 'kz' && !cardData?.is_fix_price) ? 'бастап' : ''} }}
            <div *ngIf="cardData?.min_order_count > 1" class="batch mobile-hidden">
              ({{ 'MinimumBatch' | translate : {batch: cardData?.min_order_count} }})
            </div>
          </div>
        </div>

        <div class="order-btn-group">
          <button (click)="order(cardData)" pButton class="order-btn">{{ 'Order' | translate }}</button>
          <div *ngIf="isAuth" class="relative">
            <button (click)="favorite()" pButton class="to-favorite-btn">{{ 'ToFavorites' | translate }}
              <img [src]="cardData?.favorite == true ? 'assets/images/main/heart-filled-icon.svg' : 'assets/images/main/heart-icon.svg'" alt="">
            </button>
          </div>
          <button (click)="share()" [life]="2500"
                  [pTooltip]="'LinkCopied' | translate"
                  tooltipPosition="bottom"
                  tooltipEvent="focus"
                  tooltipStyleClass="tooltip-prime"
                  class="share-btn cursor-pointer">
            <img src="./assets/images/main/share-icon.svg" alt="">
          </button>

        </div>

      </div>


    </div>
    <div *ngIf="!loading" class="desktop-hidden mobile-description-wrap">
      <div class="mobile-description">
        <div class="desc-header">
          {{ 'Description' | translate }}
        </div>
        <p-inplace *ngIf="cardData?.description_ru?.length > 150">
          <ng-template pTemplate="display">
            <div class="desc-body" [innerHTML]="getSafeDescriptionPreview(descriptionPreview)">
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="desc-body" [innerHTML]="getSafeDescription()">

            </div>
          </ng-template>
        </p-inplace>
        <div *ngIf="cardData?.description_ru?.length <= 150" class="desc-body" [innerHTML]="getSafeDescription()">

        </div>
      </div>
    </div>
    <div *ngIf="!loading" class="desktop-hidden reviews-mobile mobile-description-wrap">
      <div class="mobile-description">
        <div class="desc-header">
          {{ 'Reviews' | translate }} <span class="check-all" (click)="showAllReviews()">{{ 'LookToAll' | translate }}</span>
        </div>
        <div *ngFor="let item of feedbackList; index as i">
          <div class="review-item" *ngIf="i < 2">
            <div  class="rating-wrap">
              <p-rating [(ngModel)]="item.grade" [stars]="5" [readonly]="true">
                <ng-template class="star-fill" pTemplate="onicon">
                  <img src="/assets/images/main/star-filled-icon.svg" width="16px" height="16px" alt=""/>
                </ng-template>
                <ng-template pTemplate="officon">
                  <img class="star" src="/assets/images/main/star-icon.svg" width="16px" height="16px" alt=""/>
                </ng-template>
              </p-rating>
            </div>
            <div style="font-size: 14px" class="mb-1">
              {{ item.user_full_name }}  <span class="ml-1" style="color: #8D959D">{{ item.review_date | date: 'dd.MM.yy'}}</span>
            </div>
            <p-inplace>
              <ng-template pTemplate="display">
                {{ item.review.slice(0,150) }}<span *ngIf="item.review.length >= 150">... </span>
                <span *ngIf="item.review.length >= 150" style="color: #0d87ef">{{ 'More' | translate }}</span>
              </ng-template>
              <ng-template pTemplate="content">
                {{ item.review }}
              </ng-template>
            </p-inplace>
          </div>
        </div>
      </div>
    </div>

    <p-sidebar [(visible)]="showReviews" position="bottom" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
      <div class="flex align-items-center side-bar-review-header">
        <div (click)="showReviews = false" class="back-arrow">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5329_29354)">
              <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
            </g>
            <defs>
              <clipPath id="clip0_5329_29354">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="">
          {{ 'Reviews' | translate }}
        </div>
      </div>
      <div class="review-types-wrap">
        <div class="review-types">
          <button [ngClass]="{'active-btn': activeBtn[0]}" (click)="toggleBtn(0)" class="rating-btn">{{ 'AllReviews' | translate }}<span class="ml-1">{{cardData.feedback.all}}</span></button>
          <button [ngClass]="{'active-btn': activeBtn[1]}" (click)="toggleBtn(1)" class="rating-btn">{{ 'Positive' | translate }}<span class="ml-1">{{cardData.feedback.positive}}</span></button>
          <button [ngClass]="{'active-btn': activeBtn[2]}" (click)="toggleBtn(2)" class="rating-btn">{{ 'Negative' | translate }}<span class="ml-1">{{cardData.feedback.negative}}</span></button>
        </div>
      </div>
      <div class="side-bar-review-body-wrap">
        <div class="side-bar-review-body">
          <div *ngFor="let item of feedbackList; index as i" class="side-bar-review-item">
            <div class="review-item">
              <div  class="rating-wrap">
                <p-rating [(ngModel)]="item.grade" [stars]="5" [readonly]="true">
                  <ng-template class="star-fill" pTemplate="onicon">
                    <img src="/assets/images/main/star-filled-icon.svg" width="16px" height="16px" alt=""/>
                  </ng-template>
                  <ng-template pTemplate="officon">
                    <img class="star" src="/assets/images/main/star-icon.svg" width="16px" height="16px" alt=""/>
                  </ng-template>
                </p-rating>
              </div>
              <div style="font-size: 14px" class="mb-1">
                {{ item.user_full_name }}  <span class="ml-1" style="color: #8D959D">{{ item.review_date | date: 'dd.MM.yy'}}</span>
              </div>
              <p-inplace>
                <ng-template pTemplate="display">
                  {{ item.review.slice(0,150) }}<span *ngIf="item.review.length >= 150">... </span>
                  <span *ngIf="item.review.length >= 150" style="color: #0d87ef">Ещё</span>
                </ng-template>
                <ng-template pTemplate="content">
                  {{ item.review }}
                </ng-template>
              </p-inplace>
            </div>
          </div>
        </div>
      </div>



    </p-sidebar>

    <div *ngIf="!loading" class="desktop-hidden order-btn-wrap">
      <div (click)="showOrderForm = true" class="order-btn">
        {{ 'Order' | translate }}
      </div>
    </div>

    <div class="filter-sidebar">
      <div class="main-sidebar">
        <p-sidebar [(visible)]="showOrderForm" position="bottom" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
          <div class="order-head">
            Обратный звонок
          </div>
          <div class="cross-icon w-full text-right">
            <svg (click)="showOrderForm = false" width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.084 9.69375L23.6402 8.25L16.834 15.0563L10.0277 8.25L8.58398 9.69375L15.3902 16.5L8.58398 23.3063L10.0277 24.75L16.834 17.9437L23.6402 24.75L25.084 23.3063L18.2777 16.5L25.084 9.69375Z" fill="#6C757D"/>
            </svg>
          </div>
          <div class="">
            <form [formGroup]="callbackFormGroup" *ngIf="showForm">
              <div  class="callback-modal">
                <div class="callback-input-wrap">
                  <label class="callback-label">{{ 'PhoneNumber' | translate }} <span class="text-red-500">*</span>
                  </label>
                  <p-inputMask [autoFocus]="true"  placeholder="+7 (___) ___ __ __" styleClass="w-full callback-input" mask="+7 (999) 999 99 99" [unmask]="true" formControlName="phone"></p-inputMask>
                  <span *ngIf="callbackFormGroup.get('phone')!.value == ''  && callbackFormGroup.get('phone')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
                </div>
                <div class="callback-input-wrap">
                  <label class="callback-label">{{'LastName' | translate}} {{'FirstName' | translate}} <span class="text-red-500">*</span>
                  </label>
                  <input placeholder="Иванов Иван" class="w-full callback-input" pInputText formControlName="full_name" />
                  <span *ngIf="callbackFormGroup.get('full_name')!.value == '' && callbackFormGroup.get('full_name')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
                </div>
                <div class="callback-input-wrap">
                  <label class="callback-label">{{'Email'}}
                  </label>
                  <input placeholder="example@mail.com" class="w-full callback-input" pInputText formControlName="email" />
                  <span *ngIf="callbackFormGroup.get('email')!.value == '' && callbackFormGroup.get('email')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
                </div>
                <div class="callback-input-wrap">
                  <label class="callback-label">{{'Region' | translate}}
                  </label>
                  <p-dropdown formControlName="region_id" optionValue="id" [options]="regionList" placeholder="{{ 'SelectRegion' | translate }}" styleClass="w-full callback-input" [editable]="false" optionLabel="{{translate.currentLang == 'ru' ? 'region_name_ru' : 'region_name_kz'}}"></p-dropdown>
                </div>
                <div class="callback-input-wrap quantity-wrap">
                  <div class="count-header">{{'QuantityOfGoods' | translate}}</div>
                  <p-inputNumber (onBlur)="changeTariffCount()" [min]="cardData?.min_order_count" formControlName="count" styleClass="w-full callback-count" [showButtons]="true" buttonLayout="horizontal" #el id="el"
                                 (onInput)="changeTariffCount(el.input.nativeElement)" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                                 decrementButtonClass="callback-count" incrementButtonClass="callback-count" mode="decimal">
                    <ng-template pTemplate="incrementbuttonicon"><span class="decimal-icon flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_4753_9121)">
                        <path d="M12 8.66665H8.66667V12C8.66667 12.3666 8.36667 12.6666 8 12.6666C7.63334 12.6666 7.33334 12.3666 7.33334 12V8.66665H4C3.63334 8.66665 3.33334 8.36665 3.33334 7.99998C3.33334 7.63331 3.63334 7.33331 4 7.33331H7.33334V3.99998C7.33334 3.63331 7.63334 3.33331 8 3.33331C8.36667 3.33331 8.66667 3.63331 8.66667 3.99998V7.33331H12C12.3667 7.33331 12.6667 7.63331 12.6667 7.99998C12.6667 8.36665 12.3667 8.66665 12 8.66665Z" fill="#363636"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4753_9121">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </span></ng-template>
                    <ng-template pTemplate="decrementbuttonicon"><span class="decimal-icon flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_4753_9118)">
                        <path d="M12 8.66665H3.99999C3.63333 8.66665 3.33333 8.36665 3.33333 7.99998C3.33333 7.63331 3.63333 7.33331 3.99999 7.33331H12C12.3667 7.33331 12.6667 7.63331 12.6667 7.99998C12.6667 8.36665 12.3667 8.66665 12 8.66665Z" fill="#363636"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4753_9118">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </span></ng-template>
                  </p-inputNumber>
                </div>
                <div class="relative comment-wrap">
                  <textarea maxlength="500" formControlName="comment" [placeholder]="'Comment' | translate" class="callback-textarea" pInputTextarea></textarea>
                  <span class="comment-limit">{{textLength()}}/500 {{ 'Characters' | translate }}</span>
                </div>
                <div class="offer-wrap flex mb-3" [ngClass]="{'mb-3' : !showAgreeErr}">
                  <p-checkbox [(ngModel)]="checked" (onChange)="showAgreeErr = !checked" [binary]="true" [ngModelOptions]="{standalone: true}" inputId="offer" id="offer"></p-checkbox>
                  <label for="offer">{{ 'IAcceptTheTerms' | translate }}
                    <span class="offer">
              <a [href]="translate.currentLang == 'ru' ? 'assets/docs/Типовая%20форма%20Публичного%20договора.pdf' : 'assets/docs/Типовая%20форма%20Публичного%20договора%20каз.pdf'" target="_blank">{{ 'PublicOffer' | translate }}</a>
            </span>
                  </label>
                </div>
                <div *ngIf="showAgreeErr" class="text-sm text-red-500 mb-3 mt-1">{{ 'Вы не приняли согласие с офертой' | translate }}</div>
                <div>

                  <button (click)="createOrder()"
                          class="order-btn w-full justify-content-center" pButton type="button">{{ 'SubmitYourApplication' | translate }}
                  </button>
                  <button (click)="close()" class="cancel-btn">{{ 'Cancel' | translate }}</button>
                </div>
              </div>
            </form>
          </div>
        </p-sidebar>
      </div>
    </div>



    <div class="good-card">
      <p-tabView (onChange)="getFeedBackList($event)" class="mobile-hidden">
        <p-tabPanel [header]="'Description' | translate">
          <p [innerHtml]="getSafeDescription()">

          </p>
        </p-tabPanel>
        <p-tabPanel [header]="'Reviews' | translate" >
          <div>
            <div class="mid-grade-header"><span *ngIf="cardData.feedback.all > 0">{{ 'AverageRating' | translate }}</span> <span *ngIf="cardData.feedback.all == 0">{{ 'ThisProductHasNoReviewsYet' | translate }}</span> </div>
            <div class="flex align-items-center">
              <p-rating [(ngModel)]="cardData.rating" class="mr-2 pt-1" [stars]="5" [readonly]="true">
                <ng-template class="star-fill" pTemplate="onicon">
                  <img src="/assets/images/main/star-filled-icon.svg" width="24px" height="24px" alt=""/>
                </ng-template>
                <ng-template pTemplate="officon">
                  <img class="star" src="/assets/images/main/star-icon.svg" width="24px" height="24px" alt=""/>
                </ng-template>
              </p-rating>
              {{cardData.rating}} из 5
            </div>
            <div class="flex justify-content-between review-btn-group align-items-center">
              <div>
                <button [ngClass]="{'active-btn': activeBtn[0]}" (click)="toggleBtn(0)" class="rating-btn">{{ 'AllReviews' | translate }}<span class="ml-1">{{cardData.feedback.all}}</span></button>
                <button [ngClass]="{'active-btn': activeBtn[1]}" (click)="toggleBtn(1)" class="rating-btn">{{ 'Positive' | translate }}<span class="ml-1">{{cardData.feedback.positive}}</span></button>
                <button [ngClass]="{'active-btn': activeBtn[2]}" (click)="toggleBtn(2)" class="rating-btn">{{ 'Negative' | translate }}<span class="ml-1">{{cardData.feedback.negative}}</span></button>
              </div>
              <div class="review-btn-wrap">
                <button class="order-btn" pButton (click)="showDialog()">{{ 'LeaveFeedback' | translate }}</button>
              </div>
            </div>
            <div class="review-item-wrap">
              <div *ngFor="let item of feedbackList; index as i" class="review-item flex">
                <div class="review-data" >
                  <div class="review-rating">
                    <p-rating [(ngModel)]="item.grade" class="mr-2 pt-1" [stars]="5" [readonly]="true">
                      <ng-template class="star-fill" pTemplate="onicon">
                        <img src="/assets/images/main/star-filled-icon.svg" width="16px" height="24px" alt=""/>
                      </ng-template>
                      <ng-template pTemplate="officon">
                        <img class="star" src="/assets/images/main/star-icon.svg" width="16px" height="24px" alt=""/>
                      </ng-template>
                    </p-rating>
                  </div>
                  <div class="review-username">
                    {{item.user_full_name}}
                  </div>
                  <div class="review-date">
                    {{item.review_date | date:'dd.MM.y'}}
                  </div>
                </div>
                <div class="review-txt">
                  {{ item.review }}
                </div>

              </div>
            </div>
            <div *ngIf="reviewTotalElements > feedbackList.length" class="show-more" (click)="nextPage()">
              {{ 'ShowMore' | translate }}<img src="./assets/images/main/blue-arrow.svg" alt="">
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </ng-container>
  <ng-container *ngIf="errorNotification">
    <div class="block-no-content">
      <div inlineSVG="assets/images/main/no-moderation.svg"></div>
      <div class="text-block">
        <div class="text-no-content">
          {{'aCardWithThisIDWasNotFoundToFindTheNecessaryInformationUseTheNavigationToolsProvidedOnTheSiteYouCanReturnTo' | translate}}
          <a href="/" class="text-no-content-blue">{{'mainPageOfThe1' | translate}}
            <span *ngIf="translate.currentLang != 'ru'" class="text-no-content">{{'оралуыңызға болады.'}}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<p-dialog (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{width: '400px'}"
          [draggable]="true" [resizable]="false">
  <div>
    <div class="text-center">
      <h5>{{ 'LeaveFeedback' | translate  }}</h5>
    </div>
    <div class="text-center review-wrap">
      <p-rating [(ngModel)]="reviewData.grade" class="mr-2 pt-1" [stars]="5" [readonly]="false">
        <ng-template class="star-fill" pTemplate="onicon">
          <img src="/assets/images/main/star-filled-icon.svg" width="50px" height="50px" class="cursor-pointer" alt=""/>
        </ng-template>
        <ng-template pTemplate="officon">
          <img class="star cursor-pointer" src="/assets/images/main/star-icon.svg" width="50px" height="50px" alt=""/>
        </ng-template>
      </p-rating>
    </div>
    <div class="review-txt-area flex">
      <textarea [(ngModel)]="reviewData.review" [placeholder]="'WriteYourReview' | translate" class="mx-auto" [rows]="5" [cols]="60" pInputTextarea></textarea>
    </div>
    <div class="feedback-btn-group">
      <div>
        <button [disabled]="disableBtn()" (click)="sendReview()" class="justify-content-center" pButton type="button">
          {{ 'PostAReview' | translate  }}
        </button>
      </div>
      <div>
        <button (click)="cancel()" class="cancel-review">{{ 'Cancel' | translate  }}</button>
      </div>
    </div>
  </div>
</p-dialog>
<app-callback *ngIf="callback" (newItemEvent)="close()" [currentItem]="currentFav" [callback]="callback"></app-callback>
<p-dialog (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{width: '400px'}"
          [draggable]="true" [resizable]="false">
  <div>
    <div class="text-center">
      <h5>{{ 'LeaveFeedback' | translate  }}</h5>
    </div>
    <div class="text-center review-wrap">
      <p-rating [(ngModel)]="reviewData.grade" class="mr-2 pt-1" [stars]="5" [readonly]="false">
        <ng-template class="star-fill" pTemplate="onicon">
          <img src="/assets/images/main/star-filled-icon.svg" width="50px" height="50px" class="cursor-pointer" alt=""/>
        </ng-template>
        <ng-template pTemplate="officon">
          <img class="star cursor-pointer" src="/assets/images/main/star-icon.svg" width="50px" height="50px" alt=""/>
        </ng-template>
      </p-rating>
    </div>
    <div class="review-txt-area flex">
      <textarea [(ngModel)]="reviewData.review" [placeholder]="'WriteYourReview' | translate" class="mx-auto" [rows]="5" [cols]="60" pInputTextarea></textarea>
    </div>
    <div class="feedback-btn-group">
      <div>
        <button [disabled]="disableBtn()" (click)="sendReview()" class="justify-content-center" pButton type="button">
          {{ 'PostAReview' | translate  }}
        </button>
      </div>
      <div>
        <button (click)="cancel()" class="cancel-review">{{ 'Cancel' | translate  }}</button>
      </div>
    </div>
  </div>
</p-dialog>
<app-callback *ngIf="callback" (orderCreated)="orderCreated()" (newItemEvent)="close()" [isAuth]="isAuth" [currentItem]="currentFav" [callback]="callback"></app-callback>
<p-toast class="main-toast mobile-hidden" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast class="main-toast desktop-hidden" position="center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<div *ngIf="showFullImage" class="gallery-wrap">
  <div class="overlay" (click)="showFullImage = false"></div>
  <div class="toolbar">
    <div class="toolbar-item flex justify-content-center">
      <img (click)="zoom(false)" src="assets/images/main/zoom-out.svg" alt="">
    </div>
    <div class="toolbar-item flex justify-content-center">
      <img (click)="zoom()" src="assets/images/main/zoom-in.svg" alt="">
    </div>
    <div class="toolbar-item flex justify-content-center">
      <img (click)="showFullImage = false" src="assets/images/main/cross-icon.svg" alt="">
    </div>
  </div>

  <div *ngIf="cardData.images.length > 1" class="arrow left" (click)="changeMainImage(-1)"></div>

  <img *ngIf="loading == false" class="full-main-image" [src]="fillerUrl(cardData)" alt="">

  <div *ngIf="cardData.images.length > 1" class="arrow right" (click)="changeMainImage(1)"></div>

</div>
