import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.sass']
})
export class RatingComponent {
  @Input() rating: number = 0;
  @Input() height: any = '20px';
  @Input() width: any = '20px';
  @Input() floating: any = false;
  stars = Array(5).fill(0);


  getFillPercentage(index: number): string {
    const fullStars = Math.floor(this.rating);
    const hasFraction = this.rating % 1 !== 0; // Проверяем, есть ли дробная часть

    if (!this.floating) {
      // Если floating = false, то просто заполняем целое число звезд
      return index < fullStars ? '100%' : '0%';
    }

    if (index < fullStars) {
      return '100%';
    } else if (index === fullStars && hasFraction) {
      return `${(this.rating - fullStars) * 100}%`;
    }
    return '0%';
  }

  getFillWidth(index: number): number {
    const percentage = this.getFillPercentage(index);
    return this.floating ? (this.width * parseFloat(percentage) / 100) : this.width;
  }

}
