<div class="goods-wrap">
  <div class="flex align-items-center goods-header-wrap">

    <div *ngIf="getCards()?.content?.length != 0" class="sort-type-list">
      <div *ngFor="let item of sortTypes; index as i" (click)="setSortType(item, i)" [ngClass]="{'active' : item.active}" class="sort-type-item">
        {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
      </div>
    </div>


    <img (click)="openFilters()" class="filter-sliders"
       [src]="checkFilters() ? 'assets/images/main/filter-sliders.svg' : 'assets/images/main/active-filters.svg'" alt="">

  </div>
  <div class="filter-sidebar">
    <div class="main-sidebar">
      <p-sidebar [(visible)]="showFilters" position="bottom" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
        <div class="flex filter-header-wrap">
          <div (click)="showFilters = false" class="back-arrow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5329_29354)">
                <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
              </g>
              <defs>
                <clipPath id="clip0_5329_29354">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="filter-header">
            Фильтр
          </div>
          <div *ngIf="!checkFilters()" class="clear-filters" (click)="removeFilters()">
            Сбросить фильтры
          </div>
        </div>
        <div class="filter-content">
          <div class="filter-content-wrap">
            <div class="">
              <div class="w-full mb-1">
                {{ 'Price' | translate }}
              </div>
              <div class="price-wrap mb-4">
                <input type="number" [(ngModel)]="searchService._priceRange[0]" (input)="changeValue(0,$event.target)" placeholder="От">
                <input type="number" [(ngModel)]="searchService._priceRange[1]" (input)="changeValue(1,$event.target)" placeholder="До">
              </div>
            </div>
            <div class="region-wrap" (click)="openRegionList()">
              <div class="">
                {{ 'Region' | translate }}
              </div>
              <div class="flex align-items-center">
                <div class="region-name">
                  {{ translate.currentLang == 'ru' ? selectedCity.region_name_ru : selectedCity.region_name_kz }}
                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_5329_28687)">
                    <path d="M10 17L15 12L10 7" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_5329_28687">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="sort-wrap" (click)="sortOpen = true">
              <div class="">
                {{ 'Sorting' | translate }}
              </div>
              <div class="flex align-items-center">
                <div class="region-name">
<!--                  {{ translate.currentLang == 'ru' ? selectedSortType.sort_name_ru : selectedSortType.sort_name_kz }}-->
                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_5329_28687)">
                    <path d="M10 17L15 12L10 7" stroke="#CED4DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_5329_28687">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <p-button styleClass="filter-btn" [disabled]="checkFilters()" (click)="applyFilters()">
            {{ 'Apply' | translate }}
          </p-button>
        </div>

      </p-sidebar>
    </div>

    <p-sidebar [(visible)]="regionListOpen" position="bottom" styleClass="filter-item">
      <ng-template pTemplate="header">
        <div class="region-sidebar">
          <div class="w-full text-center">
            <div class="sidebar-header">
              {{ 'Region' | translate }}
            </div>
          </div>
        </div>
      </ng-template>
      <div class="">
        <p-listbox (onClick)="regionListOpen = false" [options]="regions" [(ngModel)]="selectedCity"
                   optionLabel="region_name_ru"
                   [filter]="true"
                   [checkbox]="false"
                   filterPlaceHolder="Найти город, регион или область"
                   [listStyle]="{'max-height': '84vh','min-height': '84vh'}">
            <ng-template let-city pTemplate="item">
              <div class="w-full region-item">
                <input type="radio" [id]="city.id" [checked]="selectedCity.id == city.id">
                {{ translate.currentLang == 'ru' ? city.region_name_ru : city.region_name_kz }}
              </div>
            </ng-template>
        </p-listbox>
      </div>
      <ng-template pTemplate="closeicon">
        <img src="assets/images/main/cross-icon.svg" alt="">
      </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="sortOpen" position="bottom" styleClass="filter-item">
      <ng-template pTemplate="header">
        <div class="region-sidebar">
          <div class="w-full text-center">
            <div class="sidebar-header">
              {{ 'Sorting' | translate }}
            </div>
          </div>
        </div>
      </ng-template>
      <div class="">
<!--        <p-listbox (onClick)="sortOpen = false" [options]="sort" [(ngModel)]="selectedSortType"-->
<!--                   optionLabel="sort_name_ru"-->
<!--                   [filter]="false"-->
<!--                   [checkbox]="false"-->
<!--                   [listStyle]="{'max-height': '84vh'}">-->
<!--          <ng-template let-sortType pTemplate="item">-->
<!--            <div class="w-full region-item">-->
<!--              <input type="radio" [id]="sortType?.id" [checked]="selectedSortType.id == sortType.id">-->
<!--              {{ translate.currentLang == 'ru' ? sortType.sort_name_ru : sortType.sort_name_kz }}-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </p-listbox>-->
      </div>
      <ng-template pTemplate="closeicon">
        <img src="assets/images/main/cross-icon.svg" alt="">
      </ng-template>
    </p-sidebar>
  </div>


  <div class="goods-items-wrap">

    <div *ngIf="!searchService._loading && searchService._cards?.list.length == 0" class="empty-search desktop-hidden">
      <img src="assets/images/errors/mobile-empty-request.svg" alt="">
      <div class="mb-3 empty-search-head mx-auto">
        {{ 'EmptyRequest' | translate }}
      </div>
      <div class="empty-search-desc mx-auto">
        {{ 'PleaseTryAgain' | translate }}
      </div>
    </div>

    <ng-container>
      <app-card-template *ngFor="let item of getCards().content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>
    </ng-container>

    <div class="goods-items-wrap" *ngIf="searchService._loading">
      <div class="good-item relative" *ngFor="let item of skeletonList; index as i">
        <div class="img-wrap">
          <p-skeleton width="13vw" height="200px"></p-skeleton>
        </div>
        <div class="item-title mt-2">
          <p-skeleton width="100%" height="20px"></p-skeleton>
        </div>
        <div class="item-title mt-2">
          <p-skeleton width="100%" height="20px"></p-skeleton>
        </div>
        <div class="item-title mt-2">
          <p-skeleton width="100%" height="20px"></p-skeleton>
        </div>
      </div>
    </div>


    <div *ngIf="getCards()?.content?.length == 0 && !searchService._loading" class="nothing-found-block">
      <div class="nothing-found-head">
        {{ 'Ничего не найдено' | translate }}
      </div>
      <div class="nothing-found-desc">
        {{ 'Увы, мы не нашли ничего по вашему запросу. Попробуйте изменить запрос или сбросить фильтры и повторить поиск' | translate }}

      </div>
      <div (click)="removeFilters()" class="nothing-found-btn">
        {{ 'Сбросить все фильры' | translate }}
      </div>
    </div>

  </div>

  <div class="show-more" *ngIf="searchService._cards?.list.totalPages > this.searchService._pageNumber+1 && !searchService._loading" (click)="this.searchService.scrollNextPage()">
    Показать ещё
  </div>

</div>
