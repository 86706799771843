import { NgModule} from '@angular/core';
import {InterceptService} from "./services/intercept/intercept.service";
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import {CarouselModule} from 'primeng/carousel';
import {AccordionModule} from 'primeng/accordion';
import {MenubarModule} from 'primeng/menubar';
import {InputTextModule} from 'primeng/inputtext';
import {MenuModule} from 'primeng/menu';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputMaskModule} from 'primeng/inputmask';
import {RatingModule} from 'primeng/rating';
import {PaginatorModule} from 'primeng/paginator';
import {ImageModule} from 'primeng/image';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {DialogModule} from 'primeng/dialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastModule} from 'primeng/toast';
import {MessageService} from "primeng/api";
import {SkeletonModule} from 'primeng/skeleton';
import {PanelModule} from 'primeng/panel';
import {DividerModule} from 'primeng/divider';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {FileUploadModule} from 'primeng/fileupload';
import {ChipsModule} from 'primeng/chips';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ContextMenuModule} from 'primeng/contextmenu';
import {StepsModule} from 'primeng/steps';
import {AppComponent} from './app.component';
import {Routes, RouterModule, Router, RouterOutlet} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {HeaderComponent} from './components/main/header/header.component';
import {SliderComponent} from './components/main/slider/slider.component';
import {TagModule} from 'primeng/tag';
import {SearchBarComponent} from './components/main/search-bar/search-bar.component';
import {GoodsComponent} from './components/main/goods/goods.component';
import {FiltersComponent} from './components/main/goods/filters/filters.component';
import {GoodsListComponent} from './components/main/goods/goods-list/goods-list.component';
import { ListboxModule } from 'primeng/listbox';
import {GoodsCardComponent} from './components/main/goods/goods-list/goods-card/goods-card.component';
import {CompanyCardComponent} from './components/main/goods/company-card/company-card.component';
import {MainService} from "./services/main/main.service";
import {SearchService} from "./services/search/search.service";
import {WebViewCommunicationService} from "./services/webview/web-view-communication.service";
import {FavoriteComponent} from './components/main/favorite/favorite.component';
import {CallbackComponent} from './components/callback/callback.component';
import {Secure} from './pipes/secure.pipe';
import {NumberspacerPipe} from "./pipes/numberspacer.pipe";
import {StartSaleComponent} from './components/start-sale/start-sale.component';
import {ModeratorComponent} from './components/moderator/moderator.component';
import {CardCheckComponent} from './components/moderator/card-check/card-check.component';
import {AccountComponent} from './components/account/account.component';
import {SalesComponent} from './components/account/sales/sales.component';
import {PurchasesComponent} from './components/account/purchases/purchases.component';
import {MyProductsAndServicesComponent} from './components/account/my-products-and-services/my-products-and-services.component';
import {CounterpartiesComponent} from './components/account/counterparties/counterparties.component';
import {BalanceAndDetailComponent} from './components/account/balance-and-detail/balance-and-detail.component';
import {RatesComponent} from './components/account/rates/rates.component';
import {request} from "../ncalayer";
import {CreateLsComponent} from './components/account/my-products-and-services/create-ls/create-ls.component';
import {ContactsComponent} from './components/main/contacts/contacts.component';
import {InstallAppComponent} from './components/main/install-app/install-app.component';
import {UniversalCallbackButtonComponent} from "./components/main/universal-callback-button/universal-callback-button.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {CompanyImageComponent} from './components/main/header/company-image/company-image.component';
import {InlineSVGModule} from "ng-inline-svg-2";
import {authGuard} from "./app.authguard";
import {ImageCropperModule} from 'ngx-image-cropper';
import {DatePipe, NgOptimizedImage, registerLocaleData} from "@angular/common";
import {NotFoundComponent} from "./components/errors/not-found/not-found.component";
import {SiteUnavailableComponent} from "./components/errors/site-unavailable/site-unavailable.component";
import { PaginationComponent } from './components/pagination/pagination.component';
import localeRu from '@angular/common/locales/ru';
import { SidebarModule } from 'primeng/sidebar';
import {TruncateHtmlPipe} from "./pipes/truncate.pipe";
import { InplaceModule } from 'primeng/inplace';
import { GalleriaModule } from 'primeng/galleria';
import {PinchZoomModule} from "@mtnair/ngx-pinch-zoom";
import {CatalogComponent} from "./components/main/catalog/catalog.component";
import {SharedModule} from "./modules/shared/shared.module";
import {AuthDialogModule} from "./components/auth-dialog/auth-dialog.module";
import {SideMenuComponent} from "./components/side-menu/side-menu.component";
import {CardTemplateComponent} from "./components/card-template/card-template.component";
import {NewMainPageComponent} from "./components/main/new-main-page/new-main-page.component"
import { SwiperModule } from 'swiper/angular';
import { SliderModule } from 'primeng/slider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {TuOrdersComponent} from "./components/account/tu-orders/tu-orders.component";
import {TuOrderComponent} from "./components/account/tu-orders/tu-order/tu-order.component";
import {FileSizePipe} from "./pipes/fileSize.pipe";
import {YandexComponent} from './components/account/yandex/yandex.component';
import {SigningModule} from "./components/signing/signing.module";
import {RatingComponent} from "./components/tools/rating/rating.component";
import { InputSwitchModule } from 'primeng/inputswitch';

registerLocaleData(localeRu);

const routes: Routes = [
  {
    path: '', component: NewMainPageComponent, children: [
    ]
  },
  {
    path: 'catalog', component: CatalogComponent
  },
  {
    path: 'company/:bin', component: CompanyCardComponent
  },
  {
    path: 'favorite', component: FavoriteComponent, canActivate: [authGuard]
  },
  {
    path: 'item/:id', component: GoodsCardComponent
  },
  {
    path: 'search', component: MainComponent, children: [
      {
        path: '', component: GoodsComponent
      }
    ]
  },
  {
    path: 'moderator', component: ModeratorComponent, canActivate: [authGuard]
  },
  {
    path: 'tu-order/:id', component: TuOrderComponent, canActivate: [authGuard]
  },
  {
    path: 'account', component: AccountComponent,
    children: [
      {
        path: 'sales', component: SalesComponent, canActivate: [authGuard]
      },
      {
        path: 'purchases', component: PurchasesComponent, canActivate: [authGuard]
      },
      {
        path: 'my-products-and-services', component: MyProductsAndServicesComponent, canActivate: [authGuard]
      },
      {
        path: 'tu-orders', component: TuOrdersComponent, canActivate: [authGuard]
      },
      {
        path: 'counterparties', component: CounterpartiesComponent, canActivate: [authGuard]
      },
      {
        path: 'balance-and-detail', component: BalanceAndDetailComponent, canActivate: [authGuard]
      },
      {
        path: 'rates', component: RatesComponent, canActivate: [authGuard]
      },
      {
        path: 'edit-company',
        canActivate: [authGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./components/account/my-products-and-services/company-info/edit-company-card/edit-company-card.module').then(m => m.EditCompanyCardModule)
          }
        ]
      },
      {
        path: 'yandex', component: YandexComponent, canActivate: [authGuard]
      }
    ]
  },
  {
    path: 'create-card',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/account/my-products-and-services/create-card/create-card.module').then(m => m.CreateCardModule)
      }
    ]
  },
  {
    path: 'edit-card/:id',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/account/my-products-and-services/edit-card/edit-card.module').then(m => m.EditCardModule)
      }
    ]
  },
  {
    path: 'company-info',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/account/my-products-and-services/company-info/company-info.module').then(m => m.CompanyInfoModule)
      }
    ]
  },
  {
    path: 'attach-company',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/account/my-products-and-services/attaching-a-company/attaching-a-company.module').then(m => m.AttachingACompanyModule)
      }
    ]
  },
  {
    path: 'create-ls', component: CreateLsComponent, canActivate: [authGuard]
  },
  {
    path: 'side-menu', component: SideMenuComponent
  },
  {
    path: 'moderator/card-edit/:id', component: CardCheckComponent, canActivate: [authGuard]
  },
  {
    path: 'contacts', component: ContactsComponent
  },
  {
    path: 'become-partner',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/main/become-partner/become-partner.module').then(m => m.BecomePartnerModule)
      }
    ]
  },
  {
    path: 'site-unavailable', component: SiteUnavailableComponent
  },
  {
    path: 'nachnite-zarabatyvat-vmeste',
    children: [
      {
        path: '',
        loadChildren: () => import('./components/landing/nachnite-zarabatyvat-vmeste/nachnite-zarabatyvat-vmeste.module').then(m => m.NachniteZarabatyvatVmesteModule)
      }
    ]
  },
  {
    path: 'thank-you-page',
    children: [
      {
        path: '',
        loadChildren: () => import('./components/landing/thank-you-page/thank-you-page.module').then(m => m.ThankYouPageModule)
      }
    ]
  },

  /**
   * Всегда должна быть последней страницей
   * */
  {
    path: '**', component: NotFoundComponent
  }
]

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    SliderComponent,
    SearchBarComponent,
    GoodsComponent,
    FiltersComponent,
    GoodsListComponent,
    GoodsCardComponent,
    CompanyCardComponent,
    NumberspacerPipe,
    FavoriteComponent,
    CallbackComponent,
    Secure,
    TruncateHtmlPipe,
    RatingComponent,
    StartSaleComponent,
    ModeratorComponent,
    CardCheckComponent,
    AccountComponent,
    SalesComponent,
    PurchasesComponent,
    MyProductsAndServicesComponent,
    CounterpartiesComponent,
    BalanceAndDetailComponent,
    RatesComponent,
    CreateLsComponent,
    ContactsComponent,
    InstallAppComponent,
    UniversalCallbackButtonComponent,
    CompanyImageComponent,
    NotFoundComponent,
    SiteUnavailableComponent,
    PaginationComponent,
    CatalogComponent,
    SideMenuComponent,
    CardTemplateComponent,
    NewMainPageComponent,
    TuOrdersComponent,
    TuOrderComponent,
    FileSizePipe,
    YandexComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    RouterOutlet,
    TableModule,
    TagModule,
    MenubarModule,
    RatingModule,
    CarouselModule,
    AccordionModule,
    PanelModule,
    BreadcrumbModule,
    InputMaskModule,
    FileUploadModule,
    ListboxModule,
    SelectButtonModule,
    StepsModule,
    InplaceModule,
    MultiSelectModule,
    InputTextModule,
    ChipsModule,
    DividerModule,
    HttpClientModule,
    SidebarModule,
    ToggleButtonModule,
    SkeletonModule,
    SwiperModule,
    GalleriaModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageModule,
    InputTextareaModule,
    KeyFilterModule,
    TabMenuModule,
    TabViewModule,
    RadioButtonModule,
    ToastModule,
    ContextMenuModule,
    PaginatorModule,
    DialogModule,
    DropdownModule,
    HttpClientModule,
    FormsModule,
    CheckboxModule,
    ReactiveFormsModule,
    InputNumberModule,
    MenuModule,
    RouterModule.forRoot(routes),
    ProgressSpinnerModule,
    InlineSVGModule,
    ImageCropperModule,
    NgOptimizedImage,
    PinchZoomModule,
    SharedModule,
    AuthDialogModule,
    SliderModule,
    OverlayPanelModule,
    InputSwitchModule,
    SigningModule
  ],
  providers: [
    MainService,
    SearchService,
    WebViewCommunicationService,
    request,
    MessageService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
