<div class="callback-modal-wrap flex justify-content-center">
  <div class="overlay" (click)="close()">
  </div>
  <div class="callback-wrap">
    <form [formGroup]="callbackFormGroup">
      <div  class="callback-modal">
        <div class="text-right">
          <img (click)="close()" class="cross-icon" src="assets/images/main/cross-icon.svg" alt="">
        </div>
        <div class="callback-header">{{ 'BackCall' | translate }}</div>
        <div class="callback-input-wrap">
          <label class="callback-label">{{ 'PhoneNumber' | translate }}
            <span class="required">*</span>
          </label>
          <p-inputMask [autoFocus]="true"  placeholder="+7 (___) ___ __ __" styleClass="w-full callback-input" mask="+7 (999) 999 99 99" [unmask]="true" formControlName="phone"></p-inputMask>
          <span *ngIf="callbackFormGroup.get('phone')!.value == ''  && callbackFormGroup.get('phone')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
        </div>
        <div class="callback-input-wrap">
          <label class="callback-label">{{'LastName' | translate}} {{'FirstName' | translate}}
            <span class="required">*</span>
          </label>
          <input placeholder="Иванов Иван" class="w-full callback-input" pInputText formControlName="full_name" />
          <span *ngIf="callbackFormGroup.get('full_name')!.value == '' && callbackFormGroup.get('full_name')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
        </div>
        <div class="callback-input-wrap">
          <label class="callback-label">{{'Email'}}
          </label>
          <input placeholder="example@mail.com" class="w-full callback-input" pInputText formControlName="email" />
          <span *ngIf="callbackFormGroup.get('email')!.value == '' && callbackFormGroup.get('email')!.dirty" class="err-txt">{{ 'EmptyField' | translate }}</span>
        </div>
        <div class="callback-input-wrap">
          <label class="callback-label">{{'Region' | translate}}
          </label>
          <p-dropdown formControlName="region_id" optionValue="id" [options]="regionList" placeholder="{{ 'SelectRegion' | translate }}" styleClass="w-full callback-input" [editable]="false" optionLabel="{{translate.currentLang == 'ru' ? 'region_name_ru' : 'region_name_kz'}}"></p-dropdown>
        </div>
        <div class="flex callback-input-wrap">
          <div class="count-header">{{'QuantityOfGoods' | translate}}</div>
          <p-inputNumber (onBlur)="changeTariffCount()" [min]="this.currentItem.min_order_count" formControlName="count" styleClass="w-full callback-count" [showButtons]="true" buttonLayout="horizontal" #el id="el"
                         (onInput)="changeTariffCount(el.input.nativeElement)" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                         decrementButtonClass="callback-count" incrementButtonClass="callback-count" mode="decimal">
            <ng-template pTemplate="incrementbuttonicon"><span class="decimal-icon">+</span></ng-template>
            <ng-template pTemplate="decrementbuttonicon"><span class="decimal-icon">-</span></ng-template>
          </p-inputNumber>
        </div>
        <div class="relative">
          <textarea maxlength="500" formControlName="comment" [placeholder]="'Comment' | translate" class="callback-textarea" pInputTextarea></textarea>
          <span class="err-txt">{{textLength()}}/500 {{ 'Characters' | translate }}</span>
        </div>
        <div class="offer-wrap">
          <p-checkbox [(ngModel)]="checked" [binary]="true" [ngModelOptions]="{standalone: true}" inputId="offer" id="offer"></p-checkbox>
          <label for="offer">{{ 'IAcceptTheTerms' | translate }}
            <span (click)="offerClick()" class="offer">
              <a [href]="translate.currentLang == 'ru' ? 'assets/docs/Типовая%20форма%20Публичного%20договора.pdf' : 'assets/docs/Типовая%20форма%20Публичного%20договора%20каз.pdf'" target="_blank">{{ 'PublicOffer' | translate }}</a>
            </span>
          </label>
        </div>
        <div *ngIf="showAgreeErr" class="text-sm text-red-500 mt-1">{{ 'Вы не приняли согласие с офертой' | translate }}</div>
        <div>
          <button (click)="createOrder()"
                  class="order-btn w-full justify-content-center" pButton type="button">{{ 'SubmitYourApplication' | translate }}
          </button>
          <button (click)="close()" class="cancel-btn">{{ 'Cancel' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</div>

<p-toast class="main-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
