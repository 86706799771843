import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberspacer'
})
export class NumberspacerPipe implements PipeTransform {
  constructor() {}

  transform(number: any): any {
    return this.addSpacesToNumberString(number);
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }
    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы
    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }

}
