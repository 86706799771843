import { Component } from '@angular/core';

@Component({
  selector: 'app-balance-and-detail',
  templateUrl: './balance-and-detail.component.html',
  styleUrls: ['./balance-and-detail.component.sass']
})
export class BalanceAndDetailComponent {

}
