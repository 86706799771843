import {Component, OnInit, Input, HostListener} from '@angular/core';
import {SearchService} from "../../../services/search/search.service";
import {MainService} from "../../../services/main/main.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.sass']
})
export class CatalogComponent implements OnInit{

  openSearch = false;
  catalogItems: any = [];
  categoryTypes: any = [];
  currentTab: any = 0;
  parentSideBar = false;
  category: any;
  subCategoriesList: any;
  cardsSideBar = false;
  isAuth: any;
  allCardsData: any;
  showFilters = false;
  regions: any;
  priceRange: any = ['',''];
  selectedCity= {
    id: 0,
    region_name_kz: "Весь Казахстан",
    region_name_ru: "Весь Казахстан",
  };
  sortOpen = false;
  selectedSortType= {
    id: 'rating',
    sort_name_kz: "По рейтингу",
    sort_name_ru: "По рейтингу",
  };

  sort= [{
    id: 'rating',
    sort_name_kz: "По рейтингу",
    sort_name_ru: "По рейтингу",
  },
    {
      id: 'lower',
      sort_name_kz: "Сначала дешёвые",
      sort_name_ru: "Сначала дешёвые",
    },
    {
      id: 'higher',
      sort_name_kz: "Сначала дорогие",
      sort_name_ru: "Сначала дорогие",
    }];
  regionListOpen = false;

  constructor(public searchService: SearchService,private mainService: MainService,
              private authService: AuthService, public translate: TranslateService) {
    this.authService.auth.subscribe(() => this.getInfo());
  }

  ngOnInit(){
    this.getCategoryTypes();
    this.getCategories();
    this.getInfo();
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      this.searchService.search();
    })
  }

  backToMain(){
    this.searchService._categoryId = '';
    this.searchService.search();
  }

  search(){
    this.searchService._categoryId = '';
    this.searchService._pageNumber = 0;
    this.searchService.search();
    this.cardsSideBar = true;
  }

  applyFilters(){
    this.searchService._sortBy = this.selectedSortType.id;
    this.searchService._regionId = this.selectedCity.id;
    this.searchService.priceRange = this.priceRange;
    this.showFilters = false;
  }

  removeFilters(){
    this.searchService._regionId = '';
    this.searchService._sortBy = '';
    this.searchService._priceRange = ['', ''];
    this.priceRange = ['', ''];
    this.selectedCity = this.regions[0];
    this.selectedSortType = this.sort[0];
    this.searchService.checkCurrentPage();
  }

  changeValue(index: any, value: any = ''){
    this.priceRange[index] = value.value;
  }

  openRegionList(){
    this.regionListOpen = true;
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }
    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы
    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }

  favorite(itemIndex: any) {
    this.allCardsData = this.searchService._cards;
    if (this.allCardsData.list.content[itemIndex].favorite == true) {
      this.removeFavorite(this.allCardsData.list.content[itemIndex].id, itemIndex);
    } else {
      this.setFavorite(this.allCardsData.list.content[itemIndex].id, itemIndex);
    }
  }

  removeFavorite(itemId: any, itemIndex: any) {
    this.mainService.deleteFavorite(itemId).subscribe({
      next: () => {
        this.allCardsData.list.content[itemIndex].favorite = !this.allCardsData?.list.content[itemIndex].favorite;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  checkFilters(){
    return !(this.priceRange[0].length != 0 || this.priceRange[1].length != 0 || this.searchService._regionId != 0
      || this.searchService._sortBy != '' || this.selectedCity.id != 0 || this.selectedSortType.id != 'rating');
  }

  setFavorite(itemId: any, itemIndex: any) {
    this.mainService.postFavorite(itemId).subscribe({
      next: () => {
        this.allCardsData.list.content[itemIndex].favorite = !this.allCardsData?.list.content[itemIndex].favorite;
        // @ts-ignore
        window['yaCounter48820577'].reachGoal('obshee-kolichestvo-dobavleniy-tovarov-v-izbrannoe');
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (this.isNearBottom()) {
      // Вызывайте ваш метод или обработчик события при приближении к концу страницы
      this.loadMoreData();
    }
  }

  isNearBottom(): boolean {
    if(window.innerWidth > 760) {
      return false;
    }
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0;

    // Порог, близкий к концу страницы, когда считается, что пользователь достиг конца
    const threshold = 200;

    return windowHeight + scrollPosition >= documentHeight - threshold;
  }

  loadMoreData(): void {
    // Здесь вы можете загрузить дополнительные данные или выполнить другие действия
    if(this.searchService._cards.totalPages > this.searchService._pageNumber){
      this.searchService.scrollNextPage();
    }
  }

  allGoods(){
    this.searchService.categoryId = this.category.id;
    this.parentSideBar = false;
    this.cardsSideBar = true;
  }

  goodsByParentCategoryId(item: any){
    this.subCategoriesList.child_categories.forEach((element: any) => {
      if(item.id == element.id){
        element.active = true;
      }else{
        element.active = false;
      }
    });
    this.category.id = this.subCategoriesList.id;
    this.searchService.categoryId = item.id;
    this.parentSideBar = false;
    this.cardsSideBar = true;

  }

  openFilters(){
    this.showFilters = true;
    this.getRegions();
  }

  getRegions() {
    this.mainService.getRegionList().subscribe({
      next: (res: any) => {
        this.regions = res;
      },
      error: () => {
      }
    });
  }

  closeCardsSideBar(){
    this.cardsSideBar = false;
    this.searchService._categoryId = '';
    this.category = '';
    this.subCategoriesList = [];
  }

  genInp(item: any) {
    return `url('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[0]?.full_path}')`;
  }

  changeSubCategory(item: any){
    this.searchService._name = '';
    this.subCategoriesList.child_categories.forEach((element: any) => {
      element.active = item.id == element.id;
    });
    this.searchService.categoryId = item.id;
  }

  openParentSideBar(item: any){
    this.category = item;
    if(item.id == 0){
      this.searchService._categoryId = '';
     this.cardsSideBar = true;
     this.searchService.search();
    }else{
      this.parentSideBar = true;
      this.mainService.getSelectedCategory(item.id)
        .subscribe((res: any) => {
          this.subCategoriesList = res;
        });
    }
  }

  getCategoryTypes() {
    this.mainService.getAllCategoryTypes()
      .subscribe({
        next: (res: any) => {
          this.categoryTypes = res;
        },
        error: () => {
        }
      })
  }

  getCategories(type = 'GOOD') {
    this.mainService.getCatalogCategories(type)
      .subscribe({
        next: (res: any) => {
          this.catalogItems = res;
        },
        error: () => {
        }
      })
  }

}
