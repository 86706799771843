<div class="bmg-attachment">
  <div class="info-block"><div class="info-block_ico" inlineSVG="assets/images/signing/info.svg"></div>{{'Подходит только для ИП и самозанятых' | translate}}</div>
  <div *ngIf="spinner" class="progress-block">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <form [formGroup]="iinForm" *ngIf="!spinner" class="bmg-attachment-form">
    <div class="bmg-label">{{'Введите ИИН' | translate}}</div>
    <p-inputMask mask="999999999999" id="iin" placeholder="____________" [styleClass]="'bmg-input'"
                 [autoClear]="false" formControlName="iin" [unmask]="true"
                 (onComplete)="changeIIN()" (change)="clearFields()"></p-inputMask>
    <div class="iin-info" *ngIf="!errorOfCompany && !nameOfCompany">{{'ИИН должен быть связан с указанным при регистрации номером телефона на ismet.kz' | translate}}</div>
  </form>
  <div class="company-info" [ngClass]="{'error': errorOfCompany}">
    <ng-container *ngIf="nameOfCompany && !spinner">
      <div class="find-company-label">{{'Найденная компания' | translate}}</div>
      <div>{{nameOfCompany}}</div>
    </ng-container>
    <ng-container *ngIf="errorOfCompany  && !spinner">
      <div>{{errorOfCompany}}</div>
    </ng-container>
  </div>
  {{iinForm.errors}}
  <button class="btn btn-md primary-btn-color bmg-button" (click)="addBMG()" *ngIf="!errorOfCompany && !iinForm.get('iin')?.errors && !spinner">
    {{'Прикрепить компанию' | translate}}
  </button>
</div>
