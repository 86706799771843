import {AfterViewInit, Component, Input, ViewContainerRef} from '@angular/core';
import {MainService} from "../../../../services/main/main.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-company-image',
  templateUrl: './company-image.component.html',
  styleUrls: ['./company-image.component.sass']
})
export class CompanyImageComponent implements AfterViewInit {
  @Input() uin!: string;
  @Input() src!: any;
  @Input() style: any = '';
  init: boolean = false

  constructor(private mainService: MainService, private sanitizer: DomSanitizer, private vcRef: ViewContainerRef) {
  }

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement
    const observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting)
    })
    observer.observe(observedElement)
  }

  renderContents(isIntersecting: boolean) {
    if(isIntersecting && !this.init) {
      this.mainService.getCompanyImg(this.uin).subscribe(data => {
        this.src = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
      })
      this.init = true
    }
  }
}
