import {Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {MainService} from "../../../../services/main/main.service";
import {SearchService} from "../../../../services/search/search.service";
import {AuthService} from "../../../../services/auth/auth.service";
import {Paginator} from "primeng/paginator";
import {TranslateService} from "@ngx-translate/core";
import {HeaderService} from "../../../../services/header/header.service";

@Component({
  selector: 'app-goods-list',
  templateUrl: './goods-list.component.html',
  styleUrls: ['./goods-list.component.sass']
})
export class GoodsListComponent implements OnInit {

  @ViewChild(Paginator, {static: true}) paginator!: Paginator;

  rating: any;
  isAuth: any;
  skeletonList = new Array(4);
  showFilters = false;
  regions: any;
  selectedCity= {
    id: 0,
    region_name_kz: "Весь Казахстан",
    region_name_ru: "Весь Казахстан",
  };
  sortOpen = false;


  sortTypes = [
    {
      name_ru: 'Сначала дешевые',
      name_kz: 'Алдымен арзаны',
      active: false,
      id: 1,
    },
    {
      name_ru: 'Сначала дорогие',
      name_kz: 'Алдымен қымбаты',
      active: false,
      id: 2,
    }
  ]

  priceRange: any = ['',''];

  regionListOpen = false;

  constructor(private mainService: MainService, public searchService: SearchService,
              private authService: AuthService, public translate: TranslateService) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.getInfo();
    this.searchService.pageEvent.subscribe(event => {
      // Обработка события
      this.setFirstPage(event);
    });
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      this.searchService.search();
    })
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (this.isNearBottom()) {
      // Вызывайте ваш метод или обработчик события при приближении к концу страницы
      this.loadMoreData();
    }
  }

  isNearBottom(): boolean {
    if(window.innerWidth > 760) {
      return false;
    }
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0;

    // Порог, близкий к концу страницы, когда считается, что пользователь достиг конца
    const threshold = 200;

    return windowHeight + scrollPosition >= documentHeight - threshold;
  }

  loadMoreData(): void {
    // Здесь вы можете загрузить дополнительные данные или выполнить другие действия
    if(this.searchService._cards.totalPages > this.searchService._pageNumber){
      this.searchService.scrollNextPage();
    }
  }

  ngOnInit() {

  }

  applyFilters(){
    this.searchService._regionId = this.selectedCity.id;
    this.searchService.priceRange = this.priceRange;
    this.showFilters = false;
  }

  changeValue(index: any, value: any = ''){
    this.priceRange[index] = value.value;
  }

  checkFilters(){
    return !(this.priceRange[0].length != 0 || this.priceRange[1].length != 0 || this.searchService._regionId != 0
      || this.searchService._sortBy != '' || this.selectedCity.id != 0);
  }

  removeFilters(){
    this.searchService.clearAllFilters();
  }

  setSortType(item: any, index: any){

    this.searchService._pageNumber = 0;

    if(item.active){
      item.active = false;
      this.searchService._sortBy = '';
      this.searchService._sortByAsc = '';
      this.searchService.search();
    }else{
      this.sortTypes.forEach((element: any) => {
        element.active = false;
      })
      item.active = true;
      this.searchService._sortBy = 'price';
      this.searchService.sortByAsc = item.id;
    }

  }


  openRegionList(){
    this.regionListOpen = true;
  }

  openFilters(){
    this.showFilters = true;
    this.getRegions();
  }

  getRegions() {
    this.mainService.getRegionList().subscribe({
      next: (res: any) => {
        this.regions = res;
      },
      error: () => {
      }
    });
  }

  getCards() {
    return this.searchService.cards;
  }

  setFirstPage(event: any) {
    this.searchService.pageNumber = 0;
  }


}
