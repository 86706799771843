<div *ngIf="!isMobile">
  <div class="setup-app">
    <div class="setup-app_item" (click)="showModalDialog(0)">
      <img class="setup-app_item__gp" src="/assets/images/main/gp.svg" alt="">
    </div>
    <div class="setup-app_item" (click)="showModalDialog(1)">
      <img class="setup-app_item__as" src="/assets/images/main/as.svg" alt="">
    </div>
  </div>
  <p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '400px'}" [draggable]="false" [resizable]="false">
    <div class="dialog-title">{{'Скачайте наше приложение!' | translate}}</div>
    <div class="dialog-desc">
      {{'Отсканируйте QR-код с помощью камеры вашего устройства или нажмите кнопку ниже, чтобы перейти в магазин приложений' | translate}}
    </div>
    <img class="dialog-qr" src="/assets/images/main/qr-code-ios.svg" alt="">
    <div class="dialog-button" (click)="goToInstall()">{{'Скачать приложение' | translate}}</div>
  </p-dialog>
</div>
<div *ngIf="isMobile">
  <div class="setup-app mobile">
    <a class="setup-app_item" href="https://play.google.com/store/apps/details?id=kz.ismet.kt">
      <img class="setup-app_item__gp" src="/assets/images/main/gp.svg" alt="">
    </a>
    <a class="setup-app_item__as" href="https://apps.apple.com/kz/app/ismet/id1449003074?platform=iphone">
      <img class="setup-app_item__as" src="/assets/images/main/as.svg" alt="">
    </a>
  </div>
</div>

