<div class="breadcrumb flex">
  <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
</div>

<div class="over-header">
  {{ 'CompanyData' | translate }}
</div>
<div class="">
  <div class="label-txt">
    {{ 'SelectCompany' | translate }}
  </div>
  <div class="field-wrap">
    {{ translate.currentLang == 'ru' ? userData?.region?.region_name_ru : userData?.region?.region_name_kz }}
    <p-dropdown [options]="userData.companyList" [optionLabel]="translate.currentLang == 'ru' ? 'companyName' : 'companyNameKz' " styleClass="w-6 card-input"></p-dropdown>
  </div>
</div>
<div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
  <div class="w-3">
    <div class="label-txt">
      {{ 'FirstName' | translate }}
    </div>
    <div class="relative field-wrap">
      <input [value]="userData.userInfo.firstName" class="card-input" type="text" pInputText/>
    </div>
  </div>
  <div class="mt-1">
    <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
    </svg>
  </div>
  <div class="w-3">
    <div class="">
      <div class="label-txt">
        {{ 'LastName' | translate }}
      </div>
      <div class="relative field-wrap">
        <input [value]="userData.userInfo.lastName" class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
</div>
<div class="w-3">
  <div class="">
    <div class="label-txt">
      {{ 'Patronymic' | translate }}
    </div>
    <div class="relative field-wrap pr-3">
      <input [value]="userData.userInfo.middleName" class="card-input" type="text" pInputText/>
    </div>
  </div>
</div>
<div class="over-header mt-4">
  {{ 'LegalAddress' | translate }}
</div>
<div class="field-wrap">
  <div class="label-txt">
    {{ 'Address' | translate }}
  </div>
  <p-dropdown [filterValue]="UAdress" [filter]="true" [(ngModel)]="city" styleClass="w-6 card-input">
    <ng-template pTemplate="filter">
      <span class="p-input-icon-left relative w-full">
         <i class="pi pi-search"></i>
         <input [placeholder]="'Search' | translate" class="filter-field w-full" type="text" pInputText (change)="getFullAddress()" [(ngModel)]="searchText" />
      </span>
    </ng-template>
  </p-dropdown>
</div>
<div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">

  <div class="w-3 field-wrap">
    <div class="label-txt">
      {{ 'HouseNumber' | translate }}
    </div>
    <div class="relative">
      <input class="card-input" type="text" pInputText/>
    </div>
  </div>
  <div class="mt-1">
    <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
    </svg>
  </div>
  <div class="w-3 field-wrap">
    <div class="">
      <div class="label-txt">
        {{ 'FractionAtHome' | translate }}
      </div>
      <div class="relative">
        <input class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
</div>
<div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">

  <div class="w-3 field-wrap">
    <div class="label-txt">
      {{ 'ApartmentOrOfficeNumber' | translate }}
    </div>
    <div class="relative">
      <input class="card-input" type="text" pInputText/>
    </div>
  </div>
  <div class="mt-1">
    <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
    </svg>
  </div>
  <div class="w-3 field-wrap">
    <div class="">
      <div class="label-txt">
        {{ 'ApartmentOrOfficeFraction' | translate }}
      </div>
      <div class="relative">
        <input class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
</div>
<div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">

  <div class="w-3 field-wrap">
    <div class="label-txt">
      Email
    </div>
    <div class="relative">
      <input class="card-input" type="text" pInputText/>
    </div>
  </div>
  <div class="mt-3">
<!--    <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>-->
<!--    </svg>-->
  </div>
  <div class="w-3 ml-2 field-wrap">
    <div class="">
      <div class="label-txt">
        {{ 'PhoneNumber' | translate }}
      </div>
      <div class="relative">
        <input class="card-input" type="text" pInputText/>
      </div>
    </div>
  </div>
</div>
<div class=" field-wrap">
  <div class="label-txt">
    {{ 'IIK' | translate }}
  </div>
  <input class="card-input w-3" type="text" pInputText/>
</div>
<div class="">
  <p-checkbox></p-checkbox>
  <label> {{ 'Offer-txt-1' | translate }} <a [href]="translate.currentLang == 'ru' ? 'assets/docs/Типовая%20форма%20Публичного%20договора.pdf' : 'assets/docs/Типовая%20форма%20Публичного%20договора%20каз.pdf'" target="_blank">{{ 'Offer-txt-2' | translate }}</a></label>
</div>
<div class="flex my-3">
  <div class="">
    <button class="cancel-btn">{{ 'Cancel' | translate }}</button>
  </div>
  <div class="">
    <button class="sign-btn">{{ 'SignWithDigitalSignature' | translate }}</button>
  </div>
</div>
