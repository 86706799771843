import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.sass']
})



export class SliderComponent implements OnInit {

  @ViewChild('swiper', { static: false }) swiper?: any;

  config: any = {
    spaceBetween: 30,
    navigation: false,
    pagination: { clickable: false },
    scrollbar: { draggable: false },
    slidesPerView: 'auto',
    loop: true,
    autoplay: true
  };

  products: any[] = [
    {
      image: 'new-banner.png',
      imageMobile: 'new-banner.png',
      header: ``,
      headerKz: ``,
      description: ``,
      descriptionKz: ``
    },
    {
      image: 'new-banner.png',
      imageMobile: 'new-banner.png',
      header: ``,
      headerKz: ``,
      description: ``,
      descriptionKz: ``
    },
    {
      image: 'new-banner.png',
      imageMobile: 'new-banner.png',
      header: ``,
      headerKz: ``,
      description: ``,
      descriptionKz: ``
    }

    ];

  constructor(public translate: TranslateService) {}

  ngOnInit() {

  }

  slideNext(){
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
  }


}
