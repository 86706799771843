import {Component, OnInit} from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {SearchService} from "../../../services/search/search.service";
import {TranslateService} from "@ngx-translate/core";
import {Router,ActivatedRoute} from "@angular/router";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass']
})
export class SearchBarComponent implements OnInit {
  catalogItems: any = [[], [], []];
  activeState: boolean[] = [false];
  subCategoryList: any = [];
  categoryTypes: any;
  startSale:boolean = false;
  regions: any;
  count: any = [];
  name = '';

  constructor(private mainService: MainService,private route: ActivatedRoute, public searchService: SearchService,
              public translate: TranslateService, private router: Router) {
  }

  ngOnInit() {
    this.getCategoryTypesAndCount();
    this.getRegions();
    if(this.route.snapshot.queryParams['salemodal']){
      this.startSale = true;
    }
    if(this.route.snapshot.queryParams.hasOwnProperty('category')){
      this.searchService._pageNumber = this.route.snapshot.queryParams['page']
      this.searchService.categoryId = this.route.snapshot.queryParams['category']
      this.mainService.getSelectedCategory(this.route.snapshot.queryParams['category'])
        .subscribe((res: any) => {
        })
    }
  }

  getCategoryTypesAndCount() {
    forkJoin([
      this.mainService.getAllCategoryTypes(),
      this.mainService.getNumberOfCards()
    ]).subscribe(([types, count]: any) => {
      this.categoryTypes = types;
      this.count = count
    }).add(() => {
      this.getCatalogItems({index: 0});
    })
  }

  close() {
    this.startSale = false;
  }

  onEnter(value: any) {
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('poisk');
    this.router.navigate(['/']);
    this.name = value;
    this.searchService._sortBy = '';
    this.searchService._sortByAsc = '';
    this.searchService._priceRange = ['', ''];
    this.searchService._categoryId = '';
    this.searchService.name = value;
  }

  startedSale = () => {
    this.startSale = true;
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('nachat-prodavat');
  }

  getCatalogItems(event: any) {
    if (event == 0) {
      this.catalogItems.forEach((element: any) => {
        element.forEach((el: any) => {
          if (el.activeTab == true) {
            this.searchService.categoryType = el.type;
          }
        });
      });
      this.toggle(0);
    } else {
      if (this.catalogItems[event.index].length == 0) {
        this.mainService.getCatalogCategories(this.categoryTypes[event.index].name).subscribe({
          next: (res: any) => {
            this.catalogItems[event.index] = res;
          },
          complete: () => {
          },
          error: () => {
          }
        });
      }
      this.removeClick();
    }
  }

  clear() {
    this.name = '';
    this.searchService.name = '';
  }

  getRegions() {
    this.mainService.getRegionList().subscribe({
      next: (res: any) => {
        this.regions = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  getSubCategory(categoryId: any, index: any, categoryIndex: any) {
    if (categoryId == 0 && window.location.href != window.location.origin + '/') {
      this.router.navigate(['/']);
    }
    let currentState = this.catalogItems[categoryIndex][index].activeTab;
    this.catalogItems.forEach((element: any) => {
      element.forEach((el: any) => {
        el.activeTab = false;
      });
    });
    this.catalogItems[categoryIndex][index].activeTab = !currentState;
    if (!this.catalogItems[categoryIndex][index].activeTab) {
      this.subCategoryList = [];
      return;
    }

    if (categoryId == 0) {
      // первый шаг при поиске всех категорий
      this.getCatalogItems(categoryId)
    } else {
      if (this.catalogItems[categoryIndex][index].childList == undefined) {
        this.mainService.getSubCategory(categoryId).subscribe({
          next: (res: any) => {
            this.catalogItems[categoryIndex][index].childList = res.child_categories;
          },
          complete: () => {
            this.subCategoryList = this.catalogItems[categoryIndex][index];
          },
          error: () => {
          }
        });
      } else {
        this.subCategoryList = this.catalogItems[categoryIndex][index];
      }
    }
  };

  checkPage(categoryId: any, parentCategory: any) {
      // @ts-ignore
      window['yaCounter48820577'].reachGoal('obshee-kolichestvo-perehodov-po-kategoriyam');
      this.router.navigate(['/'], {
        relativeTo: this.route,
        queryParams: {
          category: categoryId,
          parentCategory: parentCategory,
          page: this.searchService._pageNumber
        },
        queryParamsHandling: 'merge'
      })

  }

  toggle(index: number) {
    this.removeClick();
    this.activeState[index] = !this.activeState[index];
  }

  removeClick() {
    this.catalogItems.forEach((element: any) => {
      element.forEach((el: any) => {
        el.activeTab = false;
      });
    });
    this.subCategoryList = [];
  }
}
