import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'truncateHtml'
})
export class TruncateHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, isTruncated: boolean, limit: number = 150, trail: string = '...'): SafeHtml {
    const truncatedText = isTruncated && value.length > limit ? value.substring(0, limit) + trail : value;
    return this.sanitizer.bypassSecurityTrustHtml(truncatedText);
  }
}
