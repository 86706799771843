<div class="catalog-overlay" *ngIf="activeState[0]" (click)="toggle(0)"></div>
<div class="relative">
  <div class="search-bar-container" [ngClass]="{'border-left-bottom' : activeState[0]}">
    <div class="catalog-btn-wrap" >
      <button class="catalog-btn" pButton type="button" (click)="toggle(0)">
        {{ "WholeCatalog" | translate }}
        <i *ngIf="!activeState[0]" class="pi pi-bars hamburger-icon"></i>
        <i *ngIf="activeState[0]" class="pi pi-times cross-icon"></i>
      </button>
    </div>

    <span class="p-input-icon-left flex search-bar-wrap">
    <img src="assets/images/main/search.svg" class="search-icon" alt="">
        <input (keyup.enter)="onEnter(name)" [(ngModel)]="name" [placeholder]="'FindProduct' | translate" class="main-search" type="text" pInputText />
      <span (click)="onEnter(name)" class="search">{{ "Find" | translate}}</span>
    <img *ngIf="name != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
  </span>
    <div class="flex region-wrap">
      <div class="region-txt">
        {{ "DeliveryRegion" | translate }}:
      </div>
      <div class="select-region-wrap flex">
        <img src="assets/images/main/map-marker.svg" class="map-marker-icon" alt="">
        <select [(ngModel)]="searchService.regionId" class="select-region">
          <option [value]="0">
            {{ "Kazakhstan" | translate }}
          </option>
          <option *ngFor="let item of regions; index as i" [value]="item.id">
            {{ translate.currentLang == 'ru' ? item.region_name_ru : item.region_name_kz }}
          </option>
        </select>
      </div>
    </div>

    <div class="sell-btn-wrap">
      <button (click)="startedSale()" class="sell-btn" pButton type="button">
        <i class="pi pi-plus sell-icon"></i>
        {{ "StartSelling" | translate }}
      </button>
    </div>
  </div>
  <div class="accordion-wrap">
    <p-accordion>
      <p-accordionTab class="flex" [(selected)]="activeState[0]">
        <div class="catalog-menu">
          <p-tabView (onChange)="getCatalogItems($event)">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div>{{ "Goods" | translate }}</div>
              </ng-template>
              <div *ngFor="let item of catalogItems[0]; index as i;let isFirst = first">
                <div (click)="getSubCategory(item.id, i, 0)" class="catalog-category align-items-center" [ngClass]="{'active-category': catalogItems[0][i].activeTab == true}">
                  <div>
                    <span class="mr-1">{{ translate.currentLang == 'ru' ? item.name_ru : item.name_kz }}</span>
                  </div>
                  <img [ngClass]="{'hidden': isFirst}" src="/assets/images/main/catalog-arrow.svg" alt="">
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div>{{"Services" | translate}}</div>
              </ng-template>
              <div *ngFor="let item of catalogItems[1]; index as i;let isFirst = first">
                <div (click)="getSubCategory(item.id, i, 1)" class="catalog-category align-items-center" [ngClass]="{'active-category': catalogItems[1][i].activeTab == true}">
                  <div>
                    <span class="mr-1">{{ translate.currentLang == 'ru' ? item.name_ru : item.name_kz }}</span>
                  </div>
                  <img [ngClass]="{'hidden': isFirst}" src="/assets/images/main/catalog-arrow.svg" alt="">
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div>{{"DigitalGoods" | translate}}</div>
              </ng-template>
              <div *ngFor="let item of catalogItems[2]; index as i;let isFirst = first">
                <div (click)="getSubCategory(item.id, i, 2);" class="catalog-category align-items-center" [ngClass]="{'active-category': catalogItems[2][i].activeTab == true}">
                  <div>
                    <span class="mr-1">{{ translate.currentLang == 'ru' ? item.name_ru : item.name_kz }}</span>
                  </div>
                  <img [ngClass]="{'hidden': isFirst}" src="/assets/images/main/catalog-arrow.svg" alt="">
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
        <div class="sub-category" *ngIf="subCategoryList.length != 0">
          <div class="sub-category-item" *ngFor="let item of subCategoryList.childList;  index as i;">
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>

<app-start-sale *ngIf="startSale" (newItemEvent)="close()" [startSale]="startSale" ></app-start-sale>
