import {Component, HostListener, ViewChild, OnInit} from '@angular/core';
import {SearchService} from "../../../services/search/search.service";
import {AuthService} from "../../../services/auth/auth.service";
import {Paginator} from "primeng/paginator";
import {TranslateService} from "@ngx-translate/core";
import {MainService} from "../../../services/main/main.service";
import {Router} from "@angular/router";
import {HeaderService} from "../../../services/header/header.service";

@Component({
  selector: 'app-new-main-page',
  templateUrl: './new-main-page.component.html',
  styleUrls: ['./new-main-page.component.sass']
})
export class NewMainPageComponent implements OnInit{

  constructor(public searchService: SearchService, private router: Router,
              public headerService: HeaderService,
              private mainService: MainService,
              private route: Router,
              public translate: TranslateService,
              private authService: AuthService) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.getInfo();
    this.searchService.pageEvent.subscribe(event => {
      // Обработка события
      this.setFirstPage(event);
    });
  }

  goToCategory(item: any, level: any, index: any, column: any, array: any, type: any, security: any = false){
    if(type == 1){
      this.headerService._typeIndex = 0;
      this.headerService._catalog[0].active = true;
    }else{
      this.headerService._typeIndex = 1;
      this.headerService._catalog[1].active = true;
    }

    if(security){
      this.headerService._catalog[0].parentlist[1].active = true;
      this.headerService._catalog[0].parentlist[1].child_categories[4].active = true;
      this.headerService._catalogPage = 3;
      this.headerService._parentIndex = 1;
    }

    this.setActiveItem(array, index, column);

    this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex]?.child_categories.forEach((element: any) => {
      element.active = false;
    });
    item.active = true;
    if(level == 2){
      this.headerService._catalogPage = 1;
    }else if(level == 3){
      this.headerService._catalogPage = 2;
    }
    this.headerService.changeCatalogItem(level, item, index);
    this.searchService.categoryId = item.id;
    this.route.navigate(['search']);
  }

  setActiveItem(array: any, index: any, column: any){
      if(column == 1){
        this.headerService._typeIndex = index;
        this.headerService._parentIndex = 0;
        if(index != 0){
          this.headerService._catalog[1].parentlist.forEach((element: any) => {
            element.active = false;
          });
          this.headerService._catalog[1].parentlist[0].active = true;
        }else{
          this.headerService._catalog[0].parentlist.forEach((element: any) => {
            element.active = false;
          });
          this.headerService._catalog[0].parentlist[0].active = true;
        }

      }
      if(column == 2){
        this.headerService._parentIndex = index;
      }

      array.forEach((element: any) => {
        element.active = false;
      });
      array[index].active = true;
  }


  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      this.searchGoods();
      this.searchServices();
    })
  }


  allCardsData: any;

  loading = false;

  @ViewChild('swiper', { static: false }) swiper?: any;

  config: any = {
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: false },
    scrollbar: { draggable: false },
    slidesPerView: '1',
    loop: false,
    autoplay: false,
    draggable: false

  };

  @ViewChild(Paginator, {static: true}) paginator!: Paginator;

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (this.isNearBottom()) {
      // Вызывайте ваш метод или обработчик события при приближении к концу страницы
      this.loadMoreData();
    }
  }

  variation = 1;

  isNearBottom(): boolean {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0;

    // Порог, близкий к концу страницы, когда считается, что пользователь достиг конца
    const threshold = 200;

    return windowHeight + scrollPosition >= documentHeight - threshold;
  }

  loadMoreData(): void {
    // Здесь вы можете загрузить дополнительные данные или выполнить другие действия
    if(this.searchService._cards.totalPages > this.searchService._pageNumber){
      this.searchService.scrollNextPage();
    }
  }

  pageNumber = 0;

  goods: any = [];
  services: any = [];


  ngOnInit() {

  }

  favorite(itemIndex: any) {
    this.allCardsData = this.searchService._cards;
    if (this.allCardsData.list.content[itemIndex].favorite == true) {
      this.removeFavorite(this.allCardsData.list.content[itemIndex].id, itemIndex);
    } else {
      this.setFavorite(this.allCardsData.list.content[itemIndex].id, itemIndex);
    }
  }

  goToGoods(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-vse-tovary');
    this.searchService.categoryType = 'GOOD';
    this.headerService._catalogPage = 1;
    this.router.navigate(['/search']);
  }

  goToServices(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-vse-uslugy');
    this.searchService.categoryType = 'SERVICE';
    this.headerService._catalog[0].active = false;
    this.headerService._catalog[1].active = true;
    this.headerService._typeIndex = 1;
    this.headerService._catalogPage = 1;

    this.router.navigate(['/search']);
  }

  setFavorite(itemId: any, itemIndex: any) {
    this.mainService.postFavorite(itemId).subscribe({
      next: () => {
        this.allCardsData.list.content[itemIndex].favorite = !this.allCardsData?.list.content[itemIndex].favorite;
        // @ts-ignore
        window['yaCounter48820577'].reachGoal('obshee-kolichestvo-dobavleniy-tovarov-v-izbrannoe');
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  removeFavorite(itemId: any, itemIndex: any) {
    this.mainService.deleteFavorite(itemId).subscribe({
      next: () => {
        this.allCardsData.list.content[itemIndex].favorite = !this.allCardsData?.list.content[itemIndex].favorite;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  isAuth = false;

  type = 1;

  setFirstPage(event: any) {
    this.paginator.changePageToFirst(new Event('0'));
  }

  searchGoods(){

    this.mainService.getAllCardsWithParams('','','', 'GOOD', '','','',this.pageNumber, 5, '',  '')
      .subscribe({
        next: (res: any) => {
          this.goods = res.list.content;
          if(this.isAuth){
            this.headerService.checkFavItem(res.list.content);
          }
        }
      });
  }

  findPartner(){
    // @ts-ignore
    window['yaCounter48820577'].reachGoal('partner-naity-partnerov');
  }

  searchServices(){
    this.mainService.getAllCardsWithParams('','','', 'SERVICE', '','','',this.pageNumber, 5, '',  '')
      .subscribe({
        next: (res: any) => {
          this.services = res.list.content;
          if(this.isAuth){
            this.headerService.checkFavItem(res.list.content);
          }
        }
      });
  }

  openAuth() {
    if(this.isAuth) {
      this.route.navigate(['/account/yandex']);
    } else {
      this.headerService.openAuthEmitter.emit('/account/yandex')
    }
  }
}
