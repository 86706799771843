import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortname'
})
export class ShortnamePipe implements PipeTransform {

  constructor() { }

  transform(name: any): string {
    return this.getShortName(name);
  }

  getShortName(name: string): string {
    if(!name){
      return name;
    }
    name = this.insensitiveRelpace(name,'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСВЕННОСТЬЮ', 'ТОО');
    name = this.insensitiveRelpace(name,'SELF-EMPLOYED', 'ИП');
    name = this.insensitiveRelpace(name,'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ТОО');
    name = this.insensitiveRelpace(name,'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕСТВЕННОСТЬЮ', 'ТОО');
    name = this.insensitiveRelpace(name,'ТОВАРИЩЕСТВА С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ТОО');
    name = this.insensitiveRelpace(name,'Товарищество с ограниченной ответственностью', 'ТОО');
    name = this.insensitiveRelpace(name,'АКЦИОНЕРНОЕ ОБЩЕСТВО', 'АО');
    name = this.insensitiveRelpace(name,'ДИРЕКЦИЯ ИНФОРМАЦИОННЫХ СИСТЕМ - ФИЛИАЛ АКЦИОНЕРНОГО ОБЩЕСТВА', 'ДИС ФИЛИАЛ АО');
    name = this.insensitiveRelpace(name,'НАЦИОНАЛЬНАЯ ПАЛАТА ПРЕДПРИНИМАТЕЛЕЙ РЕСПУБЛИКИ КАЗАХСТАН', 'НПП РК');
    name = this.insensitiveRelpace(name,'ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ', 'ИП');
    name = this.insensitiveRelpace(name,'ЖАУАПКЕРШІЛІГІ ШЕКТЕУЛІ СЕРІКТЕСТІГІ', 'ЖШС');
    name = this.insensitiveRelpace(name,'Индивидуальный предприниматель', 'ИП');
    while (name.indexOf('\\"') != -1){
      name = name.replace('\\"', '"');
    }
    return name;
  }

  insensitiveRelpace(str: string, strReplace: string, strWith: string): string{
    let esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    let reg = new RegExp(esc, 'ig');
    return str.replace(reg, strWith);
  }
}
