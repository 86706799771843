import {Component, OnInit} from '@angular/core';

declare let jivo_api: any;

@Component({
  selector: 'app-universal-callback-button',
  templateUrl: './universal-callback-button.component.html',
  styleUrls: ['./universal-callback-button.component.sass']
})

export class UniversalCallbackButtonComponent implements OnInit {
  open: boolean = false;
  isMobile:  boolean = false;

  constructor() {}

  ngOnInit() {
    this.isMobile = this.detectMob();
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      if (typeof window !== 'undefined') {
        return navigator.userAgent.match(toMatchItem);
      }
      return false;
    });
  }

  openUniversalCallback() {
    this.open = true;
  }

  openWhatsApp() {
    this.open = false;
    window.location.href = 'https://wa.me/77070000160';
  }

  openTelegramBot() {
    this.open = false;
    window.location.href = 'https://t.me/ismetkzbot'
  }

  openJivo() {
    this.open = false;
    try {
      jivo_api.open();
    } catch {}
  }
}
