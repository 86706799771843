import {NgModule} from '@angular/core';
import {AuthDialogComponent} from './auth-dialog.component';
import {DialogModule} from 'primeng/dialog';
import {InputMaskModule} from 'primeng/inputmask';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PasswordModule} from 'primeng/password';
import {InputTextModule} from 'primeng/inputtext';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {AutoFocusModule} from 'primeng/autofocus';
import {NgOtpInputModule} from 'ng-otp-input';
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {AutofocusModule} from "../../../common/autofocus/autofocus.module";


@NgModule({
  declarations: [AuthDialogComponent],
  exports: [
    AuthDialogComponent
  ],
  imports: [
    DialogModule,
    InputMaskModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    PasswordModule,
    InputTextModule,
    NgClass,
    InlineSVGModule,
    FormsModule,
    AutoFocusModule,
    NgOtpInputModule,
    AutofocusModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ProgressSpinnerModule,
    NgTemplateOutlet

  ]
})
export class AuthDialogModule {
}
