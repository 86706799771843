import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit{
  year: number = new Date().getFullYear()
  displayModal: boolean = false;
  linkApp: string = '';
  isMobile: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.isMobile = this.detectMob();
  }

  showModalDialog(id: number) {
    this.displayModal = true;
    this.linkApp = id == 0 ? 'https://play.google.com/store/apps/details?id=kz.ismet.kt' : 'https://apps.apple.com/kz/app/ismet-app/id6478113420'
  }

  goToInstall() {
    window.location.href = this.linkApp
  }

  detectMob() {
    const toMatch = [
      /Android/i, /webOS/i, /iPhone/i, /iPad/i,
      /iPod/i, /BlackBerry/i, /Windows Phone/i,
      /ismet-mobile/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
