<div class="eds-block">
  <div class="eds-info" *ngIf="!notStarted">
    <div class="info-block"><div class="info-block_ico" inlineSVG="assets/images/signing/info.svg"></div>{{'Подходит для всех типов компаний' | translate}}</div>
  </div>
  <div *ngIf="errorCompanyInfo" class="error-company-info">
    {{'Информация о компании не найдена. Попробуйте позже' | translate}}
  </div>
  <ng-container *ngIf="!notStarted && !spinner">
    <button class="btn btn-md primary-btn-color eds-button" (click)="signXml()" [disabled]="opened"><p-progressSpinner *ngIf="loader"></p-progressSpinner>{{(loader ? 'Загрузка' : 'Выбрать сертфикат') | translate}}</button>
  </ng-container>
  <ng-container *ngIf="notStarted">
    <div class="eds-info">
      <div inlineSVG="assets/images/signing/error-circle.svg"></div>
      <div class="error-title">{{'Ошибка при подключении к NCALayer' | translate}}</div>
      <div class="error-text" [innerHTML]="'Проверьте, что программа NCALayer запущена и настроена, после этого попробуйте снова. Если ошибка сохраняется ознакомьтесь с руководством пользователя NCALayer' |  translate : {link: locale == 'ru' ? 'https://pki.gov.kz/docs/nl_ru/' : 'https://pki.gov.kz/docs/nl_kaz/'}"></div>
    </div>
    <button class="btn btn-md primary-btn-color eds-button" (click)="signXml()">{{'Попробовать снова' | translate}}</button>
  </ng-container>
  <div *ngIf="spinner" class="progress-block">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
