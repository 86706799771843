<div class="top-block">
  <div class="flex fav-wrap w-fit">
    <div [routerLink]="['/']" class="left-arrow mobile-hidden cursor-pointer"></div>
    <div class="fav-txt">{{ 'Favorite' | translate }}</div>
    <div class="goods-txt mobile-hidden">{{ favoriteItemsList?.totalElements | suffix: ('OneGood'| translate): ('SomeGoods'| translate): ('ManyGoods' | translate) }}</div>
  </div>
</div>
<div class="flex mobile-hidden fav-item" *ngFor="let item of favoriteItemsList?.content, index as i">
  <div [routerLink]="['/item/' + item.id]" class="fav-img">
<!--    <img [src]="fillerUrl(item)" alt="">-->
    <div class="card-image" [routerLink]="['/item/' + item.id]" [ngStyle]="{'background-image': genInp(item)}"></div>
  </div>
  <div class="fav-body">
    <div class="fav-body-item">
      <div class="fav-body-item-header">
        {{ 'Good' | translate }}
      </div>
      <div [routerLink]="['/item/' + item.id]" class="item-name">
        {{ translate.currentLang == 'ru' ? item.name_ru : item.name_kz }}
      </div>
    </div>
    <div class="fav-body-item">
      <div class="fav-body-item-header">
        {{ 'Price' | translate }}
      </div>
      <div class="price">
        {{ 'CardPrice' | translate : { price: addSpacesToNumberString(''+ item.price), ru: (translate.currentLang == 'ru' && !item.is_fix_price) ? 'От' : '', kz: (translate.currentLang == 'kz' && !item.is_fix_price) ? 'бастап' : ''} }}
      </div>
      <div class="flex align-items-center">
        <p-rating [(ngModel)]="item.rating" [stars]="5" [readonly]="true">
          <ng-template class="star-fill" pTemplate="onicon">
            <img src="/assets/images/main/star-filled-icon.svg" width="12px" height="12px" />
          </ng-template>
          <ng-template pTemplate="officon">
            <img class="star" src="/assets/images/main/star-icon.svg" width="12px" height="12px" />
          </ng-template>
        </p-rating>
        <div class="feedback-count">
          {{ item.feedback.all | suffix: ('OneReview' | translate): ('SomeReviews' | translate): ('ManyReviews' | translate)}}
        </div>
      </div>

    </div>

    <div class="fav-body-item">
      <div class="fav-body-item-header"> {{ 'Company' | translate }} </div>
      <div [routerLink]="['/company/' + item.company_bin]" class="item-name">
        {{(translate.currentLang == 'ru' ? item?.company_name : item?.company_name_kz) | shortname}}
      </div>
    </div>
    <div class="fav-body-item my-auto">
      <button (click)="order(item)" class="order-btn">{{ 'Order' | translate }}</button>
    </div>
  </div>
  <div (click)="removeFavorite(item.id, i)" class="cross-icon">
    <img src="assets/images/main/cross-icon.svg" alt="">
  </div>
</div>

<div class="desktop-hidden">
  <div class="goods-wrap">
    <div class="goods-items-wrap">

      <div class="good-item relative" *ngFor="let item of favoriteItemsList?.content; index as i">
        <a [href]="['/item/' + item['id']]" target="_self">
          <div class="img-wrap">
            <div *ngIf="item['images']?.length > 0" class="card-image"
                 [ngStyle]="{'background-image': genInp(item)}"></div>
            <div *ngIf="item['images']?.length == 0" class="card-image"
                 style="background-image: url('/assets/images/main/card-fill.svg')"></div>
          </div>
          <div (click)="removeFavorite(item.id, i)" [ngClass]="{'favorite-icon-filled': item['favorite']}" class="favorite-icon"></div>
          <div class="price">
            {{ 'CardPrice' | translate : {price: addSpacesToNumberString('' + item['price']), ru: (translate.currentLang == 'ru' && !item['is_fix_price']) ? 'От' : '', kz: (translate.currentLang == 'kz' && !item['is_fix_price']) ? 'бастап' : ''} }}
          </div>
          <div [title]="translate.currentLang == 'ru' ? item['name_ru'] : item['name_kz']"
               class="item-title">
            {{translate.currentLang == 'ru' ? item['name_ru'] : item['name_kz']}}
          </div>
          <a [href]="['/company/' + item['company_bin']]">
            <div [title]="(translate.currentLang == 'ru' ? item['company_name'] : item['company_name_kz']) | shortname"
                 class="company-name">
              {{(translate.currentLang == 'ru' ? item['company_name'] : item['company_name_kz']) | shortname}}
            </div>
          </a>
          <div class="flex rating-wrap">
            <div>
              <img *ngIf="item['rating'] > 0" src="/assets/images/main/star-filled-icon.svg" width="12px" height="12px"/>
              <img *ngIf="item['rating'] == 0" class="star" src="/assets/images/main/star-icon.svg" width="12px" height="12px"/>
            </div>
            <div class="feedback-count flex">
              <span *ngIf="item['rating'] > 0">{{ item['rating'] }}</span>
              <div class="feedback-count-txt" *ngIf="item['rating'] > 0">
                {{ item['feedback'].all | suffix: ('OneReview' | translate): ('SomeReviews' | translate): ('ManyReviews' | translate)}}
              </div>
              <div class="" *ngIf="item['rating'] == 0">
                {{'NoRating' | translate}}
              </div>
            </div>
          </div>
        </a>

      </div>
    </div>
  </div>

</div>

<div *ngIf="favoriteItemsList?.totalElements > 5" class="flex align-items-center mobile-hidden justify-content-center fav-paginator">
  <div class="flex-1">
    <p-paginator (onPageChange)="changePage($event)" [rows]="5" [totalRecords]="favoriteItemsList?.totalElements" [showFirstLastIcon]="false" ></p-paginator>
  </div>
</div>

<div [ngClass]="{'banner-margin': favoriteItemsList?.totalElements <= 5}" class="banner mobile-hidden relative">
  <div class="">
    <div class="banner-txt">
      {{ 'ISMETBanner' | translate }}
    </div>
  </div>

  <img class="banner-img" src="assets/images/main/phone.svg" alt="">
</div>

<app-callback *ngIf="callback" (newItemEvent)="close()" [currentItem]="currentFav" [callback]="callback"></app-callback>

<app-start-sale *ngIf="startSale" (newItemEvent)="closeSale()" [startSale]="startSale" ></app-start-sale>
